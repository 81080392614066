import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress,Container } from 'reactstrap'
import AdministrationService from "../../services/AdministrationService"
import { RestaurantDropDown } from "../Controls/RestaurantDropDown"
import { RoleDropDown } from "../Controls/RoleDropDown"
import { IconContext } from "react-icons";
import { IoIosArrowDropupCircle, IoIosCloseCircle } from "react-icons/io";
import { Navigate } from 'react-router-dom'
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged"
import {Users } from "./Users"
import { useParams } from 'react-router-dom'


export function AddUser(props) {

    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }
    let { uId } = useParams();


    const [userDetails, addUserDetails] = useState({ userId:0,loginId: '', firstName: '', lastName: '', emailAddress: '', password: '',mobileNo:'', confirmPassword: '', restaurantId: [], roleId: 0, createdBy: logged.UserId })
    const [InProgress, setInProgress] = useState(false);
    const administrationService = new AdministrationService
    const [selectedRestaurantId, updateRestaurantId] = useState(0)
    const [selectedRole, updateSelectedRole] = useState(null)
    const [restaurants, updateRestaurants] = useState([]);
    const [close, setClose] = useState(false);
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    useEffect(() => {
        getRestaurants();
        if (props.mode == 'u') {

            getUserById(parseInt(uId));
        }
    }, [])

    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const handleSubmit = (evt) => {
        if (props.mode == 'u')
            updateUser();
        else
        addUser();
    }
    const getRestaurants = async () => {
        let result = await administrationService.GetRestaurants();
        if (!result.redirect) {

            updateRestaurants(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectedRole = (item) => {
        updateSelectedRole(item);
        addUserDetails({ ...userDetails, roleId: item.value })
    }

    const onRestaurantSelection = (event) => {
      
        if (event.target.checked) {
            let cloneRes = [...userDetails.restaurantId]
            cloneRes.push(parseInt(event.target.value))
            addUserDetails({ ...userDetails, restaurantId: cloneRes });

        }
        else {
            let cRestaturant = [...userDetails.restaurantId]
            for (let i = 0; i < cRestaturant.length; i++) {
                if (cRestaturant[i] === parseInt(event.target.value) ){
                    cRestaturant.splice(i, 1);
                    addUserDetails({ ...userDetails, restaurantId: cRestaturant });
                    break;
                }
            }
        }
    }

    const addUser = async () => {
        let initialValue = { loginId: '', firstName: '', lastName: '', emailAddress: '', password: '', confirmPassword: '', mobileNo:'', restaurantId: [], roleId: 0, createdBy: logged.UserId }
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            // setInProgress(true);
            let result = await administrationService.AddUser(userDetails.loginId, userDetails.password, userDetails.firstName, userDetails.lastName, userDetails.emailAddress, userDetails.restaurantId, userDetails.roleId, userDetails.createdBy, userDetails.mobileNo);
            if (!result.redirect) {
                toast.success('User has been successfully added')
                closeMessageModal();
                addUserDetails({ ...userDetails, ...initialValue })
                setClose(true)
            }
            else {
                toast.error("Error occured while adding user");
                closeMessageModal();

            }
        }
    }

    const updateUser = async () => {
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            let result = await administrationService.UpdateUser(userDetails.userId, userDetails.loginId, userDetails.password, userDetails.firstName, userDetails.lastName, userDetails.emailAddress, userDetails.restaurantId, userDetails.roleId, logged.UserId, userDetails.mobileNo);
            if (!result.redirect) {
                toast.success('User details has been successfully updated')
                closeMessageModal();
                setClose(true);
                //addUserDetails({ ...userDetails, ...initialValue })
            }
            else {
                toast.error("Error occured while updating user details");
                closeMessageModal();

            }
        }
    }

    const validateForm = () => {
        if (userDetails.firstName == '') {
            toast.error('Please enter the first name of the user', alertOptions)
            return false;
        }
        else if (userDetails.password == '' && props.mode != 'u') {
            toast.error('Please enter the password', alertOptions)
            return false;
        }
        else if (userDetails.confirmPassword == '' && props.mode != 'u') {
            toast.error('Please enter the confirm password', alertOptions)
            return false;
        }
        else if (userDetails.confirmPassword !== userDetails.password) {
            toast.error('Password and Confirm password does not match', alertOptions)
            return false;
        }
        else if (userDetails.roleId == 0) {
            toast.error('Please select a role', alertOptions)
            return false;
        }
        else if (userDetails.restaurantId.length == 0) {
            toast.error('Please choose atleast one restaurant', alertOptions)
            return false;
        }




        return true;
    }


    const getUserById = async (userId) => {
        setMessage('LOADING IN PROGRESS');
        openMessageModal();

        let result = await administrationService.GetUserById(userId);
        if (!result.redirect) {
            if (result.data.status) {
                addUserDetails(result.data.data);
                closeMessageModal();

                //updateBookingSource({ label: '', value: result.data.data.booking.source })
            }
            else
                toast.error('Failure in loading user details');
        }
        else {
            toast.error('Error occured in fetching user details');
        }
    }

    const onMobileNoChange = (e) => {
        if (!isNaN(Number(e.target.value))) {
            addUserDetails({ ...userDetails, mobileNo: e.target.value });
        }
    }


    return (
        close ? (props.mode == 'u' ? <Navigate to='/administration/users' /> :< Users />) : (<Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>
            <Row style={{ }}>
                <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> Add Users </Col>
                <Col sm='1' style={{ textAlign: 'right' }}><Button style={{ backgroundColor: 'white', border: 0 }} onClick={() => setClose(true)}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        First Name{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={userDetails.firstName}
                        onChange={e => addUserDetails({ ...userDetails, firstName: e.target.value })}
                    />
                </Col>
                <Col sm="2" >
                    <label>
                        Last Name{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={userDetails.lastName}
                        onChange={e => addUserDetails({ ...userDetails, lastName: e.target.value })}
                    />
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>
             

                <Col sm="2" >
                    <label>
                        Email Address {` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={userDetails.emailAddress}
                        onChange={e => addUserDetails({ ...userDetails, emailAddress: e.target.value })}
                    />
                </Col>
                <Col sm="2" >
                    <label >
                        Mobile No {` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        readOnly
                        type="text"
                        value={'+91'}
                        className="bookingInput"
                        style={{ width: '35px', backgroundColor: '#9e9e9e30' }}
                    />

                    <input
                        maxLength="10"
                        type="text"
                        className="bookingInput"
                        value={userDetails.mobileNo}
                        onChange={onMobileNoChange}
                        style={{ width: 'auto' }}


                    />
                </Col>
            </Row>
            <hr></hr>
            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        Login Id {` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={userDetails.loginId}
                        onChange={e => addUserDetails({ ...userDetails, loginId: e.target.value })}
                        disabled={props.mode=='u'?true:false}
                    />
                </Col>

             
            </Row>
            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        Password{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="password"
                        style={{ width: '100%' }}
                        value={userDetails.password}
                        onChange={e => addUserDetails({ ...userDetails, password: e.target.value })}
                    />
                </Col>
                <Col sm="2" >
                    <label>
                        Confirm Password{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="password"
                        style={{ width: '100%' }}
                        value={userDetails.confirmPassword}
                        onChange={e => addUserDetails({ ...userDetails, confirmPassword: e.target.value })}
                    />
                </Col>

            </Row>
            <hr></hr>
            <Row style={{ paddingTop: '25px',paddingBottom:'15px' }}>
                {/*<Col sm="2" >*/}
                {/*    <label>*/}
                {/*        Restaturant:*/}

                {/*    </label>*/}

                {/*</Col>*/}
                {/*<Col sm="4">*/}

                {/*    <input*/}
                {/*        type="text"*/}
                {/*        style={{ width: '100%' }}*/}
                {/*        value={userDetails.restaurantId}*/}
                {/*        onChange={e => addUserDetails({ ...userDetails, restaurantId: e.target.value })}*/}
                {/*    />*/}

                {/*    <RestaurantDropDown selectedRestaurantId={updateRestaurantId} />*/}

                {/*</Col>*/}
                <Col sm="2" >
                    <label>
                        Role:

                    </label>

                </Col>
                <Col sm="4">

                    <RoleDropDown selectedRole={onSelectedRole} Role={userDetails.roleId !== 0 ? userDetails.roleId : null} />


                    {/*<input*/}
                    {/*    type="text"*/}
                    {/*    style={{ width: '100%' }}*/}
                    {/*    value={userDetails.roleId}*/}
                    {/*    onChange={e => addUserDetails({ ...userDetails, roleId: e.target.value })}*/}
                    {/*/>*/}

                </Col>
            </Row>
            <Row style={{paddingTop:'10px',borderStyle:'double',paddingBottom:'10px',borderColor:'antiqueWhite'} }>Restaurants :<br/><Col sm="12">

                {restaurants.map((r, i) => (
                    <span key={i} style={{ paddingRight:'15px',color:'royalblue' } }>                   <input key={r.value}
                        type="checkbox"
                        value={r.value}
                        onChange={onRestaurantSelection}
                        checked={userDetails.restaurantId.find(o => o === r.value) == null?false:true}
                    />{` `}{r.label} </span>))}

            </Col></Row>
            <Row style={{paddingTop:'15px'} }>
                <Col sm="12" style={{textAlign:'center'} }>  <Button color="primary" size="sm" onClick={handleSubmit} disabled={InProgress} >Save{""}</Button></Col>
            </Row>

            {InProgress ? (<div>
                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" />
                    <Col sm="7" >
                        <h3> SAVING IN PROGRESS...</h3>

                    </Col>
                </Row>
                <Row>
                    <Col sm="2" />
                    <Col sm="7" >
                        <Progress animated color="info" value="100" />
                    </Col>
                </Row></div>) : (null)
            }


            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>)
    );
}