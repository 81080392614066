import React, { useState } from "react";
import { IndentStatusId } from '../../lib/IndentStatusId';
import { Roles } from '../../lib/Roles';
import { logged } from "../../lib/logged"

export function ItemQuantityRenderer(props) {

    const [itemQuantity, setItemQuantity] = useState(props.data.quantity);

    return (

        props.context.orderStatus == IndentStatusId.Approved || (props.data.price == 'Total Amount') || (logged.Roles[0].roleId == Roles.ExecutiveChef && props.context.orderStatus == IndentStatusId.L2Approval) || (logged.Roles[0].roleId == Roles.SousChef && props.context.orderStatus == IndentStatusId.L1Approval) ? <span>{props.data.quantity}</span>: <input
            type="text"
            value={props.data.quantity}
            style={{ height: '19px', width: '50px',borderColor:'black',borderWidth:'thin' }}
            onChange={e => {
                setItemQuantity(e.target.value);
                props.context.updateItemQuantity(e.target.value, props.data.listId);
            }}
        /> 
    )
}