import { Row, Col, Button, Progress, Container } from 'reactstrap'
import { IoRemoveCircle } from "react-icons/io5";
import { IconContext } from "react-icons";
import React, { useState } from "react";
import { logged } from "../../lib/logged"
import { Roles } from '../../lib/Roles';
import { IndentStatus } from '../../lib/IndentStatus';
import { IndentStatusId } from '../../lib/IndentStatusId';

export function RemoveOrderItemRenderer(props) {

    const onRemove = () => {
        props.context.removeOrderItem(props.data);
    }

    return (

        props.context.orderStatus == IndentStatusId.Approved || (props.data.price == 'Total Amount') || (logged.Roles[0].roleId == Roles.SousChef && props.context.orderStatus != IndentStatusId.Draft) || (logged.Roles[0].roleId == Roles.ExecutiveChef && props.context.orderStatus == IndentStatusId.L2Approval) ? null : <Button onClick={onRemove} color="danger" size="sm" style={{ backgroundColor: '#cddc3900', border: 0, textAlign: 'left' }}  >

            <IconContext.Provider value={{ size: '22px', color: 'red' }}> < IoRemoveCircle /></IconContext.Provider>

        </Button >
            




    )
}