import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container } from 'reactstrap';
import AdministrationService from "../../services/AdministrationService";
import { IconContext } from "react-icons";
import { IoIosArrowDropupCircle, IoIosCloseCircle } from "react-icons/io";
import { Navigate } from 'react-router-dom';
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged";
import { Ocassion } from "./Ocassion";
import { useParams } from 'react-router-dom'


export function AddOcassion(props) {

    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }
    let { pId } = useParams();


    const [ocassionDetails, addOcassionDetails] = useState({ ocassionId: 0, ocassionName: '', isActive: '', createdBy: logged.UserId, modifiedBy: 0, createdDate: 0, modifiedDate: 0 })
    const [InProgress, setInProgress] = useState(false);
    const administrationService = new AdministrationService
    const [close, setClose] = useState(false);
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    /* useEffect(() => {
        if (props.mode === 'u') {

            getOcassionById(parseInt(pId));
        }
    }, []) */

    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const handleSubmit = (evt) => {
        /*  if (props.mode == 'u')
              updateOcassion();
          else */
        addOcassion();
    }

    const addOcassion = async () => {
        let initialValue = { ocassionName: '', createdBy: 0, modifiedBy: 0 }
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            // setInProgress(true);
            let result = await administrationService.AddOcassion(ocassionDetails.ocassionName, ocassionDetails.createdBy, ocassionDetails.modifiedBy);
            if (!result.redirect) {
                toast.success('Occasion has been successfully added')
                closeMessageModal();
                addOcassionDetails({ ...ocassionDetails, ...initialValue });
                setClose(true);
            }
            else {
                toast.error("Error occured while adding occasion");
                closeMessageModal();

            }
        }
    }

    //TO be used when modify occasion is required!
    /*const updateOcassion = async () => {
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            let result = await administrationService.UpdateOcassion(packageDetails.packageId, packageDetails.packageName, packageDetails.isActive);
            if (!result.redirect) {
                toast.success('Package details has been successfully updated')
                closeMessageModal();
                setClose(true);
                //addPackageDetails({ ...packageDetails, ...initialValue })
            }
            else {
                toast.error("Error occured while updating package details");
                closeMessageModal();

            }
        }
    }*/

    const validateForm = () => {
        if (ocassionDetails.ocassionName === '') {
            toast.error('Please enter the occasion name', alertOptions)
            return false;
        }
        return true;
    }

    // To be used when UpdateOcassion is done(modify ocassion)!
    /* const getOcassionById = async (ocassionId) => {
        setMessage('LOADING IN PROGRESS');
        //TODO update GetOcassionById - AdministrationService.js
        /*openMessageModal();

        let result = await administrationService.GetOcassionById(ocassionId);
        if (!result.redirect) {
            if (result.data.status) {
                addOcassionDetails(result.data.data);
                closeMessageModal();

                //updateBookingSource({ label: '', value: result.data.data.booking.source })
            }
            else
                toast.error('Failure in loading user details');
        }
        else {
            toast.error('Error occured in fetching user details');
        }  
    } */


    return (
        close ? (props.mode == 'u' ? <Navigate to='/administration/ocassion' /> : < Ocassion />) : (<Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>
            <Row style={{}}>
                <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> Add Occasion </Col>
                <Col sm='1' style={{ textAlign: 'right' }}><Button style={{ backgroundColor: 'white', border: 0 }} onClick={() => setClose(true)}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        Occasion Name{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={ocassionDetails.ocassionName}
                        onChange={e => addOcassionDetails({ ...ocassionDetails, ocassionName: e.target.value })}
                    />
                </Col>

            </Row>


            <Row style={{ paddingTop: '15px' }}>
                <Col sm="12" style={{ textAlign: 'center' }}>  <Button color="primary" size="sm" onClick={handleSubmit} disabled={InProgress} >Save{""}</Button></Col>
            </Row>

            {InProgress ? (<div>
                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" />
                    <Col sm="7" >
                        <h3> SAVING IN PROGRESS...</h3>

                    </Col>
                </Row>
                <Row>
                    <Col sm="2" />
                    <Col sm="7" >
                        <Progress animated color="info" value="100" />
                    </Col>
                </Row></div>) : (null)
            }


            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>)
    );
}