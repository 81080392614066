import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Progress, Container, Collapse,Card, CardHeader, CardBody } from 'reactstrap'
import BookingService from "../../services/BookingService"
import { RestaurantDropDown } from "../Controls/RestaurantDropDown"
import { CountryDropDown } from "../Controls/CountryDropDown"
import { StateDropDown } from "../Controls/StateDropDown"
import { BookingSourceDropDown } from "../Controls/BookingSourceDropDown"
import { PackageDropDown } from "../Controls/PackageDropDown"
import { OcassionDropDown } from "../Controls/OcassionDropDown"
import { IdCardTypeDropDown } from "../Controls/IdCardTypeDropDown"
import { TimeDropDown } from "../Controls/TimeDropDown"
import { PaymentModeDropDown } from "../Controls/PaymentModeDropDown"
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { IconContext } from "react-icons";
import { IoIosArrowDropupCircle, IoIosCloseCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import moment from "moment";
import { Navigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react';
import { ToastContainer, toast } from 'react-toastify';
import { RemovePaymentRenderer } from './RemovePaymentRenderer'
import { GenerateQRCode } from './GenerateQRCode'
import Modal from "react-modal";
import { ReservationStatus } from "../../lib/ReservationStatus";
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import "react-datepicker/dist/react-datepicker.css";
import "../../custom.css"



export function AddBooking(props) {

    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }
    let { bId } = useParams();

    useEffect(() => {
        if(props.mode=='n')
            getCustomerAccounts();
        if (props.mode == 'u') {
            
            getBookingById(parseInt(bId));
        }
        
    }, [])

    const checkoutModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow:'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom:'5px'
        },
    };

    const confirmModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    const cancelModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };
    const [cancelModalIsOpen, setCancelModalIsOpen] = React.useState(false);

    const [cancelComments, updateCancelComments] = useState('')

    const openCancelModal = () => {
        setCancelModalIsOpen(true)
    }

    const closeCancelModal = () => {
        setCancelModalIsOpen(false)
    }

    const bookingService = new BookingService();
    const [confirmMessage, setConfirmMessage] = useState('')
    const [confirmBookingStatus,setConfirmBookingStatus]=useState('')
    const [redirectBooking,setRedirectBooking]=useState(false)
    const [bookingDetails, addbookingDetails] = useState({
        customer: { customerId: 0, firstName: '', lastName: '', mobileNo: '', emailAddress: '', address1: '', address2: '', city: '', state: 23, country: 1, pincode: '', idCardType: 0, idCardNo:'' },
        booking: { bookingId:0,noOfPax: 0, adults: '', children:'', source: 0, isPackageRequired: false, ocassion: 0, specialInstruction: '', reservedDate: null, reservedTime: null },
        payments:[],
        package: { packageId: 0, cakeFlavour: '', isCakeEggless: false, cakeWordings: '', bannerWordings: '', comments: '' },
        createdBy: logged.UserId,
        restaurant: props.restaurantId == undefined ? 0 : props.restaurantId,
        reservationStatus:''
    }
    )   
    const [payment, updatePayment] = useState({ paymentId: 0, dummyId: 0, paymentDate: null, paymentMode: null, paymentModeText:'', paymentReference: '', amountPaid: '', paymentBy: '' });
    const [InProgress, setInProgress] = useState(false);
    const [initialLoad, setInitialLoad] = useState(0);
    const [customerAccounts, updateCustomerAccounts] = useState([]);
    const [selectedCustomerAccount, updateSelectedCustomerAccount] = useState({});
    const [bookingStatus, setBookingStatus] = useState(false);
    const [bookingId, setBookingId] = useState(0);
    const [moreData, setMoreData] = useState(false)  
    const [collapse, setCollapse] = useState(1);
    const [columnDefs, setColumnDefs] = useState([
        { cellRenderer:RemovePaymentRenderer,headerName:'',width:40},
        { field: 'paymentModeText', headerName:'Mode',width:90 },
        { field: 'paymentReference', headerName: 'Reference Id', width: 100 },
        { field: 'amountPaid', headerName: 'Amount', width: 90 },
        { field: 'paymentDate', headerName: 'Paid On', width: 90, cellRenderer: (d) => { return moment(d.data.payment).format("DD/MM/YY") } },
        { field: 'paymentBy', headerName: 'Paid By', width: 90 }
    ]);
    const [defaultColDef, setDefaultColDef] = useState( {
        resizable: true,
    })
    const [checkoutModalIsOpen, setCheckoutModalIsOpen] = React.useState(false);
    const [tableNo, updateTableNo] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const [confirmModalIsOpen, setConfirmModalIsOpen] = React.useState(false);
    const [disableIdcard, setDisableIdCard] = useState(bookingDetails.customer.idCardType > 0 ? false : true)

    //const openCheckoutModal = () => {
    //    setCheckoutModalIsOpen(true)
    //}

    const closeCheckoutModal = () => {
        setCheckoutModalIsOpen(false)
    }

    

    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }

    const openConfirmModal = () => {
        setConfirmModalIsOpen(true)
    }

    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false)
    }

    const updateBookingStatus = async (bookingId, status) => {
        if (status == 'X') {
            openCancelModal();
        }
        else {
            setMessage('UPDATE IN PROGRESS');
            openMessageModal();
            let result = await bookingService.UpdateReservationStatus(bookingId, status, tableNo, '', logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();

                    if (status === 'I') {
                        toast('Check-In successfull', alertOptions)
                    }
                    if (status === 'X') {
                        toast('Booking is cancelled', alertOptions)
                    }
                    setRedirectBooking(true);
                }
                else {
                    closeMessageModal();

                    if (status === 'I') {
                        toast.error('Check-In failure')
                    }
                    if (status === 'X') {
                        toast.error('Booking cancellation failed')
                    }
                }
            }
            else {
                closeMessageModal();
                toast('Error occured in check-in or cancellation of the booking', 'error');
            }
        }
    }

    const cancelBooking = async () => {
        setMessage('UPDATE IN PROGRESS');
        openMessageModal();
        let result = await bookingService.UpdateReservationStatus(bookingDetails.booking.bookingId, 'X', tableNo, cancelComments, logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                closeMessageModal();

                  toast('Booking is cancelled', alertOptions)
                
                setRedirectBooking(true);
            }
            else {
                closeMessageModal();

                
                    toast.error('Booking cancellation failed')
                
            }
        }
        else {
            closeMessageModal();
            toast('Error occured in check-in or cancellation of the booking', 'error');
        }
    }

    const toggle=(val) =>{
        setCollapse(collapse===val?0:val);
    }

    const handleSubmit = (status) => {
   
        let isValid = validateForm();
        if (isValid) {

            if (bookingDetails.reservationStatus == '')
                addbooking(status);
            else
                updatebooking(status);
        }
    }

    const onPackageChange = (evt) => {
      addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, isPackageRequired: !bookingDetails.booking.isPackageRequired } })
    }

    const getCustomerAccounts = async () => {
        let result = await bookingService.GetCustomerAccounts(props.restaurantId);
        if (!result.redirect) {
            updateCustomerAccounts(result.data.data);
            setInitialLoad(1);
        }
        else {
            toast.error('Error occured in fetching customer accounts');
        }
    }

    const getBookingById = async (bookingId) => {
        setMessage('LOADING IN PROGRESS');
        openMessageModal();

        let result = await bookingService.GetBookingDetailsById(bookingId);
        if (!result.redirect) {
            if (result.data.status) {
                addbookingDetails(result.data.data);
                
                closeMessageModal();
                if (result.data.data.customer.idCardType > 0) {
                    setDisableIdCard(false);
                }
                //updateBookingSource({ label: '', value: result.data.data.booking.source })
            }
            else
                toast.error('Failure in loading booking details');
            closeMessageModal();
        }
        else {
            toast.error('Error occured in fetching booking details');
            closeMessageModal();
        }
    }

    const loadOptions = (inputValue, callBack) => {
        setTimeout(() => { callBack(filterCustomerAccounts(inputValue)) }, 500);
    }

    const filterCustomerAccounts = (inputValue) => {
        if (inputValue.length >= 3) {
            return customerAccounts.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
        else {
            if (customerAccounts.length > 0) {
                const cloneCustomerAccounts = [...customerAccounts];
                return cloneCustomerAccounts.splice(0, 100);

            }
            else
                return customerAccounts;
        }
    }

    const handleInputChange = (newValue) => {

       
     
        if (newValue !== '' && !isNaN(Number(newValue))) {
            //if (bookingDetails.customer.mobileNo.length <= 10) {
            addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, mobileNo: newValue.substring(0, 10) } })
               
            //}
            return newValue;
        }
    }

    const onCustomerAccountChange = (e) => {
        if (e == null) {
            addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, ...{ customerId: 0, firstName: '', lastName: '', mobileNo: '', emailAddress: '', address1: '', address2: '', city: '', state: 23, country: 1, pincode: '', idCardType: 0, idCardNo: '' } } })

        }
        else {
            //updateSelectedCustomerAccount(e);
            getCustomerDetailsById(e.value, props.restaurantId);
        }

    }

    const addbooking = async (status) => {
        setMessage('BOOKING IN PROGRESS');
        openMessageModal();
        let result = await bookingService.AddBooking(bookingDetails,status);
        if (!result.redirect) {
            if (result.data.status) {
                if (status == ReservationStatus.Confirmed) {
                    toast('Booking has been confirmed');
                    setBookingStatus(true);
                    setBookingId(result.data.data);
                }
                else if (status == ReservationStatus.WaitList) {
                    toast('Booking has been waitlisted');
                    setRedirectBooking(true);
                }
            }
            else {
                if (result.data.data == -1) {
                    setConfirmBookingStatus(status);
                    setConfirmMessage(result.data.message);
                    openConfirmModal();
                }
                else {
                    toast.error(result.data.message);
                }
            }
            closeMessageModal();
        }
        else {
            toast.error("Error Occured");
            closeMessageModal();

        }
    }

    const addConfirmBooking = async () => {
        setMessage('BOOKING IN PROGRESS');
        openMessageModal();
        let result = await bookingService.AddConfirmBooking(bookingDetails, confirmBookingStatus);
        if (!result.redirect) {
            if (result.data.status) {
                if (confirmBookingStatus == ReservationStatus.Confirmed) {
                    toast('Booking has been confirmed');
                    setBookingStatus(true);
                    setBookingId(result.data.data);
                }
                else if (confirmBookingStatus == ReservationStatus.WaitList) {
                    toast('Booking has been waitlisted');
                    setRedirectBooking(true);
                }
            }
            else
                toast.error(result.data.message);
            closeMessageModal();
        }
        else {
            toast.error("Error Occured");
            closeMessageModal();

        }
    }

    const updatebooking = async (status) => {
        setMessage('UPDATE IN PROGRESS');
        openMessageModal();
        //setInProgress(true);
        let result = await bookingService.UpdateBooking(bookingDetails, status);
        if (!result.redirect) {
            if (result.data.status) {
                //toast.success(result.data.message);
                if (status == ReservationStatus.Confirmed) {
                    toast('Booking has been confirmed');

                    setBookingStatus(true);
                    setBookingId(result.data.data);
                }
                else {
                    closeMessageModal();

                    toast('Booking has been updated');
                }

            }
            else {
                closeMessageModal();

                toast.error(result.data.message);
            }
            //setInProgress(false);
        }
        else {
            closeMessageModal();
            toast.error("Error Occured");
            //setInProgress(false);

        }
    }

    const getCustomerDetailsById = async (customerId,restaurantId) => {
        //setInProgress(true);
        let result = await bookingService.GetCustomerDetailsById(customerId,restaurantId);
        if (!result.redirect) {
            if (result.data.status) {
                //toast.success(result.data.message);
                
                addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, ...result.data.data } })
                if (result.data.data.idCardType != null && result.data.data.idCardType > 0) {
                    setDisableIdCard(false)
                }
                else {
                    setDisableIdCard(true)
                }
               
            }
            else
                toast.error(result.data.message);
            //setInProgress(false);
        }
        else {
            toast.error("Error Occured");
           // setInProgress(false);

        }
    }

    const updateNoOfAdults = (e) => {
        
        let childrenCount = 0;
        let pax = 0;
        if (bookingDetails.booking.children == '')
            childrenCount = 0
        else
            childrenCount = bookingDetails.booking.children

        pax = parseInt(e.target.value == '' ? 0 : e.target.value) + parseInt(childrenCount);

        addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, adults: e.target.value, noOfPax: pax } })
 
    }
    
    const updateNoOfChildren = (e) => {
        let adultCount = 0;
        let pax = 0;
        if (bookingDetails.booking.adults == '')
            adultCount = 0
        else
            adultCount = bookingDetails.booking.adults

        pax = parseInt(e.target.value == '' ? 0 : e.target.value) + parseInt(adultCount);

        addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, children: e.target.value, noOfPax: pax } })
       

    }

    const updateSelectedCountry = (val) => {
       
        addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, country: val.value } });
        
    }

    const updateSelectedState= (val) => {
      
        addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, state: val.value } });
        
    }

    const updateSelectedSource = (val) => {
      
        addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, source: val == null ? 0 : val.value } });
    }

    const updateSelectedPackage = (val) => {
      
        addbookingDetails({ ...bookingDetails, package: { ...bookingDetails.package, packageId: val == null ? 0 : val.value } });
    }

    const updateSelectedOcassion = (val) => {
       
        addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, ocassion: val==null?0:val.value } });
    }

    const updateSelectedCardType = (val) => {

        addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, idCardType: val == null ? 0 : val.value } })

        if (val == null) {
            setDisableIdCard(true)
        }
        else {
            if (val.value > 0) {
                setDisableIdCard(false)
            }
            else {
                setDisableIdCard(true)
            }

        }
      
        }

    

    const setReservationDate = (val) => {
        addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, reservedDate: val } });
    }

    const setPaymentDate = (val) => {
        updatePayment({ ...payment, paymentDate: val });
    }

    const updateSelectedTime = (val) => {
       
        addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, reservedTime: val == null ? null : val.value } });
    }

    const updateSelectedPaymentMode = (val) => {
       
        updatePayment({ ...payment, paymentMode: val == null ? 0 : val.value, paymentModeText: val == null ? '' : val.label });
    }  

    const onPaymentAdd = () => {
        let paymentInitialValue = { paymentId: 0, dummyId: 0, paymentDate: null, paymentMode: null, paymentModeText: '', paymentReference: '', amountPaid: '', paymentBy: '' }
        if (validatePayments()) { 
            let paymentsClone = [...bookingDetails.payments]
            let payClone = { ...payment }
            let max = 0;
            if (paymentsClone.length > 0) {
                const ids = paymentsClone.map(object => {
                    return object.dummyId;
                });
                 max = Math.max(...ids);
            }

            payClone.dummyId = max + 1;
            payClone.paymentDate = moment(payClone.paymentDate).format(moment.HTML5_FMT.DATE)
            paymentsClone.push(payClone);
            addbookingDetails({ ...bookingDetails, payments: paymentsClone });
            updatePayment({ ...payment, ...paymentInitialValue })
       
            toast('Payment has been added')
    }
    }

    const onPaymentRemove = (id) => {
        let r_paymentsClone = [...bookingDetails.payments]
        for (let i = 0; i < r_paymentsClone.length; i++) {
            if (r_paymentsClone[i].dummyId === id)
                r_paymentsClone.splice(i, 1);
        }
        addbookingDetails({ ...bookingDetails, payments: r_paymentsClone });
        toast('Payment has been removed')
    }

    const toggleMore=() => {
        setMoreData(!moreData);
    }
   
 const containsOnlyNumbers=(str)=> {
        return /^[0-9]+$/.test(str);
    }


    const validateForm = () => {
        if (bookingDetails.customer.firstName == '') {
            toast.error('Please enter the name of the customer', alertOptions)
            return false;
        }
        else if (bookingDetails.customer.firstName.match("^[a-zA-Z ]*$") == null) {
            toast.error('Only alphabets are allowed in the name', alertOptions)
            return false;
        }
        else if (bookingDetails.customer.mobileNo == null || bookingDetails.customer.mobileNo == '') {
            toast.error('Please enter the mobile no of the customer', alertOptions)
            return false;
        }
        else if (bookingDetails.customer.mobileNo.length!=10) {
            toast.error('Mobile No should be of 10 digits', alertOptions)
            return false;
        }
        else if (bookingDetails.customer.idCardNo != "" && (bookingDetails.customer.idCardType == 0 || bookingDetails.customer.idCardType == null)) {
            toast.error('Please choose the Id card type for the given Id card no', alertOptions)
            return false;
        }
        else if (bookingDetails.customer.idCardType == 3 && !containsOnlyNumbers(bookingDetails.customer.idCardNo)) {
            toast.error('Aadhaar Id can contain only numbers', alertOptions)
            return false;
            }

            else if (bookingDetails.customer.idCardType == 3 && bookingDetails.customer.idCardNo.length != 12) {
                toast.error('Aadhaar Id should be of 12 digits', alertOptions)
                return false;
            }
          
           
       

        
        else if (bookingDetails.booking.reservedDate == null) {
            toast.error('Please choose a reservation date', alertOptions)
            return false;
        }
        else if (bookingDetails.booking.reservedTime == null) {
            toast.error('Please choose a reservation time', alertOptions)
            return false;
        }
        else if (bookingDetails.booking.source == 0) {
            toast.error('Please choose a reservation source', alertOptions)
            return false;
        }
        else if (bookingDetails.booking.noOfPax == 0) {
            toast.error('Please provide number of pax', alertOptions)
            return false;
        }
        else if (bookingDetails.booking.ocassion == 0) {
            toast.error('Please choose an ocassion', alertOptions)
            return false;
        }
        else if (bookingDetails.booking.isPackageRequired) {
            if (bookingDetails.package.packageId == 0) {
                toast.error('Please choose a package', alertOptions)
                return false;
            }
            else if (bookingDetails.package.cakeFlavour === '' && bookingDetails.package.packageId !== 5 && bookingDetails.package.packageId !== 6 && bookingDetails.package.packageId !== 7) {
                toast.error('Please provide the cake flavour', alertOptions)
                return false;
            }

            else if (bookingDetails.package.cakeWordings === '' && bookingDetails.package.packageId !== 5 && bookingDetails.package.packageId !== 6 && bookingDetails.package.packageId !== 7) {
                toast.error('Please provide the cake wordings', alertOptions)
                return false;
            }
            else if (bookingDetails.package.bannerWordings === '' && bookingDetails.package.packageId !== 5 && bookingDetails.package.packageId !== 6 && bookingDetails.package.packageId !== 7) {
                toast.error('Please provide the banner wordings', alertOptions)
                return false;
            }
            
        }

        return true;
    }

    const validatePayments = () => {
        if (payment.paymentDate == null) {
            toast.error('Please provide the payment date', alertOptions)
            return false;
        }
        else if (payment.paymentMode == null) {
            toast.error('Please select a payment mode', alertOptions)
            return false;
        }
        else if (payment.paymentReference == '') {
            toast.error('Please provide a payment refrernce id', alertOptions)
            return false;
        }
        else if (payment.amountPaid == '' || payment.amountPaid==0) {
            toast.error('Please provide the amount paid', alertOptions)
            return false;
        }
        else if (payment.paymentBy == '') {
            toast.error('Please provide the payment by details', alertOptions)
            return false;
        }

        return true;
    }

  
    const onMobileNoChange = (e) => {
        if (!isNaN(Number(e.target.value))) {
            addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, mobileNo: e.target.value } })
        }
    }
    const handleNumberOnlyInput = e => {
        console.log(e.nativeEvent.code);
        if (e.nativeEvent.code === "KeyA" || e.nativeEvent.code === "KeyB" || e.nativeEvent.code === "KeyC" || e.nativeEvent.code === "KeyD" || e.nativeEvent.code === "KeyE" || e.nativeEvent.code === "KeyF" || e.nativeEvent.code === "Minus"||
            e.nativeEvent.code === "KeyG" || e.nativeEvent.code === "KeyH" || e.nativeEvent.code === "KeyI" || e.nativeEvent.code === "KeyJ" || e.nativeEvent.code === "KeyK" || e.nativeEvent.code === "KeyL" || e.nativeEvent.code === "KeyM" ||
                e.nativeEvent.code === "KeyN" || e.nativeEvent.code === "KeyO" || e.nativeEvent.code === "KeyP" || e.nativeEvent.code === "KeyQ" || e.nativeEvent.code === "KeyR" || e.nativeEvent.code === "KeyS" || e.nativeEvent.code === "KeyT" ||
            e.nativeEvent.code === "KeyU" || e.nativeEvent.code === "KeyW" || e.nativeEvent.code === "KeyX" || e.nativeEvent.code === "KeyY" || e.nativeEvent.code === "KeyZ" || e.nativeEvent.code === "Plus" ||
            e.nativeEvent.code === "Equal"
                )
        {
            e.preventDefault();
        }
    };

    return (

        redirectBooking ? (props.from === 'dash' ? <Navigate to='/' /> : <Navigate to='/bookings' />) : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>
            {bookingStatus ? <GenerateQRCode Name={bookingDetails.customer.firstName} Restaurant={props.restaurantName} ReservationDate={moment(bookingDetails.booking.reservedDate).format("MMM Do YYYY")} ReservationTime={bookingDetails.booking.reservedTime} BookingId={bookingId} MobileNo={bookingDetails.customer.mobileNo} NoOfPax={bookingDetails.booking.noOfPax} /> : (<div>
                <Row style={{ paddingTop: '5px', paddingBottom: '10px' }}>
                    <Col sm="4" style={{ color: 'darkblue', fontWeight: 'bold' }}> RESERVATION FORM </Col>
                    <Col sm="3" style={{ textAlign: 'right' }}><span style={{ color: '#e91e63', fontWeight: 'bold' }} >{props.mode=='u'?'':props.restaurantName.toUpperCase()}</span></Col>
                    <Col sm="3" style={{ textAlign: 'right', fontWeight: 600, color: bookingDetails.reservationStatus == 'C' ? '#198754' : bookingDetails.reservationStatus == 'X' ? 'rgb(220, 53, 69)' : bookingDetails.reservationStatus == 'I' ? 'rgb(33, 107, 165)' : bookingDetails.reservationStatus == 'W' ? '#ffc107' : '' }}>{props.mode == 'u' ? (`${bookingDetails.reservationStatus == 'C' ? 'CONFIRMED' : bookingDetails.reservationStatus == 'X' ? 'CANCELLED' : bookingDetails.reservationStatus == 'I' ? 'CHECK-IN' : bookingDetails.reservationStatus == 'W' ? 'WAITLIST' : bookingDetails.reservationStatus == 'N' ? 'NO SHOW': ''}`) : (null)}</Col>
                    <Col sm='2' style={{ textAlign:'right' }}><Button style={{ backgroundColor: 'white', border: 0 }} onClick={() => setRedirectBooking(true)}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>

            </Row>
            
            
            <Card style={{ marginBottom: '1rem' }} key={1}>
                <CardHeader onClick={()=>toggle(1)} data-event={1}>
                    <Row>
                        <Col sm="10" ><p style={{marginBottom:0, color: 'black', fontWeight: 600, width: '130px' }}> Customer Details </p></Col>
                        <Col sm="2" style={{ textAlign: 'right' }}>
                            <IconContext.Provider value={{ size: '25px' }}> {collapse === 1 ? < IoIosArrowDropdownCircle /> : <IoIosArrowDropupCircle />}</IconContext.Provider>
                        </Col>
                    </Row>
                </CardHeader>
            
              
            
                <Collapse isOpen={collapse === 1}>
                <CardBody>
                            {props.mode == "n" ? <Row >
                            <Col sm="2">Select Customer :</Col>
                                <Col sm="10">
                                    <AsyncSelect key={initialLoad} isClearable={true} cacheOptions loadOptions={loadOptions} defaultOptions onInputChange={handleInputChange} onChange={onCustomerAccountChange} onKeyDown={handleNumberOnlyInput}  />
                                </Col>
                            </Row> : (null)}
            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label className="required-fields">
                        Name{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                                    type="text"
                                    pattern="[A-Za-z]"
                        className="bookingInput"
                        value={bookingDetails.customer.firstName}
                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, firstName: e.target.value } })}
                    />
                </Col>
                <Col sm="2" >
                    <label className="required-fields">
                        Mobile No {` : `}

                    </label>
                </Col>
                                <Col sm="4">
                                    <input
                                        readOnly
                                        type="text"
                                        value={'+91'}
                                        className="bookingInput"
                                        style={{ width: '35px', backgroundColor:'#9e9e9e30'} }
                                     />

                                    <input
                                        disabled={true }
                                        maxLength="10"
                                        type="text"
                                        className="bookingInput"
                                        value={bookingDetails.customer.mobileNo}
                                        onChange={onMobileNoChange}
                                        style={{ width:'auto' }}

                                       
                    />
                </Col>

                            </Row>


            <Row style={{ paddingTop: '25px' }}>

                <Col sm="2" >
                    <label >
                        Id Card Type{` : `}

                    </label>
                </Col>
                                <Col sm="4">
                                    <IdCardTypeDropDown selectedCardTypeId={updateSelectedCardType} idCardType={bookingDetails.customer.idCardType} />
                </Col>

                <Col sm="2" >
                    <label>
                        Id Card No{` : `}

                    </label>
                </Col>
                <Col sm="4">
                                    <input
                                        type="text"
                                        className="bookingInput"
                                        value={bookingDetails.customer.idCardNo}
                                        type={props.mode == "n" ? "text" : "password"}
                                        disabled={disableIdcard}
                                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, idCardNo: e.target.value } })}
                    />


                </Col>

            </Row>



                        <Row style={{ paddingTop: '10px' }}><Col sm='12' style={{ textAlign: 'right' }}><Button color="link" onClick={toggleMore}>{moreData ? `Less...` : `More...` }</Button></Col></Row>
            <Collapse isOpen={moreData}>
            <Row style={{ paddingTop: '25px' }}>
                
                <Col sm="2" >
                    <label>
                        LastName {` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                            className="bookingInput"
                        value={bookingDetails.customer.lastName}
                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, lastName: e.target.value } })}
                    />
                </Col>

                <Col sm="2" >
                    <label>
                        Email Address {` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                            className="bookingInput"
                        value={bookingDetails.customer.emailAddress}
                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, emailAddress: e.target.value } })}
                    />
                </Col>
            </Row>

            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        Address 1{` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                        className="bookingInput"
                        value={bookingDetails.customer.address1}
                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, address1: e.target.value } })}
                    />
                </Col>
                <Col sm="2" >
                    <label>
                        Address 2{` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                      className="bookingInput"
                        value={bookingDetails.customer.address2}
                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, address2: e.target.value } })}
                    />
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label className="required-fields">
                        Country{` : `}

                    </label>

                </Col>
                <Col sm="4">


                                        <CountryDropDown selectedCountryId={updateSelectedCountry} country={bookingDetails.customer.country } />

                </Col>
                <Col sm="2" >
                    <label className="required-fields">
                        State{` : `}

                    </label>

                </Col>
                <Col sm="4">

                                        <StateDropDown key={bookingDetails.customer.country} selectedStateId={updateSelectedState} selectedCountryId={bookingDetails.customer.country} state={bookingDetails.customer.state} />

                </Col>
            </Row>

            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        City / Region{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                            className="bookingInput"
                        value={bookingDetails.customer.city}
                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, city: e.target.value } })}
                    />
                </Col>
                <Col sm="2" >
                    <label>
                        Pincode {` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                            className="bookingInput"
                        value={bookingDetails.customer.pincode}
                        onChange={e => addbookingDetails({ ...bookingDetails, customer: { ...bookingDetails.customer, pincode: e.target.value } })}
                    />
                </Col>

                </Row>
                </Collapse>
                </CardBody>
                </Collapse>
            </Card>
           
            <Card style={{ marginBottom: '1rem' }} key={2}>
                <CardHeader onClick={() => toggle(2)} data-event={2}>
                
            <Row >
                        <Col sm="10" ><p style={{ marginBottom:0, color: 'black', fontWeight: 600, width: '150px' }}> Reservation Details </p></Col>
                        <Col sm="2" style={{ textAlign: 'right' }}>
                            <IconContext.Provider value={{ size: '25px' }}> {collapse === 2 ? < IoIosArrowDropdownCircle /> : <IoIosArrowDropupCircle />}</IconContext.Provider>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={collapse === 2}>
                    <CardBody>
            <Row style={{ paddingTop: '5px' }}>

                <Col sm="2" >
                    <label className="required-fields">
                        Reservation Date {` : `}

                                    </label>
                </Col>
                            <Col sm="4">
                                    <DatePicker selected={props.mode == 'u' ? new Date(bookingDetails.booking.reservedDate) : bookingDetails.booking.reservedDate} minDate={moment().toDate()} onChange={(date) => setReservationDate(date)} />
                </Col>

                <Col sm="2" >
                    <label className="required-fields">
                        Reservation Time {` : `}

                    </label>
                </Col>
                <Col sm="4">

                                    <TimeDropDown selectedTimeId={updateSelectedTime} time={bookingDetails.booking.reservedTime} />
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>

                <Col sm="2" >
                    <label className="required-fields">
                        Source {` : `}

                    </label>
                </Col>
                                <Col sm="4">
                                    <BookingSourceDropDown selectedBookingSourceId={updateSelectedSource} bookingSource={bookingDetails.booking.source} />
                </Col>

                <Col sm="2" > 
                                <label className="required-fields">
                                    No of pax{` : `}

                    </label>
                </Col>
                <Col sm="4">
                    Adults{` : `}
                    <input
                        type="text"
                        className="bookingInput"
                        style={{ width: '15%', marginRight: '50px' }}
                        value={bookingDetails.booking.adults}
                        onChange={updateNoOfAdults}
                    />{`    `}
                    Children{` : `}
                    <input
                        type="number"
                        className="bookingInput"
                        style={{ width: '15%' }}
                        value={bookingDetails.booking.children}
                        onChange={updateNoOfChildren}
                    />
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>

                <Col sm="2" >
                    <label className="required-fields">
                        Ocassion{` : `}

                    </label>
                </Col>
                                <Col sm="4">
                                    <OcassionDropDown selectedOcassionId={updateSelectedOcassion} ocassion={bookingDetails.booking.ocassion} />
                </Col>

                <Col sm="2" >
                    <label>
                        Special Instruction{` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                        className="bookingInput"
                        value={bookingDetails.booking.specialInstruction}
                        onChange={e => addbookingDetails({ ...bookingDetails, booking: { ...bookingDetails.booking, specialInstruction: e.target.value } })}
                    />


                </Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>

                <Col sm="2" >
                    <label >
                        Package Required {` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="checkbox"
                        checked={bookingDetails.booking.isPackageRequired}
                        onChange={onPackageChange}
                    />
                </Col>

                <Col sm="2" >
                    <label>


                    </label>
                </Col>
                <Col sm="4">



                </Col>

                        </Row>

                    </CardBody>
                </Collapse>
            </Card>
      
            {bookingDetails.booking.isPackageRequired ? (
                <div>
                    <Card style={{ marginBottom: '1rem' }} key={3}>
                        <CardHeader onClick={() => toggle(3)} data-event={3}>
                    <Row >
                                <Col sm="10" ><p style={{ marginBottom: 0, color: 'black', fontWeight: 600, width: '150px' }}> Package Details </p></Col>
                                <Col sm="2" style={{ textAlign: 'right' }}>
                                    <IconContext.Provider value={{ size: '25px' }}> {collapse === 3 ? < IoIosArrowDropdownCircle /> : <IoIosArrowDropupCircle />}</IconContext.Provider>
                                </Col>       
                    </Row>
                        </CardHeader>
                        <Collapse isOpen={collapse === 3}>
                            <CardBody>
                    <Row style={{ paddingTop: '5px' }}>

                        <Col sm="2" >
                            <label className="required-fields">
                                Package{` : `}

                            </label>
                        </Col>
                                        <Col sm="4">
                                            <PackageDropDown selectedPackageId={updateSelectedPackage} bookingPackage={bookingDetails.package.packageId} />


                                    </Col>
                                    <Col sm="2" >
                                        <label className="required-fields">
                                            Is Cake Eggless{` : `}

                                        </label>
                                    </Col>
                                    <Col sm="4">
                                        <input
                                            type="checkbox"
                                            checked={bookingDetails.package.isCakeEggless}
                                            onChange={e => addbookingDetails({ ...bookingDetails, package: { ...bookingDetails.package, isCakeEggless: !bookingDetails.package.isCakeEggless } })}
                                        />
                                    </Col>

                    </Row>

                    <Row style={{ paddingTop: '25px' }}>


                        

                        <Col sm="2" >
                            <label className="required-fields">
                                Cake Flavour {` : `}

                            </label>
                        </Col>
                        <Col sm="4">
                            <input
                                type="text"
                                className="bookingInput"
                                value={bookingDetails.package.cakeFlavour}
                                onChange={e => addbookingDetails({ ...bookingDetails, package: { ...bookingDetails.package, cakeFlavour: e.target.value } })}
                            />
                        </Col>
                                    <Col sm="2" >
                                        <label className="required-fields">
                                            Cake Wordings {` : `}

                                        </label>
                                    </Col>
                                    <Col sm="4">

                                        <input
                                            type="text"
                                            className="bookingInput"
                                            value={bookingDetails.package.cakeWordings}
                                            onChange={e => addbookingDetails({ ...bookingDetails, package: { ...bookingDetails.package, cakeWordings: e.target.value } })}
                                        />
                                    </Col>

                    </Row>
                    <Row style={{ paddingTop: '25px' }}>



                        

                        <Col sm="2" >
                            <label className="required-fields">
                                Banner Wordings {` : `}

                            </label>
                        </Col>
                        <Col sm="4">
                            <input
                                type="text"
                                className="bookingInput"
                                value={bookingDetails.package.bannerWordings}
                                onChange={e => addbookingDetails({ ...bookingDetails, package: { ...bookingDetails.package, bannerWordings: e.target.value } })}
                            />
                                    </Col>
                                    <Col sm="2" >
                                        <label >
                                            Comments {` : `}

                                        </label>
                                    </Col>
                                    <Col sm="4">
                                        <input
                                            type="text"
                                            className="bookingInput"
                                            value={bookingDetails.package.comments}
                                            onChange={e => addbookingDetails({ ...bookingDetails, package: { ...bookingDetails.package, comments: e.target.value } })}
                                        />
                                    </Col>

                    </Row>
                   
                            </CardBody>
                        </Collapse>
                    </Card>
                </div>
            ) : null}



            {
                bookingDetails.booking.isPackageRequired ? (
                    <div>

                        <Card style={{ marginBottom: '1rem' }} key={4}>
                            <CardHeader onClick={() => toggle(4)} data-event={4}>
                                <Row >
                                    <Col sm="10" ><p style={{ marginBottom:0, color: 'black', fontWeight: 600, width: '130px', marginTop:0}}> Payment Details </p></Col>
                                    <Col sm="2" style={{ textAlign: 'right' }}>
                                        <IconContext.Provider value={{ size: '25px' }}> {collapse === 4 ? < IoIosArrowDropdownCircle /> : <IoIosArrowDropupCircle />}</IconContext.Provider>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Collapse isOpen={collapse === 4}>
                                <CardBody>
                        <Row><Col sm="6">
                        <Row style={{ paddingTop: '5px' }}>
                            <Col sm="4" >
                                <label className="required-fields">
                                                    Payment Date {` : ` }

                                </label>

                            </Col>
                            <Col sm="8">

                                                <DatePicker selected={payment.paymentDate} maxDate={moment().toDate()} onChange={(date) => setPaymentDate(date)} />

                            </Col>
                        </Row>


                        <Row style={{ paddingTop: '25px' }}>
                            <Col sm="4" >
                                <label className="required-fields">
                                                    Payment Mode {` : ` }

                                </label>

                            </Col>
                            <Col sm="8">

                                                    <PaymentModeDropDown selectedPaymentModeId={updateSelectedPaymentMode} paymentMode={payment.paymentMode} />

                            </Col>
                        </Row>
                        <Row style={{ paddingTop: '25px' }}>

                            <Col sm="4" >
                                                <label className="required-fields">
                                    Pay. Reference {` : `}

                                </label>
                            </Col>
                            <Col sm="8">
                                <input
                                        type="text"
                                        className="bookingInput"
                                    value={payment.paymentReference}
                                    onChange={e => updatePayment({ ...payment, paymentReference: e.target.value } )}
                                />
                            </Col>


                        </Row>

                        <Row style={{ paddingTop: '25px' }}>

                            <Col sm="4" >
                                                <label className="required-fields">
                                    Amount Paid {` : `}

                                </label>
                            </Col>
                            <Col sm="8">
                                <input
                                    type="text"
                                        className="bookingInput"
                                                    value={payment.amountPaid}
                                                    onChange={e => updatePayment({ ...payment, amountPaid: e.target.value == '' ? 0 : parseInt(e.target.value) })}
                                />
                            </Col>


                        </Row>

                        <Row style={{ paddingTop: '25px' }}>

                            <Col sm="4" >
                                                <label className="required-fields">
                                    Payment By {` : `}

                                </label>
                            </Col>
                            <Col sm="8">
                                <input
                                    type="text"
                                        className="bookingInput"
                                        value={payment.paymentBy}
                                        onChange={e => updatePayment({ ...payment, paymentBy: e.target.value } )}
                                />
                            </Col>


                                        </Row>
                                        <Row style={{ paddingTop: '15px' }}>
                                            <Col sm="12" style={{ textAlign: 'center' }}>  <Button color="primary" size="sm" onClick={onPaymentAdd} disabled={InProgress} >Add{""}</Button></Col>
                                        </Row>
                        </Col>
                            <Col sm="6">
                                <div className="ag-theme-balham" style={{ height: '100%', width: '100%' }}>
                                                <AgGridReact rowHeight={30}
                                                    rowData={bookingDetails.payments}
                                                    defaultColDef={defaultColDef}
                                                    columnDefs={columnDefs}
                                                    context={{ onPaymentRemove }}>
                                    </AgGridReact>
                                </div>

                            </Col>

                                </Row>
                                </CardBody>
                            </Collapse>
                        </Card>

                    </div>) : (null)
            }

            <Row style={{ paddingTop: '10px' }}>
              
                <Col sm="12" style={{ textAlign: 'right' }}>
                        {(bookingDetails.reservationStatus == '' || bookingDetails.reservationStatus == 'W') ? <Button style={{marginRight:'5px'}} color="success" onClick={() => handleSubmit(ReservationStatus.Confirmed)} disabled={InProgress ? true : !bookingDetails.booking.isPackageRequired ? false : (bookingDetails.booking.isPackageRequired && bookingDetails.payments.length > 0) ? false : true} >CONFIRM{""}</Button> : (null)}
                        {(bookingDetails.reservationStatus == '') ? <Button style={{ marginRight: '5px' }} color="warning" onClick={() => handleSubmit(ReservationStatus.WaitList)} disabled={InProgress} >WAITLIST{""}</Button> : (null)}
                        {(bookingDetails.reservationStatus == 'C') ? <Button style={{ marginRight: '5px' }} color="success" onClick={() => handleSubmit(bookingDetails.reservationStatus)} disabled={InProgress ? true : !bookingDetails.booking.isPackageRequired ? false : (bookingDetails.booking.isPackageRequired && bookingDetails.payments.length > 0) ? false : true} >UPDATE{""}</Button> : (null)}
                        {/*{(bookingDetails.reservationStatus == 'C' && logged.Roles[0].roleId !== Roles.CallCenter) ? <Button style={{ marginRight: '5px', backgroundColor: '#216ba5' }} onClick={updateBookingStatus(bookingDetails.booking.bookingId, ReservationStatus.CheckIn)} disabled={InProgress} >CHECK-IN{""}</Button> : (null)}*/}
                        {(bookingDetails.reservationStatus == 'C' || bookingDetails.reservationStatus == 'W') ? <Button style={{ marginRight: '5px', backgroundColor: '#dc3545' }} color="danger" onClick={() => updateBookingStatus(bookingDetails.booking.bookingId, ReservationStatus.Cancelled)} disabled={InProgress} >CANCEL{""}</Button> : (null)}


                    </Col>
            </Row>
        
            {InProgress ? (
                <div>
                    <Row style={{ paddingTop: '25px' }}>
                        <Col sm="4" />
                        <Col sm="7" >
                            <h3> SAVING IN PROGRESS...</h3>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm="2" />
                        <Col sm="7" >
                            <Progress animated color="info" value="100" />
                        </Col>
                    </Row>
                </div>) : (null)
            }

        

                <Modal isOpen={checkoutModalIsOpen}  style={checkoutModalStyles}
                    conetentLable="CheckOut" ariaHideApp={false }
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                        <Col sm='11' style={{ color: 'white', fontWeight:600 }}>Check-In Table</Col>
                        <Col sm="1" style={{ textAlign: "right" }}>
                            <IconContext.Provider value={{size:'25px',color:'white'} }>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={closeCheckoutModal}>

                                <AiFillCloseCircle />
                            </Button>
                            </IconContext.Provider>
                        </Col>

                    </Row>
                    <Row style={{ paddingTop: '25px' }}><Col sm='12' style={{ textAlign: 'center', fontWeight:500 }} >Table No {` :  `} <input
                        type="text"
                        className="bookingInput"
                        style={{ width: '25%' }}
                        value={tableNo}
                        onChange={e => updateTableNo( e.target.value )}
                    /> </Col></Row>

                    <Row><Col sm='12' style={{ textAlign: 'right' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={() => { closeCheckoutModal(); updateBookingStatus(bookingDetails.booking.bookingId, ReservationStatus.CheckIn); }} disabled={InProgress} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={closeCheckoutModal} disabled={InProgress} >Close{""}</Button>

                    </Col></Row>
                </Modal>


                <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign:'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign:'center' }}><img src='./img/Loading.gif' width='150px'/></Col></Row>
                </Modal>

                <Modal isOpen={confirmModalIsOpen} style={confirmModalStyles}
                    conetentLable="CheckOut" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                        <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Confirm Booking</Col>
                        <Col sm="1" style={{ textAlign: "right" }}>
                            <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                                <Button color='link' title='close' style={{ padding: 0 }} onClick={closeCheckoutModal}>

                                    <AiFillCloseCircle />
                                </Button>
                            </IconContext.Provider>
                        </Col>

                    </Row>
                    <Row style={{ paddingTop: '25px' }}><Col sm='12' style={{ textAlign: 'center', fontWeight: 500 }} >
                        { confirmMessage}
                     </Col></Row>

                    <Row><Col sm='12' style={{ textAlign: 'right' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={() => { closeConfirmModal(); addConfirmBooking(); }} disabled={InProgress} >Confirm{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={closeConfirmModal} disabled={InProgress} >Cancel{""}</Button>

                    </Col></Row>
                </Modal>
                <Modal isOpen={cancelModalIsOpen} style={cancelModalStyles}
                    conetentLable="Cancel" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                        <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Reason For Cancellation</Col>
                        <Col sm="1" style={{ textAlign: "right" }}>
                            <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                                <Button color='link' title='close' style={{ padding: 0 }} onClick={closeCancelModal}>

                                    <AiFillCloseCircle />
                                </Button>
                            </IconContext.Provider>
                        </Col>

                    </Row>
                    <Row><Col sm="12" sytle={{ textAlign: 'center', fontWeight: 600 }}>Please provide the reason for cancellation</Col></Row>
                    <Row style={{ paddingTop: '5px' }}><Col sm='12'> <textarea
                        rows="4" cols="50"

                        value={cancelComments}
                        onChange={e => updateCancelComments(e.target.value)}
                    /> </Col></Row>

                    <Row><Col sm='12' style={{ textAlign: 'right' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={() => { closeCancelModal(); cancelBooking(); }} disabled={InProgress} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={closeCancelModal} disabled={InProgress} >Close{""}</Button>

                    </Col></Row>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />

            </div>
                )
}
            </Container>


    );
    
} 