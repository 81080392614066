import { React, useState, useMemo, useRef, useCallback, useEffect } from "react";
import { Row, Col, Button, Container } from 'reactstrap';
import { AddBulkBooking } from './AddBulkBooking';
import { Navigate, Link } from 'react-router-dom'
import moment from "moment";
import { BulkEnquiryStatus } from "../../lib/BulkEnquiryStatus";
import { ToastContainer, toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import BookingService from "../../services/BookingService"
import { logged } from "../../lib/logged";
import { Roles } from "../../lib/Roles";


export function BulkBooking(props) {
    const bookingService = new BookingService();

    const [isBulkBooking, setBulkBooking] = useState(false);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '90%', width: '100%' }), []);
    const gridRef = useRef(null);

    const [InProgress, setInProgress] = useState(false);
    const [reservations, updateReservations] = useState([]);
    const [isAdd, updateIsAdd] = useState(false);
    const [bulkEnquiries, setBulkEnquiries] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'bulkEnquiryId', headerName: 'Enquiry Id', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true, cellRenderer: (d) => {
                if (logged.Roles[0].roleId == Roles.MarketingManager || logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.GeneralManager) return <Link data-testid="link-bulkbookingId" to={`/bulkbooking/${d.data.bulkEnquiryId}`}>{d.data.bulkEnquiryId}</Link>
                else return d.data.bulkEnquiryId
            } 
        },
        {
            field: 'restaurantName', headerName: 'Restaurant', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'reserverdDate', headerName: 'Date', width: 120, sortable: true, cellRenderer: (d) => { return moment(d.data.reserverdDate).format("DD/MM/YY") }
        },
        {
            field: 'reservedTime', headerName: 'Time', width: 100, sortable: true
        },
        {
            field: 'bookingSourceName', headerName: 'Source', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'firstName', headerName: 'First Name', width: 140, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'mobileNo', headerName: 'Mobile No', width: 140, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'enquiryStatus', headerName: 'Enquiry Status', width: 170, sortable: true,
            cellStyle: (p) => {
                if (p.node.data.enquiryStatus == BulkEnquiryStatus.Confirmed) return { field: p.node.data.enquiryStatus = "Confirmed", color: "green", fontWeight: 600 }
                else if (p.node.data.enquiryStatus == BulkEnquiryStatus.UnderDiscussion) return { field: p.node.data.enquiryStatus = "Under Discussion", color: "red", fontWeight: 600 }
                else if (p.node.data.enquiryStatus == BulkEnquiryStatus.Completed) return { field: p.node.data.enquiryStatus = "Completed", color: "#216ba5", fontWeight: 600 }
                else if (p.node.data.enquiryStatus == BulkEnquiryStatus.Cancelled) return { field: p.node.data.enquiryStatus = "Cancelled", color: "grey", fontWeight: 600 }
            }, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'bookingId', headerName: 'Booking ID', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
          
        },
        {
            field: 'cancelReasonName', headerName: 'Cancel Reason', width: 170, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'noOfPax', headerName: 'PAX', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'foodCostPerPax', headerName: 'Cost Per Pax', width: 160, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'foodTotalAmount', headerName: 'Total Amount', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'createdBy', headerName: 'Created By', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'createDate', headerName: 'Create Date', width: 150, sortable: true, cellRenderer: (d) => { return moment(d.data.createDate).format("DD/MM/YY h:mm:ss") }
        },
        {
            field: 'modifiedBy', headerName: 'Modified By', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'modifyDate', headerName: 'Modified Date', width: 150, sortable: true, cellRenderer: (d) => {
                if (d.data.modifyDate != null)
                    return moment(d.data.modifyDate).format("DD/MM/YY h:mm:ss")
                else
                    return d.data.modifyDate
            }
        }

    ]);


    useEffect(() => {
        getBulkEnquiries();


    }, [props.status])

    const checkoutModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    const cancelModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);

    const [checkoutModalIsOpen, setCheckoutModalIsOpen] = useState(false);

    const openCancelModal = () => {
        setCancelModalIsOpen(true)
    }

    const closeCancelModal = () => {
        setCancelModalIsOpen(false)
    }
    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const openCheckoutModal = () => {
        setCheckoutModalIsOpen(true)
    }

    const closeCheckoutModal = () => {
        setCheckoutModalIsOpen(false)
    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })
    const onGridReady = () => {
        //Call the SP where it gets all enquiry details from tblBulkEnquiries
    }
    const onBulkClick = () => {
        //setBulkBooking(true);
    }
    const showAlert = (message, type) => {

        if (type == 'error') {
            toast.error(message);
        }
        else if (type == 'success') {
            toast.success(message);
        }
    }
    const getBulkEnquiries = async () => {
        //showLoading();
        let result = await bookingService.GetBulkEnquiryDetails(props.status);
        if (!result.redirect) {

            setBulkEnquiries(result.data.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching Bulk Enquiries');
            hideLoading();
        }

    }
    return (
        isBulkBooking ? <AddBulkBooking restaurantId={props.restaurantId} restaurantName={props.restaurantName} mode={props.mode} /> :
        <Container fluid={true} style={{ backgroundColor: "white"}}>

        <Row style={{ paddingTop: '15px', paddingBottom: '5px' }}>
                    <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> </Col>
                    <Col sm="2" style={{ textAlign: 'right' }}>  <Button color="link" size="md" style={{ backgroundColor: '#1b6ec2', }}  ><Link style={{ color: 'white',textDecoration:'none' }} to={`/bulkbooking/${0}`}>Add</Link></Button></Col>
                </Row>

                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact rowHeight={32}
                            ref={gridRef}
                            rowData={bulkEnquiries}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            context={{ showAlert, getBulkEnquiries, openCheckoutModal, openCancelModal }}
                            onGridReady={onGridReady}
                            overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while bulk Enquiries are loading</span>'
                            }
                            overlayNoRowsTemplate={
                                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No bulk enquiry to show</span>'
                            }
                            domLayout={'autoHeight'}


                            suppressCellFocus={true} >

                        </AgGridReact>
                    </div>


    </Container>
    )
}