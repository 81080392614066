import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container } from 'reactstrap';
import IndentService from "../../services/IndentService";
import { IconContext } from "react-icons";
import { IoIosCloseCircle } from "react-icons/io";
import { Navigate } from 'react-router-dom';
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged";
import { IndentDepartment } from "./IndentDepartment";
import { ParentDepartmentDropDown } from "../Controls/ParentDepartmentDropDown";
import { useParams } from 'react-router-dom'

/*
Master screen for Indent Department (tblIndentDepartments)

1)Department Name  --> Textbox
2)Parent Department ---> Dropdown   

It will be dropdown. But it should has values of only Department which is active and whose parentdepartment =0
It will not be a mandatory field. If adding a sub department, then they will choose the parent department

TODO: It will also have edit mode where they will be able to edit or map parent�department

*/

export function AddIndentDepartment(props) {

    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }

    const [indentDepartmentDetails, addIndentDepartmentDetails] = useState({ indentDeptId: 0, indentDeptName: '', isActive: '', createdBy: logged.UserId, modifiedBy: 0, createdDate: 0, modifiedDate: 0 ,parentDept: 0})
    const [InProgress, setInProgress] = useState(false);
    const indentService = new IndentService();
    const [close, setClose] = useState(false);
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const handleSubmit = (evt) => {
        addIndentDepartment();
    }

    const onDepartmentSelected = (val) => {
        addIndentDepartmentDetails({ ...indentDepartmentDetails, parentDept: val });
    }

    const addIndentDepartment = async () => {
        let initialValue = { indentDeptName: '', createdBy: 0, parentDept:0 }
        if (validateForm()) {

            //setMessage('SAVING IN PROGRESS');
            openMessageModal();

            // setInProgress(true);
            let result = await indentService.AddIndentDepartment(indentDepartmentDetails.indentDeptName, indentDepartmentDetails.createdBy, indentDepartmentDetails.parentDept.value);
            
            if (!result.redirect) {
                toast.success('Indent Department has been successfully added')
                //setMessage('SAVING IN PROGRESS');

                closeMessageModal();
                addIndentDepartmentDetails({ ...indentDepartmentDetails, ...initialValue });
                setClose(true);
            }
            else {
                setMessage('Error occured while adding record');
                //toast.error("Error occured while adding indent department");
                closeMessageModal();


            }
        }
    }

    const validateForm = () => {
        if (indentDepartmentDetails.indentDeptName === '') {
            toast.error('Please enter the indent department name', alertOptions)
            return false;
        }

        return true;
    }


    return (
        close ? (props.mode == 'u' ? <Navigate to='/indent/indentDepartment' /> : < IndentDepartment />) : (<Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>
            <Row style={{}}>
                <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> Add Indent Department </Col>
                <Col sm='1' style={{ textAlign: 'right' }}><Button style={{ backgroundColor: 'white', border: 0 }} onClick={() => setClose(true)}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        Indent Department Name{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={indentDepartmentDetails.indentDeptName}
                        onChange={e => addIndentDepartmentDetails({ ...indentDepartmentDetails, indentDeptName: e.target.value })}
                    />
                </Col>

            </Row>


            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        Parent Department {` : `}

                    </label>
                </Col>
                <Col sm="4">

                    < ParentDepartmentDropDown selectedDepartmentId={onDepartmentSelected} department={ indentDepartmentDetails.indentDeptName} />
                </Col>

            </Row>

            <Row style={{ paddingTop: '15px' }}>
                <Col sm="12" style={{ textAlign: 'center' }}>  <Button color="primary" size="sm" onClick={handleSubmit} disabled={InProgress} >Save{""}</Button></Col>
            </Row>

            {InProgress ? (<div>
                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" />
                    <Col sm="7" >
                        <h3> SAVING IN PROGRESS...</h3>

                    </Col>
                </Row>
                <Row>
                    <Col sm="2" />
                    <Col sm="7" >
                        <Progress animated color="info" value="100" />
                    </Col>
                </Row></div>) : (null)
            }


            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Container>)
    );
}