import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function BookingSourceDropDown(props) {

    const administrationService = new AdministrationService();

    const [bookingSources, updateBookingSources] = useState([]);
    //const [selectedBookingSource, updateSelectedBookingSource] = useState(null);

    useEffect(() => { getBookingSources(); }, [])

    const getBookingSources = async () => {
        let result = await administrationService.GetBookingSources();
        if (!result.redirect) {

            updateBookingSources(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
       // updateSelectedBookingSource(val);
        //if (action.action == 'clear')
            props.selectedBookingSourceId(val)
        //else
          //  props.selectedBookingSourceId(val.value)
    }

    //bookingSources.find(o => o.value === props.bookingSource);

    return (
        <Select options={bookingSources} isClearable={true} onChange={onSelectChange} value={props.bookingSource==null?null:bookingSources.find(o => o.value === props.bookingSource)} />
    );



}