import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container } from 'reactstrap';

import IndentService from "../../services/IndentService";
import { logged } from "../../lib/logged";
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import { IndentStatus } from '../../lib/IndentStatus';
import { IoIosArrowDropupCircle, IoIosCloseCircle } from "react-icons/io";
import { IconContext } from "react-icons";
import { UserRestaurantDropDown } from '../Controls/UserRestaurantDropDown';
import { IndentOrderList } from "./IndentOrderList";
import { useParams } from 'react-router-dom'
import { IndentTypeDropDown } from "../Controls/IndentTypeDropDown";
import { DepartmentDropDown } from "../Controls/DepartmentDropDown";
import { SubDepartmentDropDown } from "../Controls/SubDepartmentDropDown";
export function AddIndentOrderList(props) {


    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }

    const [indentOrderDetails, addIndentOrderDetails] = useState({ indentId: 0, indentType: null, restaurant: 0, department: null, createdBy: logged.UserId})
    const [InProgress, setInProgress] = useState(false);
    const indentService = new IndentService;
    const [close, setClose] = useState(false);
    const [message, setMessage] = useState('')
    const [subDepartment, setSubDepartment] = useState(null);

    const onRestaurantSelectionChange = (val) => { 
        addIndentOrderDetails({ ...indentOrderDetails, restaurant: val });
    }

    const updateSelectedSubDepartment = (val) => {
        setSubDepartment( val!=null? val.value:val);
    }

    const updateSelectedDepartment = (val) => {
        addIndentOrderDetails({ ...indentOrderDetails, department: val != null ? val.value : val });
    }

    const onIndentTypeChange = (val) => {
        addIndentOrderDetails({ ...indentOrderDetails, indentType: val != null ? val.value : val });
    }
    const handleSubmit = (evt) => {
        /*  if (props.mode == 'u')
              updatePackage();
          else */
        addIndentOrderList();
    }
    const addIndentOrderList = async () => {
        let initialValue = { indentId: 0, indentType: null, restaurant: 0, department: null, createdBy: logged.UserId }
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            
            // setInProgress(true);

            

            let result = await indentService.AddIndentOrder(indentOrderDetails.indentType, indentOrderDetails.department == 1 ? subDepartment : indentOrderDetails.department, indentOrderDetails.restaurant.value, IndentStatus.Draft, logged.UserId);
            if (!result.redirect) {
                //toast.success('Indent has been successfully created. Please proceed to add the items')
               
                addIndentOrderDetails({ ...indentOrderDetails, ...initialValue });
                props.modalClose(true);
            }
            else {
               // toast.error("Error occured while creating the indent");
                props.modalClose(false);

            }
        }
    }

    const validateForm = () => {
        if (indentOrderDetails.indentType === null) {
            toast.error('Please select the Indent Type', alertOptions)
            return false;
        }
        if (indentOrderDetails.restaurant === 0) {
            toast.error('Please select the Restaurant', alertOptions)
            return false;
        }
        if (indentOrderDetails.department ===  null) {
            toast.error('Please select the Department', alertOptions)
            return false;
        }
        if (indentOrderDetails.department === 1 && (subDepartment == null || subDepartment == 0)) {
            toast.error('Please select the Sub Department', alertOptions)
            return false;
        }
        return true;
    }


    return (
        <Container fluid={true} style={{ backgroundColor: "rgb(142 225 191)", height: "100%" }}>
      

            <Row style={{ paddingTop: '15px' }}>
                <Col sm="4" >
                    <label>
                        Indent Type{` : `}

                    </label>
                </Col>
                <Col sm="8">
                    <IndentTypeDropDown selectedIndentId={onIndentTypeChange} type={indentOrderDetails.indentType} />
                   
                </Col>
                </Row>
            <Row style={{ paddingTop: '15px' }}>
                <Col sm="4" >
                    <label>
                        Restaurant{` : `}

                    </label>
                </Col>
                <Col sm="8">
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={indentOrderDetails.restaurant}

                       

                    />
                </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>

                <Col sm="4" >
                    <label>
                        Department{` : `}

                    </label>
                </Col>
                <Col sm="8">

                    <DepartmentDropDown key={indentOrderDetails.restaurant != null ? indentOrderDetails.restaurant.value : null} selectedDepartmentId={updateSelectedDepartment} selectedRestaurantId={indentOrderDetails.restaurant != null ? indentOrderDetails.restaurant.value : null} department={indentOrderDetails.department} /> 
                   
                </Col>
               

            </Row>

            {indentOrderDetails.department==1? <Row style={{ paddingTop: '15px' }}>

                <Col sm="4" >
                    <label>
                        Sub Department{` : `}

                    </label>
                </Col>
                <Col sm="8">

                    <SubDepartmentDropDown key={indentOrderDetails.department != null ? indentOrderDetails.department : 0} selectedSubDepartmentId={updateSelectedSubDepartment} selectedDepartmentId={indentOrderDetails.department} subDepartment={subDepartment} />

                </Col>


            </Row>:null}



            <Row style={{ paddingTop: '15px' }}>
                <Col sm="12" style={{ textAlign: 'center' }}>  <Button color="primary" size="sm" onClick={handleSubmit} disabled={InProgress} >Add{""}</Button></Col>
            </Row>

            {InProgress ? (<div>
                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" />
                    <Col sm="7" >
                        <h3> SAVING IN PROGRESS...</h3>

                    </Col>
                </Row>
                <Row>
                    <Col sm="2" />
                    <Col sm="7" >
                        <Progress animated color="info" value="100" />
                    </Col>
                </Row></div>) : (null)
            }


           
            {/*<ToastContainer*/}
            {/*    position="top-center"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="dark"*/}
            {/*/>*/}
        </Container>
    );

}