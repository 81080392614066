import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import IndentService from "../../services/IndentService";
import BookingService from "../../services/BookingService";

import { UserRestaurantDefaultDropDown } from '../Controls/UserRestaurantDefaultDropDown';
import { MonthDropDown } from '../Controls/MonthDropDown';
import { AgGridReact } from 'ag-grid-react';
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import { Navigate, Link } from 'react-router-dom'
import { logged } from "../../lib/logged"
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import moment from "moment";
const BulkEnquirySummary = (props) => {




    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedCreatedMonth, setSelectedCreatedMonth] = useState((new Date().getMonth() + 1));
    const [selectedReservedMonth, setSelectedReservedMonth] = useState((new Date().getMonth() + 1));
    const [enquiryDataByCreateDate, updateEnquiryDataByCreateDate] = useState([])
    const [enquiryDataByReservedDate, updateEnquiryDataByReservedDate] = useState([])
    const indentService = new IndentService()
    const bookingService = new BookingService()

    const [pieChartData, updatePieChartData] = useState({
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    });
    const [barChartData, updateBarChartData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Kitchen',
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Dataset 2',
                data: [7, 8, 9, 10, 11, 12],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    })

    const [EnquiryColumnDefs, setEnquiryColumnDefs] = useState([

        {
            field: 'status', headerName: 'Status', filter: "agTextColumnFilter",
            cellStyle: (p) => {
                if (p.node.data.status == 'Total Amount') return { color: "Red", fontWeight: 'bold', background: 'yellow' }

            }

        },
        {
            field: 'bookings', headerName: ' Bookings', filter: "agTextColumnFilter"
        },
        {
            field: 'pax', headerName: 'PAX', filter: "agTextColumnFilter"
        },
        
        {
            field: 'amount', headerName: 'Amount', filter: "agTextColumnFilter",
            valueFormatter: params => parseFloat(params.data.amount).toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            }).substring(1),
            cellStyle: (p) => {
                if (p.node.data.categoryName == 'Total Amount') return { color: "Red", fontWeight: 'bold', background: 'yellow', textAlign: 'right', borderLeftColor: 'black' }
                else
                    return { textAlign: 'right' }
            }
        }
    ]);
    useEffect(() => {
        getPieCategoryData();
        getBarBulkData();
        getEnquiryDataByCreateDate( 0, selectedCreatedMonth)

    }, [])

    useEffect(() => {
        sizeToFit();
    }, [enquiryDataByCreateDate, enquiryDataByReservedDate])
    const cgridRef = useRef(null);
    const rgridRef = useRef(null);


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false,
        sortable: true,
    })

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: 'white',
                font: {
                    size: 12,
                    weight: 'bold'
                },
                display: 'auto',
                anchor: 'center',
                backgroundColor: 'red',
                borderColor: 'yellow',
                borderWidth: 1
            },
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Cancellation Reasons',
            },

        },
    }

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y:
            {
                title: {
                    display: true,
                    text: 'Amount ',
                    color: 'blue'
                },
                min: 0,
                max: 600000,
                ticks: {
                    stepSize: 25000,
                }
            },
        },
        plugins: {
            datalabels: {
                color: '#36A2EB',
                display: 'auto',
                rotation: 90,
                anchor: 'end',
                align: 'end',
                offset: 0,
                font: {
                    size: 10,
                    weight: 'bold'
                }
            },
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Last 6 months confirmed enquiries',
            },

        },
    };


    const dynamicColors = () => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
    }

    const getRandomColor = () => {
        var characters = "0123456789ABCDEF";
        var color = '#';

        for (var i = 0; i < 6; i++) {
            color += characters[getRandomNumber(0, 15)];
        }

        return color;
    }

    const getRandomNumber = (low, high) => {
        var r = Math.floor(Math.random() * (high - low + 1)) + low;
        return r;
    }

    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        getPieCategoryData(val != null ? val.value : 0);
        getBarBulkData(val != null ? val.value : 0);
        getEnquiryDataByCreateDate(val != null ? val.value : 0, selectedCreatedMonth);
    }
    const getPieCategoryData = async () => {
        let result = await bookingService.GetBulkEnquiryCancelledReasons();
        if (!result.redirect) {

            let cLabels = result.data.data.map(a => a.reason)
            let cData = result.data.data.map(a => a.reasonTotal)
            let cBackgroundColor = [], cBorderColor = []
            for (let i = 0; i < result.data.data.length; i++) {
                let color = dynamicColors();
                cBackgroundColor.push(color);
                cBorderColor.push(color);
            }
            updatePieChartData({
                labels: cLabels, datasets: [{ label: 'Reasons', data: cData, backgroundColor: cBackgroundColor, borderColor: cBorderColor, borderWidth: 1 }],
            });
        }
        else {

        }
    }

    const getBarBulkData = async () => {
        let result = await bookingService.GetBulkEnquiryConfirmedBookings();
        let barDateLabels = []
        let datasets = []
        if (!result.redirect) {
            const distinctMonths = [...new Set(result.data.data.map(obj => obj.reservedMonth))]
            const distinctRestaurants = [...new Set(result.data.data.map(obj => obj.restaurantName))]
            for (let m = 0; m < distinctMonths.length; m++) {
                let mon = ""
                if (distinctMonths[m] == 1)
                    mon = "Jan"
                else if (distinctMonths[m] == 2)
                    mon = "Feb"
                else if (distinctMonths[m] == 3)
                    mon = "Mar"
                else if (distinctMonths[m] == 4)
                    mon = "Apr"
                else if (distinctMonths[m] == 5)
                    mon = "May"
                else if (distinctMonths[m] == 6)
                    mon = "Jun"
                else if (distinctMonths[m] == 7)
                    mon = "Jul"
                else if (distinctMonths[m] == 8)
                    mon = "Aug"
                else if (distinctMonths[m] == 9)
                    mon = "Sep"
                else if (distinctMonths[m] == 10)
                    mon = "Oct"
                else if (distinctMonths[m] == 11)
                    mon = "Nov"
                else if (distinctMonths[m] == 12)
                    mon = "Dec"
                barDateLabels.push(mon)
            }

            for (let n = 0; n < distinctRestaurants.length; n++) {
                let datasetData = []
                for (let p = 0; p < distinctMonths.length; p++) {
                    let val = result.data.data.find(a => a.restaurantName == distinctRestaurants[n] && a.reservedMonth == distinctMonths[p])
                    if (val != undefined) {
                        datasetData.push(val.amount);

                    }
                    else {
                        datasetData.push(0);
                    }

                    if (p == distinctMonths.length - 1) {
                        datasets.push({ label: distinctRestaurants[n], data: datasetData, backgroundColor: getRandomColor() })
                    }
                }


            }


            updateBarChartData({ labels: barDateLabels, datasets: datasets })
        }
        else {

        }

    }

    const updateSelectedCreateMonth = (val) => {

        setSelectedCreatedMonth(val == null ? null : val.value);
        if (val != null)
            getEnquiryDataByCreateDate(selectedRestaurant == null ? 0 : selectedRestaurant.value, val.value)
    }

    const updateSelectedReservedMonth = (val) => {

        setSelectedReservedMonth(val == null ? null : val.value);
        if (val != null)
            getEnquiryDataByReservedDate(selectedRestaurant == null ? 0 : selectedRestaurant.value, val.value)
    }

    const getEnquiryDataByCreateDate = async (rest = selectedRestaurant, mnth = selectedCreatedMonth) => {
        let result = await bookingService.GetBulkEnquiryDataByCreateDate(rest, mnth);
        if (!result.redirect) {
            if (result.data.data.length > 0) {

                let categoryTotalAmount = 0;
                for (let m = 0; m < result.data.data.length; m++) {
                    categoryTotalAmount = categoryTotalAmount + result.data.data[m].amount;
                }

                updateTotalByCreateDate([{
                    status: 'Total Amount', amount: parseFloat(categoryTotalAmount)
                 
                }])

                updateEnquiryDataByCreateDate(result.data.data);
            }
            else {
                updateEnquiryDataByCreateDate(result.data.data);
                updateTotalByCreateDate([{
                    status: 'Total Amount', amount: parseFloat(0)

                }])

            }


        }
        else {

        }


    }

    const getEnquiryDataByReservedDate = async (rest = selectedRestaurant, mnth = selectedReservedMonth) => {
        let result = await bookingService.GetBulkEnquiryDataByReservedDate(rest, mnth);
        if (!result.redirect) {
            if (result.data.data.length > 0) {

                let categoryTotalAmount = 0;
                for (let m = 0; m < result.data.data.length; m++) {
                    categoryTotalAmount = categoryTotalAmount + result.data.data[m].amount;
                }

                updateTotalByReservedDate([{
                    status: 'Total Amount', amount: parseFloat(categoryTotalAmount)

                }])

                updateEnquiryDataByReservedDate(result.data.data);
            }
            else {
                updateEnquiryDataByReservedDate(result.data.data);
                updateTotalByCreateDate([{
                    status: 'Total Amount', amount: parseFloat(0)

                }])

            }


        }
        else {

        }


    }


    const updateTotalByCreateDate = (rows) => {
        if (cgridRef.current != null)
            cgridRef.current.api.setPinnedBottomRowData(rows)
    }

    const updateTotalByReservedDate = (rows) => {
        if (rgridRef.current != null)
            rgridRef.current.api.setPinnedBottomRowData(rows)
    }

    const pinnedBottomRowByCreateData = useMemo(() => {
        return [{
            categoryName: 'Total Amount', amount: parseFloat('0.00')
                .toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                })
        }]

    }, []);

    const pinnedBottomRowByReservedData = useMemo(() => {
        return [{
            categoryName: 'Total Amount', amount: parseFloat('0.00')
                .toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                })
        }]

    }, []);


    const onGridReady = useCallback(() => {
        getEnquiryDataByCreateDate(selectedRestaurant == null ? 1 : selectedRestaurant.value, selectedCreatedMonth == null ? 0 : selectedCreatedMonth.value);

    }, []);

    const onRGridReady = useCallback(() => {
        getEnquiryDataByReservedDate(selectedRestaurant == null ? 1 : selectedRestaurant.value, selectedReservedMonth == null ? 0 : selectedReservedMonth.value);

    }, []);





    const sizeToFit = useCallback(() => {
        if (cgridRef.current.api != undefined)
            cgridRef.current.api.sizeColumnsToFit();
        if (rgridRef.current.api != undefined)
            rgridRef.current.api.sizeColumnsToFit();
    }, []);

    return (
        <Container fluid={true}>
           

            <Row>
                <Col sm="6" style={{ paddingTop: '5px' }}>
                    <Row style={{ background: 'linear-gradient(120deg, #009688, rgb(76, 175, 80))', padding: 0, marginRight: '1px', marginLeft: '1px' }}><Col sm="9" style={{ paddingTop: '8px', color:'white' }}><h5>BOOKINGS - BY CREATE DATE</h5></Col><Col sm="3" style={{ padding: 0, paddingRight: '15px', paddingBottom: '5px', paddingTop: '5px' }}><MonthDropDown selectedMonthId={updateSelectedCreateMonth} month={selectedCreatedMonth} /></Col></Row>
                    <div style={{ width: '100%', height: 175, paddingBottom: '5px' }} className="ag-theme-alpine" >
                        <AgGridReact rowHeight={25}
                            ref={cgridRef}
                            rowData={enquiryDataByCreateDate}
                            defaultColDef={defaultColDef}
                            columnDefs={EnquiryColumnDefs}
                            enableRangeSelection={true}
                            headerHeight={30}
                            onGridReady={onGridReady}
                            overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                            }
                            overlayNoRowsTemplate={
                                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No items</span>'
                            }
                            enableCellTextSelection={true}
                            suppressCellFocus={true}
                            pinnedBottomRowData={pinnedBottomRowByCreateData}

                        >

                        </AgGridReact>
                    </div>

                </Col>

                <Col sm="6" style={{ paddingTop: '5px' }}>
                    <Row style={{ background: 'linear-gradient(120deg, rgb(33, 150, 243), rgb(182 221 239))', padding: 0, marginRight: '1px', marginLeft: '1px' }}><Col sm="9" style={{ paddingTop: '8px',color:'white' }}><h5>BOOKINGS - BY RESERVE DATE</h5></Col><Col sm="3" style={{ padding: 0, paddingRight: '15px', paddingBottom: '5px', paddingTop: '5px' }}><MonthDropDown selectedMonthId={updateSelectedReservedMonth} month={selectedReservedMonth} /></Col></Row>
                    <div style={{ width: '100%', height: 175, paddingBottom: '5px' }} className="ag-theme-alpine" >
                        <AgGridReact rowHeight={25}
                            ref={rgridRef}
                            rowData={enquiryDataByReservedDate}
                            defaultColDef={defaultColDef}
                            columnDefs={EnquiryColumnDefs}
                            enableRangeSelection={true}
                            headerHeight={30}
                            onGridReady={onRGridReady}
                            overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                            }
                            overlayNoRowsTemplate={
                                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No items</span>'
                            }
                            enableCellTextSelection={true}
                            suppressCellFocus={true}
                            pinnedBottomRowData={pinnedBottomRowByReservedData}

                        >

                        </AgGridReact>
                    </div>

                </Col>

                
            </Row>
            <Row style={{marginTop:'25px'} }>
                <Col sm="6" style={{ height: '450px' }}>
                    <Row style={{ background: 'linear-gradient(120deg, #9C27B0, rgb(182, 221, 239))', textAlign: 'center', paddingTop: '3px', marginRight: '3px', marginLeft: '3px' }} ><Col> <h4>CANCELLATION REASONS</h4></Col> </Row>
                    <div style={{ height: '450px' }}>
                        <Pie data={pieChartData} options={pieOptions} />
                    </div>
                    </Col>
                <Col sm="6" style={{ height: '450px' }} >
                    <Row style={{ background: 'linear-gradient(120deg, rgb(239 160 187), rgb(182, 221, 239))', textAlign: 'center', paddingTop: '3px', marginRight: '3px', marginLeft: '3px' }} ><Col> <h4>CONFIRMED BOOKINGS - 6 MONTHS- RESERVED DATE</h4></Col> </Row>

                    <div style={{ height: '450px' }}>

                        <Bar options={barOptions} data={barChartData} />
                    </div>
               </Col>
            </Row>
        </Container>
    )

}


export default BulkEnquirySummary;