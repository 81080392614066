
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import BookingService from "../../services/BookingService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged"
import { WaitlistedRenderer } from "./WaitlistedRenderer"
import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";
import * as XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";



export function WaitlistedBooking(props) {

    const bookingService = new BookingService();
    const cancelModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };
    const [cancelModalIsOpen, setCancelModalIsOpen] = React.useState(false);
    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [bookingData, updateBookingData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        { cellRenderer: WaitlistedRenderer, headerName: '', width: 75 },

        { field: 'bookingId', headerName: 'Id', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true, cellRenderer: (d) => { return <Link to={`/bookings/d/${d.data.bookingId}`}>{d.data.bookingId}</Link> } },
        { field: 'reservationTime', headerName: 'Time', width: 100, sortable: true },
        { field: 'name', headerName: 'Name', width: 200, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'Mobile No', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'noOfPax', headerName: 'PAX', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'ocassion', headerName: 'Ocassion', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'restaurant', headerName: 'Restaurant', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'source', headerName: 'Source', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'externalBookingId', headerName: 'Source Booking Id', width: 175, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        { field: 'package', headerName: 'Package', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },

        { field: 'specialInstruction', headerName: 'Instruction', width: 185, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },

        { field: 'bookedBy', headerName: 'Booked By', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'bookedOn', headerName: 'Booked On', width: 120, sortable: true, cellRenderer: (d) => { return moment(d.data.bookedOn).format("DD/MM/YY H:mm:ss") } },
        { field: 'modifiedBy', headerName: 'Modified By', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'modifiedOn', headerName: 'Modified On', width: 120, sortable: true, cellRenderer: (d) => {
                if (d.data.modifiedOn != null)
                    return moment(d.data.modifiedOn).format("DD/MM/YY H:mm:ss")
                else
                    return d.data.modifiedOn
            }
        }
    ]);

    const [InProgress, setInProgress] = useState(false);

    const [bookingId, setBookingId] = useState(0)

    const [cancelComments, updateCancelComments] = useState('')

    const openCancelModal = () => {
        setCancelModalIsOpen(true)
    }

    const closeCancelModal = () => {
        setCancelModalIsOpen(false)
    }

    const updateBookingId = (val) => {
        setBookingId(val);
    }

    const updateBookingStatus = async (status) => {
        let result = await bookingService.UpdateReservationStatus(bookingId, status, '', cancelComments, logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                if (status === 'I') {
                    toast('Check-In successfull')
                }
                if (status === 'X') {
                    toast('Booking is cancelled', 'success')
                }

                getBookingData();
            }
            else {
                if (status === 'I') {
                    toast('Check-In failure', 'error')
                }
                if (status === 'X') {
                    props.context.showAlert('Booking cancellation failed', 'error')
                }
            }
        }
        else {
            toast('Error occured in check-in or cancellation of the booking', 'error');
        }

    }


    const showAlert = (message, type) => {

        if (type == 'error') {
            toast.error(message);
        }
        else if (type == 'success') {
            toast.success(message);
        }
    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const onGridReady = useCallback((params) => {
        getBookingData();
    }, []);


    const getBookingData = async () => {

        showLoading();
        let result = await bookingService.GetWaitListedBookings(logged.UserId)
        if (!result.redirect) {
            let rptData = result.data.data
            updateBookingData(rptData);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching report data');
            hideLoading();
        }
        props.refreshStatusCount();

    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "ActiveBooking" });
    }, []);




    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>


            <Row style={{ paddingTop: '25px', }}>
                <Col sm="4"></Col>
                <Col sm="4" style={{ textAlign: 'center', color: 'darkblue', fontWeight: 700 }}>WAITLISTED BOOKINGS</Col>

                <Col sm="4" style={{ textAlign: 'right' }}>
                    {/*<Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>*/}

                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>
            </Row>
            <div id="WaitlistedBooking">

                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={32}
                                ref={gridRef}
                                rowData={bookingData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                context={{ showAlert, getBookingData,updateBookingId, openCancelModal }}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }

                                suppressCellFocus={true}
                                enableCellTextSelection={true}
                            >

                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal isOpen={cancelModalIsOpen} style={cancelModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Reason For Cancellation</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={closeCancelModal}>

                                <AiFillCloseCircle />
                            </Button>
                        </IconContext.Provider>
                    </Col>

                </Row>
                <Row><Col sm="12" sytle={{ textAlign: 'center', fontWeight: 600 }}>Please provide the reason for cancellation</Col></Row>
                <Row style={{ paddingTop: '5px' }}><Col sm='12'> <textarea
                    rows="4" cols="50"

                    value={cancelComments}
                    onChange={e => updateCancelComments(e.target.value)}
                /> </Col></Row>

                <Row><Col sm='12' style={{ textAlign: 'right' }}>
                    <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={() => { closeCancelModal(); updateBookingStatus('X'); }} disabled={InProgress} >Save{""}</Button>
                    <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={closeCancelModal} disabled={InProgress} >Close{""}</Button>

                </Col></Row>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
