
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";
import { Restaurant } from "../Booking/Restaurant";



export function ItemOrderReport(props) {

    const reportService = new ReportService();


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '375px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [itemOrderData, updateItemOrderData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);

    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        updateItemOrderData([]);
    }

    useEffect(() => {
        //sizeToFit();
    }, [itemOrderData])



    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);


    const getColumDefs = (obj) => {
        var defs = [];
        let childs = [];
        if (!obj) return defs;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key == 'ItemName')
                    defs.push({ field: key, headerName: 'Item', width: 150, filter: "agTextColumnFilter", floatingFilter: true });
                else if (key == 'UnitName')
                    defs.push({ field: key, headerName: 'Unit Name', width: 120, filter: "agTextColumnFilter", floatingFilter: true });
              
                else
                    childs.push({ field: key, headerName: getHeaderName(key), width: 110 });
            }
        }
        defs.push({ headerName: 'QUANTITY ORDERED', children: childs })

        return defs;


    }

    const getHeaderName = (headerValue) => {
        const headerArray = headerValue.split(" ");
        return headerArray[1] + "-" + headerArray[0] + "-" + headerArray[2].substring(2, 4)
    }

    const getItemOrderData = async () => {
        if (isInputValid()) {
            showLoading();
            let result = await reportService.GetItemOrderData(startDate, endDate, selectedRestaurant.value);
            if (!result.redirect) {
                let strData = result.data.data
                let rptData = JSON.parse(strData);
                let o = rptData[0];
                let defData = getColumDefs(o)
                setColumnDefs(defData);
                updateItemOrderData(rptData);
                hideLoading();
                //sizeToFit();
            }
            else {
                toast.error('Error occured in fetching report data');
                hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "ItemOrderReport" });
    }, []);

    const isInputValid = () => {
        let isAfter = false;
        if (startDate !== null && endDate !== null) {
            isAfter = moment(endDate).isSameOrAfter(startDate);

        }


        if (selectedRestaurant == null) {
            toast.error('Please choose a restaurant')
            return false;
        }
        else if (startDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (endDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }

    return (
        isClose ? <Navigate to='/reports' /> : <Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ marginBottom: '10px', background: 'whitesmoke', padding: 0, margin: 0 }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> <span>ITEM ORDER REPORT</span> </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'whitesmoke', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row style={{ background: '#a4b3dc', padding: 0, margin: 0 }}>
                
               
              


                <Col sm="1" style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: '5px', marginTop: '10px' }} >
                            <label className="required-fields" >
                                Restaurant {` : `}

                    </label>
                </Col>
                <Col sm="2" style={{ marginTop: '6px', marginBottom: '6px' }}>
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />

                        </Col>
                 

                <Col sm="1" style={{ textAlign: 'right', padding: 0, marginTop: '10px' }}>
                            <label className="required-fields">
                                Start Date {` : `}

                    </label>
                </Col>


                <Col sm="2" style={{ marginTop: '6px' }}>
                    <DatePicker selected={startDate} onChange={(date) => { setStartDate(date); updateItemOrderData([]); }} />

                        </Col>




                <Col sm="1" style={{ textAlign: 'right', padding: 0, marginTop: '10px' }}>
                    <label className="required-fields">
                        End Date {` : `}

                    </label>
                </Col>


                <Col sm="2" style={{ marginTop: '6px' }}>
                            <DatePicker selected={endDate} onChange={(date) => { setEndDate(date); updateItemOrderData([]); }} />
                        </Col>

                <Col sm="2" style={{ marginTop: '8px', textAlign: 'right' }}>
                    <Button  color="success" size='sm' onClick={getItemOrderData}>Submit</Button>
                    <Button style={{ backgroundColor: '#a4b3dc', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                        </Col>

               
         

               
            </Row>


            <div id="itemOrderReport">
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={22}
                                ref={gridRef}
                                rowData={itemOrderData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }
                                suppressCellFocus={true} >


                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
