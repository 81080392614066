import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container } from 'reactstrap';
import AdministrationService from "../../services/AdministrationService";
import { IconContext } from "react-icons";
import { IoIosArrowDropupCircle, IoIosCloseCircle } from "react-icons/io";
import { Navigate } from 'react-router-dom';
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged";
import { GroceryCategory } from "./GroceryCategory";
import { useParams } from 'react-router-dom'


export function AddGroceryCategory(props) {

    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }


    const [groceryCategoryDetails, addGroceryCategoryDetails] = useState({ categoryId: 0, categoryName: '', isActive: '', createdBy: logged.UserId, modifiedBy: 0, createdDate: 0, modifiedDate: 0 })
    const [InProgress, setInProgress] = useState(false);
    const administrationService = new AdministrationService
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
        },
    };



    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const handleSubmit = (evt) => {

        addGroceryCategory();
    }

    const addGroceryCategory = async () => {
        let initialValue = { categoryName: '', createdBy: 0, modifiedBy: 0 }
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            let result = await administrationService.AddGroceryCategory(groceryCategoryDetails.categoryName, groceryCategoryDetails.createdBy, groceryCategoryDetails.modifiedBy);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();
                    addGroceryCategoryDetails({ ...groceryCategoryDetails, ...initialValue });
                    props.modalClose(true);
                    toast("Category has been successfully added")
                }
                else {
                    closeMessageModal();
                    toast(result.data.message);
                }
            }
            else {
                closeMessageModal();
                props.modalClose(false);
            }
        }
    }



    const validateForm = () => {
        if (groceryCategoryDetails.categoryName === '') {
            toast.error('Please enter the Grocery Category name', alertOptions)
            return false;
        }
        return true;
    }




    return (
        <Container>


            <Row style={{ padding: '25px' }}>

                <Col sm="12" style={{ textAlign:'center' }}>
                    Category Name{` : `}
                    <input
                        type="text"
                        value={groceryCategoryDetails.categoryName}
                        onChange={e => addGroceryCategoryDetails({ ...groceryCategoryDetails, categoryName: e.target.value })}
                    />

                    <Button style={{ marginLeft: '10px' }} color="primary" size="sm" onClick={handleSubmit} disabled={InProgress} >Save{""}</Button>
                </Col>


            </Row>


            {InProgress ? (<div>
                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" />
                    <Col sm="7" >
                        <h3> SAVING IN PROGRESS...</h3>

                    </Col>
                </Row>
                <Row>
                    <Col sm="2" />
                    <Col sm="7" >
                        <Progress animated color="info" value="100" />
                    </Col>
                </Row></div>) : (null)
            }


            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>
            {/*<ToastContainer*/}
            {/*    position="top-center"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="dark"*/}
            {/*/>*/}
        </Container>
    );
}