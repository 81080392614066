import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container } from 'reactstrap';
import AdministrationService from "../../services/AdministrationService";
import { IconContext } from "react-icons";
import { IoIosArrowDropupCircle, IoIosCloseCircle } from "react-icons/io";
import { Navigate } from 'react-router-dom';
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged";
import { GrocerySupplier } from "./GrocerySupplier";
import { useParams } from 'react-router-dom'


export function AddGrocerySupplier(props) {

    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }


    const [grocerySupplierDetails, addGrocerySupplierDetails] = useState({ supplierTypeId: 0, supplierName: '', restaurantId: [], address: '',mobileNo: '',emailAddress:'', isActive: '', createdBy: logged.UserId, modifiedBy: 0, createdDate: 0, modifiedDate: 0 })
    const [InProgress, setInProgress] = useState(false);
    const administrationService = new AdministrationService();
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [restaurants, updateRestaurants] = useState([]);
    const [message, setMessage] = useState('');
    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
        },
    };

    useEffect(() => {
        getRestaurants();
        if (props.mode == 'M' && props.selectedSupplierId != 0) {
            GetSupplierDetailsById();
        }
       
    }, [])

    const GetSupplierDetailsById = async () => {
        setMessage('LOADING IN PROGRESS');
        openMessageModal();
        let result = await administrationService.GetSupplierDetailsById(props.selectedSupplierId);
        if (!result.redirect) {

            addGrocerySupplierDetails({ ...grocerySupplierDetails, ...result.data.data });
            closeMessageModal();
        }
        else {
            closeMessageModal();
            toast.error('Error occured in fetching Grocery items');

        }

    }



    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const handleSubmit = (evt) => {

        addGrocerySupplier();
    }

    const getRestaurants = async () => {
        let result = await administrationService.GetRestaurants();
        if (!result.redirect) {

            updateRestaurants(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onRestaurantSelection = (event) => {

        if (event.target.checked) {
            let cloneRes = [...grocerySupplierDetails.restaurantId]
            cloneRes.push(parseInt(event.target.value))
            addGrocerySupplierDetails({ ...grocerySupplierDetails, restaurantId: cloneRes });

        }
        else {
            let cRestaturant = [...grocerySupplierDetails.restaurantId]
            for (let i = 0; i < cRestaturant.length; i++) {
                if (cRestaturant[i] === parseInt(event.target.value)) {
                    cRestaturant.splice(i, 1);
                    addGrocerySupplierDetails({ ...grocerySupplierDetails, restaurantId: cRestaturant });
                    break;
                }
            }
        }
    }

    const addGrocerySupplier = async () => {
        let initialValue = { supplierName: '',address:'',mobileNo:'', createdBy: 0, modifiedBy: 0 }
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            let result = await administrationService.AddGrocerySupplier(props.selectedSupplierId, grocerySupplierDetails.supplierName, grocerySupplierDetails.address, grocerySupplierDetails.mobileNo, logged.UserId, logged.UserId, grocerySupplierDetails.emailAddress, grocerySupplierDetails.restaurantId);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();
                    addGrocerySupplierDetails({ ...grocerySupplierDetails, ...initialValue });
                    props.modalClose(true);
                    toast(result.data.message);
                }
                else {

                    closeMessageModal();
                    toast(result.data.message);
                }
            }
            else {
                closeMessageModal();
                props.modalClose(false);
            }
        }
    }



    const validateForm = () => {
        if (grocerySupplierDetails.supplierName === '') {
            toast.error('Please enter the Grocery Supplier name', alertOptions)
            return false;
        }
        if (grocerySupplierDetails.address === '') {
            toast.error('Please enter the Grocery Supplier address', alertOptions)
            return false;
        }
        if (grocerySupplierDetails.mobileNo === '') {
            toast.error('Please enter the Grocery Supplier Mobile Number', alertOptions)
            return false;
        }
        return true;
    }




    return (
        <Container>


            <Row style={{ padding: '15px' }}>

                <Col sm="3">
                    <label>
                        Supplier Name{` : `}
                    </label>
                </Col>
                <Col sm="9">
                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={grocerySupplierDetails.supplierName}
                        onChange={e => addGrocerySupplierDetails({ ...grocerySupplierDetails, supplierName: e.target.value })}
                    />
                </Col>
            </Row>
            <Row style={{ padding: '15px' }}>
                <Col sm="3">
                    <label>
                        Address {` : `}
                    </label>
                </Col>
                <Col sm="9">
                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={grocerySupplierDetails.address}
                        onChange={e => addGrocerySupplierDetails({...grocerySupplierDetails, address: e.target.value }) }
                    />
                </Col>
            </Row>
            <Row style={{ padding: '15px' }}>
                <Col sm="3">
                    <label>
                        Mobile No{` : `}
                    </label>
                </Col>
                <Col sm="9">
                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={grocerySupplierDetails.mobileNo}
                        onChange={e => addGrocerySupplierDetails({ ...grocerySupplierDetails, mobileNo: e.target.value })}
                    />
                </Col>
            </Row>
            <Row style={{ padding: '15px' }}>
                <Col sm="3">
                    <label>
                        Email Address {` : `}
                    </label>
                </Col>
                <Col sm="9">
                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={grocerySupplierDetails.emailAddress}
                        onChange={e => addGrocerySupplierDetails({ ...grocerySupplierDetails, emailAddress: e.target.value })}
                    />
                </Col>
            </Row>
            <Row style={{ padding: '15px' }}>
                <Col sm="3">
                    <label>
                        Restaurants{` : `}
                    </label>
                </Col>
                <Col sm="9">

                    {restaurants.map((r, i) => (
                        <span key={i} style={{ paddingRight: '15px', color: 'royalblue' }}>                   <input key={r.value}
                            type="checkbox"
                            value={r.value}
                            onChange={onRestaurantSelection}
                            checked={grocerySupplierDetails.restaurantId.find(o => o === r.value) == null ? false : true}
                        />{` `}{r.label} </span>))}
                </Col>
            </Row>


            <Row style={{ paddingTop: '15px' }}>
                <Col sm="12" style={{ textAlign:'center' }}>
                    <Button style={{ textAlign: 'center', marginLeft: '10px' }} color="primary" size="sm" onClick={handleSubmit} disabled={InProgress} >Save{""}</Button>
                </Col>


            </Row>


            {InProgress ? (<div>
                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" />
                    <Col sm="7" >
                        <h3> SAVING IN PROGRESS...</h3>

                    </Col>
                </Row>
                <Row>
                    <Col sm="2" />
                    <Col sm="7" >
                        <Progress animated color="info" value="100" />
                    </Col>
                </Row></div>) : (null)
            }


            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>
            {/*<ToastContainer*/}
            {/*    position="top-center"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="dark"*/}
            {/*/>*/}
        </Container>
    );
}