import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container } from 'reactstrap'
import { AddBooking } from "./AddBooking"
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"

export function Restaurant(props) {

    const [restaurantId, setRestaurantId] = useState(0);
    const [showBooking, updateShowBooking] = useState(false)
    const [restaurantName,setRestaurantName]=useState('')

    const onRestaurantclick = (restaurantId) => {
        setRestaurantId(restaurantId);
        setRestaurantName(getRestaurantName(restaurantId))
        updateShowBooking(true);
    }

    const getAvailableRestaurants = () => {
        let rl = logged.Roles;
        let availableRestaurants=[]
        if (rl.length > 0) {
            let restcount = 1;
            let row = { restaurant1: 0, restaurant2: 0, restaurant3: 0 }

            for (let i = 0; i < rl.length; i++) {
                if (rl[i].roleId == Roles.Administrator || rl[i].roleId == Roles.FrontDesk || rl[i].roleId == Roles.CallCenter || rl[i].roleId == Roles.GeneralManager) {
                    row['restaurant' + restcount] = rl[i].restaurantId;
                    restcount++;
                    
                }
                if (restcount == 4 || i == rl.length - 1) {
                    const cloneRow = { ...row };
                    availableRestaurants.push(cloneRow);
                    restcount = 1;
                    row = { restaurant1: 0, restaurant2: 0, restaurant3: 0 };
                }
            }
        }

        return availableRestaurants;
    }

    const getRestaurantName = (id) => {
        let rl = logged.Roles;
        let rName = "";
        for (let i = 0; i < logged.Roles.length; i++) {
            if (rl[i].restaurantId === id) {
                rName = rl[i].restaurantName;
                break;
            }
        }

        return rName;

    }
    return (

        <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>
            {!showBooking ?

   

                 getAvailableRestaurants().map((r, i) => (
                     <Row key={i} style={{marginTop:'15px'} }>
                         <Col sm="4">
                             <button key={r.restaurant1} style={{ backgroundColor: 'white', border: 0 }}><img key={r.restaurant1} src={`./img/restaurantLogo/${r.restaurant1}.jpg`} alt="my image" onClick={() => onRestaurantclick(r.restaurant1)} style={{ width: '200px' }} /></button>
                         </Col>
                         {r.restaurant2 != 0 ? (<Col sm="4">
                             <button key={r.restaurant2} style={{ backgroundColor: 'white', border: 0 }}><img key={r.restaurant2} src={`./img/restaurantLogo/${r.restaurant2}.jpg`} alt="my image" onClick={() => onRestaurantclick(r.restaurant2)} style={{ width: '200px' }} /></button>
                         </Col>) : null}
                         {r.restaurant3 != 0 ? (<Col sm="4">
                             <button key={r.restaurant3} style={{ backgroundColor: 'white', border: 0 }}><img key={r.restaurant3} src={`./img/restaurantLogo/${r.restaurant3}.jpg`} alt="my image" onClick={() => onRestaurantclick(r.restaurant3)} style={{ width: '200px' }} /></button>
                         </Col>) : null}
                     </Row>
                 ) 
                    )

               

                : (<AddBooking restaurantId={restaurantId} restaurantName={restaurantName} mode={'n' } />)}

            
        </Container>
        
     )
}