import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import MultiSelect from "./MultiSelect.js";
import { components } from "react-select";
import { logged } from "../../lib/logged"

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const allOption = {
    label: "Select all",
    value: "*"
};

const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};

const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label}, `;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All";
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};

const animatedComponents = makeAnimated();
export default class BBStatusMultiSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionSelected: null,
            BBStatusOptions: []
        };
    }

    componentDidMount() {
        let res = this.getBBStatusOptions();
        this.setState({ BBStatusOptions: res });
    }


    handleChange = selected => {
        this.setState({
            optionSelected: selected
        });
        this.props.selectedBBStatusId(selected);
    };

    getBBStatusOptions = () => {
        let BBStatus = []
       
        BBStatus.push({ value: 'Cancelled', label: 'Cancelled' });
        BBStatus.push({ value: 'Confirmed', label: 'Confirmed' });
        BBStatus.push({ value: 'Under Discussion', label: 'Under Discussion' });

        
        return BBStatus;
    }



    render() {
        return (
            <span
                style={{ width: '300px' }}
                className="d-inline-block"
                data-toggle="popover"
                data-trigger="focus"
                data-content="Please selecet account(s)"
            >
                <MultiSelect
                    options={this.state.BBStatusOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option,
                        MultiValue,
                        ValueContainer,
                        animatedComponents
                    }}
                    onChange={this.handleChange}
                    allowSelectAll={true}
                    //value={this.state.optionSelected}
                    value={this.props.BBStatus}
                    isClearable={true}
                />


            </span>
        );
    }
}


