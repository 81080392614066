import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { AddUser } from "./components/Administration/AddUser"
import { AddBooking } from "./components/Booking/AddBooking"
import { Restaurant } from "./components/Booking/Restaurant"
import { AdminConsole } from "./components/Administration/AdminConsole"
import { ReportConsole } from "./components/Reports/ReportConsole"
import { Bookings } from "./components/Booking/Bookings";
import { DayCloseReport } from "./components/Reports/DayCloseReport"
import { BigTableReport } from "./components/Reports/BigTableReport"
import { PackageReport } from "./components/Reports/PackageReport"
import { BookingReport } from "./components/Reports/BookingReport"
import { CallAndBookingReport } from "./components/Reports/CallAndBookingReport"
import { FeedbackBookings } from "./components/Booking/FeedbackBookings"
import { ReminderBookings } from "./components/Booking/ReminderBookings"
import { MembershipReport } from "./components/Reports/MembershipReport"
import { Users } from "./components/Administration/Users";
import { MissedCalls } from "./components/MyOperator/MissedCalls";
import { Package } from "./components/Administration/Package";
import { Ocassion } from "./components/Administration/Ocassion";
import { GroceryUnitType } from "./components/Administration/GroceryUnitType";
import { GroceryItem } from "./components/Administration/GroceryItem";
import AddGroceryItem from "./components/Administration/AddGroceryItem";
import { IndentConsole } from "./components/Indent/IndentConsole"
import { GroceryCategory } from "./components/Administration/GroceryCategory";
import { GrocerySupplier } from "./components/Administration/GrocerySupplier";
import { ManagePrice } from "./components/Indent/ManagePrice";
import { PriceChangeReport } from "./components/Reports/PriceChangeReport"
import { FeedbackNotificationReport } from "./components/Reports/FeedbackNotificationReport"
import { ReminderNotificationReport } from "./components/Reports/ReminderNotificationReport"
import { IndentOrderList } from "./components/Indent/IndentOrderList";
import { PastIndentOrderList } from "./components/Indent/PastIndentOrderList";
import BulkItemSupplierMapping from "./components/Indent/BulkItemSupplierMapping";
import { IndentPendingApproval } from "./components/Indent/IndentPendingApproval";
import ConsolidatedIndentOrder from "./components/Indent/ConsolidatedIndentOrder";
import AddIndentOrderItem from "./components/Indent/AddIndentOrderItem";
import { ItemOrderReport } from "./components/Reports/ItemOrderReport"
import { BulkEnquiryReport } from "./components/Reports/BulkEnquiryReport"
import { Roles } from "./components/Administration/Roles";
import { IndentType } from "./components/Indent/IndentType";
import { Restaurants } from "./components/Administration/Restaurants";
import { BulkBooking } from "./components/Booking/BulkBooking";
import { BulkEnquiryDashboard } from "./components/Booking/BulkEnquiryDashboard";
import { AddBulkBooking } from "./components/Booking/AddBulkBooking";
import { FeedbackBulkEnquiry } from "./components/Booking/FeedbackBulkEnquiry";
import { IndentDepartment } from "./components/Indent/IndentDepartment";
import { AddIndentDepartment } from "./components/Indent/AddIndentDepartment";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/administration/adduser',
    element: <AddUser />
    },
    {
        path: '/bookings',
        element: <Bookings />
    },
    {
        path: '/bulkbooking',
        element: <BulkEnquiryDashboard />
    },
    {
        path: '/bulkbooking/:bId',
        element: <AddBulkBooking mode={'u'} from={'bulkenquiry'} />
    },
   {
      path: '/booking/addbooking',
      element: <AddBooking />
    },
    {
        path: '/booking/restaurant',
        element: <Restaurant />
    },
    {
        path: '/booking/bulkbooking',
        element: <BulkBooking />
    },
    {
        path: '/booking/addbulkbooking',
        element: <AddBulkBooking />
    },
    {
        path: '/administration',
        element: <AdminConsole />
    },
    {
        path: '/missedCalls',
        element: <MissedCalls />
    },
    {
        path: '/administration/users',
        element: <Users/>
    },
    {
        path: '/administration/users/:uId',
        element: <AddUser mode={'u' } />
    },
    {
        path: '/reports',
        element: <ReportConsole />
    },
    {
        path: '/bookings/:bId',
        element: <AddBooking mode={'u'} from={'reserv' } />
    },
    {
        path: '/indent/:bId',
        element: <AddIndentDepartment mode={'u'} from={'inddept'} />
    },
    {
        path: '/bookings/d/:bId',
        element: <AddBooking mode={'u'} from={'dash' } />
    },
    {
        path: '/reports/dayclose',
        element: <DayCloseReport />
    },
    {
        path: '/reports/bigtable',
        element: <BigTableReport />
    },
    {
        path: '/reports/package',
        element: <PackageReport />
    },
    {
        path: '/reports/booking',
        element: <BookingReport />
    },
    {
        path: '/reports/bulkenquiry',
        element: <BulkEnquiryReport />
    },
    {
        path: '/reports/callandbooking',
        element: <CallAndBookingReport />
    }
    ,
    {
        path: '/bookings/feedback',
        element: <FeedbackBookings />
    },
    {
        path: '/bookings/reminder',
        element: <ReminderBookings />
    }
    ,
    {
        path: '/reports/membership',
        element: <MembershipReport />
    },
       {
        path: '/administration/package',
        element: <Package />
    },
    {
        path: '/administration/package',
        element: <Package />
    },
    {
        path: '/administration/ocassion',
        element: <Ocassion />
    },
    {
        path: '/administration/addgroceryitem',
        element: <GroceryItem />
    },
    {
        path: 'administration/groceryunittype',
        element: <GroceryUnitType />
    },
    {
        path: '/indent',
        element: <IndentConsole />
    },
    {
        path: 'administration/grocerycategory',
        element: <GroceryCategory />
    },
    {
        path: 'administration/grocerysupplier',
        element: <GrocerySupplier />
    },
    {
        path: 'indent/manageprice',
        element: <ManagePrice />
    },
    {
        path: '/reports/pricechange',
        element: <PriceChangeReport />
    },
    {
        path: '/reports/feedbacknotification',
        element: <FeedbackNotificationReport />
    },
    {
        path: '/reports/remindernotification',
        element: <ReminderNotificationReport />
    },
    {
        path: '/indent/indentorderlist',
        element: <IndentOrderList />
    },
    {
        path: '/indent/pastindentorderlist',
        element: <PastIndentOrderList />
    },
    {
        path: '/indent/addIndentOrder/:indentId/:statusId/:mode',
        element: <AddIndentOrderItem />
    },
    {
        path: '/reports/itemorder',
        element: <ItemOrderReport />
    },
    {
        path: '/indent/bulksuppliermapping',
        element: <BulkItemSupplierMapping />
    },
    {
        path: '/indent/pendingapproval',
        element: <IndentPendingApproval />
    },
    
    {
        path: '/indent/consolidated',
        element: <ConsolidatedIndentOrder />
    },

    
    
    {
        path: '/administration/roles',
        element: <Roles />
    },
    {
        path: '/indent/indenttype',
        element: <IndentType />
    },
    {
        path: '/administration/restaurants',
        element: <Restaurants />
    },
    {
        path: '/booking/feedbackbulkenquiry',
        element: <FeedbackBulkEnquiry />
    },
    {
        path: '/indent/indentdepartment',
        element: <IndentDepartment />
    }

];


export default AppRoutes;
