import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import { Navigate, Link } from 'react-router-dom'
import AdministrationService from "../../services/AdministrationService"
import { AgGridReact } from 'ag-grid-react';
import { logged } from "../../lib/logged"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import 'react-toastify/dist/ReactToastify.css';
import { AddGroceryUnitType } from './AddGroceryUnitType';
import { AiFillCloseCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export function GroceryUnitType(props) {

    const administrationService = new AdministrationService();
    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef(null);

    const [InProgress, setInProgress] = useState(false);
    const [groceryUnitType, setGroceryUnitType] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        { field: 'value', headerName: 'Unit Type Id', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 200 },
        {
            field: 'label', headerName: 'Unit Name', filter: "agTextColumnFilter", floatingFilter: true, width: 275
        },
        { headerName: 'Inactivate', width: 200, cellRenderer: (d) => { return <Button onClick={() => { onInactivate(d.data.value); }} style={{ backgroundColor: '#ff1200', padding: '2px', margin: 0, marginBottom: '6px', visibility: 'visible' }} color="danger" size="sm" > Inactivate</Button> }, floatingFilter: false },
    ]);

    const onInactivate = async (unitTypeId) => {

        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure want to proceed with inactivating the unit type?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        let result = await administrationService.InactivateGroceryUnitType(unitTypeId);
                        toast.info(result.data);
                        if (!result.redirect) {
                            if (result.data.status == true) {
                                toast.success('Grocery Unit Type has been inactivated successfully')
                                getGroceryUnitTypes();
                            }
                            else {
                                toast.error(result.data.message);
                            }
                        }
                        else {
                            toast.error("Error occured while inactivating the Grocery Unit Type");

                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
        
      
    }

    useEffect(() => {
        sizeToFit();
    }, [groceryUnitType])

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: true
    })

    const onGridReady = useCallback(() => {
        getGroceryUnitTypes();
    }, []);

    const getGroceryUnitTypes = async () => {
        showLoading();
        let result = await administrationService.GetGroceryUnitTypes();
        if (!result.redirect) {

            setGroceryUnitType(result.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching Grocery Unit Type details');
            hideLoading();
        }
    }

    const onAdd = () => {
        setIsAdd(true);
    }
 
    const showAlert = (message, type) => {

        if (type === 'error') {
            toast.error(message);
        }
        else if (type === 'success') {
            toast.success(message);
        }
    }

    const unitTypeModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background:'#ede7f6'
        },
    };
    const [unitTypeModalIsOpen, setUnitTypeModalIsOpen] = useState(false);
    const closeUnitTypeModal = (status=0) => {
        setUnitTypeModalIsOpen(false)
        getGroceryUnitTypes();
        if (status == 1) {
           // toast.success('Unit Type has been successfully added')
        }
        else if (status == 0)
            toast.error("Error occured while adding Grocery Unit Type");
        else
            toast.info('Action has been cancelled');
    }
    const openUnitTypeModal = () => {
        setUnitTypeModalIsOpen(true)
    }



    return (


   <Container >

            <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> Grocery Unit Type </Col>
                <Col sm="1" style={{ textAlign: 'right' }}>  <Button color="primary" size="sm" onClick={openUnitTypeModal} disabled={InProgress} >Add{""}</Button></Col>
            </Row>
            <div style={containerStyle}>

                <div style={{ width: '100%', height: 500 }} className="ag-theme-alpine" >
                    <AgGridReact rowHeight={32}
                        ref={gridRef}
                        rowData={groceryUnitType}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{ showAlert, getGroceryUnitTypes }}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while bookings are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Grocery Unit Types to show</span>'
                        }

                        suppressCellFocus={true} >

                    </AgGridReact>
                </div>
            </div>


            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            <Modal isOpen={unitTypeModalIsOpen} style={unitTypeModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#3F51B5, #AB47BC)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Add Unit Types</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={()=>closeUnitTypeModal(2)}>
                                <AiFillCloseCircle />

                            </Button>
                        </IconContext.Provider>

                    </Col>

                </Row>
                <Row><Col sm="12"  ><AddGroceryUnitType modalClose={closeUnitTypeModal} /></Col></Row>
                
            </Modal> 

        </Container>
    )

}