import React from 'react';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import { SiAirtable } from "react-icons/si";
import {UncontrolledTooltip } from "reactstrap"
import { IconContext } from "react-icons";
import { ReservationStatus } from "../../lib/ReservationStatus";
import BookingService from "../../services/BookingService"




export function BookingActionRenderer(props) {

    const bookingService = new BookingService();

    const BookingAction = async (bookingId, status) => {
       
            let result = await bookingService.UpdateReservationStatus(bookingId, status,'','', logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    if (status === 'I') {
                        props.context.showAlert('Check-In successfull', 'success')
                    }
                    if (status === 'X') {
                        props.context.showAlert('Booking is cancelled', 'success')
                    }

                    props.context.getReservations();
                }
                else {
                    if (status === 'I') {
                        props.context.showAlert('Check-In failure', 'error')
                    }
                    if (status === 'X') {
                        props.context.showAlert('Booking cancellation failed', 'error')
                    }
                }
            }
            else {
                props.context.showAlert('Error occured in check-in or cancellation of the booking', 'error');
            }
        
    }

    const UpdateTable = async (bookingId) => {
        props.context.updateBookingId(bookingId);
        props.context.openCheckoutModal();
    }

    return (
        <React.Fragment>
        {/*    <button id="btncheckin" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, visibility: (logged.Roles[0].roleId == Roles.CallCenter || props.data.bookingStatus == 'Waitlisted' || props.data.bookingStatus == 'Check-In' || props.data.bookingStatus == 'Cancelled') ? 'hidden' : 'visible' }} onClick={() => { BookingAction(props.data.bookingId, ReservationStatus.CheckIn) }*/}
        {/*}>*/}
        {/*        <IconContext.Provider value={{ size: '22px', color: '#216ba5',verticalAlign:'sub' }}> < IoCheckmarkCircleSharp /></IconContext.Provider>*/}
        {/*    </button>*/}
            <button id="btncancel" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '2px', visibility: (props.data.bookingStatus == 'Check-In' || props.data.bookingStatus == 'Cancelled') ? 'hidden' : 'visible' }} onClick={() => { props.context.updateBookingId(props.data.bookingId); props.context.openCancelModal(); }
        }>
                <IconContext.Provider value={{ size: '22px', color: '#dc3545', verticalAlign:'sub' }}> < IoMdCloseCircle /></IconContext.Provider>
            </button>

            {/*<UncontrolledTooltip placement="left" target="btncheckin">*/}
            {/*    Check-In*/}
            {/*</UncontrolledTooltip>*/}

            <UncontrolledTooltip placement="left" target="btncancel">
                Cancel
            </UncontrolledTooltip>

        </React.Fragment>

    )

}