
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import RestaurantMultiSelect from "../Controls/RestaurantMultiDropDown"
import BBStatusMultiSelect from "../Controls/BBStatusDropDown"
import { BulkEnquiryStatus } from "../../lib/BulkEnquiryStatus";
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";



export function BulkEnquiryReport(props) {

    const reportService = new ReportService();


    const [reservationStartDate, setReservationStartDate] = useState(null);
    const [reservationEndDate, setReservationEndDate] = useState(null);

    const [selectedRestaurant, setSelectedRestaurant] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedDateType, setSelectedDateType] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0)


    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        updateBookingData([]);
    }


    const onStatusSelectionChange = (val) => {
        setSelectedStatus(val);
        updateBookingData([]);
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [bookingData, updateBookingData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'bulkEnquiryId', headerName: 'Enquiry Id', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'restaurantName', headerName: 'Restaurant', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'reserverdDate', headerName: 'Date', width: 120, sortable: true, cellRenderer: (d) => { return moment(d.data.reserverdDate).format("DD/MM/YY") }
        },
        {
            field: 'reservedTime', headerName: 'Time', width: 100, sortable: true
        },
        {
            field: 'bookingSourceName', headerName: 'Source', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'firstName', headerName: 'First Name', width: 140, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'mobileNo', headerName: 'Mobile No', width: 140, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'enquiryStatus', headerName: 'Enquiry Status', width: 170, sortable: true,
            cellStyle: (p) => {
                if (p.node.data.enquiryStatus == BulkEnquiryStatus.Confirmed) return { field: p.node.data.enquiryStatus = "Confirmed", color: "green", fontWeight: 600 }
                else if (p.node.data.enquiryStatus == BulkEnquiryStatus.UnderDiscussion) return { field: p.node.data.enquiryStatus = "Under Discussion", color: "red", fontWeight: 600 }
                else if (p.node.data.enquiryStatus == BulkEnquiryStatus.Completed) return { field: p.node.data.enquiryStatus = "Completed", color: "#216ba5", fontWeight: 600 }
                else if (p.node.data.enquiryStatus == BulkEnquiryStatus.Cancelled) return { field: p.node.data.enquiryStatus = "Cancelled", color: "grey", fontWeight: 600 }
            }, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'bookingId', headerName: 'Booking ID', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true

        },
        {
            field: 'cancelReasonName', headerName: 'Cancel Reason', width: 170, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'noOfPax', headerName: 'PAX', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'foodCostPerPax', headerName: 'Cost Per Pax', width: 160, filter: "agTextColumnFilter", floatingFilter: true, sortable: true,
            valueFormatter: params => {
                if (params.data.foodCostPerPax != null && params.data.foodCostPerPax != '' && params.data.foodCostPerPax != undefined) {
                    return parseFloat(params.data.foodCostPerPax).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }).substring(1)
                }
                else {
                    return parseFloat(0).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }).substring(1)
                }
            }
        },
        {
            field: 'foodTotalAmount', headerName: 'Total Amount', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true,
            valueFormatter: params => {
                if (params.data.foodTotalAmount != null && params.data.foodTotalAmount != '' && params.data.foodTotalAmount != undefined) {
                   return parseFloat(params.data.foodTotalAmount).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }).substring(1)
                }
                else {
                   return parseFloat(0).toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }).substring(1)
                }
            }
        },
        {
            field: 'createdBy', headerName: 'Created By', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'createDate', headerName: 'Create Date', width: 150, sortable: true, cellRenderer: (d) => { return moment(d.data.createDate).format("DD/MM/YY h:mm:ss") }
        },
        {
            field: 'modifiedBy', headerName: 'Modified By', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'modifyDate', headerName: 'Modified Date', width: 150, sortable: true, cellRenderer: (d) => {
                if (d.data.modifyDate != null)
                    return moment(d.data.modifyDate).format("DD/MM/YY h:mm:ss")
                else
                    return d.data.modifyDate
            }
        }

    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })



    const getBookingData = async () => {
        if (isInputValid()) {
           let choosenRestaurants = [];
            if (selectedRestaurant.length > 0) {
                for (let i = 0; i < selectedRestaurant.length; i++) {
                    if (selectedRestaurant[i].value!=='*')
                    choosenRestaurants.push(selectedRestaurant[i].value)

                }
            }
            let choosenStatus = [];
            if (selectedStatus.length > 0) {
                for (let i = 0; i < selectedStatus.length; i++) {
                    if (selectedStatus[i].value !== '*')
                        choosenStatus.push(selectedStatus[i].value)

                }
            }
            let res = choosenRestaurants.join(',')
            let st = choosenStatus.join(',')
            showLoading();
            let result = await reportService.GetBulkEnquiryData(selectedDateType, reservationStartDate, reservationEndDate,res,st  );
            if (!result.redirect) {
                let rptData = result.data.data
                let totAmount = 0;
                for (let m = 0; m < result.data.data.length; m++) {
                    if (result.data.data[m] != null && result.data.data[m]!='')
                    totAmount = totAmount + result.data.data[m].foodTotalAmount;
                }
                setTotalAmount(totAmount);
                //updateTotal([{
                //    status: 'Total Amount', foodTotalAmount: parseFloat(totAmount)

                //}])
                updateBookingData(rptData);
                hideLoading();
            }
            else {
                toast.error('Error occured in fetching report data');
                hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }
    const pinnedBottomRow = useMemo(() => {
        return [{
            bookingId: 'Total', foodTotalAmount: parseFloat('0.00')
                .toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                })
        }]

    }, []);

    const updateTotal = (rows) => {
        if (gridRef.current != null)
            gridRef.current.api.setPinnedBottomRowData(rows)
    }
    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "BulkEnquiryReport" });
    }, []);

    const isInputValid = () => {
        let isAfter = false;
        if (reservationStartDate !== null && reservationEndDate !== null) {
            isAfter = moment(reservationEndDate).isSameOrAfter(reservationStartDate);

        }

        if (selectedRestaurant.length == 0) {
            toast.error('Please choose a restaurant')
            return false;
        }
        else if (selectedStatus.length == 0) {
            toast.error('Please choose a status')
            return false;
        }
        else if (reservationStartDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (reservationEndDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }


    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingBottom: '20px' }}>
                <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> BULK ENQUIRY REPORT </Col>
                <Col sm="1" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row>

                <Col sm="2" style={{}} >
                    <label className="required-fields" >
                        Date Type {` : `}

                    </label>

                </Col>
                <Col sm="10">
                    <label>
                        <input
                            type="radio"
                            value={0}
                            checked={selectedDateType === 0}
                            onChange={()=>setSelectedDateType(0)}
                            name="dateType"
                        />
                        Create Date
                    </label>
                    <label style={{paddingLeft:'15px'} }>
                        <input
                            type="radio"
                            value={1}
                            checked={selectedDateType === 1}
                            onChange={() => setSelectedDateType(1)}
                            name="dateType"
                        />
                        Reserved Date
                    </label>
                </Col>

            </Row>
            <Row style={{paddingTop:'15px'} }>


                <Col sm="2" style={{ }} >
                    <label className="required-fields" >
                        Restaurant {` : `}

                    </label>

                </Col>
                <Col sm="3">
                    < RestaurantMultiSelect selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant} />

                </Col>


                <Col sm="2" style={{ }} >
                    <label className="required-fields" >
                        Status {` : `}

                    </label>

                </Col>
                <Col sm="3">
                    < BBStatusMultiSelect selectedBBStatusId={onStatusSelectionChange} BBStatus={selectedStatus} />

                </Col>
                </Row>
            <Row style={{paddingTop:"15px"} }>

                <Col sm="2" >
                    <label className="required-fields">
                        Start Date {` : `}

                    </label>
                </Col>


                <Col sm="3">
                    <DatePicker selected={reservationStartDate} onChange={(date) => { setReservationStartDate(date); updateBookingData([]); }} />
                </Col>

                <Col sm="2" >
                    <label className="required-fields">
                        End Date {` : `}

                    </label>
                </Col>


                <Col sm="3">
                    <DatePicker selected={reservationEndDate} onChange={(date) => { setReservationEndDate(date); updateBookingData([]); }} />
                </Col>

                <Col sm="2" >
                    <Button color="primary" size='sm' onClick={getBookingData}>Submit</Button>
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px', }}>
                <Col sm="8"></Col>
                <Col sm="3" style={{ textAlign:'center',backgroundColor: 'yellow', color: 'red', fontWeight: 'bold', paddingTop:'8px' }}>Total Amount : {parseFloat(TotalAmount).toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                }).substring(1)}</Col>

                <Col sm="1" style={{ textAlign: 'right' }}>
                    {/*<Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>*/}

                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>
                <Col sm="2" ></Col>
            </Row>
            <div id="bookingreport">
                {/*<Row>*/}

                {/*     <Col sm="12" style={{ textAlign: 'center' }}> <span style={{ color: 'black', fontWeight: 700 }}>BOOKING REPORT</span></Col>*/}
                {/* </Row> */}

                {/*<Row>*/}

                {/*    <Col sm="2" style={{ textAlign: 'left' }}> <span style={{ fontWeight: 700, color: "#6c757d" }}>{selectedRestaurant == null ? '' : selectedRestaurant.label}</span>{`     `} <span style={{ paddingLeft: '50px' }}>{reservationStartDate == null ? '' : ``}</span> </Col>*/}
                {/*    <Col sm="8"  ></Col>*/}
                {/*    <Col sm="2" style={{ textAlign: 'right' }} ><span style={{ fontWeight: 700, color: "#6c757d" }}>{reservationStartDate == null ? '' : moment(reservationStartDate).format(moment.HTML5_FMT.DATE)}</span> </Col>*/}
                {/*</Row>*/}
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={22}
                                ref={gridRef}
                                rowData={bookingData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }

                                suppressCellFocus={true}
                                enableCellTextSelection={true}
                               // pinnedBottomRowData={pinnedBottomRow}
                            >

                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
