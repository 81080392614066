var Roles = {
    Administrator: 1,
    FrontDesk: 2,
    CallCenter: 3,
    Service: 4,
    SousChef: 5,
    ExecutiveChef: 6,
    PurchaseManager:7,
    GeneralManager: 8,
    MarketingManager:9
}

export { Roles };