import React from 'react';
import { IoRemoveCircle } from "react-icons/io5";
import { IconContext } from "react-icons";


export function RemovePaymentRenderer(props) {

    return (
        <button style={{ backgroundColor: 'white', border: 0 }} onClick={() => {props.context.onPaymentRemove(props.data.dummyId) }
}>
            <IconContext.Provider value={{ size: '22px', color:'red' }}> < IoRemoveCircle /></IconContext.Provider>
</button>

        )

}