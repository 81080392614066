import React from 'react';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import { SiAirtable } from "react-icons/si";
import { IconContext } from "react-icons";
import { ReservationStatus } from "../../lib/ReservationStatus";
import BookingService from "../../services/BookingService"
import { CgUnavailable } from "react-icons/cg";
import { BsFillBagXFill } from "react-icons/bs";
import { HiEmojiSad } from "react-icons/hi";
import { UncontrolledTooltip } from "reactstrap"





export function WaitlistedRenderer(props) {

    const bookingService = new BookingService();

    const BookingAction = async (bookingId, status) => {

        let result = await bookingService.UpdateReservationStatus(bookingId, status,'','', logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                if (status === 'I') {
                    props.context.showAlert('Check-In successfull', 'success')
                }
                if (status === 'X') {
                    props.context.showAlert('Booking is cancelled', 'success')
                }

                props.context.getBookingData();
            }
            else {
                if (status === 'I') {
                    props.context.showAlert('Check-In failure', 'error')
                }
                if (status === 'X') {
                    props.context.showAlert('Booking cancellation failed', 'error')
                }
            }
        }
        else {
            props.context.showAlert('Error occured in check-in or cancellation of the booking', 'error');
        }

    }


    return (
        <React.Fragment>

         

            <button id="btncancel" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '10px' }} onClick={() => { props.context.updateBookingId(props.data.bookingId); props.context.openCancelModal(); }
            }>
                <IconContext.Provider value={{ size: '22px', color: '#dc3545', verticalAlign: 'sub' }}> < IoMdCloseCircle /></IconContext.Provider>
            </button>


            <UncontrolledTooltip placement="left" target="btncancel">
                Cancel
            </UncontrolledTooltip>
            

        </React.Fragment>

    )

}