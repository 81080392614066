import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Roles } from '../../lib/Roles';
import IndentService from "../../services/IndentService";
import { ItemMappedUnitTypesDropDown } from "../Controls/ItemMappedUnitTypesDropDown"
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import { Navigate, Link } from 'react-router-dom'
import { IoIosCloseCircle } from "react-icons/io";
import AsyncSelect from 'react-select/async';
import { IconContext } from "react-icons";
import { GrDocumentCsv } from "react-icons/gr";
import Modal from "react-modal";
import { useParams } from 'react-router-dom'
import { AiFillCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged"
import { ItemQuantityRenderer } from './ItemQuantityRenderer'
import { RemoveOrderItemRenderer } from './RemoveOrderItemRenderer'
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { IndentStatusId } from '../../lib/IndentStatusId';
import { IndentStatus } from '../../lib/IndentStatus';

const AddIndentOrderItem = (props) => {

    useEffect(() => {
        GetIndentOrderDetails();
        getGrocerySearch();
        GetIndentOrderItems();
        
    }, []);



    let _groceryData = [];
    let { indentId } = useParams();
    let { statusId } = useParams();
    let { mode } = useParams();

    const indentService = new IndentService()
    const [orderStatus, setOrderStatus] = useState(parseInt(statusId))
    const [grocerySearchItems, updateGrocerySearchItems] = useState([]);
    const [orderDetails, updateOrderDetails] = useState({indentOrderId:0,indentType:'',restaurantName:'',departmentName:'',status:''});
    const [selectedGroceryItem, updateSelectedGroceryItem] = useState(null);
    const [selectedUnitType, updateSelectedUnitType] = useState(null);
    const [quantity, updateQuantity] = useState('');
    const [initialLoad, setInitialLoad] = useState(0);
    const [groceryItems, updateGroceryItems] = useState([])
    const [itemsByCategory, updateItemsByCategory] = useState([])

    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const gridRef = useRef(null);
    const cgridRef = useRef(null);
    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);


    //useEffect(() => {
    //    sizeToFit();
    //}, [groceryItems])

    const [columnDefs, setColumnDefs] = useState([
        { headerName: '', colId:'remove',  width: 70, floatingFilter: false, cellRenderer: RemoveOrderItemRenderer },
        { field: 'listId', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'indentOrderListId', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'itemId', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'unitTypeId', headerName: '', filter: "agTextColumnFilter", hide: true },   
        { field: 'supplierName', headerName: 'Supplier', width: 225, filter: "agTextColumnFilter", floatingFilter: true },
        { field: 'category', headerName: 'Category', filter: "agTextColumnFilter", width: 175, floatingFilter: true },
        { field: 'item', headerName: 'Product Name', filter: "agTextColumnFilter", width: 190, floatingFilter: true },
        {
            field: 'unitType', headerName: 'UoM', filter: "agTextColumnFilter", width: 112,
},
        { field: 'quantity', headerName: 'Qty', width: 100, cellRenderer: ItemQuantityRenderer },
       
        {
            field: 'price', headerName: 'Unit Price', filter: "agTextColumnFilter", width: 160,
            cellStyle: (p) => {
                if (p.node.data.price == 'Total Amount') return { color: "Red", fontWeight: 'bold', background: 'yellow', textAlign: 'right' }
                else return { textAlign: 'right' }
            },
            valueFormatter: (params) => {
                if (params.node.data.price != 'Total Amount')
                    return params.data.price.toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                }).substring(1)
            }

        },
        {
            field: 'total', headerName: 'Total Price', filter: "agTextColumnFilter", width: 160,
            cellStyle: (p) => {
                if (p.node.data.price == 'Total Amount') return { color: "red", fontWeight: 'bold', background: 'yellow', borderLeftColor: 'black', textAlign: 'right' }
                else return { textAlign: 'right' }
            },
            valueFormatter: (params) => {
                if (params.node.data.price != 'Total Amount')
                    return params.data.total.toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }).substring(1)
            }

        },

      
        
    



    ]);

    const [categoryColumnDefs, setCategoryColumnDefs] = useState([
       
        {
            field: 'category', headerName: 'Category', filter: "agTextColumnFilter", width: 180,
            cellStyle: (p) => {
                if (p.node.data.category == 'Total Amount') return { color: "Red", fontWeight: 'bold', background: 'yellow' }
               
            }

        },
        {
            field: 'total', headerName: 'Total Price', filter: "agTextColumnFilter", width: 180,
            valueFormatter: params => params.data.total.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            }).substring(1),
            cellStyle: (p) => {
                if (p.node.data.category == 'Total Amount') return { color: "Red", fontWeight: 'bold', background: 'yellow', textAlign: 'right', borderLeftColor: 'black' }
                else
                 return { textAlign: 'right' }
            }
        }
    ]);


    const addrows = useCallback((gitems,addIndex) => {
        const newItems = [
            gitems
        ];
    gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: addIndex,
        });
      
    }, []);




    const getGridData =() => {
       
            let gdata = []
            gridRef.current.api.forEachNode(node => gdata.push(node.data));
            return gdata;
        
}

    const pinnedBottomRowIndentData = useMemo(() => {
        return [{
            listId: -1, indentOrderListId: 0, itemId: 0, unitTypeId: 0, item: '', category: '', unitType: '', quantity: '', supplierName: '', price: 'Total Amount', total: parseFloat('0.00').toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            })
        }]
       
    }, []);  

    const pinnedBottomRowCategoryData = useMemo(() => {
        return[{
            category: 'Total Amount', total: parseFloat('0.00').toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            })
        }]

    }, []);

    const updateTotal = (rows) => {
        if (gridRef.current != null)
        gridRef.current.api.setPinnedBottomRowData(rows)

    }
    
    const updateCategoryTotal = (rows) => {
        if (cgridRef.current != null)
        cgridRef.current.api.setPinnedBottomRowData(rows)
    }
    const onItemAdd = () => {

        let isValid = validateForm();
        if (isValid) {
            let itemAndCategory = selectedGroceryItem.label.split("-->");
            let _item = itemAndCategory[0].trim();
            let _category = itemAndCategory[1].trim();



            let itemFound = false;
            let _groceryItems = getGridData();
            let nextListId = 0;
            if (_groceryItems.length == 0) {
                nextListId = 1
            }
            else {
                let maxListId = Math.max(..._groceryItems.map(o => o.listId));
                nextListId = maxListId + 1;
            }


            for (let i = 0; i < _groceryItems.length; i++) {
                if (_groceryItems[i].unitTypeId === selectedUnitType.value && _groceryItems[i].itemId == selectedGroceryItem.value) {
                    itemFound = true
                    break;
                }

            }
            if (!itemFound) {

                addrows({ listId: nextListId, indentOrderListId: 0, itemId: selectedGroceryItem.value, unitTypeId: selectedUnitType.value, item: _item, category: _category, unitType: selectedUnitType.label, quantity: parseFloat(quantity),supplierName:'',price:0.0,total:0.0 }, undefined)
                updateSelectedGroceryItem(null);
                updateSelectedUnitType(null);
                updateQuantity('')

            }
            else {
                toast.info('The item is already in the list');
            }
        }

   
    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    const [close, setClose] = useState(false);


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false,
        sortable: true,
    })

    const onGridReady = useCallback(() => {


    }, []);

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }


    const onRemoveSelected = useCallback((selectedData) => {
       gridRef.current.api.applyTransaction({ remove: selectedData });
  
    }, []);
   

    function removeOrderItem(data) {
        onRemoveSelected([data]);
     }



    const onClose = () => {
        setClose(true);
    }





    const validateForm = () => {

        if (selectedGroceryItem == null) {
            toast.error('Please select an item')
            return false;
        }
        else if (selectedUnitType == null ) {
            toast.error('Please choose an unit type')
            return false;
        }
        else if (quantity == 0 || quantity=='') {
            toast.error('Please enter the required quantity')
            return false;
        }
      
        return true;
    }


    const getRowId = useMemo(() => {
        return (params) => {
            return params.data.listId;
        };
    }, []);


    const addUpdateItems = async () => {

        setMessage('SAVING IN PROGRESS');
        openMessageModal();
        let _groceryItems = getGridData();
        if (_groceryItems.length > 0) {
            for (let i = 0; i < _groceryItems.length; i++) {
                _groceryItems[i].quantity = _groceryItems[i].quantity == '' || parseFloat(_groceryItems[i].quantity) == NaN ? 0 : parseFloat(_groceryItems[i].quantity)

            }
            let result = await indentService.AddIndentOrderList(indentId, _groceryItems, logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();
                    toast("Indent order has been updated")
                    GetIndentOrderItems();
                }
                else {
                    closeMessageModal();
                    toast("Failure in adding grocery item")
                }
            }
            else {
                closeMessageModal();
                toast('Error occured while adding the grocery item', 'error');
            }
        }
        else
            toast.info("There are no records to save");
        
    }



    const saveItem =async  (sUpdate = false) => {
        //let _groceryItems = getGridData();
        //if (_groceryItems.length > 0) {
            await addUpdateItems();
            if (!sUpdate) {
                setClose(true);
            }
        //}
        //else
        //    toast.info("There are no records to save");
 

    }

    const updateItemQuantity = (val, id) => {
        var rowNode = gridRef.current.api.getRowNode(id);
        //if (val != '')
        //    rowNode.setDataValue('quantity', parseFloat(val));
        //else
            rowNode.setDataValue('quantity', val);
    }
    const groupBy=(array, key) =>{
        return Array.from(array
            .reduce((m, o) => m.set(o[key], [...(m.get(o[key]) || []), o]), new Map)
            .values()
        );
    }
  
    const GetIndentOrderItems = async () => {
        setMessage('LOADING IN PROGRESS');
        openMessageModal();
        let result = await indentService.GetIndentOrderItems(indentId);
        const categoryData = []

        if (!result.redirect) {
            if (result.data.data.length > 0 ) {

                const categoryResult = groupBy(result.data.data, 'category');

               
                    
                for (let j = 0; j < categoryResult.length; j++) {
                    let totalamt = 0;
                    for (let k = 0; k < categoryResult[j].length; k++) {
                        totalamt = totalamt + categoryResult[j][k].total;
                        if (k == categoryResult[j].length-1)
                            categoryData.push({ category: categoryResult[j][k].category,total:totalamt })
                    }
                    
                }
                let categoryTotalAmount = 0;
                for (let m = 0; m < categoryData.length; m++) {
                    categoryTotalAmount = categoryTotalAmount + categoryData[m].total;
                }
                updateCategoryTotal([{
                    category: 'Total Amount', total: parseFloat(categoryTotalAmount).toLocaleString('en-IN', {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                })
}])


               // if (statusId == IndentStatusId.Approved) {
                    let totalAmount = 0;
                    for (let i = 0; i < result.data.data.length; i++) {
                        totalAmount = totalAmount + result.data.data[i].total;

                    }

                    updateTotal([{
                        listId: -1, indentOrderListId: 0, itemId: 0, unitTypeId: 0, item: '', category: '', unitType: '', quantity: '', supplierName: '', price: 'Total Amount', total: parseFloat(totalAmount).toLocaleString('en-IN', {
                            maximumFractionDigits: 2,
                            style: 'currency',
                            currency: 'INR'
                        })
                    }])
                    //result.data.data.push()
                //}
            }

            updateGroceryItems(result.data.data);
            updateItemsByCategory(categoryData)

            if (orderStatus == IndentStatusId.Approved) {
                gridRef.current.columnApi.setColumnVisible('remove', false);
            }
            closeMessageModal();
        }
        else {
            closeMessageModal();
            toast.error('Error occured in fetching Grocery items');

        }

    }

    const GetIndentOrderDetails = async () => {

        let result = await indentService.GetIndentOrderDetails(indentId);
        if (!result.redirect) {

            updateOrderDetails(result.data.data);
            if (orderStatus !== IndentStatusId[result.data.data.status]) {
                //alert(result.data.data.status.replace(/\s/g, ''));
                //alert(IndentStatusId[result.data.data.status.replace(/\s/g, '')])
                setOrderStatus(IndentStatusId[result.data.data.status.replace(/\s/g, '')]);
            }
 
        }
        else {
  
            toast.error('Error occured in fetching Grocery items');

        }

    }

    const updateOrderStatus = async (action) => {
       
          await  saveItem(true);
        
        let status=''
        setMessage('UPDATING STATUS IN PROGRESS');
        openMessageModal();

        if (logged.Roles[0].roleId === Roles.ExecutiveChef && action == 'submit') {
            status = IndentStatus.L2Approval
        }
        else if (logged.Roles[0].roleId === Roles.PurchaseManager && action == 'submit') {
            status = IndentStatus.Approved
        }
        else if (logged.Roles[0].roleId === Roles.SousChef && action == 'submit') {
            status = IndentStatus.L1Approval

        }
        else if (logged.Roles[0].roleId === Roles.Administrator && action == 'submit') {
            status = IndentStatus.Approved
        }
        else if ((logged.Roles[0].roleId === Roles.ExecutiveChef) && action == 'approve') {
            status = IndentStatus.L2Approval

        }
        else if (logged.Roles[0].roleId === Roles.PurchaseManager && action == 'approve') {
            status = IndentStatus.Approved

        }
        else if (logged.Roles[0].roleId === Roles.Administrator && action == 'approve') {
            status = IndentStatus.Approved

        }
        else if (action == 'referback') {
            status = IndentStatus.Draft

        }

        let result = await indentService.UpdateOrderStatus(indentId, status, logged.UserId);
        if (!result.redirect) {
           // toast.info("Indent order has been updated");
            setClose(true);
            closeMessageModal();
        }
        else {
            closeMessageModal();
            toast.error('Error occured');

        }
    }

    const loadOptions = (inputValue, callBack) => {
        setTimeout(() => { callBack(filterGroceryItems(inputValue)) }, 500);
    }

    const handleInputChange = (newValue) => {



        if (newValue !== '' ) {
           
            
            return newValue;
        }
    }

    const onGroceryItemChange = (e) => {
       
            updateSelectedGroceryItem(e);

        

    }

    const filterGroceryItems = (inputValue) => {
        if (inputValue.length >= 3) {
            return grocerySearchItems.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
        else {
            if (grocerySearchItems.length > 0) {
                const cloneGroceryItems = [...grocerySearchItems];
                return cloneGroceryItems.splice(0, 100);

            }
            else
                return grocerySearchItems;
        }
    }

    const getGrocerySearch = async () => {
        let result = await indentService.GetGrocerySearch();
        if (!result.redirect) {
            updateGrocerySearchItems(result.data);
            setInitialLoad(1);
        }
        else {
            toast.error('Error occured in fetching grocery item for search');
        }
    }

    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "ItemOrder" });
    }, []);

    return (
        close ? (mode == 1 ? <Navigate to='/indent/indentorderlist' /> : <Navigate to='/indent/pastindentorderlist' />) : (<Container>
            <form >
                <Row style={{ paddingBottom: '3px' }}>
                    <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> MANAGE INDENT </Col>
                    <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
                </Row>
                {orderStatus == IndentStatusId.Approved || (logged.Roles[0].roleId === Roles.SousChef && orderStatus != IndentStatusId.Draft) || (logged.Roles[0].roleId == Roles.ExecutiveChef && orderStatus == IndentStatusId.L2Approval) ? null : <div>
                    <Row style={{ marginTop: '5px', background: '#b5eafb4a'}}>

                        <Col sm="2" style={{ paddingRight: 0 }} >
                            <label className="required-fields" style={{ color: '#2691d9', fontSize: '16px' }}>
                                Search items:

                            </label>
                        </Col>
                        <Col sm="8">
                            <AsyncSelect key={initialLoad} value={selectedGroceryItem == null ? 0 : selectedGroceryItem} isClearable={true} cacheOptions loadOptions={loadOptions} defaultOptions onInputChange={handleInputChange} onChange={onGroceryItemChange} />

                        </Col>



                    </Row>

                    <Row style={{ background: '#b5eafb4a', paddingBottom: '5px' }}>
                        <Col sm="2" style={{ paddingRight: 0, marginTop: '15px' }}>
                            <label className="required-fields" style={{ marginBottom: '5px', color: '#2691d9', fontSize: '16px' }}>
                                Select Unit

                            </label>
                        </Col>
                        <Col sm="3" style={{ marginTop: '15px' }}>

                            <ItemMappedUnitTypesDropDown key={selectedGroceryItem == null ? 0 : selectedGroceryItem.value} selectedUnitTypeId={updateSelectedUnitType} selectedItemId={selectedGroceryItem == null ? 0 : selectedGroceryItem.value} unitType={selectedUnitType} />
                        </Col>

                        <Col sm="1" style={{ paddingRight: 0, marginTop: '15px' }}>
                            <label className="required-fields" style={{ color: '#2691d9', fontSize: '16px' }}>Quantity</label>
                        </Col>

                        <Col sm="2" style={{ marginTop: '15px' }} >


                            <input
                                type="text"
                                
                                value={quantity}
                                onChange={e => updateQuantity(e.target.value)}
                                style={{ height: '35px', width: '100%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'lightgrey' }}
                            />




                        </Col>
                        <Col sm="2" style={{ marginTop: '15px' }}>
                            <Button style={{ marginRight: '5px',marginTop:'3px' }} color="success" size='sm' onClick={onItemAdd} >ADD{""}</Button>
                        </Col>


                    </Row>

                </div>}
                <Row style={{ marginTop: '10px', textAlign:'center' }}>

                    <Col sm="11">
                        <span style={{ fontWeight: 500 }}>{`No : `}</span> <span style={{ color: '#5f0fe9' }}>{orderDetails.indentOrderId}</span>
                        <span style={{ paddingLeft: '15px', fontWeight: 500 }}>{`Indent Type : `}</span> <span style={{ color: '#5f0fe9' }}>{orderDetails.indentType}</span>
                        <span style={{ paddingLeft: '15px', fontWeight: 500 }}>{`Restaurant : `}</span> <span style={{ color: '#5f0fe9' }}>{orderDetails.restaurantName}</span>
                        <span style={{ paddingLeft: '15px', fontWeight: 500 }}>{`Department : `}</span> <span style={{ color: '#5f0fe9' }}>{orderDetails.departmentName}</span>
                        <span style={{ paddingLeft: '15px', fontWeight: 500 }}>{`Status : `}</span> <span style={{ color: '#5f0fe9' }}>{orderDetails.status}</span>
                    </Col><Col><Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button></Col>


                </Row>

    
                <Row >

                    <Col sm="12" style={{ margin: 0, padding: 0, marginTop: '3px' }}>

                        <div style={{ width: '100%', height: 400 }} className="ag-theme-alpine" >
                            <AgGridReact rowHeight={25}
                                ref={gridRef}
                                headerHeight={30}
                                floatingFiltersHeight={30}
                                rowData={groceryItems}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                context={{ updateItemQuantity, removeOrderItem, orderStatus, groceryItems }}
                                getRowId={getRowId}
                                animateRows={true}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add items to be ordered</span>'
                                }
                                enableCellTextSelection={true}
                                suppressCellFocus={true}
                                pinnedBottomRowData={pinnedBottomRowIndentData}
                            >
                                
                            </AgGridReact>
                        </div>

                    </Col>
                </Row>

                {orderStatus == IndentStatusId.L2Approval || orderStatus == IndentStatusId.Approved ? <Row style={{ marginTop: '10px' }}>
                <Col sm="4" />

                    <Col sm="4" style={{ margin: 0, padding: 0, marginTop: '3px'}}>

                        <div style={{ width: '100%', height: 250 }} className="ag-theme-alpine" >
                            <AgGridReact rowHeight={25}
                                ref={cgridRef}
                                rowData={itemsByCategory}
                                defaultColDef={defaultColDef}
                                columnDefs={categoryColumnDefs}                
                                enableRangeSelection={true}
                                headerHeight={30}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add items to be ordered</span>'
                                }
                                enableCellTextSelection={true }
                                suppressCellFocus={true}
                                pinnedBottomRowData={pinnedBottomRowCategoryData}

                            >
                                
                            </AgGridReact>
                        </div>

                    </Col>
                    <Col sm="4" />
                </Row> :null}

              





                <Row style={{ marginTop: '15px' }}>

                    <Col sm="12" style={{ textAlign: 'center',marginBottom:'10px' }}>
                        {orderStatus == IndentStatusId.Approved || (logged.Roles[0].roleId == Roles.SousChef && orderStatus != IndentStatusId.Draft) || (logged.Roles[0].roleId == Roles.ExecutiveChef && orderStatus == IndentStatusId.L2Approval) ?null: <Button style={{ marginRight: '5px' }} color="primary" size='md' onClick={()=>saveItem()} >Save{""}</Button>}
                        <Button style={{ marginRight: '5px' }} color="secondary" size='md' onClick={onClose} >Close{""}</Button>
                        {orderStatus == IndentStatusId.Draft ? <Button style={{ marginRight: '5px' }} color="success" size='md' onClick={() => { updateOrderStatus('submit') }} >Submit{""}</Button> : null}
                        {orderStatus == IndentStatusId.Draft || orderStatus == IndentStatusId.Approved || (logged.Roles[0].roleId == Roles.ExecutiveChef && orderStatus == IndentStatusId.L2Approval) || (logged.Roles[0].roleId == Roles.SousChef && orderStatus == IndentStatusId.L1Approval) ? null : <Button style={{ marginRight: '5px' }} color="warning" size='md' onClick={() => { updateOrderStatus('approve') }}>Approve{""}</Button>}
                        {orderStatus == IndentStatusId.Draft || orderStatus == IndentStatusId.Approved || (logged.Roles[0].roleId == Roles.ExecutiveChef && orderStatus == IndentStatusId.L2Approval) || (logged.Roles[0].roleId == Roles.SousChef && orderStatus == IndentStatusId.L1Approval) ? null : <Button style={{ marginRight: '5px' }} color="info" size='md' onClick={() => { updateOrderStatus('referback') } } >ReferBack{""}</Button>}
                    </Col>


                </Row>

                <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
                </Modal>

            </form>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />


        </Container>)
    );
};

export default AddIndentOrderItem;