import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function GroceryCategoryDropDown(props) {

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '30px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
    };

    const administrationService = new AdministrationService();

    const [groceryCategory, updateGroceryCategory] = useState([]);

    useEffect(() => { getGroceryCategories(); }, [])

    const getGroceryCategories = async () => {
        let result = await administrationService.GetGroceryCategories();
        if (!result.redirect) {

            updateGroceryCategory(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {

        props.selectedCategoryId(val)

    }


    return (
        <Select options={groceryCategory} isClearable={true} onChange={onSelectChange} value={props.categoryType == null ? null : groceryCategory.find(o => o.value === props.categoryType)} styles={customStyles} />
    );



}