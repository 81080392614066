import { Link } from 'react-router-dom'
import { Row, Col, Button, Progress, Container } from 'reactstrap'
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
export function ReportConsole(props) {


    return (
        <Container style={{ backgroundColor: "white", height: "100%" }}>
            <Row style={{ paddingTop: '25px' }}>
                <Col sm="6" style={{ height: '40px', color: 'white', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', paddingTop: '10px', background: 'linear-gradient(120deg, #3F51B5, #F1F8E9)' }}>
                    REPORTS
                </Col>
                <Col sm="6" style={{ height: '40px', color: 'black', fontWeight: 500, borderBlockColor: 'black', borderLeftStyle: 'solid', borderColor:'darkblue', borderWidth: 'thin', paddingTop: '10px', background: 'linear-gradient(120deg, #F1F8E9,#3F51B5)' }}>
                    SUMMARY

                </Col>

            </Row>
            <Row syle={{ paddingTop: '15px' }} >
                <Col sm="12" style={{ marginTop: '15px',background: 'linear-gradient(120deg, #42A5F5, #00BCD4)', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', color: 'white', height:'30px' ,paddingTop:'5px'}}>
                    BOOKING 
                </Col>


            </Row>
            <Row style={{minHeight:'25px' } }>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87',paddingLeft: '25px' }}>
                    <Link to="/reports/dayclose">Day Close Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87' }}>
                    Generate day close report
                </Col>

            </Row>

            <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa', paddingLeft: '25px' }}>
                    <Link to="/reports/bigtable">Big Table Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background:'#e0f7fa' }}>
                    Show reservation details made for 5 or more PAX
                </Col>
            </Row >
            <Row style={{ minHeight: '25px' }} >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87', paddingLeft: '25px' }}>
                        <Link to="/reports/package">Package Report</Link>
                    </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87' }}>
                        Show package details requested by reservations
                    </Col>

                </Row>
            <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa', paddingLeft: '25px' }}>
                    <Link to="/reports/booking">Booking Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa' }}>
                    Show booking information
                </Col>

            </Row>
            <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87', paddingLeft: '25px' }}>
                    <Link to="/reports/callandbooking">Call And Booking Analysis Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87' }}>
                    Shows the number of calls attended, missed and booking taken
                </Col>

            </Row>
            {logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.GeneralManager ? (<Row style={{ minHeight: '25px' }} >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa', paddingLeft: '25px' }}>
                    <Link to="/reports/membership">Membership Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa' }}>
                    Shows the member details
                </Col>

            </Row>) : null}

            {logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.GeneralManager || logged.Roles[0].roleId==Roles.MarketingManager ? (<Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa', paddingLeft: '25px' }}>
                    <Link to="/reports/bulkenquiry">Bulk Enquiry Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa' }}>
                    Shows the bulk enquiry bookings
                </Col>

            </Row>) : null}
           

            <Row syle={{ paddingTop: '15px' }} >
                <Col sm="12" style={{ marginTop: '15px', background: 'linear-gradient(120deg, #42A5F5, #00BCD4)', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', color: 'white', height: '30px', paddingTop: '5px' }}>
                    INDENT
                </Col>

            

            </Row>
            <Row style={{ minHeight: '25px' }} >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87', paddingLeft: '25px' }}>
                    <Link to="/reports/pricechange">Price Change Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87' }}>
                    Shows the change in price of the grocery items
                </Col>

            </Row>
            <Row style={{ minHeight: '25px' }} >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87', paddingLeft: '25px' }}>
                    <Link to="/reports/itemorder">Item Order Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87' }}>
                    Shows the quantities of items ordered
                </Col>

            </Row>
            <Row syle={{ paddingTop: '15px' }} >
                <Col sm="12" style={{ marginTop: '15px', background: 'linear-gradient(120deg, #42A5F5, #00BCD4)', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', color: 'white', height: '30px', paddingTop: '5px' }}>
                    NOTIFICATIONS
                </Col>


            </Row>


            <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87', paddingLeft: '25px' }}>
                    <Link to="/reports/feedbacknotification">Feedback Notification Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#cfeeac87' }}>
                    Show the feedback message notification status for the bookings
                </Col>

            </Row>


            <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa', paddingLeft: '25px' }}>
                    <Link to="/reports/remindernotification">Reminder Notification Report</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'none', borderWidth: 'thin', borderTopStyle: 'none', background: '#e0f7fa' }}>
                    Shows the number of calls attended, missed and booking taken
                </Col>

            </Row>

            
        </Container>
    )

}