export default class SecurityService {

    async ValidateLogin(loginId, password) {
        try {

            const response = await fetch("api/security/ValidateLogin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    LoginId: loginId,
                    Password: password,


                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
}