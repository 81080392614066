import React from 'react';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import { SiAirtable } from "react-icons/si";
import { FiPhoneMissed } from "react-icons/fi";
import { UncontrolledTooltip } from "reactstrap"
import { IconContext } from "react-icons";
import { ReservationStatus } from "../../lib/ReservationStatus";
import MyOperatorService from "../../services/MyOperatorService"




export function MissedActionRenderer(props) {

    const myOperatorService = new MyOperatorService();

    const purgeAction = async (callLogId) => {

        let result = await myOperatorService.PurgeRecord(callLogId, logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
               
                    props.context.showAlert('Record has been successfully purged', 'success')
                
                    if(props.context.DayCode=='T')
                        props.context.getTodaysData();
                else
                        props.context.getYesterdaysData();
            }
            else {
                
                    props.context.showAlert('Failure in purging the missed call record', 'error')
                }
            
        }
        else {
            props.context.showAlert('Error occured in purging the missed call record', 'error');
        }

    }

 

    return (
        <React.Fragment>
            <button id="btnPurge" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0 }} onClick={() => { purgeAction(props.data.callLogId) }
            }>
                <IconContext.Provider value={{ size: '22px', color: '#216ba5', verticalAlign: 'sub' }}> < FiPhoneMissed /></IconContext.Provider>
            </button>
           
            <UncontrolledTooltip placement="left" target="btnPurge">
                Purge
            </UncontrolledTooltip>

    

        </React.Fragment>

    )

}