import moment from "moment"
import { logged } from "../lib/logged"

export default class AdministrationService {

    async AddRestaurant(restaurantName, restaurantCode, createdBy) {
        try {

            const response = await fetch("api/administration/AddRestaurant", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantName: restaurantName,
                    RestaurantCode: restaurantCode,
                    CreatedBy: createdBy

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async AddUser(loginId, password, firstName,lastName,emailAddress,restaurantId,roleId ,createdBy,mobileNo) {
        try {

            const response = await fetch("api/administration/AddUser", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    LoginId: loginId,
                    Password: password,
                    FirstName: firstName,
                    LastName: lastName,
                    EmailAddress: emailAddress,
                    RestaurantId: restaurantId,
                    RoleId: roleId,
                    CreatedBy: createdBy,
                    MobileNo:mobileNo

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetRestaurants() {
        try {

            const response = await fetch("api/administration/GetRestaurants", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetCountries() {
        try {

            const response = await fetch("api/administration/GetCountries", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetStates(countryId) {
        try {

            const response = await fetch("api/administration/GetStates", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CountryId: countryId

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetOcassions() {
        try {

            const response = await fetch("api/administration/GetOcassions", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetIdCardTypes() {
        try {

            const response = await fetch("api/administration/GetIdCardTypes", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBookingSources() {
        try {

            const response = await fetch("api/administration/GetBookingSources", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetPackages() {
        try {

            const response = await fetch("api/administration/GetPackages", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetPaymentModes() {
        try {

            const response = await fetch("api/administration/GetPaymentModes", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetRoles() {
        try {

            const response = await fetch("api/administration/GetRoles", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetUsers() {
        try {

            const response = await fetch("api/administration/GetUsers", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetUserById(userId) {
        try {

            const response = await fetch("api/administration/GetUserById", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,
                   

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateUser(userId,loginId, password, firstName, lastName, emailAddress, restaurantId, roleId, createdBy,mobileNo) {
        try {

            const response = await fetch("api/administration/UpdateUser", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId:userId,
                    LoginId: loginId,
                    Password: password,
                    FirstName: firstName,
                    LastName: lastName,
                    EmailAddress: emailAddress,
                    RestaurantId: restaurantId,
                    RoleId: roleId,
                    CreatedBy: createdBy,
                    MobileNo:mobileNo

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivateUser(userId) {
        try {

            const response = await fetch("api/administration/InactivateUser", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,
                    ModifiedBy: logged.UserId

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async AddPackage(packageName, createdBy, modifiedBy) {

        try {

            const response = await fetch("api/administration/AddPackage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    PackageName: packageName,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async UpdatePackage(packageId, packageName, isActive, createdBy, modifiedBy, createdDate, modifyDate) {
        try {

            const response = await fetch("api/administration/UpdatePackages", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    PackageId: packageId,
                    PackageName: packageName,
                    IsActive: isActive,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy,
                    CreateDate: createdDate,
                    ModifyDate: modifyDate

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivatePackage(packageId) {
        try {

            const response = await fetch("api/administration/InactivatePackage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    PackageId: packageId,
                    ModifiedBy: logged.UserId

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async AddOcassion(ocassionName, createdBy, modifiedBy) {

        try {

            const response = await fetch("api/administration/AddOcassion", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    OcassionName: ocassionName,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivateOcassion(ocassionId) {
        try {

            const response = await fetch("api/administration/InactivateOcassion", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    OcassionId: ocassionId,
                    ModifiedBy: logged.UserId

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetGroceryCategories() {
        try {

            const response = await fetch("api/administration/GetGroceryCategories", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    
    async InactivateGroceryCategory(catergoryId) {
        try {

            const response = await fetch("api/administration/InactivateGroceryCategory", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CategoryId: catergoryId,
                    ModifiedBy: logged.UserId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    
    async AddGroceryCategory(categoryName, createdBy, modifiedBy) {

        try {
            
            const response = await fetch("api/administration/AddGroceryCategory", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CategoryName: categoryName,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetGroceryUnitTypes() {
        try {

            const response = await fetch("api/administration/GetGroceryUnitTypes", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    
    async InactivateGroceryUnitType(unitTypeId) {
        try {

            const response = await fetch("api/administration/InactivateGroceryUnitType", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UnitTypeId: unitTypeId,
                    ModifiedBy: logged.UserId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }


    async AddGroceryUnitType(unitName, createdBy, modifiedBy) {

        try {

            const response = await fetch("api/administration/AddGroceryUnitType", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UnitName: unitName,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }


    async GetGrocerySuppliers() {
        try {

            const response = await fetch("api/administration/GetGrocerySuppliers", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetRestaurantSuppliers(restaurantId) {
        try {

            const response = await fetch("api/administration/GetRestaurantSuppliers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetItemSuppliers(itemId) {
        try {

            const response = await fetch("api/administration/GetItemSuppliers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    



    async GetGroceryItemSuppliers(itemId) {
        try {

            const response = await fetch("api/administration/GetGroceryItemSuppliers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetGroceryItemDepartments(itemId) {
        try {

            const response = await fetch("api/administration/GetGroceryItemDepartments", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }


    async GetGroceryItemUnits(itemId) {
        try {

            const response = await fetch("api/administration/GetGroceryItemUnits", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }



    async AddGroceryItem(itemName,itemCategory,itemUnits,itemSupplier,createdBy) {

        try {

            const response = await fetch("api/administration/AddGroceryItem", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
               body: JSON.stringify({
                   ItemName: itemName,
                   ItemCategory: itemCategory ,
                   ItemUnits: itemUnits,                   
                   ItemSupplier: itemSupplier,
                   CreatedBy: createdBy,

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetGroceryItemData() {
        try {

            const response = await fetch("api/administration/GetGroceryItemData", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivateGrocerySupplier(supplierId) {
        try {

            const response = await fetch("api/administration/InactivateGrocerySupplier", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    SupplierID: supplierId,
                    ModifiedBy: logged.UserId

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async AddGrocerySupplier(supplierId,supplierName, address, mobileNo, createdBy, modifiedBy,emailAddress,restaurantId) {

        try {

            const response = await fetch("api/administration/AddGrocerySupplier", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    SupplierId:supplierId,
                    SupplierName: supplierName,
                    Address: address,
                    MobileNo: mobileNo,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy,
                    EmailAddress: emailAddress,
                    RestaurantId:restaurantId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }


    async InactivateGroceryItem(itemId) {
        try {

            const response = await fetch("api/administration/InactivateGroceryItem", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,
                    ModifiedBy: logged.UserId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetGroceryDetailsById(itemId) {
        try {

            const response = await fetch("api/administration/GetGroceryDetailsById", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,
                   
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateGroceryItem(itemId,itemName, itemCategory, itemUnits, itemSupplier, createdBy) {

        try {

            const response = await fetch("api/administration/UpdateGroceryItem", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId:itemId,
                    ItemName: itemName,
                    ItemCategory: itemCategory,
                    ItemUnits: itemUnits,
                    ItemSupplier: itemSupplier,
                    CreatedBy: createdBy,

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async SaveSupplierMapping(itemId,suppliers, createdBy) {
        try {

            const response = await fetch("api/administration/SaveItemSupplierMapping", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,
                    CreatedBy: createdBy,
                    Suppliers: suppliers
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }


    }


    async SaveDepartmentMapping(itemId, departments, createdBy) {
        try {

            const response = await fetch("api/administration/SaveItemDepartmentMapping", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,
                    CreatedBy: createdBy,
                    Departments: departments
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }


    }

    async SaveUnitMapping(itemId, units, createdBy) {
        try {

            const response = await fetch("api/administration/SaveItemUnitMapping", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId,
                    CreatedBy: createdBy,
                    Units: units
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }


    }
    async GetSupplierDetailsById(supplierId) {
        try {

            const response = await fetch("api/administration/GetSupplierDetailsById", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    SupplierId: supplierId

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivateRole(roleId) {
        try {

            const response = await fetch("api/administration/InactivateRole", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RoleId: roleId,
                    ModifiedBy: logged.UserId

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async AddRole(roleName, createdBy, modifiedBy) {

        try {

            const response = await fetch("api/administration/AddRole", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RoleName: roleName,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivateRestaurant(restaurantId) {
        try {

            const response = await fetch("api/administration/InactivateRestaurant", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ModifiedBy: logged.UserId

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

}



