import { Navigate, Outlet } from 'react-router-dom'
import { Layout } from './components/Layout';
import { logged } from './lib/logged'
export const SecuredRoutes = () => {
    let auth = logged.AuthDate
    if (logged.IsAuth == undefined)
        auth = false;
    else
        auth = logged.AuthDate
    
    console.log('secured')
    return (
        auth ? <Layout><Outlet />  </Layout> : <Navigate to='/login' />
    )
}