
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import BookingService from "../../services/BookingService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged"
//import { CheckedInRenderer } from "./CheckedInRenderer"
import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";

import * as XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";



export function ReminderBookings(props) {

    const [bookingData, updateBookingData] = useState([]);

    const bookingService = new BookingService();
    useEffect(() => {
        getBookingData();

    }, [])



 

    const getBookingData = async () => {


        let result = await bookingService.GetBookingsForReminder(logged.UserId)
        if (!result.redirect) {
            let rptData = result.data.data
            updateBookingData(rptData);

        }
        else {
            toast.error('Error occured in fetching report data');

        }


    }

    const updateReminderStatus = async (bookingId) => {

        bookingService.UpdateReminderSendStatus(bookingId);

    }





    return (
        <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>


            <Row style={{ paddingTop: '25px', }}>
                <Col sm="4"></Col>
                <Col sm="4" style={{ textAlign: 'center', color: 'darkblue', fontWeight: 700 }}>REMINDER ALERTS</Col>


            </Row>
            <div id="CheckedInBooking">

                {bookingData.length > 0 ? bookingData.map((d, i) => (
                    <Row noGutters={true} style={{ height: '32px' }} key={i}>
                        <Col sm="2" style={{ color: 'blue', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '2px', paddingRight: 0, fontWeight: 500, borderTop: 'none', textAlign: 'left' }}><button key={i} id={`#btn${d.bookingId}`} data-testid="reminder-button" onClick={e => { updateReminderStatus(d.bookingId); }}>Update</button></Col>
                        <Col sm="2" style={{ color: 'blue', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '2px', paddingRight: 0, fontWeight: 500, borderTop: 'none', textAlign: 'left' }}>{d.bookingId}</Col>
                        <Col sm="4" style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.name}</Col>
                        <Col data-testid="reminder-data" sm="4" style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.alert}</Col>


                    </Row>
                )
                ) : (null)}
            </div>




        </Container>
    )
}
