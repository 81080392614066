import React, { useState, useEffect } from "react";
import BookingService from "../../services/BookingService"
import Select from 'react-select'



export function MealTypeDropDown(props) {

    const bookingService = new BookingService();

    const [mealTypes, updateMealTypes] = useState([]);

    useEffect(() => { getMealTypes(); }, [])

    const getMealTypes = async () => {
        let result = await bookingService.GetMealType();
        if (!result.redirect) {

            updateMealTypes(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {

        //  updateSelectedCountry(val);
        //   if (action.action == 'clear') {
        props.selectedMealTypeId(val)
        //   }
        //   else {
       // props.selectedMealTypeId(val.value)
        //   }
    }


    return (
        <Select options={mealTypes} isClearable={true} onChange={onSelectChange} value={props.mealtype == null ? null : mealTypes.find(o => o.value === props.mealtype)} />
    );



}