import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container } from 'reactstrap';
import AdministrationService from "../../services/AdministrationService";
import { IconContext } from "react-icons";
import { IoIosArrowDropupCircle, IoIosCloseCircle } from "react-icons/io";
import { Navigate } from 'react-router-dom';
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged";
import { Package } from "./Package";
import { useParams } from 'react-router-dom'


export function AddPackage(props) {

    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }
    let { pId } = useParams();


    const [packageDetails, addPackageDetails] = useState({ packageId: 0, packageName: '', isActive: '', createdBy: logged.UserId, modifiedBy:0,createdDate: 0,modifiedDate:0 })
    const [InProgress, setInProgress] = useState(false);
    const administrationService = new AdministrationService
    const [close, setClose] = useState(false);
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    /* useEffect(() => {
        if (props.mode === 'u') {

            getPackageById(parseInt(pId));
        }
    }, []) */

    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const handleSubmit = (evt) => {
      /*  if (props.mode == 'u')
            updatePackage();
        else */
      addPackage();
    }
        
    const addPackage = async () => {
        let initialValue = { packageName: '',createdBy: 0, modifiedBy: 0}
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            // setInProgress(true);
            let result = await administrationService.AddPackage(packageDetails.packageName,packageDetails.createdBy, packageDetails.modifiedBy);
            if (!result.redirect) {
                toast.success('Package has been successfully added')
                closeMessageModal();
                addPackageDetails({ ...packageDetails, ...initialValue });
                setClose(true);
            }
            else {
                toast.error("Error occured while adding package");
                closeMessageModal();

            }
        }
    }

    //TO be used when modify package is required!
    /*const updatePackage = async () => {
        if (validateForm()) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();

            let result = await administrationService.UpdatePackage(packageDetails.packageId, packageDetails.packageName, packageDetails.isActive);
            if (!result.redirect) {
                toast.success('Package details has been successfully updated')
                closeMessageModal();
                setClose(true);
                //addPackageDetails({ ...packageDetails, ...initialValue })
            }
            else {
                toast.error("Error occured while updating package details");
                closeMessageModal();

            }
        }
    }*/

    const validateForm = () => {
        if (packageDetails.packageName === '') {
            toast.error('Please enter the package name', alertOptions)
            return false;
        }
        return true;
    }

     // To be used when UpdatePackage is done(modify package)!
    /* const getPackageById = async (packageId) => {
        setMessage('LOADING IN PROGRESS');
        //TODO update GetPackageById - AdministrationService.js
        /*openMessageModal();

        let result = await administrationService.GetPackageById(packageId);
        if (!result.redirect) {
            if (result.data.status) {
                addPackageDetails(result.data.data);
                closeMessageModal();

                //updateBookingSource({ label: '', value: result.data.data.booking.source })
            }
            else
                toast.error('Failure in loading user details');
        }
        else {
            toast.error('Error occured in fetching user details');
        }  
    } */


    return (
        close ? (props.mode == 'u' ? <Navigate to='/administration/package' /> : < Package />) : (<Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>
            <Row style={{}}>
                <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> Add Package </Col>
                <Col sm='1' style={{ textAlign: 'right' }}><Button style={{ backgroundColor: 'white', border: 0 }} onClick={() => setClose(true)}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>

            </Row>

            <Row style={{ paddingTop: '25px' }}>
                <Col sm="2" >
                    <label>
                        Package Name{` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                        style={{ width: '100%' }}
                        value={packageDetails.packageName}
                        onChange={e => addPackageDetails({ ...packageDetails, packageName: e.target.value })}
                    />
                </Col>

            </Row>

           
            <Row style={{ paddingTop: '15px' }}>
                <Col sm="12" style={{ textAlign: 'center' }}>  <Button color="primary" size="sm" onClick={handleSubmit} disabled={InProgress} >Save{""}</Button></Col>
            </Row>

            {InProgress ? (<div>
                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" />
                    <Col sm="7" >
                        <h3> SAVING IN PROGRESS...</h3>

                    </Col>
                </Row>
                <Row>
                    <Col sm="2" />
                    <Col sm="7" >
                        <Progress animated color="info" value="100" />
                    </Col>
                </Row></div>) : (null)
            }


            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>)
    );
}