import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import { Navigate, Link } from 'react-router-dom'
import MyOperatorService from "../../services/MyOperatorService"
import { AgGridReact } from 'ag-grid-react';
import { logged } from "../../lib/logged"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { MissedActionRenderer } from './MissedActionRenderer'
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import { IconContext } from "react-icons";
import { AiFillCloseCircle } from "react-icons/ai";
import { ReservationStatus } from "../../lib/ReservationStatus";
import 'react-toastify/dist/ReactToastify.css';



export function MissedCalls(props) {

    const myOperatorService = new MyOperatorService();


    useEffect(() => {
        
        getRefreshDetails();
    }, [])


    
    const containerStyle = useMemo(() => ({ width: '100%', height: '450px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef(null);
    const [DisplayText, setDisplayText] = useState('MISSED CALLS')
    const [DayCode, setDayCode] = useState('T')
    const [RefreshDetails, updateRefreshDetails] = useState({refreshedBy:'',refreshedTime:''})
    const [InProgress, setInProgress] = useState(false);
    const [missedCalls, updateMissedCalls] = useState([]);   
    const [columnDefs, setColumnDefs] = useState([
        { cellRenderer: MissedActionRenderer, headerName: 'Purge', width: 100 },
        { field: 'callNumber', headerName: 'Call Number', filter: "agTextColumnFilter", width:275, floatingFilter: true, sortable: true },
        {
            field: 'callOperator', headerName: 'Missed By', filter: "agTextColumnFilter", width: 350, floatingFilter: true, sortable: true
        },
        {
            field: 'restaurantId', headerName: 'Restaurant ', filter: "agTextColumnFilter", width: 250, floatingFilter: false, sortable: true,
            cellRenderer: (d) => {
                if
                    (d.data.restaurantId == 1) return 'Kipling'
                else return "ECR 175 / Pause"
            }
        },
        {
            field: 'callTime', headerName: 'Missed Time', filter: "agTextColumnFilter", width: 300,
            cellRenderer: (d) => {
                let dt = new Date(d.data.callTime * 1000); 
                return moment(dt).format("MMMM Do YYYY, h:mm:ss a");
            }        }

    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

   


    const onGridReady = useCallback((params) => {
        getTodaysData();
       // onRefresh();
    }, []);

    const getMissedCalls = async () => {
        showLoading();
        let result = await myOperatorService.GetTodaysMissedCalls();
        if (!result.redirect) {
            updateMissedCalls(result.data.data);
            hideLoading();
            getRefreshDetails();
        }
        else {
            toast.error('Error occured in getting booking records');
            hideLoading();
        }
    }

    const getRefreshDetails = async () => {
        let result = await myOperatorService.GetRefreshDetails();
        if (!result.redirect) {
            updateRefreshDetails(result.data.data);
         
        }
        else {
            toast.error('Error occured in getting refresh data');
            
        }
    }

    const onRefresh = () => {
        setDisplayText('MISSED CALLS')
        setDayCode('T')
        getMissedCalls();
       
    }

    const onToday = () => {
        setDisplayText('MISSED CALLS')
        setDayCode('T')
        getTodaysData();
    }

    const onYesterday = () => {
        setDisplayText('YESTERDAY\'S MISSED CALLS')
        setDayCode('Y')
        getYesterdaysData();
    }

    const showAlert = (message, type) => {

        if (type == 'error') {
            toast.error(message);
        }
        else if (type == 'success') {
            toast.success(message);
        }
    }

    const getTodaysData = async () => {
        showLoading();
        let result = await myOperatorService.GetTodaysData();
        if (!result.redirect) {
            updateMissedCalls(result.data.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in getting booking records');
            hideLoading();
        }
    }

    const getYesterdaysData = async () => {
        showLoading();
        let result = await myOperatorService.GetYesterdaysData();
        if (!result.redirect) {
            updateMissedCalls(result.data.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in getting booking records');
            hideLoading();
        }
    }

    return (


<Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Col sm="3" style={{ color: DayCode == "T" ? '#009688' :'#FFA000', fontWeight: 'bold' }}> {DisplayText}  </Col>
                <Col sm="6" style={{ color: 'rgb(175 76 172)', fontWeight: '700' }}> Last Refresh  <span style={{ color: 'red', fontWeight: 'bold' }}>{` @ `}</span>{RefreshDetails.refreshedTime != '' ? moment(RefreshDetails.refreshedTime).format("MMMM Do, h:mm:ss A") : ''}<span style={{ color: 'red', fontWeight: 'bold' }}>{` by `}</span> {RefreshDetails.refreshedBy} </Col>
                <Col sm="3" style={{ textAlign: 'right' }}>
                    {/*<Button color="primary" style={{ marginLeft: '2px', backgroundColor: '#FFA000', borderColor:'#f44336'}} size="sm" onClick={onYesterday} disabled={InProgress} >Yesterday{""}</Button>*/}
                    {/*<Button color="primary" style={{ marginLeft: '2px', backgroundColor: '#CDDC39', borderColor: 'black' ,color:'black'}} size="sm" onClick={onToday} disabled={InProgress} >Today{""}</Button>*/}
                    <Button color="primary" style={{ marginLeft: '2px', background: 'linear-gradient(120deg, rgb(79 185 41), rgb(216 10 40))', color: 'white', borderStyle: 'inset', borderColor: 'chocolate', fontWeight:900 }} size="lg" onClick={onRefresh} disabled={InProgress} >REFRESH{""}</Button>
                </Col>

            </Row>
            <div style={containerStyle}>
                
                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact rowHeight={32}
                        ref={gridRef}
                        rowData={missedCalls}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{ showAlert, getMissedCalls, getTodaysData, getYesterdaysData, DayCode }}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while bookings are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No bookings to show</span>'
                        }

                        suppressCellFocus={true}
                        enableCellTextSelection={true}

                    >

                    </AgGridReact>
                </div>
            </div>

         

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

        </Container>
    )

}