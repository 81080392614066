import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { logged } from "../../lib/logged"


export function UserRestaurantDropDown(props) {


    const [restaurants, updateRestaurants] = useState([]);
    const [selectedRestaurant, updateSelectedRestaurant] = useState(null);

    useEffect(() => { getRestaurants(); }, [])



    const getRestaurants = async () => {
        let roles = logged.Roles;
        let rest = []
        for (let i = 0; i < roles.length; i++) {
            rest.push({ value: roles[i].restaurantId, label: roles[i].restaurantName });
        }
            updateRestaurants(rest);

        
    }

    const onSelectChange = (val, action) => {
        updateSelectedRestaurant(val);
        if (action.action == 'clear')
            props.selectedRestaurantId(val)
        else
            props.selectedRestaurantId(val)
    }


    return (
        <Select options={restaurants} isClearable={true} onChange={onSelectChange} value={props.restaurant == null ? null : restaurants.find(o => o.value === props.restaurant)} />
    );



}