import { React, useState } from "react";
import { Row, Col, Container, Button } from 'reactstrap'
import { logged } from "../../lib/logged";
import { IconContext } from "react-icons";
import { IoIosCloseCircle } from "react-icons/io";
import { BulkEnquiryStatus } from "../../lib/BulkEnquiryStatus";
import { CancelReasonDropDown } from "../Controls/CancelReasonDropDown";
import BookingService from "../../services/BookingService"

export function FeedbackBulkEnquiry(props) {
    const bookingService = new BookingService();

    const [feedbackDetails, addfeedbackDetails] = useState({
        cancelReasonId: 0, cancelReasonName: '', createdBy: logged.UserId, feedback : ''
    })
    const [close, setClose] = useState(false);
    const handleSubmit = () => {
        if (props.buttonclick == BulkEnquiryStatus.Cancelled) {
            bookingService.UpdateEnquiryStatus(props.bulkid, BulkEnquiryStatus.Cancelled, feedbackDetails.cancelReasonName, feedbackDetails.feedback, logged.UserId)
        } else if (props.buttonclick == BulkEnquiryStatus.Completed) {
            bookingService.UpdateEnquiryStatus(props.bulkid, BulkEnquiryStatus.Completed, '', feedbackDetails.feedback, logged.UserId)

        }
        props.modalClose(1)
    }
    const updateCancelReason = (val) => {
        addfeedbackDetails({ ...feedbackDetails, cancelReasonId: val});
    }

    const updateCancelReasonName = (val) => {
        addfeedbackDetails({ ...feedbackDetails, cancelReasonName: val });
    }
    return (
        <Container fluid={true} style={{ height: "100%" }}>
            {/*<Row style={{}}>*/}
            {/*    <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> Feedback Please.. </Col>*/}
            {/*    <Col sm='1' style={{ textAlign: 'right' }}><Button style={{ backgroundColor: 'white', border: 0 }} onClick={() => setClose(true)}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>*/}

            {/*</Row>*/}
            {props.buttonclick == BulkEnquiryStatus.Cancelled ?

                <Row style={{ paddingTop: '25px' }}>
                    <Col sm="4" >
                        <label>
                            Cancellation Due to{` : `}

                        </label>
                    </Col>
                    <Col sm="8">
                        <CancelReasonDropDown selectedCancelReasonName={updateCancelReasonName}  selectedCancelReasonId={updateCancelReason} cancelReason={feedbackDetails.cancelReasonId} />
                    </Col>

                </Row>
                : null}
                
                    <Row style={{ paddingTop: '25px' }}>
                        <Col sm="4" >
                            <label>
                                Remarks{` : `}

                            </label>
                        </Col>
                        <Col sm="8">

                            <input
                                type="text"
                                style={{ width: '100%' }}
                                value={feedbackDetails.feedback}
                                onChange={e => addfeedbackDetails({ ...feedbackDetails, feedback: e.target.value })}
                            />
                        </Col>

                    </Row>
                
            
            <Row style={{ paddingTop: '15px' }}>
                <Col sm="12" style={{ textAlign: 'center' }}>  <Button color="primary" size="sm" onClick={() => handleSubmit()} >Save{""}</Button></Col>
            </Row>
        </Container>
    )
}