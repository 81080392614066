import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { ConfirmedBooking } from "./ConfirmedBooking"
import { CancelledBooking } from "./CancelledBooking"
import { NoShowBooking } from "./NoShowBooking"
import { CheckedInBooking } from "./CheckedInBooking"
import { WaitlistedBooking } from "./WaitlistedBooking"
import { ImCross } from "react-icons/im";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap'
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import BookingService from "../../services/BookingService"
import { FaExpeditedssl } from "react-icons/fa";
import { BsCheckSquareFill } from "react-icons/bs";
import { HiEmojiSad } from "react-icons/hi";
import { HiQueueList } from "react-icons/hi2";
import { IconContext } from "react-icons";
import { ToastContainer, toast } from 'react-toastify';
import { BigTable } from './BigTable';


export function BookingSummary(props) {

    const [bookingStatus, updateBookingStatus] = useState('C');
    const bookingService = new BookingService();
    const [bookingCount, updateBookingCount] = useState({ confirmed: 0, cancelled: 0, noShow: 0, waitlisted: 0, checkedIn: 0 });

    useEffect(() => {
        getBookingsCount();


        const interval = setInterval(() => {
            window.location.reload();
        }, 600000);
        return () => clearInterval(interval);
    }, []);



    const changeStatus = (val) => {
        updateBookingStatus(val);
    }

    const getBookingsCount = async () => {

        let result = await bookingService.GetBookingsCount(logged.UserId)
        if (!result.redirect) {
            let rptData = result.data.data
            updateBookingCount(rptData);
        }
        else {
            toast.error('Error occured in fetching  data');
        }

    }

    return (
        < Container fluid={true } >
            <Row style={{ paddingBottom: '15px', color: 'green', fontWeight: 700 }}><Col sm="4"></Col> <Col sm="4" style={{ textAlign: 'center' }}>TODAY'S BOOKING STATUS</Col><Col sm="4"></Col></Row>
            <Row style={{ marginLeft: '5px' }}>
                <Col sm="2">
                    <Card
                        style={{ background: 'linear-gradient(120deg, rgb(7, 188, 12), var(--bs-green))', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }} >
                            <IconContext.Provider value={{ size: '22px', color: 'white', verticalAlign: 'sub' }}> < FaExpeditedssl /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>CONFIRMED</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button color="link" style={{ color: 'white' }} onClick={() => changeStatus('C')}>
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.confirmed}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="2">
                    <Card
                        style={{ background: 'linear-gradient(120deg, #3498db, var(--bs-cyan))', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }}>
                            <IconContext.Provider value={{ size: '22px', color: 'white', verticalAlign: 'sub' }}> < BsCheckSquareFill /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>CHECKED-IN</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button data-testid="checkin-dash-button" color="link" style={{ color: 'white' }} onClick={() => changeStatus('I')} >
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.checkedIn}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>


                <Col sm="2">
                    <Card
                        style={{ background: 'linear-gradient(120deg,var(--bs-gray-200),var(--bs-gray))', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }}>
                            <IconContext.Provider value={{ size: '22px', color: 'white', verticalAlign: 'sub' }}> < HiEmojiSad /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>NO SHOW</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button color="link" style={{ color: 'white' }} onClick={() => changeStatus('N')}>
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.noShow}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="2">

                    <Card
                        style={{ background: 'linear-gradient(120deg, rgb(200 209 63), rgb(244, 197, 10))', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }}>
                            <IconContext.Provider value={{ size: '22px', color: 'white', verticalAlign: 'sub' }}> < HiQueueList /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>WAITLISTED</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button color="link" style={{ color: 'white' }} onClick={() => changeStatus('W')} >
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.waitlisted}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="2">
                    <Card
                        style={{ background: 'linear-gradient(120deg,  #e74c3cab,red)', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }} >
                            <IconContext.Provider value={{ size: '18px', color: 'white', verticalAlign: 'sub' }}> < ImCross /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>CANCELLED</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button color="link" style={{ color: 'white' }} onClick={() => changeStatus('X')} >
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.cancelled}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm="2" style={{ paddingTop: '5px' }}>
                    <BigTable />
                </Col>
            </Row>
            <Row>
                <Col>
                    {bookingStatus == 'C' ? <ConfirmedBooking refreshStatusCount={getBookingsCount} /> : bookingStatus == 'I' ? <CheckedInBooking refreshStatusCount={getBookingsCount} /> : bookingStatus == 'N' ? <NoShowBooking refreshStatusCount={getBookingsCount} /> : bookingStatus == 'W' ? <WaitlistedBooking refreshStatusCount={getBookingsCount} /> : bookingStatus == 'X' ? <CancelledBooking refreshStatusCount={getBookingsCount} /> : null}
                </Col>
            </Row>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            </Container>

    )
}