import React, { useState, useEffect } from "react";
import IndentService from "../../services/IndentService";
import Select from 'react-select'



export function SubDepartmentDropDown(props) {

    const indentService = new IndentService();

    const [subDepartment, updateSubDepartment] = useState([]);


    useEffect(() => { getSubDepartment(); }, []);

    const getSubDepartment = async () => {
        let result = await indentService.GetIndentSubDepartments(props.selectedDepartmentId == null ? 0 : props.selectedDepartmentId);

        if (!result.redirect) {

            updateSubDepartment(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {

        props.selectedSubDepartmentId(val)

    }


    return (
        <Select options={subDepartment} isClearable={true} onChange={onSelectChange} value={props.subDepartment == null ? null : subDepartment.find(o => o.value === props.subDepartment)} />


    );



}