import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import { Navigate, Link } from 'react-router-dom'
import AdministrationService from "../../services/AdministrationService"
import { AgGridReact } from 'ag-grid-react';
import { logged } from "../../lib/logged"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import 'react-toastify/dist/ReactToastify.css';
import { AddUser } from './AddUser'

export function Users(props) {

    const administrationService = new AdministrationService();


    




    const containerStyle = useMemo(() => ({ width: '100%', height: '500px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef(null);

    const [InProgress, setInProgress] = useState(false);
    const [users, updateUsers] = useState([]);
    const [isAdd, updateIsAdd] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'loginId', headerName: 'Login Id', width: 300, cellRenderer: (d) => {
                if (d.data.isActive == 'Yes') { return <Link to={`/administration/users/${d.data.userId}`}>{d.data.loginId}</Link> } else { return d.data.loginId  }
            }, filter: "agTextColumnFilter", floatingFilter: true
        },
        {
            field: 'firstName', headerName: 'First Name', filter: "agTextColumnFilter", floatingFilter: true, width: 275,
        },
        { field: 'lastName', headerName: 'Last Name', filter: "agTextColumnFilter", floatingFilter: true, width: 265, },
        { field: 'role', headerName: 'Role', filter: "agTextColumnFilter", floatingFilter: true, width: 250 },
        { field: 'isActive', headerName: 'Is Active', filter: "agTextColumnFilter", floatingFilter: true, width: 150 },
        { headerName: 'Inactivate', width: 200, cellRenderer: (d) => { return <Button onClick={() => { onInactivate(d.data.userId); }} style={{ backgroundColor: '#ff1200', padding: '2px', margin: 0, marginBottom: '6px', visibility: d.data.isActive == 'Yes'?'visible':'hidden' }} disabled={d.data.userId == logged.UserId ? true : false} color="danger" size="sm" > Inactivate</Button> }, floatingFilter: false },

    ]);

    const onInactivate = async(userId) => {
        let result = await administrationService.InactivateUser(userId);
        if (!result.redirect) {
            toast.success('User has been inactivated successfully')
            getUsers();
        }
        else {
            toast.error("Error occured while inactivating the user");

        }
    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    useEffect(() => {
        sizeToFit();
    }, [users])

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);

    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const onGridReady = useCallback((params) => {
        getUsers();
    }, []);

    const getUsers = async () => {
        showLoading();
        let result = await administrationService.GetUsers();
        if (!result.redirect) {
            updateUsers(result.data.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching user accounts');
            hideLoading();
        }
    }

    const onAdd = () => {
        updateIsAdd(true);
    }

    const showAlert = (message, type) => {

        if (type == 'error') {
            toast.error(message);
        }
        else if (type == 'success') {
            toast.success(message);
        }
    }



    return (


        isAdd ? <AddUser /> : <Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> USERS </Col>
                <Col sm="2" style={{ textAlign: 'right' }}>  <Button color="primary" size="sm" onClick={onAdd} disabled={InProgress} >Add{""}</Button></Col>
            </Row>
            <div style={containerStyle}>

                <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact rowHeight={32}
                        ref={gridRef}
                        rowData={users}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{ showAlert, getUsers }}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while bookings are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No bookings to show</span>'
                        }

                        suppressCellFocus={true} >

                    </AgGridReact>
                </div>
            </div>


            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

        </Container>
    )

}