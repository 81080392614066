
import React, { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { Row, Col, Button,  Container } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import {  IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";



export function FeedbackNotificationReport() {

    const reportService = new ReportService();


    const [reservationStartDate, setReservationStartDate] = useState(null);
    const [reservationEndDate, setReservationEndDate] = useState(null);

    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        updateNotificationData([]);
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [notificationData, updateNotificationData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([

        { field: 'bookingId', headerName: 'Id', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'source', headerName: 'Source', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'firstName', headerName: 'First Name', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        { field: 'lastName', headerName: 'Last Name', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'Mobile No', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'feedbackSend', headerName: 'Status', width: 120, sortable: true, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'reservationStatus', headerName: 'Booking Status', width: 170, sortable: true, filter: "agTextColumnFilter", floatingFilter: true, sortable: true,
            cellStyle: (p) => {
                if (p.node.data.reservationStatus == 'Confirmed') return { color: "green", fontWeight: 600 }
                else if (p.node.data.reservationStatus == 'Waitlisted') return { color: "brown", fontWeight: 600 }
                else if (p.node.data.reservationStatus == 'Check-In') return { color: "#216ba5", fontWeight: 600 }
                else if (p.node.data.reservationStatus == 'Cancelled') return { color: "red", fontWeight: 600 }
                else if (p.node.data.reservationStatus == 'NoShow') return { color: "deepskyblue", fontWeight: 600 }

            }
        },
        {
            field: 'modifiedDate', headerName: 'Updated On', width: 170, sortable: true, cellRenderer: (d) => { return d.data.modifiedDate == null ? '' : moment(d.data.modifiedDate).format("DD/MM/YY H:mm:ss") }
        },
        { field: 'reservedDate', headerName: 'Reserved Date', sortable: true, width: 175, cellRenderer: (d) => { return moment(d.data.reservedDate).format("DD/MM/YY") } },
        { field: 'reservedTime', headerName: 'Reserved Time', width: 175, sortable: true },


    ]);

 

    //useEffect(() => {
    //    sizeToFit();
    //}, [notificationData])

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);


    const getFeedbackNotificationData = async () => {
        if (isInputValid()) {
            showLoading();
            let result = await reportService.GetFeedbackNotificationData(selectedRestaurant.value, reservationStartDate, reservationEndDate);
            if (!result.redirect) {
                let rptData = result.data.data
                updateNotificationData(rptData);
                hideLoading();
            }
            else {
                toast.error('Error occured in fetching report data');
                hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "FeedbackNotificationReport" });
    }, []);

    const isInputValid = () => {
        let isAfter = false;
        if (reservationStartDate !== null && reservationEndDate !== null) {
            isAfter = moment(reservationEndDate).isSameOrAfter(reservationStartDate);

        }

        if (selectedRestaurant == null) {
            toast.error('Please choose a restaurant')
            return false;
        }
        else if (reservationStartDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (reservationEndDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }


    return (
        isClose ? <Navigate to='/reports' /> : <Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ marginBottom: '10px', background: 'whitesmoke', padding: 0, margin: 0 }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> FEEDBACK NOTIFICATION REPORT </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'whitesmoke', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row style={{ background: '#a4b3dc', padding: 0, margin: 0, minHeight: '50px' }}>


                <Col sm="1" style={{ paddingTop: 0,paddingRight:0,paddingBottom:0, paddingLeft: '5px', marginTop: '10px' }} >
                    <label className="required-fields" >
                        Restaurant {` : `}

                    </label>

                </Col>
                <Col sm="2" style={{marginTop:'6px'} }>
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />
                </Col>


                <Col sm="1" style={{ textAlign: 'right', padding: 0,  marginTop: '10px' }}>
                    <label className="required-fields">
                        Start Date {` : `}

                    </label>
                </Col>


                <Col sm="2" style={{ marginTop: '6px' }}>
                    <DatePicker selected={reservationStartDate} onChange={(date) => { setReservationStartDate(date); updateNotificationData([]); }} />
                </Col>

                <Col sm="1" style={{ textAlign: 'right', padding: 0, marginTop: '10px' }}>
                    <label className="required-fields">
                        End Date {` : `}

                    </label>
                </Col>


                <Col sm="2" style={{ marginTop: '6px' }}>
                    <DatePicker selected={reservationEndDate} onChange={(date) => { setReservationEndDate(date); updateNotificationData([]); }} />
                </Col>

                <Col sm="1" style={{ marginTop: '8px' }}>
                    <Button color="success" size='sm' onClick={getFeedbackNotificationData}>Submit</Button>
                </Col>

                <Col sm="1" style={{ marginTop: '8px', textAlign: 'right' }}>
                    <Button style={{ backgroundColor: '#a4b3dc', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>

            </Row>

         
            <div id="bookingreport">
          
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">

                        <div style={containerStyle}>
                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={22}
                                ref={gridRef}
                                rowData={notificationData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }
                                    pagination={true}
                                    suppressCellFocus={true}
                                    enableCellTextSelection={true}
                                >

                            </AgGridReact>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
