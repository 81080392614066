import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import AdministrationService from "../../services/AdministrationService";
import { ItemSupplierDropDown }  from "../Controls/ItemSupplierDropDown"
import { Row, Col, Button, Container } from 'reactstrap'
import Modal from "react-modal";
import { logged } from "../../lib/logged"
import { RemoveMappedSupplier } from './RemoveMappedSupplier'
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { GroceryUnitTypeDropDown } from "../Controls/GroceryUnitTypeDropDown"
import { ItemUnitCountRenderer } from './ItemUnitCountRenderer'
import { ItemPriceRenderer } from './ItemPriceRenderer'
import { RemoveMappedPrice } from './RemoveMappedPrice'
import { ToastContainer, toast } from 'react-toastify';

const GroceryItemPrices = (props) => {

    useEffect(() => {
        GetGroceryItemPrices();

    }, []);




    const administrationService = new AdministrationService()
    const [itemPrices, updateItemPrices] = useState([]);
    const [selectedUnitType, updateSelectedUnitType] = useState(null);
    const [selectedSupplier, updateSelectedSupplier] = useState(null);
    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const gridRef = useRef(null);

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);


    useEffect(() => {
        sizeToFit();
    }, [itemPrices])

    const [columnDefs, setColumnDefs] = useState([
        { headerName: '', cellRenderer: RemoveMappedPrice, floatingFilter: false,width:50 },
        { field: 'id', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'supplierId', headerName: '', hide: true },
        { field: 'unitTypeId', headerName: '', hide: true },
        { field: 'itemPriceMappingId', headerName: '', hide: true },
        { field: 'supplierName', headerName: 'Supplier', filter: "agTextColumnFilter", width:250 },      
        { field: 'unitTypeName', headerName: 'Unit', filter: "agTextColumnFilter" },
        { field: 'itemPriceUnit', headerName: 'Quantity', cellRenderer: ItemUnitCountRenderer,width:150 },
        { field: 'itemPrice', headerName: 'Price', cellRenderer: ItemPriceRenderer,width:150 },







    ]);

    const onUnitTypeChange = (val) => {
        updateSelectedUnitType(val);
    }

    const onSupplierSelectionChange = (val) => {

        updateSelectedSupplier(val);
    }


    const addrows = useCallback((gitems, addIndex) => {
        const newItems = [
            gitems
        ];


        gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: addIndex,
        });

    }, []);




    const getGridData = () => {

        let gdata = []
        gridRef.current.api.forEachNode(node => gdata.push(node.data));
        return gdata;

    }



    const updatePriceUnitCount = (val, id) => {
        var rowNode = gridRef.current.api.getRowNode(id);
        

        rowNode.setDataValue('itemPriceUnit', val == '' ? 0 : parseInt(val));
    }
    const updatePrice = (val, id) => {
        var rowNode = gridRef.current.api.getRowNode(id);
        
        rowNode.setDataValue('itemPrice', val == '' ? 0 : parseFloat(val));
    }


    const onItemAdd = () => {

        let isValid = validateForm();
        if (isValid) {



            let itemFound = false;
            let itemSupplierPrices = getGridData();
            let nextListId = 0;
            if (itemSupplierPrices.length == 0) {
                nextListId = 1
            }
            else {
                let maxListId = Math.max(...itemSupplierPrices.map(o => o.id));
                nextListId = maxListId + 1;
            }


            for (let i = 0; i < itemSupplierPrices.length; i++) {
                if (itemSupplierPrices[i].supplierId === selectedSupplier.value && itemSupplierPrices[i].unitTypeId == selectedUnitType.value) {
                    itemFound = true
                    break;
                }

            }
            if (!itemFound) {

                addrows({ id: nextListId, itemPriceMappingId: 0, groceryItemId: props.itemId, supplierId: selectedSupplier.value, unitTypeId: selectedUnitType.value, supplierName: selectedSupplier.label, unitTypeName: selectedUnitType.label, itemPrice: 0, itemPriceUnit: 1 }, undefined)
                updateSelectedSupplier(null);
                updateSelectedUnitType(null);

            }
            else {
                toast.info('The unit type for the supplier is already in the list');
            }
        }


    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    const [close, setClose] = useState(false);


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false
    })

    const onGridReady = useCallback(() => {


    }, []);

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }


    const onRemoveSelected = useCallback((selectedData) => {
        gridRef.current.api.applyTransaction({ remove: selectedData });

    }, []);


  

    const onClose = () => {
        setClose(true);
    }





    const validateForm = () => {

        if (selectedUnitType == null) {
            toast.error('Please select a Unit')
            return false;
        }
        else if (selectedSupplier == null) {
            toast.error('Please select a supplier')
            return false;
        }

        return true;
    }

    const removePriceMap = (data) => {

        onRemoveSelected([data])

    }

    const getRowId = useMemo(() => {
        return (params) => {
            return params.data.id;
        };
    }, []);


    const addUpdateItems = async () => {

        setMessage('SAVING IN PROGRESS');
        openMessageModal();
        let _groceryPrices = getGridData();

        let result = await administrationService.SaveUnitMapping(props.itemId, _groceryPrices, logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                closeMessageModal();
                toast("Units & price details has been updated")

            }
            else {
                closeMessageModal();
                toast("Failure in updating units & price detailsdetails")
            }
        }
        else {
            closeMessageModal();
            toast('Error occured while updating units & price details details', 'error');
        }

    }




    const GetGroceryItemPrices = async () => {
        let result = await administrationService.GetGroceryItemUnits(props.itemId);
        if (!result.redirect) {
            updateItemPrices(result.data.data);
        }
        else {
            toast.error('Error occured in fetching grocery item units & prices');
        }

    }


    return (
        <Container>
            <form >



                <Row style={{ paddingTop: '15px' }}>

                    
                    <Col sm="5">
                        <label>
                            Choose Supplier{` : `}

                        </label>
                        <ItemSupplierDropDown key={props.itemId} itemId={props.itemId} selectedSupplierId={onSupplierSelectionChange} supplier={selectedSupplier} />

                    </Col>
                    <Col sm="5" style={{ textAlign: 'left' }}>
                        <label>
                            Choose UnitType{` : `}

                        </label>
                        <GroceryUnitTypeDropDown selectedUnitTypeId={onUnitTypeChange} unitType={selectedUnitType != null ? selectedUnitType.value : selectedUnitType}  />

                    </Col>
                    <Col sm="2">

                        <Button style={{ marginRight: '5px', marginTop: '23px' }} color="success" size='sm' onClick={onItemAdd} >ADD{""}</Button>

                    </Col>
                </Row>







                <Row style={{ paddingTop: '15px' }}>


                    <Col sm="10" style={{ margin: 0, padding: 0, marginTop: '3px' }}>

                        <div style={{ width: '100%', height: 300 }} className="ag-theme-alpine" >
                            <AgGridReact rowHeight={32}
                                ref={gridRef}
                                rowData={itemPrices}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                context={{ updatePriceUnitCount, updatePrice, removePriceMap }}
                                getRowId={getRowId}
                                animateRows={true}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add items to be ordered</span>'
                                }

                                suppressCellFocus={true} >

                            </AgGridReact>
                        </div>

                    </Col>
                </Row>


                <Row style={{ marginTop: '15px' }}>

                    <Col sm="12" style={{ textAlign: 'center' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={addUpdateItems} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={() => props.modalClose()} >Close{""}</Button>
                    </Col>


                </Row>






                <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
                </Modal>

            </form>

            {/*<ToastContainer*/}
            {/*    position="top-center"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="dark"*/}
            {/*/>*/}


        </Container>
    );
};

export default GroceryItemPrices;