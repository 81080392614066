import React, { useState, useEffect } from "react";
import Select from 'react-select'



export function MonthDropDown(props) {



    const [month, updateMonth] = useState([]);

    useEffect(() => { getMonths(); }, [])

    const getMonths = async () => {
        let result = [

            { label: 'Jan', value: 1 },
            { label: 'Feb', value: 2 },
            { label: 'Mar', value: 3 },
            { label: 'Apr', value: 4 },
            { label: 'May', value: 5 },
            { label: 'Jun', value: 6 },
            { label: 'Jul', value: 7 },
            { label: 'Aug', value: 8 },
            { label: 'Sep', value: 9 },
            { label: 'Oct', value: 10 },
            { label: 'Nov', value: 11 },
            { label: 'Dec', value: 12 }
            

        ]


        updateMonth(result);



    }

    const onSelectChange = (val, action) => {
    
        props.selectedMonthId(val)

    }


    return (
        <Select options={month} isClearable={false} onChange={onSelectChange} value={props.month == null ? null : month.find(o => o.value === props.month)} />
    );



}