import { Row, Col, Button, Progress, Container } from 'reactstrap'
import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import Modal from "react-modal";
import { logged } from "../../lib/logged"
import { AgGridReact } from 'ag-grid-react';
import { ToastContainer, toast } from 'react-toastify';
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { Navigate, Link } from 'react-router-dom'
import { IconContext } from "react-icons";
import { GrDocumentCsv } from "react-icons/gr";
import { ItemUnitCountRenderer } from './ItemUnitCountRenderer'
import { ItemPriceRenderer } from './ItemPriceRenderer'
import { ItemActionRenderer } from './ItemActionRenderer'
import IndentService from "../../services/IndentService"

export function ManagePrice(props) {

    const indentService = new IndentService();
    const [priceListData, updatePriceListData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const [columnDefs, setColumnDefs] = useState([
        { field: 'itemPriceMappingId', headerName: 'Units', filter: "agTextColumnFilter", hide: true },
        { field: 'supplierId', headerName: 'Supplier Id', hide: true },
        { field: 'isDisabled', headerName: 'Units', filter: "agTextColumnFilter", hide: true },
        { field: 'itemName', headerName: 'Item Name' ,filter: "agTextColumnFilter", floatingFilter: true, sortable: true, width:200 },
        { field: 'categoryName', headerName: 'Category', filter: "agTextColumnFilter", floatingFilter: true, sortable: true, width: 200 },
        { field: 'supplierName', headerName: 'Supplier', filter: "agTextColumnFilter", floatingFilter: true, sortable: true, width: 200 },
        { field: 'unitName', headerName: 'Unit Type', width: 200, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'unit', headerName: 'Unit', width: 140},
        { field: 'price', headerName: 'Price', width: 150, cellRenderer: ItemPriceRenderer },
        { field: '', headerName: '', width: 200, cellRenderer: ItemActionRenderer },



    ]);
    const gridRef = useRef(null);
    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    const onClose = () => {
        updateIsClose(true);
    }

    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "GroceryItemPrice" });
    }, []);


    const updatePriceUnitCount = (val, id) => {
        var rowNode = gridRef.current.api.getRowNode(id);
        rowNode.setDataValue('unit', val);
    }
    const updatePrice = (val, id) => {
        var rowNode = gridRef.current.api.getRowNode(id);
        rowNode.setDataValue('price', val);
    }

    const setDisable = (val, id) => {
        var api = gridRef.current.api;
        var rowNode = gridRef.current.api.getRowNode(id);
        rowNode.setDataValue('isDisabled', val);
        var rowNodes = [rowNode]; // params needs an array
        var params = {
            force: true,
            suppressFlash: true,
            rowNodes: rowNodes,
        };
        api.refreshCells(params)
    }

    const save =async (id,unit,price,supplierId) => {

        setMessage('UPDATE IN PROGRESS');
        openMessageModal();
        let itemPrice= price == '' ? 0 : parseFloat(price)
        let itemPriceUnit = unit == '' ? 0 : parseInt(unit)
        let result = await indentService.UpdateItemPrice(id, itemPriceUnit, itemPrice,supplierId);
        if (!result.redirect) {
            if (result.data.status) {
                closeMessageModal();
                toast("Price has beeen successfully updated")
                getPriceListData();
            }
            else {
                closeMessageModal();
                toast("Failure in updating the price");
            }
        }
        else {
            closeMessageModal();
            toast('Error occured while updating the grocery item price', 'error');
        }
    }

    



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false
    })

    const onGridReady = useCallback(() => {

        getPriceListData();

    }, []);

    const getPriceListData = async () => {
        
        showLoading();
        let result = await indentService.GetGroceryPriceData();
            if (!result.redirect) {
                let priceData = result.data.data
                for (let i = 0; i < priceData.length; i++) {
                    priceData[i].isDisabled = true;
                }
                updatePriceListData(priceData);
                hideLoading();
            }
            else {
                toast.error('Error occured in fetching price list');
                hideLoading();
            }
        
    }

    const getRowId = useMemo(() => {
        return (params) => {
            return params.data.itemPriceMappingId;
        };
    }, []);

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }

    return (
       
            isClose ? <Navigate to='/indent' /> : <Container>

                <Row style={{ paddingBottom: '5px' }}>
                    <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> MANAGE GROCERY ITEM PRICE </Col>
                    <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>

            <Row style={{ paddingTop: '5px', }}>
                <Col sm="11"></Col>
                <Col sm="1" style={{ textAlign: 'right' }}>

                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>
            </Row>
                
            <Row >


                <Col sm="12">

                    <div style={gridStyle} className="ag-theme-balham" >
                        <AgGridReact rowHeight={29}
                            headerHeight={30}
                            floatingFiltersHeight={30}
                            ref={gridRef}
                            rowData={priceListData}
                            defaultColDef={defaultColDef}
                            columnDefs={columnDefs}
                            context={{ updatePriceUnitCount, updatePrice, setDisable, save }}
                            getRowId={getRowId}
                            onGridReady={onGridReady}
                            overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while Grocery Item Unit are loading</span>'
                            }
                            overlayNoRowsTemplate={
                                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add Grocery Item Unit</span>'
                            }

                            suppressCellFocus={true} >

                        </AgGridReact>
                    </div>

                </Col>
            </Row>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>
        </Container>
    )

}