
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx-js-style";

import "react-datepicker/dist/react-datepicker.css";



export function PackageReport(props) {

    const reportService = new ReportService();


    const [reservationStartDate, setReservationStartDate] = useState(null);
    const [reservationEndDate, setReservationEndDate] = useState(null);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        updatePackageData([]); 
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [packageData, updatePackageData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([

        { field: 'name', headerName: 'Name', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'MobileNo', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'pax', headerName: 'Pax', width: 75, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'reservedDate', headerName: 'Event Date', width: 150, cellRenderer: (d) => { return moment(d.data.reservedDate).format("DD/MM/YY") }, sortable: true },
        { field: 'reservedTime', headerName: 'Time', width: 75, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'packageName', headerName: 'Package', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'ocassionName', headerName: 'Ocassion', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'bannerWordings', headerName: 'Banner Wordings', filter: "agTextColumnFilter", width: 150, floatingFilter: true, sortable: true },
        { field: 'cakeFlavour', headerName: 'Cake Flavour', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'cakeWordings', headerName: 'Cake Wordings', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'isEggless', headerName: 'Eggless', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'specialInstruction', headerName: 'Instruction', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'comments', headerName: 'Comments', width: 150, floatingFilter: true, sortable: true },
        { field: 'paymentMode', headerName: 'Payment Mode', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'amount', headerName: 'Amount', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },

        { field: 'paymentDate', headerName: 'Payment Date', width: 150, cellRenderer: (d) => { return moment(d.data.paymentDate).format("DD/MM/YY") }, sortable: true },
        { field: 'paymentBy', headerName: 'Payment By', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
         { field: 'bookedBy', headerName: 'Booked By', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'modifiedBy', headerName: 'Modified By', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },


    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const onExcelExport1 = () => {
        const wb = XLSX.utils.book_new();

        // STEP 2: Create data rows and styles
        let row = [
            { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
            { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
            { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: "E9E9E9" } } } },
            { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
        ];

        // STEP 3: Create worksheet with rows; Add worksheet to workbook
        const ws = XLSX.utils.aoa_to_sheet([row]);
        XLSX.utils.book_append_sheet(wb, ws, "readme demo");

        // STEP 4: Write Excel file to browser
        XLSX.writeFile(wb, "xlsx-js-style-demo.xlsx");
    }

    const onExcelExport     = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'A1' });
        let o = 1;

        for (let i = 0; i < packageData.length; i++) {
            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "", t: "s", s: { fill: { fgColor: { rgb: "65ADEA" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "", t: "s", s: { fill: { fgColor: { rgb: "65ADEA" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "H", t: "s", s: { fill: { fgColor: { rgb: "65ADEA" } }, font: { bold: true, sz: 24 }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "", t: "s", s: { fill: { fgColor: { rgb: "65ADEA" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 1) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "S.NO", t: "s", s: { fill: { fgColor: { rgb: "6B8BCD" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "PARTICULARS", t: "s", s: { fill: { fgColor: { rgb: "6B8BCD" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "DETAILS", t: "s", s: { fill: { fgColor: { rgb: "6B8BCD" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "INFORMED", t: "s", s: { fill: { fgColor: { rgb: "6B8BCD" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },]],
                { origin: 'A' + (o + 2) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "1", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "NAME OF GUEST", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['name'], t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 3) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "2", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "NO OF PAX", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['pax'], t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 4) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "3", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "TYPE OF PACKAGE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['packageName'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                 ]], { origin: 'A' + (o + 5) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "4", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "DATE OF EVENT", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: moment(packageData[i]['reservedDate']).format('DD-MM-YY'), t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 6) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "5", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "TIME", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['reservedTime'], t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 7) });


            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "6", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "OCASSION", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['ocassionName'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 8) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "7", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "BANNER WORDINGS", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['bannerWordings'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 9) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "8", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "SPECIAL REQUESTS", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['comments'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O, H/K, SERVICE", t: "s", s: { fill: { fgColor: { rgb: "FBFE58" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 10) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "9", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "CAKE FLAVOUR", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['cakeFlavour'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "BAKERY", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 11) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "10", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "CAKE WORDINGS", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['cakeWordings'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "BAKERY", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 12) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "11", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "CAKE EGGLESS", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['isEggless'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "BAKERY", t: "s", s: { fill: { fgColor: { rgb: "12A6B0" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 13) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "12", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "AMOUNT", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['amount'], t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "ACCOUNTS", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 14) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "13", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "MODE OF PAYMENT", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['paymentMode'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "ACCOUNTS", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 15) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "14", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "AMOUNT RECEIVED ON", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: moment(packageData[i]['paymentDate']).format('DD-MM-YY'), t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "ACCOUNTS", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 16) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "15", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "PAYMENT DONE BY", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['paymentBy'].toUpperCase(), t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "ACCOUNTS", t: "s", s: { fill: { fgColor: { rgb: "7CBE12" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 17) });

            XLSX.utils.sheet_add_aoa(ws, [[
                { v: "16", t: "s", s: { fill: { fgColor: { rgb: "FFC001" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "CONTACT NUMBER", t: "s", s: { fill: { fgColor: { rgb: "FFC001" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: packageData[i]['mobileNo'], t: "s", s: { fill: { fgColor: { rgb: "FFC001" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },
                { v: "F/O", t: "s", s: { fill: { fgColor: { rgb: "FFC001" } }, font: { bold: true }, border: { top: { style: "thin", color: "black" }, bottom: { style: "thin", color: "black" }, left: { style: "thin", color: "black" }, right: { style: "thin", color: "black" } } } },

            ]], { origin: 'A' + (o + 18) });

            XLSX.utils.sheet_add_aoa(ws, [['', '']], { origin: 'A' + (o + 19) })
            
            o = o + 19;

        }


        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb,"PackageDetails.xlsx")
    }

    //const onGridReady = useCallback((params) => {
    //    getDayCloseData();
    //}, []);

    const getPackageDetails = async () => {
        if (isInputValid()) {
            showLoading();
            let result = await reportService.GetPackageDetails(selectedRestaurant.value, reservationStartDate,reservationEndDate);
            if (!result.redirect) {
                let rptData = result.data.data                
                updatePackageData(rptData);
                hideLoading();
            }
            else {
                alert('Error occured in fetching report data');
                hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }

    const onCopy = () => {
        html2canvas(document.querySelector("#packagereport")).then(function (canvas) {
            canvas.toBlob(function (blob) {
                navigator.clipboard
                    .write([
                        new window.ClipboardItem(
                            Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                            })
                        )
                    ])
                    .then(function () {
                        toast.success('Copied')
                    });
            });
        });


    }
    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "PackageReport" });
    }, []);

    const isInputValid = () => {

        let isAfter = false;
        if (reservationStartDate !== null && reservationEndDate !== null) {
            isAfter = moment(reservationEndDate).isSameOrAfter(reservationStartDate);

        }

        if (selectedRestaurant == null) {
            toast.error('Please choose a restaurant')
            return false;
        }
        else if (reservationStartDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (reservationEndDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }


    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingBottom: '20px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> PACKAGE REPORT </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row>


                <Col sm="1" style={{padding:0,paddingLeft:'10px'}} >
                    <label className="required-fields" style={{ paddingRight: '10px' }}>
                        Restaurant {` : `}

                    </label>

                </Col>
                <Col sm="2">
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />
                </Col>


                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        Start Date {` : `}

                    </label>
                </Col>


                <Col sm="2">
                    <DatePicker selected={reservationStartDate} onChange={(date) => { setReservationStartDate(date); updatePackageData([]); }} />
                </Col>

                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        End Date {` : `}

                    </label>
                </Col>


                <Col sm="2">
                    <DatePicker selected={reservationEndDate} onChange={(date) => { setReservationEndDate(date); updatePackageData([]); }} />
                </Col>

                <Col sm="1" >
                    <Button color="primary" size='sm' onClick={getPackageDetails}>Submit</Button>
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px', }}>
                <Col sm="2"></Col>
                <Col sm="8" style={{ textAlign: 'right' }}>
                    {/*<Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>*/}

                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>
                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExcelExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < SiMicrosoftexcel /></IconContext.Provider></Button>

                </Col>
                <Col sm="2" ></Col>
            </Row>
            <div id="packagereport">
               {/*<Row>*/}
                    
               {/*     <Col sm="12" style={{ textAlign: 'center' }}> <span style={{ color: 'black', fontWeight: 700 }}>PACKAGE REPORT</span></Col>*/}
               {/* </Row> */}

                {/*<Row>*/}

                {/*    <Col sm="2" style={{ textAlign: 'left' }}> <span style={{ fontWeight: 700, color: "#6c757d" }}>{selectedRestaurant == null ? '' : selectedRestaurant.label}</span>{`     `} <span style={{ paddingLeft: '50px' }}>{reservationStartDate == null ? '' : ``}</span> </Col>*/}
                {/*    <Col sm="8"  ></Col>*/}
                {/*    <Col sm="2" style={{ textAlign: 'right' }} ><span style={{ fontWeight: 700, color: "#6c757d" }}>{reservationStartDate == null ? '' : moment(reservationStartDate).format(moment.HTML5_FMT.DATE)}</span> </Col>*/}
                {/*</Row>*/}
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={22}
                                ref={gridRef}
                                rowData={packageData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }

                                suppressCellFocus={true} >

                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
