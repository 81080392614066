import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function RestaurantSupplierDropDown(props) {

    const administrationService = new AdministrationService();

    const [grocerySupplier, updateGrocerySupplier] = useState([]);

    useEffect(() => { getGrocerySuppliers(); }, [])

    const getGrocerySuppliers = async () => {
        let result = await administrationService.GetRestaurantSuppliers(props.restaurantId == null ? 0 : props.restaurantId);
        if (!result.redirect) {

            updateGrocerySupplier(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {

        props.selectedSupplierId(val)

    }


    return (
        <Select options={grocerySupplier} isClearable={true} onChange={onSelectChange} value={props.supplier == null ? null : grocerySupplier.find(o => o.value === props.supplier)} />
    );



}