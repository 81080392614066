import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function CountryDropDown(props) {

    const administrationService = new AdministrationService();

    const [countries, updateCountries] = useState([]);
    //const [selectedCountry, updateSelectedCountry] = useState({ label: 'India', value: '1' });

    useEffect(() => { getCountries(); }, [])

    const getCountries = async () => {
        let result = await administrationService.GetCountries();
        if (!result.redirect) {

            updateCountries(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
        
      //  updateSelectedCountry(val);
     //   if (action.action == 'clear') {
            props.selectedCountryId(val)
     //   }
     //   else {
            props.selectedCountryId(val.value)
     //   }
    }


    return (
        <Select options={countries} isClearable={true} onChange={onSelectChange} value={props.country==null?null:countries.find(o => o.value === props.country)}  />
    );



}