
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';

import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';


export function BigTableReport(props) {

    const reportService = new ReportService();


    const [reservationDate, setReservationDate] = useState(null);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        updatePaxData(null);
    }

    const gridStyleReservation = useMemo(() => ({ width: '100%', height: '375px' }), []);
    const gridStyle = useMemo(() => ({ height: '73px', width: '100%' }), []);
    const gridRef = useRef(null);
    const gridRefReservation = useRef(null);


    const [paxData, updatePaxData] = useState(null);
    const [reservationData, updateReservationData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefsReservation, setColumnDefsReservation] = useState([
        {
            field: 'name', headerName: 'Name', width: 150, sortable: true
        },
        {
            field: 'pax', headerName: 'PAX', width: 100, sortable: true
        },
        {
            field: 'time', headerName: 'Time', width: 100, sortable: true
        },
        {
            field: 'instruction', headerName: 'Special Instruction', width: 300
        }

    ]);

    const [columnDefs, setColumnDefs] = useState([
        {
            field: '', headerName: 'PAX', width: 60
        },
        {
            field: 'one', headerName: '1', width: 45
        },
        {
            field: 'two', headerName: '2', width: 45
        },
        {
            field: 'three', headerName: '3', width: 45
        },
        {
            field: 'four', headerName: '4', width: 45

        },
        {
            field: 'five', headerName: '5', width: 45
        },
        {
            field: 'six', headerName: '6', width: 50
        },
        {
            field: 'seven', headerName: '7', width: 50
        },
        {
            field: 'eight', headerName: '8', width: 40
        },
        {
            field: 'nine', headerName: '9', width: 40
        },
        {
            field: 'ten', headerName: '10', width: 60

        },
        {
            field: 'tenPlus', headerName: '10+', width: 65
        },
        {
            field: 'total', headerName: 'Total', width: 65
        },

    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    const showLoadingReservation = useCallback(() => {
        gridRefReservation.current.api.showLoadingOverlay();
    }, []);

    const showNoRowsReservation = useCallback(() => {
        gridRefReservation.current.api.showNoRowsOverlay();
    }, []);

    const hideLoadingReservation = useCallback(() => {
        gridRefReservation.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    //const onGridReady = useCallback((params) => {
    //    getDayCloseData();
    //}, []);

    const onSubmit = async () => {
        getPaxData();
        getReservationData();
    }

    const getPaxData = async () => {
        if (isInputValid()) {
          //  showLoading();
            let result = await reportService.GetPaxCount(selectedRestaurant.value, reservationDate);
            if (!result.redirect) {
                let rptData = { one: 0, two: 0, three: 0, four: 0, five: 0, six: 0, seven: 0, eight: 0, nine: 0, ten: 0, tenPlus: 0 }
                if (result.data.data.length>0)
                 rptData = result.data.data[0];
                
                updatePaxData(rptData);
                //hideLoading();
            }
            else {
                alert('Error occured in fetching report data');
                //hideLoading();
            }
        }
    }

    const getReservationData = async () => {
        if (isInputValid()) {
           // showLoadingReservation();
            let result = await reportService.GetBigTableReservations(selectedRestaurant.value, reservationDate);
            if (!result.redirect) {
                let rptData = result.data.data

                updateReservationData(rptData);
               // hideLoadingReservation();
            }
            else {
                alert('Error occured in fetching report data');
               // hideLoadingReservation();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }

    const onCopy = () => {
        html2canvas(document.querySelector("#bigtablereport")).then(function (canvas) {
            canvas.toBlob(function (blob) {
                navigator.clipboard
                    .write([
                        new window.ClipboardItem(
                            Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                            })
                        )
                    ])
                    .then(function () {
                        toast.success('Copied')
                    });
            });
        });


    }
    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "PaxCount" });
        gridRefReservation.current.api.exportDataAsCsv({ fileName: "BigTableReport" });

    }, []);

    const isInputValid = () => {

        if (selectedRestaurant == null) {
            toast.error('Please choose a restaurant')
            return false;
        }
        else if (reservationDate == null) {
            toast.error('Please choose a reservation date')
            return false;
        }

        return true;
    }


    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingBottom: '20px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> BIG TABLE REPORT </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row>


                <Col sm="2" >
                    <label className="required-fields" style={{ paddingRight: '10px' }}>
                        Restaurant {` : `}

                    </label>

                </Col>
                <Col sm="4">
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />
                </Col>


                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        Reservation Date {` : `}

                    </label>
                </Col>


                <Col sm="3">
                    <DatePicker selected={reservationDate} onChange={(date) => { setReservationDate(date); updatePaxData(null); } } />
                </Col>

                <Col sm="1" >
                    <Button color="primary" size='sm' onClick={onSubmit}>Submit</Button>
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px', }}>
                <Col sm="2"></Col>
                <Col sm="8" style={{ textAlign: 'right' }}>
                    <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>

                {/*    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>*/}
                </Col>
                <Col sm="2" ></Col>
            </Row>
        
                


                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="3"></Col>
              <Col sm="6">
                    {paxData != null ? (<div id="bigtablereport">
                        <Row noGutters={true}>
                            <Col sm="12" style={{ textAlign: 'center', borderStyle: 'solid', borderColor: 'black', color: 'white', backgroundColor: 'crimson', fontWeight: 700, }} >BIG TABLE REPORT</Col>
                        </Row>

                        <Row noGutters={true}>
                            <Col sm="6" style={{ textAlign: 'left', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', borderBottom: 'none', backgroundColor: 'lightskyblue' }}> <span style={{ fontWeight: 600, paddingLeft: '10px',color:'black' }}>{selectedRestaurant == null ? '' : selectedRestaurant.label.toUpperCase()}</span>{`     `} <span style={{ paddingLeft: '50px' }}>{reservationDate == null ? '' : ``}</span> </Col>
                            <Col sm="6" style={{ textAlign: 'right', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', borderLeft: 'none', borderBottom: 'none', backgroundColor: 'lightskyblue' }}><span style={{ fontWeight: 600, paddingRight:'10px',color:'black' }}>{reservationDate == null ? '' : moment(reservationDate).format(moment.HTML5_FMT.DATE)}</span> </Col>
                        </Row>

                        <Row noGutters={true} style={{ height: '32px',background:'yellow' }}>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', color: 'red', fontWeight: 650, textAlign: 'center', }}><span style={{ verticalAlign:'middle' }}>1</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650,textAlign: 'center', }}><span style={{  verticalAlign: 'middle' }}>2</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>3</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>4</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>5</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>6</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>7</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>8</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>9</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>10</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, padding: 0, textAlign: 'center' }}> <span style={{ textAlign: 'center', verticalAlign: 'middle' }}>10+</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 650, padding: 0, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>Total</span></Col>
                        </Row>
                        <Row noGutters={true} style={{ height: '32px', background:'#198754'}}>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>{paxData.one == 0 ? '-' : paxData.one}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{verticalAlign: 'middle' }}>{paxData.two == 0 ? '-' : paxData.two}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{paxData.three == 0 ? '-' : paxData.three}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{paxData.four == 0 ? '-' : paxData.four}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{verticalAlign: 'middle' }}>{paxData.five == 0 ? '-' : paxData.five}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{paxData.six == 0 ? '-' : paxData.six}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{verticalAlign: 'middle' }}>{paxData.seven == 0 ? '-' : paxData.seven}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{  verticalAlign: 'middle' }}>{paxData.eight == 0 ? '-' : paxData.eight}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{verticalAlign: 'middle' }}>{paxData.nine == 0 ? '-' : paxData.nine}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{paxData.ten == 0 ? '-' : paxData.ten}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{  verticalAlign: 'middle' }}>{paxData.tenPlus == 0 ? '-' : paxData.tenPlus}</span></Col>
                            <Col sm="1" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{paxData.total == 0 ? '-' : paxData.total}</span></Col>
                        </Row>
                        <Row noGutters={true} style={{ height: '32px', background: '#fd7e14', marginTop: '10px' }}>
                            <Col sm="3" style={{ borderStyle: 'solid', borderColor: 'black', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>Name</span></Col>
                            <Col sm="2" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'white', fontWeight: 650, textAlign: 'center', }}><span style={{ verticalAlign: 'middle' }}>PAX</span></Col>
                            <Col sm="2" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>Time</span></Col>
                            <Col sm="5" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'white', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>Instruction</span></Col>
                        </Row>

                        {reservationData.map((d, i) => (
                            <Row noGutters={true} style={{ height: '32px', background: '#f1c40f' }} key={i}>
                                <Col sm="3" style={{ borderStyle: 'solid', borderColor: 'black', borderTop: 'none', color: 'black', fontWeight: 650, textAlign: 'center' }}><span style={{ textAlign: 'center', verticalAlign: 'middle' }}>{d.name}</span></Col>
                                <Col sm="2" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'black', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{d.pax}</span></Col>
                                <Col sm="2" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'black', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{d.time}</span></Col>
                                <Col sm="5" style={{ borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'black', fontWeight: 650, textAlign: 'center' }}><span style={{ verticalAlign: 'middle' }}>{d.instruction}</span></Col>
                            </Row>

                        ))}
                        {/*<div style={gridStyle} className="ag-theme-balham">*/}
                        {/*    <AgGridReact rowHeight={22}*/}
                        {/*        ref={gridRef}*/}
                        {/*        rowData={paxData}*/}
                        {/*        defaultColDef={defaultColDef}*/}
                        {/*        columnDefs={columnDefs}*/}
                        {/*        overlayLoadingTemplate={*/}
                        {/*            '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'*/}
                        {/*        }*/}
                        {/*        overlayNoRowsTemplate={*/}
                        {/*            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'*/}
                        {/*        }*/}

                        {/*        suppressCellFocus={true} >*/}

                        {/*    </AgGridReact>*/}
                        {/*</div>*/}
                    </div>) : (null)}
                    </Col>
                           
                    <Col sm="3"></Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="3"></Col>
                    <Col sm="6">


                        {/*<div style={gridStyleReservation} className="ag-theme-balham">*/}
                        {/*    <AgGridReact rowHeight={22}*/}
                        {/*        ref={gridRefReservation}*/}
                        {/*        rowData={reservationData}*/}
                        {/*        defaultColDef={defaultColDef}*/}
                        {/*        columnDefs={columnDefsReservation}*/}
                        {/*        overlayLoadingTemplate={*/}
                        {/*            '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'*/}
                        {/*        }*/}
                        {/*        overlayNoRowsTemplate={*/}
                        {/*            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'*/}
                        {/*        }*/}

                        {/*        suppressCellFocus={true} >*/}

                        {/*    </AgGridReact>*/}
                        {/*</div>*/}
                    </Col>
                    <Col sm="3"></Col>
                </Row>



            
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
