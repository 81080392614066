import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function PaymentModeDropDown(props) {

    const administrationService = new AdministrationService();

    const [paymentModes, updatePaymentModes] = useState([]);
   // const [selectedPaymentMode, updateSelectedPaymentMode] = useState(props.paymentMode);

    useEffect(() => { getPaymentModes(); }, [])

    const getPaymentModes = async () => {
        let result = await administrationService.GetPaymentModes();
        if (!result.redirect) {

            updatePaymentModes(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
      //  updateSelectedPaymentMode(val);
       // if (action.action == 'clear')
            props.selectedPaymentModeId(val)
      //  else
      //      props.selectedPaymentModeId(val)
    }


    return (
        <Select options={paymentModes} isClearable={true} onChange={onSelectChange} value={props.paymentMode==null?null:paymentModes.find(o => o.value === props.paymentMode)} />
    );



}