import moment from "moment"
import { logged } from "../lib/logged"
export default class ReportService {


    async GetDayCloseData(restaurantId,reservationDate) {
        try {

            const response = await fetch("api/report/GetDayCloseData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ReservedDate: moment(reservationDate).format(moment.HTML5_FMT.DATE)

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetPaxCount(restaurantId, reservationDate) {
        try {

            const response = await fetch("api/report/GetPaxCount", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ReservedDate: moment(reservationDate).format(moment.HTML5_FMT.DATE)

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBigTableReservations(restaurantId, reservationDate) {
        try {

            const response = await fetch("api/report/GetBigTableReservations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ReservedDate: moment(reservationDate).format(moment.HTML5_FMT.DATE)

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetPackageDetails(restaurantId, reservationDate, reservationEndDate) {
        try {

            const response = await fetch("api/report/GetPackageDetails", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ReservedDate: moment(reservationDate).format(moment.HTML5_FMT.DATE),
                    ReservedEndDate: moment(reservationEndDate).format(moment.HTML5_FMT.DATE),
                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBookingData(restaurantId,reservationDate,reservationEndDate) {
        try {

            const response = await fetch("api/report/GetBookingData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ReservedDate: moment(reservationDate).format(moment.HTML5_FMT.DATE),
                    ReservedEndDate: moment(reservationEndDate).format(moment.HTML5_FMT.DATE),
                    UserId: logged.UserId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }




    async GetMembershipData(restaurantId, startDate, endDate) {
        try {

            const response = await fetch("api/report/GetMembershipData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    StartDate: moment(startDate).format(moment.HTML5_FMT.DATE),
                    EndDate: moment(endDate).format(moment.HTML5_FMT.DATE),

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetCallAndBookingData(startDate, endDate) {
        try {

            const response = await fetch("api/report/GetCallAndBookingData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    StartDate: moment(startDate).format(moment.HTML5_FMT.DATE),
                    EndDate: moment(endDate).format(moment.HTML5_FMT.DATE),
                    UserId: logged.UserId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetPriceChangeData(startDate, endDate) {
        try {

            const response = await fetch("api/report/GetPriceChangeData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    
                    StartDate: moment(startDate).format(moment.HTML5_FMT.DATE),
                    EndDate: moment(endDate).format(moment.HTML5_FMT.DATE),
                    

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetFeedbackNotificationData(restaurantId, reservationDate, reservationEndDate) {
        try {

            const response = await fetch("api/report/GetFeedbackNotificationData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ReservedDate: moment(reservationDate).format(moment.HTML5_FMT.DATE),
                    ReservedEndDate: moment(reservationEndDate).format(moment.HTML5_FMT.DATE),
                    UserId: logged.UserId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetReminderNotificationData(restaurantId, reservationDate, reservationEndDate) {
        try {

            const response = await fetch("api/report/GetReminderNotificationData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    ReservedDate: moment(reservationDate).format(moment.HTML5_FMT.DATE),
                    ReservedEndDate: moment(reservationEndDate).format(moment.HTML5_FMT.DATE),
                    UserId: logged.UserId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetItemOrderData(startDate, endDate,restaurantId) {
        try {

            const response = await fetch("api/report/GetItemOrderData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({

                    StartDate: moment(startDate).format(moment.HTML5_FMT.DATE),
                    EndDate: moment(endDate).format(moment.HTML5_FMT.DATE),
                    RestaurantId: restaurantId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBulkEnquiryData(dateType,startDate, endDate, restaurants,status) {
        try {

            const response = await fetch("api/report/GetBulkEnquiryData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    DateType: dateType,
                    StartDate: moment(startDate).format(moment.HTML5_FMT.DATE),
                    EndDate: moment(endDate).format(moment.HTML5_FMT.DATE),
                    Restaurants: restaurants,
                    Status: status

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
}