import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function OcassionDropDown(props) {

    const administrationService = new AdministrationService();

    const [ocassions, updateOcassions] = useState([]);
    //const [selectedOcassion, updateSelectedOcassion] = useState(null);

    useEffect(() => { getOcassions(); }, [])

    const getOcassions = async () => {
        let result = await administrationService.GetOcassions();
        if (!result.redirect) {

            updateOcassions(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
        //updateSelectedOcassion(val);
        //if (action.action == 'clear')
            props.selectedOcassionId(val)
        //else
           // props.selectedOcassionId(val.value)
    }


    return (
        <Select options={ocassions} isClearable={true} onChange={onSelectChange} value={props.ocassion==null?null:ocassions.find(o => o.value === props.ocassion)} />
    );



}