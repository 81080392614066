import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function PackageDropDown(props) {

    const administrationService = new AdministrationService();

    const [packages, updatePackages] = useState([]);
    //const [selectedPackage, updateSelectedPackage] = useState(null);

    useEffect(() => { getPackages(); }, [])

    const getPackages = async () => {
        let result = await administrationService.GetPackages();
        if (!result.redirect) {

            updatePackages(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
        //updateSelectedPackage(val);
        props.selectedPackageId(val)
       
    }


    return (
        <Select options={packages} isClearable={true} onChange={onSelectChange} value={props.bookingPackage==null?null:packages.find(o => o.value === props.bookingPackage)} />
    );



}