import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function RestaurantDropDown(props) {

    const administrationService = new AdministrationService();

    const [restaurants, updateRestaurants] = useState([]);
    const [selectedRestaurant, updateSelectedRestaurant] = useState(null);

    useEffect(() => { getRestaurants(); }, [])

    const getRestaurants = async () => {
        let result = await administrationService.GetRestaurants();
        if (!result.redirect) {

            updateRestaurants(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
        updateSelectedRestaurant(val);
        if (action.action == 'clear') 
            props.selectedRestaurantId(val)
        else
            props.selectedRestaurantId(val.value)
    }
    //<Select options={restaurants} isClearable={true} onChange={onSelectChange} value={selectedRestaurant} />

    return (
        <Select options={restaurants} isClearable={true} onChange={onSelectChange} value={props.restaurant == null ? null : restaurants.find(o => o.value === props.restaurant)} />
    );



}