import { Link } from 'react-router-dom'
import { Row, Col, Button, Progress, Container } from 'reactstrap'
import { logged } from "../../lib/logged"
export function IndentConsole(props) {


    return (
        <Container  style={{ backgroundColor: "white", height: "100%" }}>
            <Row style={{ paddingTop: '25px' }}>
                <Col sm="6" style={{ height:'40px',paddingTop:'10px',color: 'white', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                    INDENT ADMINISTRATION
                </Col>
                <Col sm="6" style={{ height: '40px', paddingTop: '10px', color: 'white', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                    SUMMARY

                </Col>

            </Row>
            <Row >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/indent/indentorderlist">Manage Indents</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Create and manage indents for daily and store needs
                </Col>

            </Row>
            <Row >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/indent/pastindentorderlist">Past Indents</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    View the past approved indents
                </Col>

            </Row>

            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ? <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/indent/consolidated">Consolidated Indent</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Consolidated indents for a day across restaurants
                </Col>

            </Row> : null}

            {/*<Row >*/}
            {/*    <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>*/}
            {/*        <Link to="#">Approve Indents</Link>*/}
            {/*    </Col>*/}
            {/*    <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>*/}
            {/*        Approve indents*/}
            {/*    </Col>*/}
            {/*</Row>*/}    


            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ?<Row >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px'  }}>
                    <Link to="/indent/indentDepartment">Manage Indent Department</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Create and manage Indent Department
                </Col>

            </Row> : null}

            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ? <Row >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/indent/indentType">Manage Indent Type</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Create and manage Indent Type
                </Col>

            </Row> : null}

            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ? <Row >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/indent/manageprice">Manage Price</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Adding or updating the price of grocery items
                </Col>
            </Row> : null}
        
            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ?<Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/groceryunittype">Manage Unit Type</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Add or remove unit type for grocery items

                </Col>
            </Row> : null}
            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ? <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/grocerycategory">Manage Category</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Manage categories of Grocery items

                </Col>
            </Row>: null}
            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ? <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/addgroceryitem">Manage Grocery Items </Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Add or remove grocery items

                </Col>
            </Row>: null}
            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ?<Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/grocerysupplier">Manage suppliers</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Add or remove grocery suppliers

                </Col>

            </Row> : null}
            {logged.Roles[0].roleId == 1 || logged.Roles[0].roleId == 7 ? <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/indent/bulksuppliermapping">Map Item supplier</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Map item supplier by category in bulk
                </Col>

            </Row> : null}


        



        </Container>
    )

}