import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import { Navigate, Link } from 'react-router-dom'
import AdministrationService from "../../services/AdministrationService"
import { AgGridReact } from 'ag-grid-react';
import { logged } from "../../lib/logged"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import 'react-toastify/dist/ReactToastify.css';
import  AddGroceryItem  from './AddGroceryItem';
import { AiFillCloseCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export function GroceryItem(props) {

    const administrationService = new AdministrationService();
    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef(null);
    const [initialLoad,setInitialLoad] = useState(true);
    const [InProgress, setInProgress] = useState(false);
    const [groceryItem, setGroceryItem] = useState([]);
    const [mode, setMode] = useState('N');
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: 'Grocery Item', field:'itemName', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 200, cellRenderer: (d) => {
                return <Button onClick={() => onItemModify(d.data.itemId) } color="link" >{d.data.itemName}</Button> } },
        { 
            field: 'categoryName', headerName: 'Category', filter: "agTextColumnFilter", floatingFilter: true, width: 200
        },
       // { field: 'unitTypes', headerName: 'Units', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 150 },
       // { field: 'supplierName', headerName: 'Supplier', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 200 },
        //{ field: 'priceUnitType', headerName: 'Price - UT', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 150 },
        //{ field: 'priceUnit', headerName: 'Price - Unit', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 140 },
       // { field: 'unitPrice', headerName: 'Unit Price', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 140 },
        { headerName: '', width: 100, cellRenderer: (d) => { return <Button onClick={() => { onInactivate(d.data.itemId); }} style={{ backgroundColor: '#ff1200', padding: '2px', margin: 0, marginBottom: '6px', visibility: 'visible' }} color="danger" size="sm" > Remove</Button> }, floatingFilter: false },
    ]);

    const [selectedItemId, setSelectedItemId] = useState(0)

    useEffect(() => {
        sizeToFit();
    }, [groceryItem])

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);

   
    //useEffect(() => {
    //    if (!initialLoad)
    //        setGroceryItemModalIsOpen(true)
    //    else
    //        setInitialLoad(false);

    //}, [mode])


    const onItemModify = (itemId) => {
        setSelectedItemId(itemId)
        setMode('M');
        openGroceryItemModal();
    }

    const onInactivate = async (unitTypeId) => {

        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to inactivate the item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async() => {

                        let result = await administrationService.InactivateGroceryItem(unitTypeId);
                        if (!result.redirect) {
                            toast.success('Grocery item has been removed')
                            getGroceryItems();
                        }
                        else {
                            toast.error("Error occured while removing the grocery item");

                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });


    }


    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: true
    })

    const onGridReady = useCallback(() => {
        getGroceryItems();
    }, []);

    const getGroceryItems = async () => {
        showLoading();
        let result = await administrationService.GetGroceryItemData();
        if (!result.redirect) {

            setGroceryItem(result.data.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching Grocery items');
            hideLoading();
        }
    }

 

    const showAlert = (message, type) => {

        if (type === 'error') {
            toast.error(message);
        }
        else if (type === 'success') {
            toast.success(message);
        }
    }

    const groceryItemModalStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '95%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: 'white'
        },
    };
    const [groceryItemModalIsOpen, setGroceryItemModalIsOpen] = useState(false);
    const closeGroceryItemModal = (status = 0) => {
        setGroceryItemModalIsOpen(false)
        getGroceryItems();
        if (status == 1) {
            // toast.success('Grocery Item has been successfully added')
        }
        else if (status == 0)
            toast.error("Error occured while adding grocery item");
        //else
        //    toast.info('Action has been cancelled');
    }
    const openGroceryItemModal = () => {
        setGroceryItemModalIsOpen(true)
    }

    const onCreateGroceryItem = () => {

        setSelectedItemId(0)
        setMode('N');
        openGroceryItemModal();

    }


    return (


        <Container >

            <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> Grocery Items </Col>
                <Col sm="2" style={{ textAlign: 'right' }}>  <Button color="primary" size="sm" onClick={onCreateGroceryItem} disabled={InProgress} >Add{""}</Button></Col>
            </Row>
            <div style={containerStyle}>

                <div style={{ width: '100%', height: 500 }} className="ag-theme-alpine" >
                    <AgGridReact rowHeight={32}
                        ref={gridRef}
                        rowData={groceryItem}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{ showAlert, getGroceryItems }}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while bookings are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No grocery items to show</span>'
                        }

                        suppressCellFocus={true} >

                    </AgGridReact>
                </div>
            </div>


            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            <Modal isOpen={groceryItemModalIsOpen} style={groceryItemModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg, rgb(0, 188, 212), #9C27B0)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Add Grocery Items</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeGroceryItemModal(2)}>
                                <AiFillCloseCircle />

                            </Button>
                        </IconContext.Provider>

                    </Col>

                </Row>
                <Row style={{ paddingTop: '10px' }}><Col sm="12"  ><AddGroceryItem modalClose={closeGroceryItemModal} mode={mode} selectedItemId={selectedItemId} /></Col></Row>

            </Modal>

        </Container>
    )

}