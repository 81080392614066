import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function IdCardTypeDropDown(props) {

    const administrationService = new AdministrationService();

    const [idCardTypes, updateIdCardType] = useState([]);

    useEffect(() => { getIdCards(); }, [])

    const getIdCards = async () => {
        let result = await administrationService.GetIdCardTypes();
        if (!result.redirect) {

            updateIdCardType(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
        
        props.selectedCardTypeId(val)
     
    }


    return (
        <Select options={idCardTypes} isClearable={true} onChange={onSelectChange} value={props.idCardType == null ? null : idCardTypes.find(o => o.value === props.idCardType)} />
    );



}