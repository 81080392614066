import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import AdministrationService from "../../services/AdministrationService";
import { RestaurantSupplierDropDown } from "../Controls/RestaurantSupplierDropDown"
import { Row, Col, Button,Container} from 'reactstrap'
import Modal from "react-modal";
import { logged } from "../../lib/logged"
import { RemoveMappedSupplier } from './RemoveMappedSupplier'
import { DefaultSupplierRenderer } from './DefaultSupplierRenderer'
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from '../Controls/UserRestaurantDropDown';
import { ToastContainer, toast } from 'react-toastify';
 
const GroceryItemSuppliers = (props) => {

    useEffect(() => {
        GetGroceryItemSuppliers();

    }, []);




    const administrationService = new AdministrationService()
    const [restaurantSupppliers, updateRestaurantSuppliers] = useState([]);
    const [selectedRestaurant, updateSelectedRestaurant] = useState(null);
    const [selectedSupplier, updateSelectedSupplier] = useState(null);
    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const gridRef = useRef(null);

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);


    useEffect(() => {
        sizeToFit();
    }, [restaurantSupppliers])

    const [columnDefs, setColumnDefs] = useState([
        { headerName: '', floatingFilter: false, cellRenderer: RemoveMappedSupplier },
        { field: 'id', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'supplierId', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'restaurantId', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'restaurantName', headerName: 'Restaurant', filter: "agTextColumnFilter"},
        { field: 'supplierName', headerName: 'Supplier', filter: "agTextColumnFilter" },
        { field:'isDefault',headerName: 'Default', floatingFilter: false, cellRenderer: DefaultSupplierRenderer },

       





    ]);

    const onRestaurantSelectionChange = (val) => {
        updateSelectedRestaurant(val);
    }

    const onSupplierSelectionChange = (val) => {

        updateSelectedSupplier(val );
    }


    const addrows = useCallback((gitems, addIndex) => {
        const newItems = [
            gitems
        ];


        gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: addIndex,
        });

    }, []);




    const getGridData = () => {

        let gdata = []
        gridRef.current.api.forEachNode(node => gdata.push(node.data));
        return gdata;

    }

    const updateIsDefault = (val, rData) => {
        let rowNode = gridRef.current.api.getRowNode(rData.id);
        rowNode.setDataValue('isDefault', val);

        if (val == true) {
            let gData = getGridData();
            for (let i = 0; i < gData.length; i++) {
                if (gData[i].restaurantId == rData.restaurantId && gData[i].supplierId != rData.supplierId) {
                    let rNode = gridRef.current.api.getRowNode(gData[i].id);
                    rNode.setDataValue('isDefault', false);
                }
            }
        }
    }



    const onItemAdd = () => {

        let isValid = validateForm();
        if (isValid) {
        


            let itemFound = false;
            let itemSuppliers = getGridData();
            let nextListId = 0;
            if (itemSuppliers.length == 0) {
                nextListId = 1
            }
            else {
                let maxListId = Math.max(...itemSuppliers.map(o => o.id));
                nextListId = maxListId + 1;
            }


            for (let i = 0; i < itemSuppliers.length; i++) {
                if (itemSuppliers[i].restaurantId === selectedRestaurant.value && itemSuppliers[i].supplierId == selectedSupplier.value) {
                    itemFound = true
                    break;
                }

            }
            if (!itemFound) {

                addrows({ id: nextListId, itemSupplierId: 0, itemId: props.itemId, supplierId: selectedSupplier.value, restaurantId: selectedRestaurant.value, supplierName: selectedSupplier.label, restaurantName: selectedRestaurant.label,isdefault:false}, undefined)
                updateSelectedRestaurant(null);
                updateSelectedSupplier(null);
              

            }
            else {
                toast.info('The item is already in the list');
            }
        }


    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    const [close, setClose] = useState(false);


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false
    })

    const onGridReady = useCallback(() => {


    }, []);

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }


    const onRemoveSelected = useCallback((selectedData) => {
        gridRef.current.api.applyTransaction({ remove: selectedData });

    }, []);


    function removeSupplierMap(data) {
        onRemoveSelected([data]);
    }



    const onClose = () => {
        setClose(true);
    }





    const validateForm = () => {

        if (selectedRestaurant == null) {
            toast.error('Please select a restaurant')
            return false;
        }
        else if (selectedSupplier == null) {
            toast.error('Please select a supplier')
            return false;
        }
       
        return true;
    }


    const getRowId = useMemo(() => {
        return (params) => {
            return params.data.id;
        };
    }, []);


    const addUpdateItems = async () => {

        let _grocerySuppliers = getGridData();

        const uniqueRestaurants = [...new Set(_grocerySuppliers.map((item) => item.restaurantName))];
        for (let j = 0; j < uniqueRestaurants.length; j++) {
            let defaultFound = _grocerySuppliers.find(item => (item.isDefault == true && item.restaurantName === uniqueRestaurants[j]));
            if (defaultFound == undefined) {
                toast("Please select a default supplier for the restaurant " + uniqueRestaurants[j]);
                return;
            }
        }

        setMessage('SAVING IN PROGRESS');
        openMessageModal();
        

        let result = await administrationService.SaveSupplierMapping(props.itemId, _grocerySuppliers, logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();
                    toast("Supplier details has been updated")

                }
                else {
                    closeMessageModal();
                    toast("Failure in updating supplier details")
                }
            }
            else {
                closeMessageModal();
                toast('Error occured while updating supplier details', 'error');
            }
     
    }



    //const saveItem = (e, sUpdate = false) => {
    //    let _groceryItems = getGridData();
    //    if (_groceryItems.length > 0) {
    //        addUpdateItems();
    //        if (!sUpdate)
    //            setClose(true);
    //    }
    //    else
    //        toast.info("There are no records to save");


    //}




   



    const GetGroceryItemSuppliers = async () => {
        let result = await administrationService.GetGroceryItemSuppliers(props.itemId);
        if (!result.redirect) {
            updateRestaurantSuppliers(result.data.data);
        }
        else {
            toast.error('Error occured in fetching grocery item suppliers');
        }
       
    }


    return (
        <Container style={{} }>
            <form >



                <Row style={{ paddingTop: '15px' }}>
                    
                    <Col sm="5" style={{ textAlign:'left' }}>
                        <label>
                            Choose Restaurant{` : `}

                        </label>
                        < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant } />

                    </Col>
                    <Col sm="5">
                        <label>
                            Choose Supplier{` : `}

                        </label>
                        <RestaurantSupplierDropDown key={selectedRestaurant == null ? selectedRestaurant : selectedRestaurant.value} restaurantId={selectedRestaurant == null ? selectedRestaurant : selectedRestaurant.value} selectedSupplierId={onSupplierSelectionChange} supplier={selectedSupplier} />

                    </Col>
                    <Col sm="2">

                        <Button style={{ marginRight: '5px', marginTop: '23px' }} color="success" size='sm' onClick={onItemAdd} >ADD{""}</Button>

                    </Col>
                </Row>


               


                <Row style={{ paddingTop: '15px' }}>

       
                    <Col sm="10" style={{ margin: 0, padding: 0, marginTop: '3px' }}>
                      

                        <div style={{ width: '100%', height: '250px' }} className="ag-theme-alpine" >
                            <AgGridReact rowHeight={32}
                                ref={gridRef}
                                rowData={restaurantSupppliers}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                context={{ removeSupplierMap, updateIsDefault }}
                                getRowId={getRowId}
                                animateRows={true}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add items to be ordered</span>'
                                }

                                suppressCellFocus={true} >

                            </AgGridReact>
                        </div>

                
                
                    </Col>
                </Row>


                <Row style={{ marginTop: '15px' }}>

                    <Col sm="12" style={{ textAlign: 'center' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={addUpdateItems} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={() => props.modalClose()} >Close{""}</Button>
                    </Col>


                </Row>




           

                <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
                </Modal>

            </form>

            {/*<ToastContainer*/}
            {/*    position="top-center"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="dark"*/}
            {/*/>*/}


        </Container>
    );
};

export default GroceryItemSuppliers;