

import React, { useState } from "react";

export function ItemPriceRenderer(props) {

    const [itemPrice, setItemPrice] = useState(props.data.itemPrice);

    return (
        <input
            type="text"
            value={itemPrice}
            style={{ height: '25px', width: '60px' }}
            onChange={e => {
                setItemPrice(e.target.value);
                props.context.updatePrice(e.target.value, props.data.id);
            }}
        />
    )
}