
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx";
import { Roles } from "../../lib/Roles"
import "react-datepicker/dist/react-datepicker.css";
import { logged } from "../../lib/logged";



export function MembershipReport(props) {

    const reportService = new ReportService();


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        updateMembershipData([]);
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [membershipData, updateMembershipData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([

        { field: 'customerId', headerName: 'Customer Id', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'restaurantName', headerName: 'Restaurant', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'firstName', headerName: 'FirstName', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        { field: 'lastName', headerName: 'LastName', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'Mobile No', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'cardType', headerName: 'Id Proof', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'idCardNo', headerName: 'Id Card Number', width: 180, filter: "agTextColumnFilter", floatingFilter: true, sortable: true,
            cellRenderer: (d) => {
                if (logged.Roles[0].roleId != Roles.Administrator) {
                    if (d.data.idCardNo != null && d.data.idCardNo != '')
                        return MaskCharacter(d.data.idCardNo, '#')
                    else
                        return d.data.idCardNo
                }
                else
                    return d.data.idCardNo
            }
        },
        { field: 'noOfBookings', headerName: 'No of Bookings', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'memberSince', headerName: 'Member Since', width: 140, sortable: true,
            cellRenderer: (d) => {
                if (d.data.memberSince != null)
                    return moment(d.data.memberSince).format("DD/MM/YY")
                else
                    return d.data.memberSince
            }

        },
        { field: 'createdBy', headerName: 'Created By', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
       
       
    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    const MaskCharacter=(str, mask, n = 1)=> {

        // Slice the string and replace with
        // mask then add remaining string
        return [...str].reduce((acc, x, i) =>
            (i < str.length - n) ? acc + mask : acc + x, '');
    }


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })



    const getMembershipData = async () => {
        if (isInputValid()) {
            showLoading();
            let result = await reportService.GetMembershipData(selectedRestaurant.value, startDate, endDate);
            if (!result.redirect) {
                let rptData = result.data.data
                updateMembershipData(rptData);
                hideLoading();
            }
            else {
                toast.error('Error occured in fetching report data');
                hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "MembershipReport" });
    }, []);

    const isInputValid = () => {
        let isAfter = false;
        if (startDate !== null && endDate !== null) {
            isAfter = moment(endDate).isSameOrAfter(startDate);

        }

        if (selectedRestaurant == null) {
            toast.error('Please choose a restaurant')
            return false;
        }
        else if (startDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (endDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }


    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingBottom: '20px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> MEMBERSHIP REPORT </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row>


                <Col sm="1" style={{ padding: 0, paddingLeft: '10px' }} >
                    <label className="required-fields" >
                        Restaurant {` : `}

                    </label>

                </Col>
                <Col sm="2" >
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />
                </Col>


                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        Start Date {` : `}

                    </label>
                </Col>


                <Col sm="2">
                    <DatePicker selected={startDate} onChange={(date) => { setStartDate(date); updateMembershipData([]); }} />
                </Col>

                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        End Date {` : `}

                    </label>
                </Col>


                <Col sm="2">
                    <DatePicker selected={endDate} onChange={(date) => { setEndDate(date); updateMembershipData([]); }} />
                </Col>

                <Col sm="1" >
                    <Button color="primary" size='sm' onClick={getMembershipData}>Submit</Button>
                </Col>

            </Row>

            {logged.Roles[0].roleId == Roles.Administrator ? (<Row style={{ paddingTop: '25px', }}>
                <Col sm="2"></Col>
                <Col sm="8" style={{ textAlign: 'right' }}>
                    {/*<Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>*/}

                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>
                <Col sm="2" ></Col>
            </Row>) : (null)}
            <div id="bookingreport">
                {/*<Row>*/}

                {/*     <Col sm="12" style={{ textAlign: 'center' }}> <span style={{ color: 'black', fontWeight: 700 }}>BOOKING REPORT</span></Col>*/}
                {/* </Row> */}

                {/*<Row>*/}

                {/*    <Col sm="2" style={{ textAlign: 'left' }}> <span style={{ fontWeight: 700, color: "#6c757d" }}>{selectedRestaurant == null ? '' : selectedRestaurant.label}</span>{`     `} <span style={{ paddingLeft: '50px' }}>{reservationStartDate == null ? '' : ``}</span> </Col>*/}
                {/*    <Col sm="8"  ></Col>*/}
                {/*    <Col sm="2" style={{ textAlign: 'right' }} ><span style={{ fontWeight: 700, color: "#6c757d" }}>{reservationStartDate == null ? '' : moment(reservationStartDate).format(moment.HTML5_FMT.DATE)}</span> </Col>*/}
                {/*</Row>*/}
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={22}
                                ref={gridRef}
                                rowData={membershipData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }

                                suppressCellFocus={true} >

                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
