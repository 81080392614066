import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { SecuredRoutes } from './SecuredRoute'
import './custom.css';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
   
        <Routes>
            <Route element={<SecuredRoutes />}>
          {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              return <Route exact key={index} {...rest} element={element} />;
          })}
            </Route>
        </Routes>
     
    );
  }
}
