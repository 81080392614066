import React, { useState, useEffect } from "react";
import BookingService from "../../services/BookingService"
import Select from 'react-select'



export function CancelReasonDropDown(props) {

    const bookingService = new BookingService();

    const [cancelReasons, updateCancelReasons] = useState([]);

    useEffect(() => { getCancelReasons(); }, [])

    const getCancelReasons = async () => {
        let result = await bookingService.GetCancelReason();
        if (!result.redirect) {

            updateCancelReasons(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {

           if (action.action == 'clear') {
              props.selectedCancelReasonId(val)
           }
           else {
               props.selectedCancelReasonId(val.value)
               props.selectedCancelReasonName(val.label)
           }
    }


    return (
        <Select options={cancelReasons} isClearable={true} onChange={onSelectChange} value={props.cancelReason == null ? null : cancelReasons.find(o => o.value === props.cancelReason)} />
    );



}