
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'

import IndentService from "../../services/IndentService"

import { logged } from "../../lib/logged"
import { ToastContainer, toast } from 'react-toastify';



export function IndentPendingApproval(props) {

    const [indentData, updateIndentData] = useState([]);

    const indentService = new IndentService();
    useEffect(() => {
        getIndentData();

    }, [])





    const getIndentData = async () => {


        let result = await indentService.GetIndentPendingForApproval(logged.UserId)
        if (!result.redirect) {
            let rptData = result.data.data
            updateIndentData(rptData);

        }
        else {
            toast.error('Error occured in fetching pending  data');

        }


    }






    return (
        <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>


            <Row style={{ paddingTop: '25px', }}>
                <Col sm="4"></Col>
                <Col sm="4" style={{ textAlign: 'center', color: 'darkblue', fontWeight: 700 }}>INDENTS PENDING FOR APPROVALS</Col>


            </Row>
            <div id="pendingIndent">

                {indentData.length > 0 ? indentData.map((d, i) => (
                    <Row noGutters={true} style={{ height: '150px' }} key={i}>
                        <Col sm="1" style={{ color: 'blue', backgroundColor: 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '2px', paddingRight: 0, fontWeight: 500, borderTop: 'none', textAlign: 'left' }}>{d.indentOrderId}</Col>
                        <Col sm="2" style={{ color: 'black', backgroundColor: 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.restaurantName}</Col>
                        <Col sm="2" style={{ color: 'black', backgroundColor: 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.indentTypeName}</Col>
                        <Col sm="1" style={{ color: 'black', backgroundColor: 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.departmentName}</Col>
                        <Col sm="2" style={{ color: 'black', backgroundColor: 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.submittedBy}</Col>
                        <Col data-testid="alert-data" sm="2" style={{ color: 'black', backgroundColor: 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.alertData}</Col>
                        <Col data-testid="mobile-data" sm="2" style={{ color: 'black', backgroundColor: 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.toMobileNo}</Col>



                    </Row>
                )
                ) : (null)}
            </div>




        </Container>
    )
}
