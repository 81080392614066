import React, { useState, useEffect } from "react";
import IndentService from "../../services/IndentService";
import Select from 'react-select'



export function ParentDepartmentDropDown(props) {

    const indentService = new IndentService();

    const [department, updateDepartment] = useState([]);


    useEffect(() => { getDepartment(); }, []);

    const getDepartment = async () => {
        let result = await indentService.GetParentDepartment();

        if (!result.redirect) {

            updateDepartment(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {

        props.selectedDepartmentId(val)

    }


    return (
        <Select options={department} isClearable={true} onChange={onSelectChange} value={props.department == null ? null : department.find(o => o.value === props.department)} />


    );



}