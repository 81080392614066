import React from 'react';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import { SiAirtable } from "react-icons/si";
import { IoArrowUndoCircleSharp } from "react-icons/io5";
import { IconContext } from "react-icons";
import { ReservationStatus } from "../../lib/ReservationStatus";
import BookingService from "../../services/BookingService"
import { UncontrolledTooltip } from "reactstrap"




export function CancelledRenderer(props) {

    const bookingService = new BookingService();

    const BookingAction = async (bookingId, status) => {

        let result = await bookingService.UpdateReservationStatus(bookingId, status, '', '', logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                if (status === 'C') {
                    props.context.showAlert('Booking is moved back to confirm status', 'success')
                }


                props.context.getBookingData();
            }
            else {
                if (status === 'C') {
                    props.context.showAlert('Failure in reversing the status to confirm', 'error')
                }

            }
        }
        else {
            props.context.showAlert('Error occured while reversing the status of the booking to confrim', 'error');
        }

    }


 

    return (
        <React.Fragment>

           

            <button id="btnreverseconfirm" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '2px' }} onClick={() => { BookingAction(props.data.bookingId, ReservationStatus.Confirmed) }
            }>
                <IconContext.Provider value={{ size: '22px', color: '#198754', verticalAlign: 'sub' }}> < IoArrowUndoCircleSharp /></IconContext.Provider>
            </button>

            <UncontrolledTooltip placement="left" target="btnreverseconfirm">
                Reverse To Confirm
            </UncontrolledTooltip>
        </React.Fragment>

    )

}