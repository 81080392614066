import { Link } from 'react-router-dom'
import { Row, Col, Button, Progress, Container } from 'reactstrap'
export function AdminConsole(props) {


    return (
        <Container  style={{ backgroundColor: "white", height: "100%" }}>
            <Row style={{ paddingTop: '25px' }}>
                <Col sm="6" style={{ paddingTop: '10px', height: '40px', color: 'white', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', background: 'linear-gradient(120deg,#5C6AC0, #BBDEFB)' } }>
                    ADMINISTRATION
                </Col>
                <Col sm="6" style={{ paddingTop: '10px', height: '40px', color: 'black', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', background: 'linear-gradient(120deg, #BBDEFB,#5C6AC0)' }}>
                     SUMMARY
                    
                </Col>

            </Row>
            <Row syle={{ paddingTop: '15px' }} >
                <Col sm="12" style={{ paddingTop: '5px', height: '30px', background: 'linear-gradient(120deg,rgb(115 227 241), #E1F5FE)', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    User Management
                </Col>


            </Row>
            <Row style={{ minHeight: '25px' }} >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none',paddingLeft: '25px' }}>
                    <Link to="/administration/users">Manage Users</Link> 
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Manage user and access to the application

                </Col>
               
            </Row>
            <Row style={{ minHeight: '25px' }} >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/roles">Manage Roles</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Manage role and access to the application

                </Col>

            </Row>
            <Row style={{ minHeight: '25px' }} >
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/restaurants">Manage Restaurants</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Manage restaurant and access to the application

                </Col>

            </Row>

            <Row syle={{ paddingTop: '15px' }} >
                <Col sm="12" style={{ paddingTop: '5px', height: '30px', background: 'linear-gradient(120deg,rgb(115 227 241), #E1F5FE)', fontWeight: 500, borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Reservation Management
                </Col>


            </Row>
            <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/package">Manage Package</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Manage package and access to the application

                </Col>

            </Row>
            <Row style={{ minHeight: '25px' }}>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none', paddingLeft: '25px' }}>
                    <Link to="/administration/ocassion">Manage Occasion</Link>
                </Col>
                <Col sm="6" style={{ borderBlockColor: 'black', borderStyle: 'solid', borderWidth: 'thin', borderTopStyle: 'none' }}>
                    Manage occasion and access to the application

                </Col>

            </Row>
    

        </Container>
        )

}