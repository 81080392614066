import moment from "moment"
import { logged } from "../lib/logged"


export default class IndentService {

    async GetGroceryPriceData() {
        try {

            const response = await fetch("api/indent/GetGroceryPriceList", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateItemPrice(id,unit,price,supplierId) {

        try {

            const response = await fetch("api/indent/UpdateGroceryPrice", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemPriceMappingId: id,
                    Unit: unit,
                    Price: price,
                    SupplierId:supplierId,
                    ModifiedBy: logged.UserId,

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async GetIndentOrders(createdBy,roleId) {
        try {

            const response = await fetch("api/indent/GetIndentOrders", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                   
                    Role: roleId,
                    createdBy: createdBy
                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetPastIndentOrders(createdBy, roleId) {
        try {

            const response = await fetch("api/indent/GetPastIndentOrders", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({

                    Role: roleId,
                    createdBy: createdBy
                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async InactivateIndentOrder(IndentOrderID) {
        try {

            const response = await fetch("api/indent/InactivateIndentOrder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentOrderId: IndentOrderID,
                    ModifiedBy: logged.UserId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async SetIndentOrderStatus(IndentOrderID, Status) {
        try {

            const response = await fetch("api/indent/SetIndentOrderStatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentOrderId: IndentOrderID,
                    ModifiedBy: logged.UserId,
                    Status: Status
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetIndentTypes() {
        try {

            const response = await fetch("api/indent/GetIndentTypes", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetIndentDepartments(restaurantId) {
        try {

            const response = await fetch("api/indent/GetIndentDepartments", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetIndentSubDepartments(departmentId) {
        try {

            const response = await fetch("api/indent/GetIndentSubDepartments", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    DepartmentId: departmentId

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetItemMappedUnitTypes(itemId) {
        try {

            const response = await fetch("api/indent/GetItemMappedUnitTypes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    ItemId: itemId

                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    

    async AddIndentOrder(indentType,indentDepartment,indentRestaurant,indentStatus,createdBy) {
        try {

            const response = await fetch("api/indent/AddIndentOrder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentType: indentType,
                    Department: indentDepartment,
                    Restaurant: indentRestaurant,
                    CreatedBy: createdBy,
                    Status: indentStatus
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetGrocerySearch() {
        try {

            const response = await fetch("api/indent/GetGrocerySearch", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async AddIndentOrderList(indentOrderId,indetOrderItems,createdBy) {
        try {

            const response = await fetch("api/indent/AddIndentOrderList", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    Items: indetOrderItems,
                    CreatedBy: createdBy,
                    IndentOrderId:indentOrderId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }


    }

    async GetIndentOrderItems(indentOrderId) {
        try {

            const response = await fetch("api/indent/GetIndentOrderItems", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentOrderId: indentOrderId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }


    async GetIndentOrderDetails(indentOrderId) {
        try {

            const response = await fetch("api/indent/GetIndentOrderDetails", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentOrderId: indentOrderId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async UpdateOrderStatus(indentOrderId, status, createdBy) {
        try {

            const response = await fetch("api/indent/UpdateOrderStatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    Status:status,
                    CreatedBy: createdBy,
                    IndentOrderId: indentOrderId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }


    }


    async BulkUpdateItemSupplier(restaurantId, categoryId,supplierId,isDefault, createdBy) {
        try {

            const response = await fetch("api/indent/BulkUpdateItemSupplier", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,
                    CategoryId: categoryId,
                    IsDefault: isDefault,
                    SupplierId:supplierId,
                    CreatedBy: createdBy,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }


    }

    async GetIndentPendingForApproval(userId) {
        try {

            const response = await fetch("api/indent/GetIndentPendingForApproval", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CreatedBy: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetConsolidatedIndent(date) {
        try {

            const response = await fetch("api/indent/GetConsolidatedIndentOrders", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    Date: moment(date).format(moment.HTML5_FMT.DATE)
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivateIndentType(IndentTypeID) {
        try {

            const response = await fetch("api/indent/InactivateIndentType", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentTypeId: IndentTypeID,
                    ModifiedBy: logged.UserId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async AddIndentType(indentTypeName, createdBy, modifiedBy) {
        try {

            const response = await fetch("api/indent/AddIndentType", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentTypeName: indentTypeName,
                    CreatedBy: createdBy,
                    ModifiedBy: modifiedBy
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetIndentDepartment() {
        try {

            const response = await fetch("api/indent/GetIndentDepartment", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async InactivateIndentDepartment(IndentDeptID) {
        try {

            const response = await fetch("api/indent/InactivateIndentDepartment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    IndentDepartmentId: IndentDeptID,
                    ModifiedBy: logged.UserId
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetParentDepartment() {
        try {

            const response = await fetch("api/indent/GetParentDepartment", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async AddIndentDepartment(indentDeptName, createdBy, parentDept) {
        try {

            const response = await fetch("api/indent/AddIndentDepartment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    DepartmentName: indentDeptName,
                    CreatedBy: createdBy,
                    ParentDepartment: parentDept
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }






    async GetAmountSpendByCategory(restaurant) {
        try {

            const response = await fetch("api/indent/GetAmountSpendByCategory", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    Restaurant: restaurant,
                 
                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetAmountSpendByCategoryMonthly(restaurant,month) {
        try {

            const response = await fetch("api/indent/GetAmountSpendByCategoryMonthly", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    Restaurant: restaurant,
                    Month:month

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }
    async GetAmountSpendByIndent(restaurant) {
        try {

            const response = await fetch("api/indent/GetAmountSpendByIndent", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    Restaurant: restaurant,

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

}