import React, { useState, useEffect } from "react";
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button } from 'reactstrap';
import { logged } from "../lib/logged"
import { IconContext } from "react-icons";
import { IoIosLogOut } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";
import { Roles } from "../lib/Roles"

import './NavMenu.css';

 export function NavMenu()  {
  const displayName = NavMenu.name;
     const navigate = useNavigate();

    const [collapsed,setCollapsed]=useState(true)



     const toggleNavbar = () => {
         setCollapsed(!collapsed)
    }

    const logout=()=> {
        sessionStorage.removeItem('K1Prms');
        logged.IsAuth = false;
        logged.AuthDate = null;
        logged.UserId = 0;
        logged.Name = '';
        logged.Roles = [];
        logged.AccessToken = '';
        navigate('/login');

    }

  
      return (
          <header style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)', }}>

              <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                  <NavbarBrand style={{color:'cyan',fontSize:'larger',fontWeight:900}} tag={Link} to="/">RMS</NavbarBrand>
                  <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                  <Collapse style={{ flexDirection: 'column' }} className="d-sm-inline-flex " isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
            

                          <span className="text-white  nav-link nav-item " style={{ paddingLeft:0 }}>|</span>
                    
                          {(logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.GeneralManager || logged.Roles[0].roleId == Roles.CallCenter || logged.Roles[0].roleId == Roles.FrontDesk) ? <NavItem>
                              <NavLink tag={Link} className="text-white " to="/bookings">Reservation  |</NavLink>
                          </NavItem> : null}

                          {(logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.CallCenter || logged.Roles[0].roleId == Roles.FrontDesk || logged.Roles[0].roleId == Roles.GeneralManager || logged.Roles[0].roleId == Roles.MarketingManager) ? <NavItem>
                              <NavLink tag={Link} className="text-white " to="/bulkbooking"> Bulk Enquiry  |</NavLink>
                          </NavItem> : null}

                          {(logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.FrontDesk || logged.Roles[0].roleId == Roles.GeneralManager) ? <NavItem>
                              <NavLink tag={Link} className="text-white" to="/reports"> Reports  |</NavLink>
                          </NavItem> : null}
                          {(logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.CallCenter || logged.Roles[0].roleId == Roles.FrontDesk) || logged.Roles[0].roleId == Roles.GeneralManager ? <NavItem>
                              <NavLink tag={Link} className="text-white" to="/missedcalls"> Missed Calls  |</NavLink>
                          </NavItem> : null} 

                          {logged.Roles[0].roleId == Roles.Administrator ? < NavItem >   
                              <NavLink tag={Link} className="text-white" to="/administration"> Administration  |</NavLink>
                          </NavItem> : null}
                          {(logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.ExecutiveChef || logged.Roles[0].roleId == Roles.SousChef || logged.Roles[0].roleId == Roles.PurchaseManager || logged.Roles[0].roleId == Roles.GeneralManager) ? < NavItem > 
                              <NavLink tag={Link} className="text-white" to="/indent"> Indents  |</NavLink>
                          </NavItem> : null}
             
            </ul>
                  </Collapse>
                  <NavbarBrand style={{ fontSize: 'medium', color: 'deeppink' }}>{`Welcome, `} <span>{logged.Name}</span>
                      <Button style={{ backgroundColor:'#8549ae', border: 0,paddingLeft:'25px' }} onClick={logout}> <IconContext.Provider value={{ size: '28px', color: 'white' }}> < IoIosLogOut /></IconContext.Provider></Button>
                  </NavbarBrand>

                  </Navbar>
                  
      </header>
    );
 
}
