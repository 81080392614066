import React, { useState } from "react";
import { IoList, IoRestaurant } from "react-icons/io5";
import { SiBookbub } from "react-icons/si";
import { Row, Col, Container, UncontrolledTooltip } from 'reactstrap'
import { IconContext } from "react-icons";
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import BulkEnquirySummary from '../Booking/BulkEnquirySummary'

import IndentSummary from '../Indent/IndentSummary'
import { BookingSummary } from '../Dashboard/BookingSummary'

export function DashboardMain(props) {

    const [showBooking, setShowBooking] = useState(true);
    const [title, setTitle] = useState('RMS - RESERVATION DASHBOARD');
    const [showIndent, setShowIndent] = useState(false);
    const [showBulkEnquiry, setShowBulkEnquiry] = useState(false);


    return (
        <Container fluid={true}>
            {logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.GeneralManager ? <Row>
                <Col sm="11" style={{ paddingLeft: '35px',color:'crimson' }}><h3>{title}</h3></Col>
                <Col sm="1" style={{ padding: 0, marginBottom: '2px', textAlign: 'right', paddingRight: '3px' }}>
                    {!showBooking ? <button id="btnBookingSummary" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '2px' }} onClick={() => { setShowBooking(true); setShowIndent(false); setShowBulkEnquiry(false); setTitle('RMS - RESERVATION DASHBOARD') }
                    }>
                        <IconContext.Provider value={{ size: '22px', color: '#ff9800', verticalAlign: 'sub' }}> < IoRestaurant /></IconContext.Provider>
                        <UncontrolledTooltip placement="left" target="btnBookingSummary">
                            Reservation Dashboard
                        </UncontrolledTooltip>
                    </button> : null}

               
                    {!showIndent ? <button id="btnIndentSummary" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '2px' }} onClick={() => { setShowBooking(false); setShowIndent(true); setShowBulkEnquiry(false); setTitle('RMS - INDENT DASHBOARD') }
                    }>
                        <IconContext.Provider value={{ size: '22px', color: '#E91E63', verticalAlign: 'sub' }}> < IoList /></IconContext.Provider>
                        <UncontrolledTooltip placement="left" target="btnIndentSummary">
                            Indent Dashboard
                        </UncontrolledTooltip>
                    </button>:null}

                    {!showBulkEnquiry ? <button id="btnBulkEnquirySummary" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '2px' }} onClick={() => { setShowBooking(false); setShowIndent(false); setShowBulkEnquiry(true); setTitle('RMS - BULK ENQUIRY DASHBOARD') }
                    }>
                        <IconContext.Provider value={{ size: '22px', color: '#4CAF50', verticalAlign: 'sub' }}> < SiBookbub /></IconContext.Provider>
                        <UncontrolledTooltip placement="left" target="btnBulkEnquirySummary">
                            Bulk Enquiry Dashboard
                        </UncontrolledTooltip>
                    </button> : null}
            
                </Col>
            </Row>:null}

            {(logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.FrontDesk || logged.Roles[0].roleId == Roles.GeneralManager || logged.Roles[0].roleId == Roles.MarketingManager || logged.Roles[0].roleId == Roles.CallCenter) && showBooking ? (
                <BookingSummary />
            ) : (logged.Roles[0].roleId == Roles.Administrator || logged.Roles[0].roleId == Roles.GeneralManager || logged.Roles[0].roleId == Roles.MarketingManager ) && showBulkEnquiry ? <BulkEnquirySummary /> :< IndentSummary />

                }
        
        </Container>

        )
}