import React from "react";
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import BookingService from "../../services/BookingService"
import { Row, Col, Button, Progress, Container } from 'reactstrap';
import { Navigate } from 'react-router-dom';
import { IconContext } from "react-icons";
import { IoIosCloseCircle } from "react-icons/io";
import { logged } from "../../lib/logged";
import DatePicker from "react-datepicker";
import moment from "moment";
import { TimeDropDown } from "../Controls/TimeDropDown"
import { RestaurantDropDown } from '../Controls/RestaurantDropDown';
import { OcassionDropDown } from "../Controls/OcassionDropDown";
import { BookingSourceDropDown } from "../Controls/BookingSourceDropDown";
import { MealTypeDropDown } from "../Controls/MealTypeDropDown";
import { AiFillCloseCircle } from "react-icons/ai";

//import { PaymentModeDropDown } from "../Controls/PaymentModeDropDown";
import Modal from "react-modal";
import { AgGridReact } from 'ag-grid-react';
//import { RemovePaymentRenderer } from './RemovePaymentRenderer'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { BulkBooking } from "./BulkBooking";
import { BulkEnquiryStatus } from "../../lib/BulkEnquiryStatus";
import { FeedbackBulkEnquiry } from "./FeedbackBulkEnquiry";

export function AddBulkBooking(props) {
    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }
    let { bId } = useParams();

    useEffect(() => {
        if (props.mode == 'u' && bId!='0') {

            getBulkEnquiryById(parseInt(bId));
        }


    }, [])

    const checkoutModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };



    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };
    const cancelModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: '#ede7f6'
        },
    };
    const confirmModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    const [redirectBooking, setRedirectBooking] = useState(false);
    const [bulkbookingDetails, addbulkbookingDetails] = useState({
        bulkEnquiryId: 0, firstName: '', lastName: '', mobileNo: '', companyName: '', bookingId: 0, noOfPax: 0, sourceId: 0, ocassionId: 0, specialInstruction: '', reserverdDate: null, reservedTime: null,
        createdBy: logged.UserId,
        restaurantId: '', mealTypeId: 0, foodCostPerPax: 0, foodTotalAmount: 0,
        reservationStatus: '', enquiryStatus:''
    });

    // const [payment, updatePayment] = useState({ paymentId: 0, dummyId: 0, paymentDate: null, paymentMode: null, paymentModeText: '', paymentReference: '', amountPaid: '', paymentBy: '' });
    const [InProgress, setInProgress] = useState(false);
    const [update, setUpdate] = useState(props.mode=='u'?true:false);
    const [checkoutModalIsOpen, setCheckoutModalIsOpen] = React.useState(false);
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const [confirmModalIsOpen, setConfirmModalIsOpen] = React.useState(false);
    const bookingService = new BookingService();
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('')

    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const closeCheckoutModal = () => {
        setCheckoutModalIsOpen(false)
    }



    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }

    const openConfirmModal = () => {
        setConfirmModalIsOpen(true)
    }

    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false)
    }

    const getBulkEnquiryById = async (bookingId) => {
        setMessage('LOADING IN PROGRESS');
        openMessageModal();

        let result = await bookingService.GetBulkEnquiryDetailsById(bookingId);
        if (!result.redirect) {
            if (result.data.status) {
                addbulkbookingDetails(result.data.data);
                closeMessageModal();
            }
            else
                toast.error('Failure in loading bulk Enquiry details');
            closeMessageModal();
        }
        else {
            toast.error('Error occured in fetching bulk enquiry details');
            closeMessageModal();
        }
    }
    const onMobileNoChange = (e) => {
        if (!isNaN(Number(e.target.value))) {
            addbulkbookingDetails({ ...bulkbookingDetails, mobileNo: e.target.value })
        }
    }
    const onCostChange = (e) => {
        if (!isNaN(Number(e.target.value))) {
            let cost = parseInt(e.target.value == '' ? 0 : e.target.value);
            let amount = parseInt(bulkbookingDetails.noOfPax) * cost

            addbulkbookingDetails({ ...bulkbookingDetails, foodCostPerPax: e.target.value != '' ? parseInt(e.target.value) : '', foodTotalAmount: amount })
            //addbulkbookingDetails({ ...bulkbookingDetails, foodTotalAmount: amount });
        }
    }

    const onDisableButtons = () => {
        if (bulkbookingDetails.mealTypeId != '' || bulkbookingDetails.mealTypeId != 0) {
            setUpdate(true); //values are already updated and that needs either confirmation/cancellation/Complete
            return true;
        }
        else
            return false;
    }
    const setTotalAmount = () => {
        //total amount calculation - pax*perhead cost
        let amount = parseInt(bulkbookingDetails.foodCostPerPax) * bulkbookingDetails.noOfPax;
        addbulkbookingDetails({ ...bulkbookingDetails, foodTotalAmount: amount });

    }

    const setReservationDate = (val) => {
        addbulkbookingDetails({ ...bulkbookingDetails, reserverdDate: val });
    }
    const updateSelectedTime = (val) => {

        addbulkbookingDetails({ ...bulkbookingDetails, reservedTime: val == null ? null : val.value });
    }
    const updateNoOfAdults = (e) => {

        let pax = parseInt(e.target.value == '' ? 0 : e.target.value);
        let amount = parseInt(bulkbookingDetails.foodCostPerPax) * pax


        addbulkbookingDetails({
            ...bulkbookingDetails, noOfPax: e.target.value,foodTotalAmount: amount
})

    }
    const updateSelectedOcassion = (val) => {

        addbulkbookingDetails({ ...bulkbookingDetails, ocassionId: val.value });
    }
    const updateSelectedSource = (val) => {

        addbulkbookingDetails({ ...bulkbookingDetails, sourceId: val == null ? 0 : val.value });
    }
    const onRestaurantSelectionChange = (val) => {
        addbulkbookingDetails({ ...bulkbookingDetails, restaurantId: val });
    }
    const updateSelectedMealType = (val) => {
        addbulkbookingDetails({ ...bulkbookingDetails, mealTypeId: val == null ? 0 : val.value });
    }
    const onSpecialInstructionChange = (e) => {
        addbulkbookingDetails({ ...bulkbookingDetails, specialInstruction: e.target.value });
    }
    const openCancelModal = () => {
        setCancelModalIsOpen(true);
    }
    const closeCancelModal = () => {
        setCancelModalIsOpen(false);
    }
    const openCompleteModal = () => {
        setCompleteModalIsOpen(true);
    }
    const closeCompleteModal = () => {
        setCompleteModalIsOpen(false);
    }

    //const setPaymentDate = (val) => {
    //    updatePayment({ ...payment, paymentDate: val });
    //}
    //const updateSelectedPaymentMode = (val) => {
    //    updatePayment({ ...payment, paymentMode: val == null ? 0 : val.value, paymentModeText: val == null ? '' : val.label });
    //}  

    //const onPaymentAdd = () => {
    //    let paymentInitialValue = { paymentId: 0, dummyId: 0, paymentDate: null, paymentMode: null, paymentModeText: '', paymentReference: '', amountPaid: '', paymentBy: '' }
    //    if (validatePayments()) {

    //            let paymentsClone = [...bulkbookingDetails.payments]

    //        let payClone = { ...payment }
    //        let max = 0;
    //        if (paymentsClone.length > 0) {
    //            const ids = paymentsClone.map(object => {
    //                return object.dummyId;
    //            });
    //            max = Math.max(...ids);
    //        }

    //        payClone.dummyId = max + 1;
    //        payClone.paymentDate = moment(payClone.paymentDate).format(moment.HTML5_FMT.DATE)
    //        paymentsClone.push(payClone);
    //        addbulkbookingDetails({ ...bulkbookingDetails, payments: paymentsClone });
    //        updatePayment({ ...payment, ...paymentInitialValue })

    //        toast('Payment has been added')
    //    }
    //}
    //const onPaymentRemove = (id) => {
    //    let r_paymentsClone = [...bulkbookingDetails.payments]
    //    for (let i = 0; i < r_paymentsClone.length; i++) {
    //        if (r_paymentsClone[i].dummyId === id)
    //            r_paymentsClone.splice(i, 1);
    //    }
    //    addbulkbookingDetails({ ...bulkbookingDetails, payments: r_paymentsClone });
    //    toast('Payment has been removed')
    //}

    const handleSubmit = () => {

        let isValid = validateForm();
        if (isValid) {
            setInProgress(true);
            //if (bulkbookingDetails.reservationStatus == '')
            savebulkbooking();

        }
    }
    const handleCancel = () => {
        //Calls modal for Feedback entry
        //<FeedbackBulkEnquiry bulkid={bulkbookingDetails.bulkbookingId} buttonclick={BulkEnquiryStatus.Cancelled } />
        openCancelModal();
        //setRedirectBooking(true);
        
    }
    const handleComplete = () => {
        openCompleteModal();
        //setRedirectBooking(true);
    }
    const handleConfirm = async() => {
        //gET THE BOOKING id AND MAP IT WITH payment table. 
        setMessage('UPDATE IN PROGRESS');
        openMessageModal();
          
        let result = await bookingService.UpdateEnquiryStatus(parseInt(bId), BulkEnquiryStatus.Confirmed, '', '', logged.UserId);
        if (!result.redirect) {
  
            let result2 = await bookingService.UpdateBookingIDBulkEnquiry(bulkbookingDetails.bulkEnquiryId, result.data.data);
            if (!result2.redirect) {
                toast.info('Bulk Enuiry Confirmed and updated the booking details too')
                setRedirectBooking(true);
            }else
            {
                toast.error('Bulk Enquiry failed in updating the bookingId');
            }
            closeMessageModal();
        }
        else {
            closeMessageModal();
            toast('Error occured in confirming of the bulk enquiry', 'error');
        }

    }
    const handleUpdate = async () => {
        let isValid = validateForm();
        if (isValid) {
            setUpdate(true);
            setMessage('UPDATE IN PROGRESS');
            openMessageModal();


            let result = await bookingService.UpdateBulkEnquiry(bulkbookingDetails, logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();
                    toast('Bulk Enquiry is updated', alertOptions)
                    // setRedirectBooking(true);
                }
                else {
                    closeMessageModal();
                    toast.error('Bulk Enquiry updation failed')
                }
            }
            else {
                closeMessageModal();
                toast('Error occured in updation of the bulk enquiry', 'error');
            }
        }
    }

    const validateForm = () => {
        if (bulkbookingDetails.firstName == '') {
            toast.error('Please enter the name of the customer', alertOptions)
            return false;
        }
        else if (bulkbookingDetails.firstName.match("^[a-zA-Z ]*$") == null) {
            toast.error('Only alphabets are allowed in the name', alertOptions)
            return false;
        }
        else if (bulkbookingDetails.mobileNo == null || bulkbookingDetails.mobileNo == '') {
            toast.error('Please enter the mobile no of the customer', alertOptions)
            return false;
        }
        //else if(bulkbookingDetails.companyName == '')
        //{
        //    toast.error('Please enter the Company Name of the customer', alertOptions)
        //    return false;
        //}
        else if (bulkbookingDetails.reserverdDate == null) {
            toast.error('Please choose a reservation date', alertOptions)
            return false;
        }
        else if (bulkbookingDetails.reservedTime == null) {
            toast.error('Please choose a reservation time', alertOptions)
            return false;
        }
        else if (bulkbookingDetails.noOfPax == 0) {
            toast.error('Please provide number of pax', alertOptions)
            return false;
        }
        else if (bulkbookingDetails.restaurant == 0) {
            toast.error('Please choose a restaurant', alertOptions)
            return false;
        }
        else if (bulkbookingDetails.ocassion == 0) {
            toast.error('Please choose an ocassion', alertOptions)
            return false;
        }
        else if (bulkbookingDetails.source == 0) {
            toast.error('Please choose a reservation source', alertOptions)
            return false;
        }
        else if (props.mode == 'u' && bId!='0' && bulkbookingDetails.mealTypeId == 0) {
            toast.error('Please choose meal Type', alertOptions)
            return false;
        }
        else if (props.mode == 'u' && bId!='0' && bulkbookingDetails.foodCostPerPax == '') {
            toast.error('Please enter cost per pax', alertOptions)
            return false;
        }

        return true;
    }

    //const validatePayments = () => {
    //    if (payment.paymentDate == null) {
    //        toast.error('Please provide the payment date', alertOptions)
    //        return false;
    //    }
    //    else if (payment.paymentMode == null) {
    //        toast.error('Please select a payment mode', alertOptions)
    //        return false;
    //    }
    //    else if (payment.paymentReference == '') {
    //        toast.error('Please provide a payment refrernce id', alertOptions)
    //        return false;
    //    }
    //    else if (payment.amountPaid == '' || payment.amountPaid == 0) {
    //        toast.error('Please provide the amount paid', alertOptions)
    //        return false;
    //    }
    //    else if (payment.paymentBy == '') {
    //        toast.error('Please provide the payment by details', alertOptions)
    //        return false;
    //    }

    //    return true;
    //}

    const savebulkbooking = async () => {
        setMessage('BULK BOOKING IN PROGRESS');
        openMessageModal();
        let result = await bookingService.SaveBulkEnquiryDetails(bulkbookingDetails, BulkEnquiryStatus.UnderDiscussion); 
        if (!result.redirect) {
            if (result.data.status) {

                toast('Bulk Booking details saved successfully');
                //setBookingStatus(true);
                //setBookingId(result.data.data);
                setInProgress(true);
                closeMessageModal();
                setRedirectBooking(true);
            }
            else {

                if (result.data.data == -1) {
                    setConfirmMessage(result.data.message);
                    openConfirmModal();
                }
                else {
                    toast.error(result.data.message);
                }
                closeMessageModal();
            }
        }
        else {
            toast.error("Error Occured while saving Bulk booking details");
            closeMessageModal();
        }
    }
    const SaveConfirmBulkEnquiryDetails = async () => {
        setMessage('BULK BOOKING IN PROGRESS');
        openMessageModal();
        let result = await bookingService.SaveConfirmBulkEnquiryDetails(bulkbookingDetails, BulkEnquiryStatus.UnderDiscussion);
        if (!result.redirect) {
            if (result.data.status) {
                toast('Bulk Booking details saved successfully');
                setInProgress(true);
                closeMessageModal();
                setRedirectBooking(true);
            }
            else {
                if (result.data.data == -1) {
                    setConfirmMessage(result.data.message);
                    openConfirmModal();
                }
                else {
                    toast.error(result.data.message);
                }
                closeMessageModal();

            }
        }
        else {
            toast.error("Error Occured while saving Bulk booking details");
            closeMessageModal();

        }
    }
    
    return (
        redirectBooking ? <Navigate to='/bulkbooking' /> :
            <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>
        <div>
            
            <Row style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                <Col sm='10' style={{ color: 'darkblue', fontWeight: 'bold' }}> BULK RESERVATION FORM </Col>
                <Col sm='2' style={{ textAlign: 'right' }}><Button style={{ backgroundColor: 'white', border: 0 }} onClick={() => setRedirectBooking(true)}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>

            </Row>
            <Row style={{ paddingTop: '15px' }}>
                <Col sm="2" >
                    <label className="required-fields">
                        First Name{` : `}
                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                        pattern="[A-Za-z]"
                        className="bookingInput"
                                value={bulkbookingDetails.firstName}
                                disabled={props.mode == 'u' && bId!='0' ? true : false}
                        onChange={e => addbulkbookingDetails({ ...bulkbookingDetails, firstName: e.target.value  })}
                    />
                </Col>
            {/*</Row>*/}
            {/*<Row style={{ paddingTop: '25px' }}>*/}
                <Col sm="2" >
                    <label>
                        LastName {` : `}

                    </label>
                </Col>
                <Col sm="4">

                    <input
                        type="text"
                        className="bookingInput"
                        value={bulkbookingDetails.lastName}
                        disabled={props.mode == 'u' && bId != '0' ? true : false}
                        onChange={e => addbulkbookingDetails({ ...bulkbookingDetails, lastName: e.target.value } )}
                    />
                </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
                <Col sm="2" >
                    <label className="required-fields">
                        Mobile No {` : `}

                    </label>
                </Col>
                <Col sm="4">
                    <input
                        readOnly
                        type="text"
                        value={'+91'}
                        className="bookingInput"
                        style={{ width: '35px', backgroundColor: '#9e9e9e30' }}
                    />

                    <input
                        maxLength="10"
                        type="text"
                        className="bookingInput"
                        value={bulkbookingDetails.mobileNo}
                        disabled={props.mode == 'u' && bId != '0' ? true : false}
                        onChange={onMobileNoChange}
                        style={{ width: 'auto' }}
                    />
                </Col>
            {/*</Row>*/}
            {/*<Row style={{ paddingTop: '25px' }}>*/}
                <Col sm="2" >
                    <label>
                        Company Name{` : `}
                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                        pattern="[A-Za-z]"
                        className="bookingInput"
                        value={bulkbookingDetails.companyName}
                                disabled={false}
                        onChange={e => addbulkbookingDetails({ ...bulkbookingDetails, companyName: e.target.value } )}
                    />
                </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>

                <Col sm="2" >
                    <label className="required-fields">
                        Reservation Date {` : `}

                    </label>
                </Col>
                <Col sm="4">
                    {/*<DatePicker selected={props.mode == 'u' ? new Date(bulkbookingDetails.reservedDate) : bulkbookingDetails.reservedDate} minDate={moment().toDate()} onChange={(date) => setReservationDate(date)} />*/}

                            <DatePicker selected={props.mode == 'u' && bId!='0' ? new Date(bulkbookingDetails.reserverdDate) : bulkbookingDetails.reserverdDate} minDate={moment().toDate()} onChange={(date) => setReservationDate(date)} />
                </Col>
            {/*</Row>*/}
            {/*<Row style={{ paddingTop: '25px' }}>*/}
                <Col sm="2" >
                    <label className="required-fields">
                        Reservation Time {` : `}
                    </label>
                </Col>
                <Col sm="4">
                    <TimeDropDown selectedTimeId={updateSelectedTime} time={bulkbookingDetails.reservedTime} />
                </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
                <Col sm="2" >
                    <label className="required-fields">
                        No of pax{` : `}
                    </label>
                </Col>
                <Col sm="4">
                    <input
                        type="text"
                        className="bookingInput"
                        value={bulkbookingDetails.noOfPax}
                        onChange={updateNoOfAdults}
                    />
                </Col>
            {/*</Row>*/}
            {/*<Row style={{ paddingTop: '25px' }}>*/}
                <Col sm="2" >
                    <label className="required-fields">
                        Restaurant Name{` : `}
                    </label>
                </Col>
                <Col sm="4">
                    {/*<label>*/}
                    {/*    {props.restaurantName}*/}
                    {/*</label>*/}
                    {/*<Dropdown disabled={true} value={props.restaurantName } />*/}
                    < RestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={bulkbookingDetails.restaurantId } />
                </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>

                <Col sm="2" >
                    <label className="required-fields">
                        Ocassion{` : `}
                    </label>
                </Col>
                <Col sm="4">
                    <OcassionDropDown selectedOcassionId={updateSelectedOcassion} ocassion={bulkbookingDetails.ocassionId}  />
                </Col>
            {/*</Row>*/}
            {/*<Row style={{ paddingTop: '25px' }}>*/}
                <Col sm="2">
                    <label className="required-fields">
                        Source{` : `}
                    </label>
                </Col>
                <Col sm="4">
                    <BookingSourceDropDown selectedBookingSourceId={updateSelectedSource} bookingSource={bulkbookingDetails.sourceId} />
                </Col>
                    </Row>
                    {props.mode == 'u' && bId!='0' ? 
                <>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col sm="2">
                            <label className="required-fields">
                                Meal Type{`:`} 
                            </label>
                        </Col>
                        <Col sm="4">
                            <MealTypeDropDown selectedMealTypeId={updateSelectedMealType} mealtype={bulkbookingDetails.mealTypeId} />
                        </Col>
                        <Col sm="2" >
                            <label className="required-fields">
                                Cost per Pax{` : `}
                            </label>
                        </Col>
                        <Col sm="4">
                            <input
                                type="text"
                                className="bookingInput"
                                value={bulkbookingDetails.foodCostPerPax}
                                onChange={onCostChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '15px' }}>
                        <Col sm="2">
                            <label>
                                Total Amount{`:`}
                            </label>
                        </Col>
                        <Col sm="4">
                            <input
                                type="text"
                                className="bookingInput"
                                value={bulkbookingDetails.foodTotalAmount}
                                onFocus={setTotalAmount }
                            />
                        </Col>
                        <Col sm="2">
                            <label> 
                                Special Instruction{`:`}
                            </label>  
                        </Col>
                                <Col sm="4">
                                    <textarea
                                        type="text"
                                        value={bulkbookingDetails.specialInstruction}
                                        onChange={onSpecialInstructionChange}
                                        rows={3}
                                        cols={55}
                            />
                        </Col>
                    </Row>
                    
                    <Row style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                        <Col sm="12" style={{ textAlign: 'center' }}>
                            <Button style={{ marginRight: '5px' }} color="warning" onClick={() => handleUpdate()} disabled={bulkbookingDetails.enquiryStatus == BulkEnquiryStatus.UnderDiscussion || bulkbookingDetails.enquiryStatus == BulkEnquiryStatus.Confirmed ? false:true } >Update{""}</Button>
                        
                        {update == true || bulkbookingDetails.mealTypeId != '' || bulkbookingDetails.mealTypeId != 0 ?
                                        <span>
                                            <Button style={{ marginRight: '5px' }} color="danger" onClick={() => handleCancel(parseInt(bId))} disabled={(bulkbookingDetails.enquiryStatus == BulkEnquiryStatus.UnderDiscussion || bulkbookingDetails.enquiryStatus == BulkEnquiryStatus.Confirmed ) ? false : true}>Cancel{""}</Button>

                                            <Button style={{ marginRight: '5px' }} color="primary" onClick={() => handleConfirm()} disabled={bulkbookingDetails.enquiryStatus == BulkEnquiryStatus.UnderDiscussion == true ? false : true}>Confirm{""}</Button>

                                            {/*<Button style={{ marginRight: '5px' }} color="success" onClick={() => handleComplete(parseInt(bId))} disabled={bulkbookingDetails.enquiryStatus == BulkEnquiryStatus.Confirmed || bulkbookingDetails.enquiryStatus ==BulkEnquiryStatus.Cancelled ? false : true}>Complete{""}</Button>*/}
                        </span> : null}
                        </Col>
                    </Row>
                </>
            :
                <Row style={{ paddingTop: '15px' }}>

                    <Col sm="12" style={{ textAlign: 'center' }}>
                        <Button style={{ marginRight: '5px' }} color="success" onClick={() => handleSubmit()} >SUBMIT{""}</Button>


                    </Col>
                </Row>}

        </div>
  
        <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
            conetentLable="Message" ariaHideApp={false}
        >
            <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
            </Row>

            <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
        </Modal>
        <Modal isOpen={cancelModalIsOpen} style={cancelModalStyles}
            conetentLable="Cancel" ariaHideApp={false}
        >
            <Row style={{ background: 'linear-gradient(120deg,#3F51B5, #AB47BC)' }}>
                <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Please Enter your feedback</Col>
                <Col sm="1" style={{ textAlign: "right" }}>
                    <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                        <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeCancelModal()}>
                            <AiFillCloseCircle />

                        </Button>
                    </IconContext.Provider>

                </Col>

            </Row>
            <Row><Col sm="12"  ><FeedbackBulkEnquiry buttonclick={BulkEnquiryStatus.Cancelled} bulkid={parseInt(bId)} user={ logged.UserId} modalClose={closeCancelModal} /></Col></Row>

        </Modal>

        <Modal isOpen={completeModalIsOpen} style={cancelModalStyles}
            conetentLable="Cancel" ariaHideApp={false}
        >
            <Row style={{ background: 'linear-gradient(120deg,#3F51B5, #AB47BC)' }}>
                <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Please Enter your feedback</Col>
                <Col sm="1" style={{ textAlign: "right" }}>
                    <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                        <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeCompleteModal()}>
                            <AiFillCloseCircle />

                        </Button>
                    </IconContext.Provider>

                </Col>

            </Row>
            <Row><Col sm="12"  ><FeedbackBulkEnquiry buttonclick={BulkEnquiryStatus.Completed} bulkid={parseInt(bId)} user={logged.UserId} modalClose={closeCompleteModal} /></Col></Row>

        </Modal>
        <Modal isOpen={confirmModalIsOpen} style={confirmModalStyles}
            conetentLable="CheckOut" ariaHideApp={false}
        >
            <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Confirm Bulk Enquiry</Col>
                <Col sm="1" style={{ textAlign: "right" }}>
                    <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                        <Button color='link' title='close' style={{ padding: 0 }} onClick={closeCheckoutModal}>
                            <AiFillCloseCircle />
                        </Button>
                    </IconContext.Provider>
                </Col>
            </Row>
            <Row style={{ paddingTop: '25px' }}><Col sm='12' style={{ textAlign: 'center', fontWeight: 500 }} >
                {confirmMessage}
            </Col></Row>
            <Row><Col sm='12' style={{ textAlign: 'right' }}>
                <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={() => { closeConfirmModal(); SaveConfirmBulkEnquiryDetails(); }} >Confirm{""}</Button>
                <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={closeConfirmModal} >Cancel{""}</Button>
            </Col></Row>
        </Modal>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </Container>

)
}