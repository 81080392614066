
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';

import "react-datepicker/dist/react-datepicker.css";



export function DayCloseReport(props) {

    const reportService = new ReportService();


    const [reservationDate, setReservationDate] = useState(null);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        setShowReport(false);
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);
    const gridStyle = useMemo(() => ({ height: '250px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [dayCloseData, updateDayCloseData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [showReport,setShowReport]=useState(false)
    const [columnDefs, setColumnDefs] = useState([

        {
            field: 'bookingSource', headerName: '', width: 130, cellStyle: (p) => {
                if (p.node.data.bookingSource == 'Total') return { color: 'white', backgroundColor: "green", fontWeight: 600 }

                

            } 
        },
        {
            field: 'total', headerName: 'TOTAL', width: 115,
 cellStyle: (p) => {
            if (p.node.data.bookingSource == 'Total') return { color: 'white', backgroundColor: "green", fontWeight: 600 }

            else
                return { fontWeight: 600 }

        }        },
        //{
        //    field: 'confirmed', headerName: 'CONFIRMED', width: 110, cellStyle: (p) => {
        //        if (p.node.data.bookingSource == 'Total') return { color: 'white', backgroundColor: "green", fontWeight: 600 }

        //        else
        //        return { color: "#198754", fontWeight: 600 }

        //    }
        //},
        {
            field: 'cancelled', headerName: 'CANCELLED', width: 105, cellStyle: (p) => {
                if (p.node.data.bookingSource == 'Total') return { color: 'white', backgroundColor: "green", fontWeight: 600 }

                else
                return { color: "#dc3545", fontWeight: 600 }
            }
        },
        {
            field: 'checkedIn', headerName: 'CHECKED-IN', width: 104, cellStyle: (p) => {
                if (p.node.data.bookingSource == 'Total') return { color: 'white', backgroundColor: "green", fontWeight: 600 }

                else
                return { color: "#6c757d", fontWeight: 600 }
            }
        },
        //{
        //    field: 'waitlisted', headerName: 'WAITLISTED', width: 100, cellStyle: (p) => {

        //        if (p.node.data.bookingSource == 'Total') return { color:'white',backgroundColor: "green", fontWeight: 600 }

        //        else
        //        return { color: "rgb(225 169 4)", fontWeight: 600 }
        //    }
        //},
        {
            field: 'noShow', headerName: 'NO SHOW', width: 100, cellStyle: (p) => {

                if (p.node.data.bookingSource == 'Total') return { color: 'white', backgroundColor: "green", fontWeight: 600 }

                else
                    return { color: "rgb(225 169 4)", fontWeight: 600 }
            }
        },

    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    //const onGridReady = useCallback((params) => {
    //    getDayCloseData();
    //}, []);

    const getDayCloseData = async () => {
        if (isInputValid() ){ 
       // showLoading();
        let result = await reportService.GetDayCloseData(selectedRestaurant.value, reservationDate);
            if (!result.redirect) {
                let rptData = result.data.data
                let aggRow = {bookingSource:'Total',total:0,confirmed:0,cancelled:0,checkedIn:0,waitlisted:0,noShow:0}
                for (let i = 0; i < rptData.length; i++) {
                    aggRow['total'] = aggRow['total'] + rptData[i].total
                    aggRow['confirmed'] = aggRow['confirmed'] + rptData[i].confirmed;
                    aggRow['cancelled'] = aggRow['cancelled'] + rptData[i].cancelled;
                    aggRow['checkedIn'] = aggRow['checkedIn'] + rptData[i].checkedIn;
                    aggRow['waitlisted'] = aggRow['waitlisted'] + rptData[i].waitlisted;
                    aggRow['noShow'] = aggRow['noShow'] + rptData[i].noShow;
                }
                if (aggRow['confirmed'] > 0 || aggRow['waitlisted'] > 0) {
                    toast('There are bookings in confirmed or waitlisted status');
                }
                rptData.push(aggRow);
                updateDayCloseData(rptData);
                setShowReport(true);
           // hideLoading();
        }
        else {
            alert('Error occured in fetching report data');
            //hideLoading();
        }
         }
    }

    const onClose = () => {
        updateIsClose(true);
    }

    const onCopy = () => {
        html2canvas(document.querySelector("#dayclosereport")).then(function (canvas) {
            canvas.toBlob(function (blob) {
                navigator.clipboard
                    .write([
                        new window.ClipboardItem(
                            Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                            })
                        )
                    ])
                    .then(function () {
                        toast.success('Copied')
                    });
            });
        });


    }
    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "DayCloseReport" });
    }, []);

    const isInputValid = () => {
      
        if (selectedRestaurant == null) {
        toast.error('Please choose a restaurant')
        return false;
        }
        else if (reservationDate == null) {
            toast.error('Please choose a reservation date')
            return false;
        }

        return true;
}


    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%",width:'75%' }}>

            <Row style={{paddingBottom: '20px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> DAY CLOSE REPORT </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row>

              
                <Col sm="2" >
                    <label className="required-fields" style={{ paddingRight:'10px' }}>
                        Restaurant {` : `}

                    </label>

                </Col>
                <Col sm="4">
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />
                        </Col>
              

                <Col sm="2" style={{textAlign:'right'} }>
                    <label className="required-fields">
                        Reservation Date {` : `}

                    </label>
                </Col>

               
                <Col sm="3">
                    <DatePicker selected={reservationDate} onChange={(date) => { setReservationDate(date); setShowReport(false); }} />
                </Col>

                <Col sm="1" >
                    <Button  color="primary" size='sm' onClick={getDayCloseData}>Submit</Button>
                </Col>

            </Row>

            {showReport ? <Row style={{ paddingTop: '25px', }}>
                <Col sm="2"></Col>
                <Col sm="8" style={{ textAlign: 'right'}}>
                    <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>

                    {/*    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>*/}
                </Col>
                <Col sm="2" ></Col>
            </Row> : (null)}
          
            {showReport ? <Row style={{ paddingTop: '10px' }}>
                <Col sm="2"></Col>
                <Col sm="8">


                    <Row style={{ marginRight: 0, marginLeft: 0, textAlign: 'center' }} >
                        <Col sm="12" >
                            <div id="dayclosereport">

                                <Row noGutters={true}><Col sm="12" style={{ textAlign: 'center', borderStyle: 'solid', borderColor: 'black', color: 'white', backgroundColor: 'crimson', fontWeight: 700, }} >DAY CLOSE REPORT</Col></Row>
                                <Row noGutters={true}>
                                    <Col sm="6" style={{ textAlign: 'left', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', borderBottom: 'none', backgroundColor: 'lightskyblue' }} ><span style={{ fontWeight: 500, color: "black", paddingLeft: '10px' }}>{selectedRestaurant == null ? '' : selectedRestaurant.label.toUpperCase()}</span>{`     `} <span style={{ paddingLeft: '50px' }}>{reservationDate == null ? '' : ``}</span></Col>
                                    <Col sm="6" style={{ textAlign: 'right', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', borderLeft: 'none', borderBottom: 'none', backgroundColor: 'lightskyblue' }} ><span style={{ fontWeight: 500, color: "black", paddingRight: '10px' }}>{reservationDate == null ? '' : moment(reservationDate).format(moment.HTML5_FMT.DATE)}</span></Col>

                                </Row>

                                <Row noGutters={true} style={{ fontWeight: 600,height:'32px' }}>
                                    <Col sm="4" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '3px', paddingTop: '2px', paddingBottom: '2px',}}></Col>
                                    <Col sm="2" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none',  fontWeight: 700 }}>Total</Col>
                                    {/*<Col style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', width: '13%', flex: 'none', fontWeight: 700 }}>Confirm</Col>*/}
                                    <Col sm="2" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', fontWeight: 700, }}>Cancel</Col>
                                    <Col sm="2" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', fontWeight: 700, }}>CheckIn</Col>
                                    {/*<Col sm="2"  style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', fontWeight: 700 }}>Waiting</Col>*/}
                                    <Col sm="2" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', fontWeight: 700 }}>NoShow</Col>


                                </Row>

                                {dayCloseData.map((d, i) => (
                                    <Row noGutters={true} style={{height:'32px'}} key={i}>
                                        <Col sm="4" style={{ color: 'blue', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '2px', paddingRight: 0, fontWeight: 500, borderTop: 'none',textAlign:'left' }}>{d.bookingSource}</Col>
                                        <Col sm="2" style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.total}</Col>
                                        {/*<Col style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontSize: '15px', fontWeight: 600, borderLeft: 'none', borderTop: 'none', width: '13%', flex: 'none' }}>{d.confirmed}</Col>*/}
                                        <Col sm="2"style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none'}}>{d.cancelled}</Col>
                                        <Col sm="2" style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '3px', fontWeight: 600, borderLeft: 'none', borderTop: 'none',  }}>{d.checkedIn}</Col>
                                        {/*<Col sm="2" style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '3px', fontSize: '15px', fontWeight: 600, borderLeft: 'none', borderTop: 'none', }}>{d.waitlisted}</Col>*/}
                                        <Col sm="2" style={{ color: 'black', backgroundColor: d.bookingSource == 'Total' ? 'chartreuse' : 'ffffff', borderStyle: 'solid', borderColor: 'black', paddingLeft: '3px', paddingRight: '3px', fontWeight: 600, borderLeft: 'none', borderTop: 'none',}}>{d.noShow}</Col>


                                    </Row>
                                )
                                )}

                            </div>

                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col sm="12">*/}
                    {/*        <div style={gridStyle} className="ag-theme-balham">*/}
                    {/*            <AgGridReact rowHeight={22}x*/}
                    {/*                ref={gridRef}*/}
                    {/*                rowData={dayCloseData}*/}
                    {/*                defaultColDef={defaultColDef}*/}
                    {/*                columnDefs={columnDefs}*/}
                    {/*                overlayLoadingTemplate={*/}
                    {/*                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'*/}
                    {/*                }*/}
                    {/*                overlayNoRowsTemplate={*/}
                    {/*                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'*/}
                    {/*                }*/}

                    {/*                suppressCellFocus={true} >*/}

                    {/*            </AgGridReact>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}



                </Col>
                <Col sm="2"></Col>
            </Row> : (null)}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
        )
}
