import React, { useState, useRef, useCallback, useMemo,useEffect } from 'react';
import AdministrationService from "../../services/AdministrationService";
import { GroceryCategoryDropDown } from "../Controls/GroceryCategoryDropDown"
import { GroceryUnitTypeDropDown } from "../Controls/GroceryUnitTypeDropDown"
import { GrocerySupplierDropDown } from "../Controls/GrocerySupplierDropDown"
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import { IoRemoveCircle } from "react-icons/io5";
import { IconContext } from "react-icons";
import Modal from "react-modal";
import { AddGroceryUnitType } from './AddGroceryUnitType';
import { AddGroceryCategory } from './AddGroceryCategory';
import { AddGrocerySupplier } from './AddGrocerySupplier';
import { AiFillCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged"
import { ItemUnitCountRenderer } from './ItemUnitCountRenderer'
import { ItemPriceRenderer } from './ItemPriceRenderer'
import { RemoveUnitTypeRenderer } from './RemoveUnitTypeRenderer'
import GroceryItemSuppliers from './GroceryItemSuppliers'
import GroceryItemDepartments from './GroceryItemDepartments'
import GroceryItemPrices from './GroceryItemPrices'
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';




const AddGroceryItem = (props) => {

    useEffect(() => {
        if (props.mode == 'M' && props.selectedItemId != 0) {
            GetGroceryDetailsById();
        }
    },[]);


     
    const administrationService = new AdministrationService
    const [unitType, setUnitType] = useState({ unitTypeId:null,unitTypeName:'' });
    const [UnitMasterTrigger, updateUnitMasterTrigger] = useState(0);
    const [CategoryMasterTrigger, updateCategoryMasterTrigger] = useState(0);
    const [SupplierMasterTrigger, updateSupplierMasterTrigger] = useState(0);
    const [groceryItem, updateGroceryItem] = useState({ itemId:0, itemName: '', itemCategory: 0, itemUnits: [], itemSupplier: 0})
    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [selectedItem, updateSelectedItem] = useState(props.selectedItemId);

    const [columnDefs, setColumnDefs] = useState([
        { headerName: '', width: 50, cellRenderer: RemoveUnitTypeRenderer, floatingFilter: false },

        { field: 'unitTypeId', headerName: 'Units',  filter: "agTextColumnFilter",hide:true },
        { field: 'unitTypeName', headerName: 'Unit', filter: "agTextColumnFilter", width: 130 },
        { field: 'itemPriceUnit', headerName: 'Count', width: 100, cellRenderer: ItemUnitCountRenderer },
        { field: 'itemPrice', headerName: 'Price', width: 100, cellRenderer: ItemPriceRenderer },
        
       
        
    ]);
    const gridRef = useRef(null);
    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false
    })

    const onGridReady = useCallback(() => {
    

    }, []);

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

  
    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }


    const getGridData = () => {

        let gdata = []
        gridRef.current.api.forEachNode(node => gdata.push(node.data));
        return gdata;

    }


    const addrows = useCallback((gunits, addIndex) => {
        const newItems = [
            gunits
        ];
        gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: addIndex,
        });

    }, []);

    const onRemoveSelected = useCallback((selectedData) => {
        gridRef.current.api.applyTransaction({ remove: selectedData });

    }, []);

    const addGroceryUnitType = () => {
        let unitFound = false;
        let itemUnitTypes = getGridData();
        for (let i = 0; i < itemUnitTypes.length; i++) {
            if (itemUnitTypes[i].unitTypeId === unitType.unitTypeId) {
                unitFound = true
                break;
            }

        }
        if (!unitFound) {
            addrows({ unitTypeId: unitType.unitTypeId, unitTypeName: unitType.unitTypeName, itemPrice: '', itemPriceUnit: '' }, undefined)

        }
        else {
            toast.info('Unit type already mapped');
        }
        setUnitType({ ...unitType, ...{ unitTypeId: null, unitTypeName: '' } })
     
    }

    const removeUnitType = (data) => {
      
        onRemoveSelected([data])
      
    }

    //const removeGroceryUnitType = (id) => {
  
    //    let itemUnitsClone = [...groceryItem.itemUnits]
    //    for (let i = 0; i < itemUnitsClone.length; i++) {
    //        if (itemUnitsClone[i].unitTypeId === id)
    //            itemUnitsClone.splice(i, 1);
    //    }
    //    updateGroceryItem({ ...groceryItem, itemUnits: itemUnitsClone });

    //}

    const updateSelectedCategory = (val) => {

        updateGroceryItem({ ...groceryItem, itemCategory: val == null ? 0 : val.value });
    }
    const updateSelectedSupplier = (val) => {

        updateGroceryItem({ ...groceryItem, itemSupplier: val == null ? 0 : val.value });
    }

    const updateSelectedUnitType = (val) => {

        let selected = {}
        if (val == null)
            selected = { unitTypeId: null, unitTypeName: '' }
        else
            selected = { unitTypeId: val.value, unitTypeName: val.label }

     
        setUnitType({ ...unitType, ...selected });
    }

    const updateSelectedUnitPriceType = (val) => {

        updateGroceryItem({ ...groceryItem, itemPriceUnitType: val == null ? 0 : val.value });
    }

   
    const unitTypeModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: '#dcedc8'

        },
    };
    const [unitTypeModalIsOpen, setUnitTypeModalIsOpen] = useState(false);
    const closeUnitTypeModal = (status = 0) => {
        setUnitTypeModalIsOpen(false)
        updateUnitMasterTrigger(UnitMasterTrigger + 1);
        if (status == 1)
            toast.success('Unit Type has been successfully added')
        else if (status == 0)
            toast.error("Error occured while adding Grocery Unit Type");
        //else
        //    toast.info('Action has been cancelled');
    }
    const openUnitTypeModal = () => {
        setUnitTypeModalIsOpen(true)
    }

    const categoryModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: 'white'
        },
    };
    const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
    const closeCategoryModal = (status = 0) => {
        setCategoryModalIsOpen(false)
        updateCategoryMasterTrigger(CategoryMasterTrigger + 1);
        if (status == 1)
            toast.success('Grocery Category has been successfully added')
        else if (status == 0)
            toast.error("Error occured while adding Grocery Category");
        //else
        //    toast.info('Action has been cancelled');
    }
    const openCategoryModal = () => {
        setCategoryModalIsOpen(true)
    }


    const supplierMappingModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '85%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: 'white'
        },
    };
    const [supplierMappingModalIsOpen, setSupplierMappingModalIsOpen] = useState(false);
    const closeSupplierMappingModal = () => {
        setSupplierMappingModalIsOpen(false)
        
    }
    const openSupplierMappingModal = () => {
        setSupplierMappingModalIsOpen(true)
    }





    const supplierModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: 'rgb(243 217 142)'
        },
    };
    const [supplierModalIsOpen, setSupplierModalIsOpen] = useState(false);
    const closeSupplierModal = (status = 0) => {
        setSupplierModalIsOpen(false)
        updateSupplierMasterTrigger(SupplierMasterTrigger + 1);
        if (status == 1)
            toast.success('Supplier has been successfully added')
        else if (status == 0)
            toast.error("Error occured while adding Grocery Supplier");
        //else
        //    toast.info('Action has been cancelled');
    }
    const openSupplierModal = () => {
        setSupplierModalIsOpen(true)
    }

    const departmentMappingModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '85%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: 'white'
        },
    };
    const [departmentMappingModalIsOpen, setDepartmentMappingModalIsOpen] = useState(false);
    const closeDepartmentMappingModal = () => {
        setDepartmentMappingModalIsOpen(false)

    }
    const openDepartmentMappingModal = () => {
        setDepartmentMappingModalIsOpen(true)
    }


    const priceMappingModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '85%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: 'white'
        },
    };
    const [priceMappingModalIsOpen, setPriceMappingModalIsOpen] = useState(false);
    const closePriceMappingModal = () => {
        setPriceMappingModalIsOpen(false)

    }
    const openPriceMappingModal = () => {
        setPriceMappingModalIsOpen(true)
    }




    const validateForm = () => {
      //  let uData = getGridData();
        if (groceryItem.itemName == '') {
            toast.error('Please enter the item name')
            return false;
        }
        else if (groceryItem.itemCategory == null || groceryItem.itemCategory==0) {
            toast.error('Please choose an item category')
            return false;
        }
        //else if (uData.length == 0) {
        //    toast.error('Please add a unit type to the item')
        //    return false;
        //}
        //else if (groceryItem.itemPriceUnitType != 0 && groceryItem.itemPriceUnitType != null && groceryItem.itemPriceUnit=='' ) {
        //    toast.error('Please enter the unit for the price type ')
        //    return false;
        //}
        //else if (groceryItem.itemPriceUnitType != 0 && groceryItem.itemPriceUnitType != null && groceryItem.itemPrice == '') {
        //    toast.error('Please enter the unit price ')
        //    return false;
        //}
        return true;
    }
    const getRowId = useMemo(() => {
        return (params) => {
            return params.data.unitTypeId;
        };
    }, []);


    const addItem = async () => {

      
        let isValid = validateForm();
        if (isValid) {
            setMessage('SAVING IN PROGRESS');
            openMessageModal();
            //let itemUnitsClone = getGridData();
            //for (let i = 0; i < itemUnitsClone.length; i++) {
            //    itemUnitsClone[i].itemPrice = itemUnitsClone[i].itemPrice == '' ? 0 : parseFloat(itemUnitsClone[i].itemPrice)
            //    itemUnitsClone[i].itemPriceUnit = itemUnitsClone[i].itemPriceUnit == '' ? 0 : parseInt(itemUnitsClone[i].itemPriceUnit)

            //}
            let result = await administrationService.AddGroceryItem(groceryItem.itemName, groceryItem.itemCategory, [], 0, logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                   closeMessageModal();
                 
                   // updateGroceryItem({ ...groceryItem, ...{ itemId: 0, itemName: '', itemCategory: null, itemUnits: [], itemSupplier: null } })
                    updateSelectedItem(result.data.data)
                   // props.modalClose(1)
                }
                else {
                   closeMessageModal();
                    toast(result.data.message);
                }
            }
            else {
                closeMessageModal();
              
            }
        }
    }

    const updateItem = async () => {

     
        let isValid = validateForm();
        if (isValid) {
            setMessage('UPDATE IN PROGRESS');
            openMessageModal();
            //let itemUnitsClone = getGridData();
            //for (let i = 0; i < itemUnitsClone.length; i++) {
            //    itemUnitsClone[i].itemPrice = itemUnitsClone[i].itemPrice == '' ? 0 : parseFloat(itemUnitsClone[i].itemPrice)
            //    itemUnitsClone[i].itemPriceUnit = itemUnitsClone[i].itemPriceUnit == '' ? 0 : parseInt(itemUnitsClone[i].itemPriceUnit)

            //}
            let result = await administrationService.UpdateGroceryItem(groceryItem.itemId,groceryItem.itemName, groceryItem.itemCategory, [],0, logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();
                    toast("Grocery item has beeen successfully updated")
                    //updateGroceryItem({ ...groceryItem, ...{ itemId: 0, itemName: '', itemCategory: null, itemUnits: [], itemSupplier: null } })
                    props.modalClose(1)
                }
                else {
                    closeMessageModal();
                    toast(result.data.message);
                }
            }
            else {
                closeMessageModal();
                toast('Error occured while updating the grocery item', 'error');
            }
        }
    }

    const saveItem = () => {
        if (props.mode == 'N')
            addItem();
       else if (props.mode == 'M')
            updateItem();

    }

    const updatePriceUnitCount = (val,id) => {
        var rowNode = gridRef.current.api.getRowNode(id);
        rowNode.setDataValue('itemPriceUnit', val);
    }
    const updatePrice= (val, id) => {
        var rowNode = gridRef.current.api.getRowNode(id);
        rowNode.setDataValue('itemPrice', val);
    }
    const GetGroceryDetailsById = async () => {
        setMessage('LOADING IN PROGRESS');
        openMessageModal();
        let result = await administrationService.GetGroceryDetailsById(props.selectedItemId);
        if (!result.redirect) {

            updateGroceryItem({ ...groceryItem,... result.data.data });
            closeMessageModal();
        }
        else {
            closeMessageModal();
            toast.error('Error occured in fetching Grocery items');
       
        }
       
    }
    

    return (
        <Container>
        <form >
          
   
                <Row style={{}}>
                    <Col sm="3">
                        <label className="required-fields" style={{ color: '#2691d9', fontSize: '16px' }}>Item Name </label>
                    </Col>

                            <Col sm="7" style={{}}>

                                <div >
                                    <input
                                type="text"
                                value={groceryItem.itemName}
                                onChange={e => updateGroceryItem({ ...groceryItem, itemName: e.target.value })}
                                style={{ width: '100%', borderStyle: 'solid', borderWidth: 'thin', borderColor: 'lightgrey' }}
                                    />
                                    
                                </div>


                    </Col>
                           

                </Row>
                <Row style={{marginTop:'15px'}}>

                    <Col sm="3" style={{ paddingRight: 0 }} >
                        <label className="required-fields" style={{color: '#2691d9', fontSize: '16px' }}>
                            Item Category

                        </label>
                    </Col>
                    <Col sm="7">
                        <GroceryCategoryDropDown selectedCategoryId={updateSelectedCategory} categoryType={groceryItem.itemCategory} key={CategoryMasterTrigger} />

                    </Col>

                    <Col sm="1" style={{ paddingLeft: '1px' }}>
                        <Button onClick={openCategoryModal} size="sm" color="success" style={{ fontWeight:700 }}>+</Button>
                    </Col>
                </Row>
                {/*<Row style={{ marginTop: '15px' }}>*/}
                {/*            <Col sm="3" style={{ paddingRight: 0 }}>*/}
                {/*                <label className="required-fields" style={{ marginBottom: '5px', color: '#2691d9', fontSize: '16px' }}>*/}
                {/*                    Add Units */}

                {/*        </label>*/}
                {/*    </Col>*/}
                {/*    <Col sm="7">*/}

                {/*                <GroceryUnitTypeDropDown selectedUnitTypeId={updateSelectedUnitType} unitType={unitType.unitTypeId} key={UnitMasterTrigger} />*/}
                {/*            </Col>*/}
                {/*            <Col sm="1" style={{ paddingLeft: '1px',paddingRight:0 }}>*/}
                {/*                <Button onClick={openUnitTypeModal} size="sm" color="success" style={{  height: '40px', paddingTop: 0, width: '35px', fontWeight: 'bolder', fontSize: 'x-large' }}>+</Button>*/}
                {/*            </Col>*/}

                {/*            <Col sm="1" style={{paddingLeft:0}}>*/}
                {/*        <Button disabled={unitType.unitTypeId != null ? false : true} style={{ height: '40px', backgroundColor: '#E91E63' }} onClick={addGroceryUnitType}>Add</Button>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        <Row >*/}

                {/*        <Col sm="3" />*/}
                {/*    <Col sm="9" style={{ margin:0,padding:0,marginTop: '3px' }}>*/}
                    
                {/*                <div style={{ width: '100%', height: 180 }} className="ag-theme-alpine" >*/}
                {/*                    <AgGridReact rowHeight={32}*/}
                {/*                        ref={gridRef}*/}
                {/*                        rowData={groceryItem.itemUnits}*/}
                {/*                        defaultColDef={defaultColDef}*/}
                {/*                        columnDefs={columnDefs}*/}
                {/*                        context={{ updatePriceUnitCount, updatePrice, removeUnitType }}*/}
                {/*                        getRowId={getRowId}*/}
                {/*                        animateRows={true}*/}
                {/*                        onGridReady={onGridReady}*/}
                {/*                        overlayLoadingTemplate={*/}
                {/*                            '<span class="ag-overlay-loading-center">Please wait while Grocery Item Unit are loading</span>'*/}
                {/*                        }*/}
                {/*                        overlayNoRowsTemplate={*/}
                {/*                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add Grocery Item Unit</span>'*/}
                {/*                        }*/}

                {/*                        suppressCellFocus={true} >*/}

                {/*                    </AgGridReact>*/}
                {/*                </div>*/}

                {/*            </Col>*/}
                {/*        </Row>*/}
                 
                {/*        <Row style={{ paddingTop: '15px' }}>*/}
                {/*            <Col sm="3" style={{ paddingRight: 0 }} >*/}
                {/*                <label style={{ marginBottom: '5px', color: '#2691d9', fontSize: '16px' }}>*/}
                {/*                    Choose Supplier */}

                {/*        </label>*/}

                {/*    </Col>*/}
                {/*    <Col sm="7">*/}

                {/*                <GrocerySupplierDropDown selectedSupplierId={updateSelectedSupplier} supplier={groceryItem.itemSupplier} key={SupplierMasterTrigger} />*/}

                {/*            </Col>*/}

                {/*            <Col sm="1" style={{ paddingLeft: '1px' }}>*/}
                {/*                <Button onClick={openSupplierModal} size="sm" color="success" style={{ height: '40px', paddingTop: 0, width: '35px', fontWeight: 'bolder', fontSize: 'x-large', }}>+</Button>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
    


           
        
                <Row style={{marginTop:'15px'}}>

                    <Col sm="12" style={{ textAlign:'center' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={saveItem} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={() => props.modalClose(2)} >Close{""}</Button>
                    </Col>


                </Row>


                <Row style={{ marginTop: '15px' }}>

                    <Col sm="12" style={{ textAlign: 'center' }}>
                        <Button style={{ marginRight: '5px',fontWeight:500, color: 'black', background: selectedItem != 0 ? 'linear-gradient(120deg, rgb(247 194 243), rgb(233 49 12))'  :'#cccccc' }} disabled={selectedItem != 0 ? false : true} size='sm' onClick={() => openSupplierMappingModal()} >MAP SUPPLIERS{""}</Button>
                        <Button style={{ marginRight: '5px', fontWeight:500,color: 'black', background: selectedItem != 0 ? 'linear-gradient(120deg, rgb(140 207 236), rgb(12 123 233))' : '#cccccc' }} disabled={selectedItem != 0 ? false : true} size='sm' onClick={() => openDepartmentMappingModal()} >MAP DEPARTMENTS{""}</Button>
                        <Button style={{ marginRight: '5px', fontWeight:500,color: 'black', background: selectedItem != 0 ? 'linear-gradient(120deg, rgb(198 239 150), #4CAF50)' : '#cccccc' }} disabled={selectedItem != 0 ? false : true} size='sm' onClick={() => openPriceMappingModal()} >MAP UNIT & PRICE{""}</Button>


                    </Col>

             

                </Row>



            </form>

            {/*<ToastContainer*/}
            {/*    position="top-center"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="dark"*/}
            {/*/>*/}

            <Modal isOpen={unitTypeModalIsOpen} style={unitTypeModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#4CAF50, #CDDC39)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Add Unit Types</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={()=>closeUnitTypeModal(2)}>

                                <AiFillCloseCircle />
                            </Button>
                        </IconContext.Provider>
                    </Col>

                </Row>
                <Row><Col sm="12"><AddGroceryUnitType modalClose={closeUnitTypeModal} /></Col></Row>
                
            </Modal>

            <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                conetentLable="Message" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                    <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                </Row>

                <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
            </Modal>


            <Modal isOpen={categoryModalIsOpen} style={categoryModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#3F51B5, #AB47BC)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Add Grocery Category</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeCategoryModal(2)}>
                                <AiFillCloseCircle />

                            </Button>
                        </IconContext.Provider>

                    </Col>

                </Row>
                <Row><Col sm="12"  ><AddGroceryCategory modalClose={closeCategoryModal} /></Col></Row>

            </Modal>

            <Modal isOpen={supplierModalIsOpen} style={supplierModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg,#FFC107, #FF9800)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Add Grocery Supplier </Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeSupplierModal(2)}>
                                <AiFillCloseCircle />

                            </Button>
                        </IconContext.Provider>

                    </Col>

                </Row>
                <Row><Col sm="12"  ><AddGrocerySupplier modalClose={closeSupplierModal} /></Col></Row>

            </Modal>


            <Modal isOpen={supplierMappingModalIsOpen} style={supplierMappingModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg, #4CAF50, rgb(139, 195, 74))' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>MAP ITEM SUPPLIERS</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeSupplierMappingModal()}>
                                <AiFillCloseCircle />

                            </Button>
                        </IconContext.Provider>

                    </Col>

                </Row>
                <Row><Col sm="12"  ><GroceryItemSuppliers modalClose={closeSupplierMappingModal} itemId={selectedItem} /></Col></Row>

            </Modal>



            <Modal isOpen={departmentMappingModalIsOpen} style={departmentMappingModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg, #2196F3, #009688)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>MAP ITEM DEPARTMENTS</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeDepartmentMappingModal()}>
                                <AiFillCloseCircle />

                            </Button>
                        </IconContext.Provider>

                    </Col>

                </Row>
                <Row><Col sm="12"  ><GroceryItemDepartments modalClose={closeDepartmentMappingModal} itemId={selectedItem} /></Col></Row>

            </Modal>

            <Modal isOpen={priceMappingModalIsOpen} style={priceMappingModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg, #4CAF50, rgb(255, 235, 59))' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>MAP ITEM UNITS & PRICE</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closePriceMappingModal()}>
                                <AiFillCloseCircle />

                            </Button>
                        </IconContext.Provider>

                    </Col>

                </Row>
                <Row><Col sm="12"  ><GroceryItemPrices modalClose={closePriceMappingModal} itemId={selectedItem} /></Col></Row>

            </Modal>

        </Container>
    );
};

export default AddGroceryItem;