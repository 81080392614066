import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import IndentService from "../../services/IndentService";
import { RestaurantSupplierDropDown } from "../Controls/RestaurantSupplierDropDown"
import { Row, Col, Button, Container } from 'reactstrap'
import Modal from "react-modal";
import { logged } from "../../lib/logged"

import 'ag-grid-community/styles/ag-theme-alpine.css';

import { UserRestaurantDropDown } from '../Controls/UserRestaurantDropDown';
import { ToastContainer, toast } from 'react-toastify';
import { GroceryCategoryDropDown } from "../Controls/GroceryCategoryDropDown"

const BulkItemSupplierMapping = (props) => {

    useEffect(() => {

    }, []);




    const indentService = new IndentService()
    const [selectedCategory, updateSelectedCategory] = useState(null);
    const [selectedRestaurant, updateSelectedRestaurant] = useState(null);
    const [selectedSupplier, updateSelectedSupplier] = useState(null);
    const [defaultSupplier, setDefaultSupplier] = useState(false);

    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    

    const onRestaurantSelectionChange = (val) => {
        updateSelectedRestaurant(val);
    }

    const onSupplierSelectionChange = (val) => {

        updateSelectedSupplier(val);
    }

    const onCategorySelectionChange = (val) => {
        updateSelectedCategory(val)
    }

    



    const [close, setClose] = useState(false);


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false
    })

   
    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }



    const onClose = () => {
        setClose(true);
    }





    const validateForm = () => {

        if (selectedRestaurant == null) {
            toast.error('Please select a restaurant')
            return false;
        }
        else if (selectedSupplier == null) {
            toast.error('Please select a supplier')
            return false;
        }
        else if (selectedCategory == null) {
            toast.error('Please select a category')
            return false;
        }

        return true;
    }


 


    const addUpdateItems = async () => {

        let isValid = validateForm();
        if (isValid) {


            setMessage('SAVING IN PROGRESS');
            openMessageModal();


            let result = await indentService.BulkUpdateItemSupplier(selectedRestaurant.value, selectedCategory.value, selectedSupplier.value, defaultSupplier, logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    closeMessageModal();
                    toast("Supplier details has been mapped successfully")

                }
                else {
                    closeMessageModal();
                    toast("Failure in mapping supplier ")
                }
            }
            else {
                closeMessageModal();
                toast('Error occured while bulk mapping supplier ', 'error');
            }
        }

    }












    return (
        <Container style={{}}>
            <form >

                <Row><Col sm="12" style={{ color: 'darkblue', fontWeight:500 }}> MAP ITEM SUPPLIERS BY CATEGORY</Col></Row>


                <Row style={{ paddingTop: '15px' }}>

                    <Col sm="6" style={{ textAlign: 'left' }}>
                        <label>
                            Choose Restaurant{` : `}

                        </label>
                        < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant} />

                    </Col>
                    <Col sm="6">
                        <label>
                            Choose Supplier{` : `}

                        </label>
                        <RestaurantSupplierDropDown key={selectedRestaurant == null ? selectedRestaurant : selectedRestaurant.value} restaurantId={selectedRestaurant == null ? selectedRestaurant : selectedRestaurant.value} selectedSupplierId={onSupplierSelectionChange} supplier={selectedSupplier} />

                    </Col>
                    
             
                </Row>

                <Row style={{marginTop:'15px'} }>
                    <Col sm="6">
                        <label> Choose Category {` : `}</label>
                        <GroceryCategoryDropDown selectedCategoryId={onCategorySelectionChange} categoryType={selectedCategory == null ? selectedCategory : selectedCategory.value} />


                    </Col>
                    <Col sm="6">
                        <label style={{marginTop:'25px',marginRight:'10px'} }>Is Default Supplier : </label>
                        <input type="checkbox" className="checkbox" checked={defaultSupplier} onChange={e => {
                            setDefaultSupplier(!defaultSupplier);
                        }} />

                    </Col>
                </Row>

                <Row>
                    <Col sm="12" style={{ textAlign:"center" }} >

                        <Button style={{ marginRight: '5px', marginTop: '23px' }} color="success" size='sm' onClick={addUpdateItems} >SUBMIT{``}</Button>

                        
                    </Col>

                </Row>






                               <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
                </Modal>

            </form>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />


        </Container>
    );
};

export default BulkItemSupplierMapping;