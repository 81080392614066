import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import { Roles } from '../../lib/Roles';
import { Link } from 'react-router-dom'
import IndentService from "../../services/IndentService"
import { AgGridReact } from 'ag-grid-react';
import { logged } from '../../lib/logged';
import { IndentStatus } from '../../lib/IndentStatus';
import { IndentStatusId } from '../../lib/IndentStatusId';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import 'react-toastify/dist/ReactToastify.css';
import { AddIndentOrderList } from './AddIndentOrderList';
import { IconContext } from "react-icons";
import { AiFillCloseCircle } from "react-icons/ai";
import moment from "moment";



export function PastIndentOrderList(props) {

    const indentService = new IndentService();

    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridRef = useRef(null);


    const [IndentOrder, setIndentOrders] = useState([]);

    const indentOrderModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '75%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px',
            background: 'rgb(142 225 191)'

        },
    };
    const [indentOrderModalIsOpen, setIndentOrderModalIsOpen] = useState(false);
    const closeIndentOrderModal = (status = 0) => {
        setIndentOrderModalIsOpen(false)
        if (status == 1) {
            toast.success('Indent been successfully created')
            getPastIndentOrders();
        }

        else if (status == 0)
            toast.error("Error occured while creating indent");

    }
    const openIndentOrderModal = () => {
        setIndentOrderModalIsOpen(true)
    }


    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);


    //useEffect(() => {
    //    sizeToFit();
    //}, [IndentOrder])



    //Displays only current date indent which are not approved.
    //IndentID, which Restaurant (take the restuarant ID and loop it in tblRestaurants-RestaurantName),
    //Deparment ID - Loop tblIndentDeparment, Indent Type (tblIndentType-IndentTypeName),
    //Status (Draft, Pending with L1/L2 approval, approved, ReferBank), PendingWith (display name?? tbl??)
    //ReferBack button (enable for approver L1/L2 and Store Manager Role)
    //inactivate - remove the current dated indent
    const [columnDefs, setColumnDefs] = useState([
        {//link to be provided only if the status is PENDING for Approval. the new screen will have approve button enabled.
            field: 'indentOrderId', headerName: 'Id', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 100, cellRenderer: (d) => {
                if (d.data.status == IndentStatus.Draft)
                    return <Link data-testid="link-indentorderid" to={`/indent/addIndentOrder/${d.data.indentOrderId}/${IndentStatusId.Draft}/2`}>{d.data.indentOrderId}</Link>
                else if (d.data.status == IndentStatus.L1Approval)
                    return <Link data-testid="link-indentorderid" to={`/indent/addIndentOrder/${d.data.indentOrderId}/${IndentStatusId.L1Approval}/2`}>{d.data.indentOrderId}</Link>
                else if (d.data.status == IndentStatus.L2Approval)
                    return <Link data-testid="link-indentorderid" to={`/indent/addIndentOrder/${d.data.indentOrderId}/${IndentStatusId.L2Approval}/2`}>{d.data.indentOrderId}</Link>
                else if (d.data.status == IndentStatus.Approved)
                    return <Link data-testid="link-indentorderid" to={`/indent/addIndentOrder/${d.data.indentOrderId}/${IndentStatusId.Approved}/2`}>{d.data.indentOrderId}</Link>


            }
        },
        {
            field: 'indentTypeName', headerName: 'Type', filter: "agTextColumnFilter", floatingFilter: true, width: 250
        },
        {
            field: 'restaurantName', headerName: 'Restaurant', filter: "agTextColumnFilter", floatingFilter: true, width: 175
        },
        {
            field: 'departmentName', headerName: 'Department', filter: "agTextColumnFilter", floatingFilter: true, width: 190
        },

        {
            field: 'createdBy', headerName: 'Created By', filter: "agTextColumnFilter", floatingFilter: true, width: 250
        },
        {
            field: 'createDate', headerName: 'Created Date', filter: "agTextColumnFilter", floatingFilter: true, width: 175, floatingFilter: true, cellRenderer: (d) => {

                return moment(d.data.createDate).format("DD/MM/YY h:mm:ss A");
            }
        },
        {
            field: 'status', headerName: 'Status', filter: "agTextColumnFilter", floatingFilter: true, width: 150
        },
        {
            field: 'total', headerName: 'Total', filter: "agTextColumnFilter", floatingFilter: false, width: 140,
          
            valueFormatter: (params) => {
           
                return params.data.total!=null?params.data.total.toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }).substring(1):'0.00'
            },
            cellStyle: (p) => {
                 return { textAlign: 'right' }
            }
        }
        //{ //L1 Approval - Executive Chef; L2 Approval - Store Manager
        //    headerName: 'Pending With', cellRenderer: (d) => {
        //        return d.data.status === IndentStatus.L1Approval ? 'Executive Chef' : d.data.status === IndentStatus.L2Approval ? 'Purchase Manager' : '';
        //    },
        //    filter: "agTextColumnFilter", floatingFilter: true, width: 175
        //},
        ////{//check if status is L1/L2/storeManager, then enable the button
        ////    headerName: 'Refer Back', width: 150, cellRenderer: (d) => { return <Button onClick={onReferBack(d.data.indentOrderId)} style={{ backgroundColor: '#865C38', padding: '2px', margin: 0, marginBottom: '6px', visibility: (d.data.status === IndentStatus.L1Approval || d.data.status === IndentStatus.L2Approval) ? (logged.Roles[0].roleId === Roles.ExecutiveChef || logged.Roles[0].roleId === Roles.StoreManager) ? 'visible' : 'hidden' : 'hidden' }} color="danger" size="sm" > Refer Back</Button> }, floatingFilter: false 
        ////},
        //{
        //    headerName: '', width: 150, cellRenderer: (d) => {
        //        return (logged.Roles[0].roleId === Roles.Administrator || logged.Roles[0].roleId === Roles.PurchaseManager || (logged.Roles[0].roleId === Roles.SousChef && (d.data.status != IndentStatus.Approved && d.data.status != IndentStatus.L1Approval && d.data.status != IndentStatus.L2Approval)) || (logged.Roles[0].roleId === Roles.ExecutiveChef && (d.data.status != IndentStatus.Approved && d.data.status != IndentStatus.L2Approval))) ? < Button onClick={() => {
        //            OnInactivate(d.data.indentOrderId);
        //        }}
        //            style={{ backgroundColor: '#ff1200', padding: '2px', margin: 0, marginBottom: '6px', visibility: 'visible' }}
        //            color="danger" size="sm" > Inactivate</Button> : null
        //    }, floatingFilter: false
        //}

    ]);

  //const OnInactivate = async (indentOrderID) =>{
  //      //Inactivate the record and not display in the grid.
  //      let result = await indentService.InactivateIndentOrder(indentOrderID);
  //      toast.info(result.data);
  //      if (!result.redirect) {
  //          toast.success('Indent Order has been inactivated successfully');
  //          getPastIndentOrders();
  //      }
  //      else {
  //          toast.error("Error occured while inactivating the Indent Order");

  //      }
  //  }
    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        sortable: true
    })

    const onGridReady = useCallback(() => {
        getPastIndentOrders();
    }, []);

    const getPastIndentOrders = async () => {
        showLoading();

        let result = await indentService.GetPastIndentOrders(logged.UserId, logged.Roles[0].roleId);

        if (!result.redirect) {

            setIndentOrders(result.data.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching Indent Order details');
            hideLoading();
        }
    }



    const showAlert = (message, type) => {

        if (type === 'error') {
            toast.error(message);
        }
        else if (type === 'success') {
            toast.success(message);
        }
    }



    return (


        <Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> PAST APPROVED INDENT ORDERS</Col>
                <Col sm="2" style={{ textAlign: 'right' }}>  </Col>
            </Row>
            <div style={containerStyle}>

                <div style={gridStyle} className="ag-theme-alpine" >
                    <AgGridReact rowHeight={30}
                        headerHeight={30}
                        floatingFiltersHeight={30}
                        ref={gridRef}
                        rowData={IndentOrder}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{  }}
                        onGridReady={onGridReady}
                        pagination={true}
                        paginationAutoPageSize={true}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while Indent Orders are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Indent Orders to show</span>'
                        }
                        enableCellTextSelection={true}
                        suppressCellFocus={true} >

                    </AgGridReact>
                </div>
            </div>


        

            <Modal isOpen={indentOrderModalIsOpen} style={indentOrderModalStyles}
                conetentLable="Cancel" ariaHideApp={false}
            >
                <Row style={{ background: 'linear-gradient(120deg, #26A69A, #AED581)' }}>
                    <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Add Indent Order</Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                        <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                            <Button color='link' title='close' style={{ padding: 0 }} onClick={() => closeIndentOrderModal(2)}>

                                <AiFillCloseCircle />
                            </Button>
                        </IconContext.Provider>
                    </Col>

                </Row>
                <Row><Col sm="12"><AddIndentOrderList modalClose={closeIndentOrderModal} /></Col></Row>

            </Modal>

        </Container>
    )

}