import React, { useState } from "react";


export function DefaultSupplierRenderer(props) {

    const [defaultSupplier, setDefaultSupplier] = useState(props.data.isDefault);

    return (

        
        <input type="checkbox" className="checkbox" checked={props.data.isDefault} onChange={e => {
                    setDefaultSupplier(!defaultSupplier);
                      props.context.updateIsDefault(!defaultSupplier, props.data);
                }} />
     
    
    )
}