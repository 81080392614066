

import React, { useState } from "react";

export function ItemPriceRenderer(props) {

    const [itemPrice, setItemPrice] = useState(props.data.price);
  //  const [isDisabled, setDisabled] = useState(props.data.isDisabled);

    return (
        <input
            type="text"
            value={props.data.price}
            style={{ height: '19px', width: '50px', textAlign:'right' }}
            disabled={props.data.isDisabled}
            onChange={e => {
                setItemPrice(e.target.value);
                props.context.updatePrice(e.target.value, props.data.itemPriceMappingId);
            }}
        />
    )
}