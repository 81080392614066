import { Row, Col, Button, Progress, Container } from 'reactstrap'
import { IoRemoveCircle } from "react-icons/io5";
import { IconContext } from "react-icons";
import React, { useState } from "react";


export function RemoveMappedPrice(props) {

    const onRemove = () => {
        props.context.removePriceMap(props.data);
    }

    return (

        <Button onClick={onRemove} color="danger" size="sm" style={{ backgroundColor: '#cddc3900', border: 0, textAlign: 'left' }}  >

            <IconContext.Provider value={{ size: '22px', color: 'red' }}> < IoRemoveCircle /></IconContext.Provider>

        </Button >





    )
}