import moment from "moment"
import { logged } from "../lib/logged"

export default class MyOperatorService {

    async GetTodaysMissedCalls() {
        try {

            const response = await fetch("api/myoperator/GetTodaysMissed", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CallLogId: '',
                    UserId: logged.UserId
                })
               
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async RefreshMissedCalls() {
        try {

            const response = await fetch("api/myoperator/Missed", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetTodaysData() {
        try {

            const response = await fetch("api/myoperator/GetTodaysData", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetYesterdaysData() {
        try {

            const response = await fetch("api/myoperator/GetYesterdaysData", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async PurgeRecord(callLogId,userId) {
        try {

            const response = await fetch("api/myoperator/PurgeRecord", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CallLogId: callLogId,
                    UserId:userId
                })

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetRefreshDetails() {
        try {

            const response = await fetch("api/myoperator/GetRefreshData", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
}