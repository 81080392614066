import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import BookingService from "../../services/BookingService"
import { logged } from "../../lib/logged"
import { ToastContainer, toast } from 'react-toastify';

export function BigTable(props) {
    const bookingService = new BookingService();
    const [paxData, updatePaxData] = useState({one:0,two:0,three:0,four:0,five:0,six:0,seven:0,eight:0,nine:0,ten:0,tenPlus:0});

    useEffect(() => {
        getPaxData();

    }, [])

    const getPaxData = async () => {
        
            let result = await bookingService.GetDashboardPax(logged.UserId);
            if (!result.redirect) {
                let rptData = result.data.data

                updatePaxData(rptData);
            }
            else {
                toast('Error occured in fetching report data');
            }
        
    }


    return (
        <div>
            <Row>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle:'solid',borderColor:'black',color:'red',fontWeight:800,fontSize:'14px'}}>1</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft:'none',color:'red',fontWeight:800,fontSize:'14px' }}>2</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 800, fontSize: '14px' }}>3</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 800, fontSize: '14px' }}>4</Col>
                <Col sm="1" style={{ width: '27px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 800, fontSize: '14px' }}>5</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', color: 'red', fontWeight: 800, fontSize: '14px' }}>6</Col>
            </Row>
            <Row>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', color: '#0d6efd', fontWeight: 500, fontSize: '14px', textAlign: 'center' }}>{paxData.one == 0 ? '-' : paxData.one}</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: '#0d6efd', fontWeight: 500, fontSize: '14px', textAlign: 'center' }}>{paxData.two == 0 ? '-' : paxData.two}</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: '#0d6efd', fontWeight: 500, fontSize: '14px', textAlign: 'center' }}>{paxData.three == 0 ? '-' : paxData.three}</Col>          
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: '#0d6efd', fontWeight: 500, fontSize: '14px', textAlign: 'center' }}>{paxData.four == 0 ? '-' : paxData.four}</Col>
                <Col sm="1" style={{ width: '27px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: '#0d6efd', fontWeight: 500, fontSize: '14px', textAlign: 'center' }}>{paxData.five == 0 ? '-' : paxData.five}</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: '#0d6efd', fontWeight: 500, fontSize: '14px', textAlign: 'center' }}>{paxData.six == 0 ? '-' : paxData.six}</Col>


            </Row>
            <Row>
                <Col sm="1" style={{ width:'25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', color: 'red', fontWeight: 800, fontSize: '14px' }}>7</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'red', fontWeight: 800, fontSize: '14px',textAlign:'center' }}>8</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'red', fontWeight: 800, fontSize: '14px', textAlign: 'center' }}>9</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'red', fontWeight: 800, fontSize: '14px', textAlign: 'center' }}>10</Col>
                <Col sm="1" style={{ width: '27px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'red', fontWeight: 800, fontSize: '13px', textAlign: 'center' }}>10+</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', color: 'red', fontWeight: 800, fontSize: '14px' }}></Col>


            </Row>
            <Row>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', textAlign: 'center', color: '#0d6efd', fontWeight: 500, fontSize: '14px' }}>{paxData.seven == 0 ? '-' : paxData.seven}</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', textAlign: 'center', color: '#0d6efd', fontWeight: 500, fontSize: '14px' }}>{paxData.eight == 0 ? '-' : paxData.eight}</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', textAlign: 'center', color: '#0d6efd', fontWeight: 500, fontSize: '14px' }}>{paxData.nine == 0 ? '-' : paxData.nine}</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', textAlign: 'center', color: '#0d6efd', fontWeight: 500, fontSize: '14px' }}>{paxData.ten == 0 ? '-' : paxData.ten}</Col>
                <Col sm="1" style={{ width: '27px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', textAlign: 'center', color: '#0d6efd', fontWeight: 500, fontSize: '14px' }}>{paxData.tenPlus == 0 ? '-' : paxData.tenPlus}</Col>
                <Col sm="1" style={{ width: '25px', paddingLeft: '2px', PaddingRight: '2px', borderStyle: 'solid', borderColor: 'black', borderLeft: 'none', borderTop: 'none', textAlign: 'center', color: '#0d6efd', fontWeight: 500, fontSize: '14px' }}></Col>


            </Row>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>

        )
}