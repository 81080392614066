import moment from "moment"
import { logged } from "../lib/logged"

import { Children } from "react";
export default class BookingService {

    async AddBooking(details,status) {
        try {

            const response = await fetch("api/booking/AddBooking", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },  
                body: JSON.stringify({
                    CustomerDetails: {
                        CustomerId: details.customer.customerId,
                        FirstName: details.customer.firstName,
                        LastName: details.customer.lastName,
                        MobileNo: details.customer.mobileNo,
                        EmailAddress: details.customer.emailAddress,
                        Address1: details.customer.address1,
                        Address2: details.customer.address2,
                        City: details.customer.city,
                        State: details.customer.state,
                        Country: details.customer.country,
                        IdCardType: details.customer.idCardType,
                        IdCardNo: details.customer.idCardNo
                        
                    },
                    BookingDetails: {
                        NoOfPax: details.booking.noOfPax,
                        Adults: details.booking.adults == '' ? 0 : details.booking.adults,
                        Children: details.booking.children == '' ? 0 : details.booking.children,
                        Source: details.booking.source,
                        IsPackageRequired: details.booking.isPackageRequired,                        
                        Ocassion: details.booking.ocassion,
                        ReservedDate: moment(details.booking.reservedDate).format(moment.HTML5_FMT.DATE),//details.booking.reservedDate,
                        ReservedTime: details.booking.reservedTime,
                        //PaymentMode: details.booking.paymentMode,
                        //PaymentDate: moment(details.booking.paymentDate).format(moment.HTML5_FMT.DATE),
                        //PaymentBy: details.booking.paymentBy,
                        SpecialInstruction: details.booking.specialInstruction,
                    },
                    PackageDetails: {
                        PackageId: details.package.packageId,
                        CakeFlavour: details.package.cakeFlavour,
                        CakeWordings: details.package.cakeWordings,
                        IsCakeEggLess: details.package.isCakeEggless,
                        BannerWordings: details.package.bannerWordings,
                        Comments:details.package.comments
                    },
                    Payments: details.payments,
                    CreatedBy: details.createdBy,
                    Restaurant: details.restaurant,
                    ReservationStatus:status
                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async SaveConfirmBulkEnquiryDetails(details, status) {
        try {
            const response = await fetch("api/booking/SaveConfirmBulkEnquiryDetails", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    FirstName: details.firstName,
                    LastName: details.lastName,
                    MobileNo: details.mobileNo,
                    CompanyName: details.companyName,
                    NoOfPax: details.noOfPax,
                    SourceId: details.sourceId,
                    OcassionId: details.ocassionId,
                    ReserverdDate: moment(details.reserverdDate).format(moment.HTML5_FMT.DATE),//details.booking.reservedDate,
                    ReservedTime: details.reservedTime,
                    CreatedBy: details.createdBy,
                    RestaurantId: details.restaurantId,
                    EnquiryStatus: status
                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }
        }
        catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async SaveBulkEnquiryDetails(details, status) {
        try {

            const response = await fetch("api/booking/SaveBulkEnquiryDetails", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                        FirstName: details.firstName,
                        LastName: details.lastName,
                        MobileNo: details.mobileNo,
                        CompanyName: details.companyName,
                        NoOfPax: details.noOfPax,
                        SourceId: details.sourceId,
                        OcassionId: details.ocassionId,
                        ReserverdDate: moment(details.reserverdDate).format(moment.HTML5_FMT.DATE),//details.booking.reservedDate,
                        ReservedTime: details.reservedTime,
                        //PaymentMode: details.booking.paymentMode,
                        //PaymentDate: moment(details.booking.paymentDate).format(moment.HTML5_FMT.DATE),
                        //PaymentBy: details.booking.paymentBy,
                        //SpecialInstruction: details.booking.specialInstruction,
                   // BookingP: details.payments,
                    CreatedBy: details.createdBy,
                    RestaurantId: details.restaurantId,
                    EnquiryStatus: status
                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }
        }
        catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async GetBulkEnquiryDetailsById(bulkEnquiryId) {
        try {

            const response = await fetch("api/booking/GetBulkEnquiryDetailsById", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BulkEnquiryId: bulkEnquiryId,
                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }
        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async GetCancelReason() {
        try {
                const response = await fetch("api/booking/GetCancelReason", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + logged.AccessToken
                    },

                });
                if (response.ok) {
                    const data = await response.json();
                    return { data: data, redirect: false };
                } else {
                    throw new Error(response.status.toString());
                }

                } catch(error) {
                 return { data: [], redirect: true, message: error }
             }
    }
    async GetMealType() {
        try {

            const response = await fetch("api/booking/GetMealType", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async Get(userId) {
        try {

            const response = await fetch("api/booking/GetReservations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async AddConfirmBooking(details, status) {
        try {

            const response = await fetch("api/booking/AddConfirmBooking", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CustomerDetails: {
                        CustomerId: details.customer.customerId,
                        FirstName: details.customer.firstName,
                        LastName: details.customer.lastName,
                        MobileNo: details.customer.mobileNo,
                        EmailAddress: details.customer.emailAddress,
                        Address1: details.customer.address1,
                        Address2: details.customer.address2,
                        City: details.customer.city,
                        State: details.customer.state,
                        Country: details.customer.country,
                        IdCardType: details.customer.idCardType,
                        IdCardNo: details.customer.idCardNo

                    },
                    BookingDetails: {
                        NoOfPax: details.booking.noOfPax,
                        Adults: details.booking.adults == '' ? 0 : details.booking.adults,
                        Children: details.booking.children == '' ? 0 : details.booking.children,
                        Source: details.booking.source,
                        IsPackageRequired: details.booking.isPackageRequired,
                        Ocassion: details.booking.ocassion,
                        ReservedDate: moment(details.booking.reservedDate).format(moment.HTML5_FMT.DATE),//details.booking.reservedDate,
                        ReservedTime: details.booking.reservedTime,
                        //PaymentMode: details.booking.paymentMode,
                        //PaymentDate: moment(details.booking.paymentDate).format(moment.HTML5_FMT.DATE),
                        //PaymentBy: details.booking.paymentBy,
                        SpecialInstruction: details.booking.specialInstruction,
                    },
                    PackageDetails: {
                        PackageId: details.package.packageId,
                        CakeFlavour: details.package.cakeFlavour,
                        CakeWordings: details.package.cakeWordings,
                        IsCakeEggLess: details.package.isCakeEggless,
                        BannerWordings: details.package.bannerWordings,
                        Comments: details.package.comments
                    },
                    Payments: details.payments,
                    CreatedBy: details.createdBy,
                    Restaurant: details.restaurant,
                    ReservationStatus: status
                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetCustomerAccounts(restaurantId) {
        try {

            const response = await fetch("api/booking/GetCustomerSuggestion", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurantId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message:error }
        }
    }

    async GetReservations(userId) {
        try {

            const response = await fetch("api/booking/GetReservations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateReservationStatus(bookingId,reservationStatus,tableNo,cancelComments,createdBy,mobileNo) {
        try {

            const response = await fetch("api/booking/UpdateReservationStatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BookingId: bookingId ,
                    ReservationStatus: reservationStatus,
                    TableNo: tableNo,
                    CancelComments:cancelComments,
                    CreatedBy: createdBy,
                    MobileNo:mobileNo

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateTable(bookingId, tableNo, createdBy) {
        try {

            const response = await fetch("api/booking/UpdateTable", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BookingId: bookingId,
                    TableNo: tableNo,
                    CreatedBy: createdBy

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateFeedbackSendStatus(bookingId) {
        try {

            const response = await fetch("api/booking/UpdateFeedbackSendStatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BookingId: bookingId,
                   

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateReminderSendStatus(bookingId) {
        try {

            const response = await fetch("api/booking/UpdateReminderSendStatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BookingId: bookingId,


                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBookingDetailsById(bookingId) {
        try {

            const response = await fetch("api/booking/GetBookingById", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BookingId: bookingId,
                 

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async UpdateBooking(details, status) {
        try {

            const response = await fetch("api/booking/UpdateBooking", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CustomerDetails: {
                        CustomerId: details.customer.customerId,
                        FirstName: details.customer.firstName,
                        LastName: details.customer.lastName,
                        MobileNo: details.customer.mobileNo,
                        EmailAddress: details.customer.emailAddress,
                        Address1: details.customer.address1,
                        Address2: details.customer.address2,
                        City: details.customer.city,
                        State: details.customer.state,
                        Country: details.customer.country,
                        IdCardType: details.customer.idCardType,
                        IdCardNo: details.customer.idCardNo

                    },
                    BookingDetails: {
                        BookingId: details.booking.bookingId,
                        NoOfPax: details.booking.noOfPax,
                        Adults: details.booking.adults == '' ? 0 : details.booking.adults,
                        Children: details.booking.children == '' ? 0 : details.booking.children,
                        Source: details.booking.source,
                        IsPackageRequired: details.booking.isPackageRequired,
                        Ocassion: details.booking.ocassion,
                        ReservedDate: moment(details.booking.reservedDate).format(moment.HTML5_FMT.DATE),//details.booking.reservedDate,
                        ReservedTime: details.booking.reservedTime,
                        //PaymentMode: details.booking.paymentMode,
                        //PaymentDate: moment(details.booking.paymentDate).format(moment.HTML5_FMT.DATE),
                        //PaymentBy: details.booking.paymentBy,
                        SpecialInstruction: details.booking.specialInstruction,
                    },
                    PackageDetails: {
                        PackageId: details.package.packageId,
                        CakeFlavour: details.package.cakeFlavour,
                        CakeWordings: details.package.cakeWordings,
                        IsCakeEggLess: details.package.isCakeEggless,
                        BannerWordings: details.package.bannerWordings,
                        Comments: details.package.comments
                    },
                    Payments: details.payments,
                    CreatedBy: logged.UserId,
                    Restaurant: details.restaurant,
                    ReservationStatus: status
                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetCustomerDetailsById(customerId,restaurantId) {
        try {

            const response = await fetch("api/booking/GetCustomerDetailsById", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    CustomerId: customerId,
                    RestaurantId: restaurantId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetConfirmedBookings(userId) {
        try {

            const response = await fetch("api/booking/GetConfirmedBookings", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetCancelledBookings(userId) {
        try {

            const response = await fetch("api/booking/GetCancelledBookings", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetCheckedInBookings(userId) {
        try {

            const response = await fetch("api/booking/GetCheckedInBookings", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBookingsForFeedback(userId) {
        try {

            const response = await fetch("api/booking/GetBookingsForFeedback", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBookingsForReminder(userId) {
        try {

            const response = await fetch("api/booking/GetBookingsForReminder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async GetWaitListedBookings(userId) {
        try {

            const response = await fetch("api/booking/GetWaitListedBookings", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetNoShowBookings(userId) {
        try {

            const response = await fetch("api/booking/GetNoShowBookings", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBookingsCount(userId) {
        try {

            const response = await fetch("api/booking/GetBookingsCount", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBulkBookingsCount() {
        try {

            const response = await fetch("api/booking/GetBulkBookingsCount", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                }
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetDashboardPax(userId) {
        try {

            const response = await fetch("api/booking/GetDashboardPax", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    UserId: userId,

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBulkEnquiryDetails(status) {
        try {

            const response = await fetch("api/booking/GetBulkEnquiryDetails", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    Status: status,

                })

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async UpdateEnquiryStatus(bulkbookingId, enquiryStatus, reasonName, cancelComments, userId) {
        try {

            const response = await fetch("api/booking/UpdateEnquiryStatus", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BulkEnquiryId: bulkbookingId,
                    EnquiryStatus: enquiryStatus,
                    CancelReasonName: reasonName,
                    FeedBack: cancelComments,
                    CreatedBy: userId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async UpdateBookingIDBulkEnquiry(details, bookingId) {
        try {

            const response = await fetch("api/booking/UpdateBookingIDBulkEnquiry", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BulkEnquiryId: details,
                   /* FirstName: details.firstName,
                    LastName: details.lastName,
                    MobileNo: details.mobileNo,
                    NoOfPax: details.noOfPax,
                    SourceId: details.sourceId,
                    ReserverdDate: moment(details.reserverdDate).format(moment.HTML5_FMT.DATE),//details.booking.reservedDate,
                    ReservedTime: details.reservedTime,
                    RestaurantId: details.restaurantId,
                    EnquiryStatus: details.enquiryStatus,*/
                    ModifiedBy: logged.UserId,
                    BookingId: bookingId

                })
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }


        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }
    async UpdateBulkEnquiry(details, userId) {
        try {

            const response = await fetch("api/booking/UpdateBulkEnquiry", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    BulkEnquiryId: details.bulkEnquiryId,
                    NoOfPax: details.noOfPax,
                    ReserverdDate: moment(details.reserverdDate).format(moment.HTML5_FMT.DATE),//details.booking.reservedDate,
                    ReservedTime: details.reservedTime,
                    RestaurantId: details.restaurantId,
                    SpecialInstruction: details.specialInstruction,
                    MealTypeId: details.mealTypeId,
                    OcassionId: details.ocassionId,
                    RestaurantId: details.restaurantId,
                    FoodCostPerPax: details.foodCostPerPax,
                    FoodTotalAmount: details.foodTotalAmount,
                    ModifiedBy: userId,
                    BookingId: details.bookingId,
                    SourceId: details.sourceId,
                    NoOfPax: details.noOfPax,
                    CompanyName: details.companyName
                    
                }),

            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }
    }

    async GetBulkEnquiryDataByReservedDate(restaurant, month) {
        try {

            const response = await fetch("api/booking/GetBulkEnquiryDataByReservedDate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurant,
                    Month: month

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetBulkEnquiryDataByCreateDate(restaurant, month) {
        try {
             
            const response = await fetch("api/booking/GetBulkEnquiryDataByCreateDate", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                },
                body: JSON.stringify({
                    RestaurantId: restaurant,
                    Month: month

                }),
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetBulkEnquiryConfirmedBookings() {
        try {

            const response = await fetch("api/booking/GetBulkEnquiryConfirmedBookings", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                }
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

    async GetBulkEnquiryCancelledReasons() {
        try {

            const response = await fetch("api/booking/GetBulkEnquiryCancelledReasons", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + logged.AccessToken
                }
            });
            if (response.ok) {
                const data = await response.json();
                return { data: data, redirect: false };
            } else {
                throw new Error(response.status.toString());
            }

        } catch (error) {
            return { data: [], redirect: true, message: error }
        }

    }

}