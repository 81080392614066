import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap'
import { BulkBooking } from './BulkBooking'
import { ImCross } from "react-icons/im";
import BookingService from "../../services/BookingService"
import { FaExpeditedssl } from "react-icons/fa";
import { BsCheckSquareFill } from "react-icons/bs";
import { HiEmojiSad } from "react-icons/hi";
import { HiQueueList } from "react-icons/hi2";
import { IconContext } from "react-icons";

import { logged } from "../../lib/logged"


export function BulkEnquiryDashboard(props) {

    const [bookingStatus, updateBookingStatus] = useState('C');
    
    const bookingService = new BookingService();
    const [bookingCount, updateBookingCount] = useState({ confirmed: 0, cancelled: 0, underDiscussion: 0, past: 0 });
    useEffect(() => {
        getBulkBookingsCount();


        const interval = setInterval(() => {
            window.location.reload();
        }, 600000);
        return () => clearInterval(interval);
    }, []);

    const changeStatus = (val) => {
        updateBookingStatus(val);
    }

    const getBulkBookingsCount = async () => {

        let result = await bookingService.GetBulkBookingsCount()
        if (!result.redirect) {
            let rptData = result.data.data
            updateBookingCount(rptData);
        }
        else {
            toast.error('Error occured in fetching  data');
        }

    }

    return (
        < Container fluid={true}  >
            <Row style={{ paddingBottom: '15px', color: bookingStatus == 'C' ? 'green' : bookingStatus == 'D' ? '#424242' : bookingStatus == 'X' ? 'red' : bookingStatus=='P'?'blue':'black' , fontWeight: 700 }}><Col sm="4"></Col> <Col sm="4" style={{ textAlign: 'center' }}>{bookingStatus == 'C' ? 'CONFIRMED' : bookingStatus == 'D' ? 'UNDER DISCUSSION' : bookingStatus == 'X' ? 'CANCELLED' : bookingStatus=='P'?'PAST':'' } - BULK ENQUIRY BOOKINGS</Col><Col sm="4"></Col></Row>
            <Row style={{ marginLeft: '5px' }}>
                <Col sm="3">
                    <Card
                        style={{ background: 'linear-gradient(120deg, rgb(7, 188, 12), var(--bs-green))', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }} >
                            <IconContext.Provider value={{ size: '22px', color: 'white', verticalAlign: 'sub' }}> < FaExpeditedssl /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>CONFIRMED</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button color="link" style={{ color: 'white' }} onClick={() => changeStatus('C')}>
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.confirmed}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>



                <Col sm="3">

                    <Card
                        style={{ background: 'linear-gradient(120deg, rgb(200 209 63), rgb(244, 197, 10))', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }}>
                            <IconContext.Provider value={{ size: '22px', color: 'white', verticalAlign: 'sub' }}> < HiQueueList /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>DISCUSSION</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button color="link" style={{ color: 'white' }} onClick={() => changeStatus('D')} >
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.underDiscussion}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="3">
                    <Card
                        style={{ background: 'linear-gradient(120deg,  #e74c3cab,red)', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }} >
                            <IconContext.Provider value={{ size: '18px', color: 'white', verticalAlign: 'sub' }}> < ImCross /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>CANCELLED</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button color="link" style={{ color: 'white' }} onClick={() => changeStatus('X')} >
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.cancelled}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="3">
                    <Card
                        style={{ background: 'linear-gradient(120deg, #3498db, var(--bs-cyan))', color: 'white' }}



                    >
                        <CardHeader style={{ fontWeight: 600 }}>
                            <IconContext.Provider value={{ size: '22px', color: 'white', verticalAlign: 'sub' }}> < BsCheckSquareFill /></IconContext.Provider><span style={{ paddingLeft: '5px' }}>PAST</span>
                        </CardHeader>
                        <CardBody style={{ padding: '2px' }}>
                            <CardTitle style={{ textAlign: 'right' }} >
                                <Button data-testid="checkin-dash-button" color="link" style={{ color: 'white' }} onClick={() => changeStatus('P')} >
                                    <span style={{ fontWeight: 700, fontSize: '22px' }}>{bookingCount.past}</span>
                                </Button>
                            </CardTitle>
                            <CardText>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <BulkBooking status={bookingStatus} />

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>

    )
}