import React, { useState, useRef, useCallback, useEffect } from 'react';
import IndentService from "../../services/IndentService";
import { Row, Col, Button,Container} from 'reactstrap'
import { IoIosCloseCircle } from "react-icons/io";
import { IconContext } from "react-icons";
import { GrDocumentCsv } from "react-icons/gr";
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import { Navigate, Link } from 'react-router-dom'
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';

import DatePicker from "react-datepicker";

const ConsolidatedIndentOrder = (props) => {

    useEffect(() => {
      

    }, []);




    const indentService = new IndentService()
    const [indentOrderItems, updateIndentOrderItems] = useState([])
    const [date, setDate] = useState(null);
    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [isClose, updateIsClose] = useState(false);
    const gridRef = useRef(null);

 


    //useEffect(() => {
    //    sizeToFit();
    //}, [indentOrderItems])

    const [columnDefs, setColumnDefs] = useState([

        { field: 'indentOrderId', headerName: 'Id', filter: "agTextColumnFilter", width: 90, floatingFilter: true },
        { field: 'indentType', headerName: 'Type', width: 120, filter: "agTextColumnFilter", floatingFilter: true },
        { field: 'restaurantName', headerName: 'Restaurant', width: 140, filter: "agTextColumnFilter", floatingFilter: true },
        { field: 'departmentName', headerName: 'Department', width: 150, filter: "agTextColumnFilter", floatingFilter: true },
        { field: 'status', headerName: 'Status', filter: "agTextColumnFilter", width: 120, floatingFilter: true },

        { field: 'item', headerName: 'Product Name', filter: "agTextColumnFilter", width: 180, floatingFilter: true },
        { field: 'category', headerName: 'Category ', filter: "agTextColumnFilter", width:180, floatingFilter: true },

        { field: 'unit', headerName: 'UoM', filter: "agTextColumnFilter", width: 90 },
        { field: 'supplier', headerName: 'Supplier', filter: "agTextColumnFilter", width: 130, floatingFilter: true },


        { field: 'quantity', headerName: 'Qty', width: 100 },

        {
            field: 'price', headerName: 'Unit Price', filter: "agTextColumnFilter", width: 140,
            cellStyle: (p) => {
           return { textAlign: 'right' }
            },
            valueFormatter: (params) => {
             
                return params.data.price!=null? params.data.price.toLocaleString('en-IN', {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'INR'
                    }).substring(1):'0.00'
            }

        },
        {
            field: 'total', headerName: 'Total Price', filter: "agTextColumnFilter", width: 140,
            cellStyle: (p) => {
             return { textAlign: 'right' }
            },
            valueFormatter: params => params.data.total!=null? params.data.total.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            }).substring(1):'0.00',
        },







    ]);











  

    const [close, setClose] = useState(false);


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false,
        sortable:true
    })

    const onGridReady = useCallback(() => {


    }, []);

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }




    const onClose = () => {
        updateIsClose(true);
    }





    const validateForm = () => {
        if (date == null) {
            toast.error('Please choose a  date')
            return false;
        }

        return true;
    }



    const GetIndentOrderItems = async () => {
        if (validateForm()) {
            setMessage('LOADING IN PROGRESS');
            openMessageModal();
            let result = await indentService.GetConsolidatedIndent(date);

            if (!result.redirect) {

                updateIndentOrderItems(result.data.data);



                closeMessageModal();
            }
            else {
                closeMessageModal();
                toast.error('Error occured in fetching Grocery items');

            }
        }

    }




    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "ConsolidatedIndent" });
    }, []);

    return (
        isClose ? <Navigate to='/indent' /> : <Container>
            <form >
                <Row style={{ paddingBottom: '3px' }}>
                    <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> CONSLIDATED INDENT </Col>
                    <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
                </Row>

             

                <Row style={{ background: '#b5eafb4a', paddingBottom: '5px' }}>
                    <Col sm="2" style={{ textAlign: 'right', marginTop: '15px' }}>
                        <label className="required-fields">
                            Choose Date {` : `}

                        </label>
                    </Col>


                    <Col sm="2" style={{marginTop:'10px'} }>
                        <DatePicker selected={date} onChange={(d) => { setDate(d); updateIndentOrderItems([]); }} />
                    </Col>
                       
                    <Col sm="2" >
                        <Button style={{ marginRight: '5px', marginTop: '10px' }} color="success" size='sm' onClick={GetIndentOrderItems} >SUBMIT{""}</Button>
                        </Col>

                </Row>


        
                <Row style={{ textAlign: 'center' }}>

                    <Col sm="11"/>
                    <Col><Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button></Col>


                </Row>


                <Row >

                    <Col sm="12" style={{ margin: 0, padding: 0, marginTop: '3px' }}>

                        <div style={{ width: '100%', height: 480 }} className="ag-theme-alpine" >
                            <AgGridReact rowHeight={25}
                                headerHeight={30}
                                floatingFiltersHeight={30}
                                ref={gridRef}
                                rowData={indentOrderItems}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                animateRows={true}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add items to be ordered</span>'
                                }
                                enableCellTextSelection={true}
                                suppressCellFocus={true} >

                            </AgGridReact>
                        </div>

                    </Col>
                </Row>

        








                <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
                </Modal>

            </form>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />


        </Container>)
    
};

export default ConsolidatedIndentOrder;