
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";

import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import html2canvas from 'html2canvas';


import "react-datepicker/dist/react-datepicker.css";



export function CallAndBookingReport(props) {

    const reportService = new ReportService();


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [callBookingData, updateCallBookingData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [columnDefs, setColumnDefs] = useState([

        { field: 'bookingId', headerName: 'Id', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'restaurant', headerName: 'Restaurant', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'source', headerName: 'Source', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        { field: 'name', headerName: 'Name', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'Mobile No', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'bookingStatus', headerName: 'Status', width: 140, sortable: true,
            cellStyle: (p) => {
                if (p.node.data.bookingStatus == 'Confirmed') return { color: "green", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Waitlisted') return { color: "brown", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Check-In') return { color: "#216ba5", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Cancelled') return { color: "red", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'NoShow') return { color: "deepskyblue", fontWeight: 600 }

            }, filter: "agTextColumnFilter", floatingFilter: true
        },
        { field: 'noOfPax', headerName: 'PAX', width: 90, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'package', headerName: 'Package', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'reservationDate', headerName: 'Date', width: 110, cellRenderer: (d) => { return moment(d.data.reservationDate).format("DD/MM/YY") } },
        { field: 'reservationTime', headerName: 'Time', width: 100, sortable: true },
        { field: 'tableNo', headerName: 'Table', width: 90, sortable: true },
        { field: 'bookedBy', headerName: 'Booked By', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'modifiedBy', headerName: 'Modified By', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },

    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })



    const getCallAndBookingData = async () => {
        if (isInputValid()) {
            //showLoading();
            let result = await reportService.GetCallAndBookingData(startDate, endDate);
            if (!result.redirect) {
                let rptData = result.data.data
                updateCallBookingData(rptData);
                setShowReport(true);

                //hideLoading();
            }
            else {
                toast.error('Error occured in fetching report data');
                //hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "CallAndBookingAnalysisReport" });
    }, []);

    const isInputValid = () => {
        let isAfter = false;
        if (startDate !== null && endDate !== null) {
            isAfter = moment(endDate).isSameOrAfter(startDate);

        }

       if (startDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (endDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }

    const onCopy = () => {
        html2canvas(document.querySelector("#callBookingreport")).then(function (canvas) {
            canvas.toBlob(function (blob) {
                navigator.clipboard
                    .write([
                        new window.ClipboardItem(
                            Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                            })
                        )
                    ])
                    .then(function () {
                        toast.success('Copied')
                    });
            });
        });
    }

    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingBottom: '20px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> CALL AND BOOKING ANALYSIS REPORT </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row>


              


                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        Start Date {` : `}

                    </label>
                </Col>


                <Col sm="3">
                    <DatePicker selected={startDate} onChange={(date) => { setStartDate(date); updateCallBookingData([]); setShowReport(false); }} />
                </Col>

                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        End Date {` : `}

                    </label>
                </Col>


                <Col sm="3">
                    <DatePicker selected={endDate} onChange={(date) => { setEndDate(date); updateCallBookingData([]);setShowReport(false); }} />
                </Col>

                <Col sm="2" >
                    <Button color="primary" size='sm' onClick={getCallAndBookingData}>Submit</Button>
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px', }}>
                <Col sm="2"></Col>
                <Col sm="8" style={{ textAlign: 'right' }}>
                    {/*<Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>*/}

                    {/*<Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>*/}

                </Col>
                <Col sm="2" ></Col>
            </Row>
            {showReport ? <Row style={{ paddingTop: '25px', }}>
                <Col sm="2"></Col>
                <Col sm="8" style={{ textAlign: 'right' }}>
                    <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>

                    {/*    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>*/}
                </Col>
                <Col sm="2" ></Col>
            </Row> : (null)}

            {showReport ? <Row style={{ paddingTop: '10px' }}>
                <Col sm="2"></Col>
                <Col sm="8">


                    <Row style={{ marginRight: 0, marginLeft: 0, textAlign: 'center' }} >
                        <Col sm="12" >
                            <div id="callBookingreport">

                                <Row noGutters={true}><Col sm="12" style={{ textAlign: 'center', borderStyle: 'solid', borderColor: 'black', color: 'white', backgroundColor: 'crimson', fontWeight: 700, }} >CALL AND BOOKING ANALYSIS REPORT</Col></Row>
                                <Row noGutters={true}>
                                    <Col sm="6" style={{ textAlign: 'left', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', borderBottom: 'none', backgroundColor: 'lightskyblue' }} ><span style={{ fontWeight: 500, color: "black", paddingRight: '10px' }}>{`Start Date : `}{endDate == null ? '' : moment(startDate).format(moment.HTML5_FMT.DATE)}</span></Col>
                                    <Col sm="6" style={{ textAlign: 'right', borderStyle: 'solid', borderColor: 'black', borderTop: 'none', borderLeft: 'none', borderBottom: 'none', backgroundColor: 'lightskyblue' }} ><span style={{ fontWeight: 500, color: "black", paddingRight: '10px' }}>{`End Date : `} {endDate == null ? '' : moment(endDate).format(moment.HTML5_FMT.DATE)}</span></Col>

                                </Row>

                                <Row noGutters={true} style={{ fontWeight: 600, height: '32px' }}>
                                    <Col sm="4" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', paddingTop: '2px', paddingBottom: '2px',fontWeight: 700 }}>Operator</Col>
                                    <Col sm="2" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', flex: 'none', fontWeight: 700 }}>Calls Missed</Col>
                                    <Col sm="2" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', fontWeight: 700, }}>Calls Taken</Col>
                                    <Col sm="2" style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', fontWeight: 700, }}>Bookings</Col>
                                    <Col sm="2"  style={{ color: 'red', backgroundColor: 'yellow', borderStyle: 'solid', borderColor: 'black', paddingLeft: 0, paddingRight: 0, paddingTop: '2px', paddingBottom: '2px', borderLeft: 'none', fontWeight: 700 }}>PAX</Col>


                                </Row>

                                {callBookingData.map((d, i) => (
                                    <Row noGutters={true} style={{ height: '32px' }} key={i}>
                                        <Col sm="4" style={{ color: 'blue',borderStyle: 'solid', borderColor: 'black', paddingLeft: '2px', paddingRight: 0, fontWeight: 500, borderTop: 'none', textAlign: 'left' }}>{d.operator}</Col>
                                        <Col sm="2" style={{ color: 'black',  borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.missedCalls}</Col>
                                        <Col sm="2" style={{ color: 'black', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '6px', fontWeight: 600, borderLeft: 'none', borderTop: 'none' }}>{d.attendedCalls}</Col>
                                        <Col sm="2" style={{ color: 'black', borderStyle: 'solid', borderColor: 'black', paddingLeft: '6px', paddingRight: '3px', fontWeight: 600, borderLeft: 'none', borderTop: 'none', }}>{d.bookings}</Col>
                                        <Col sm="2" style={{ color: 'black',  borderStyle: 'solid', borderColor: 'black', paddingLeft: '3px', paddingRight: '3px', fontWeight: 600, borderLeft: 'none', borderTop: 'none', }}>{d.pax}</Col>


                                    </Row>
                                )
                                )}

                            </div>

                        </Col>
                    </Row>
              



                </Col>
                <Col sm="2"></Col>
            </Row> : (null)}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
