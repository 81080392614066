import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Button, Container } from 'reactstrap'
import IndentService from "../../services/IndentService"
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AddIndentType } from './AddIndentType';

export function IndentType(props) {

    const indentService = new IndentService();

    const gridRef = useRef(null);

    const [InProgress, setInProgress] = useState(false);
    const [indentType, setIndentType] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        { field: 'value', headerName: 'Indent Type Id', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 200 },
        {
            field: 'label', headerName: 'Indent Type Name', filter: "agTextColumnFilter", floatingFilter: true, width: 275
        },
        { headerName: 'Inactivate', width: 200, cellRenderer: (d) => { return <Button onClick={() => { onInactivate(d.data.value); }} style={{ backgroundColor: '#ff1200', padding: '2px', margin: 0, marginBottom: '6px', visibility: 'visible' }} disabled={false} color="danger" size="sm" > Inactivate</Button> }, floatingFilter: false },

    ]);

    const onInactivate = async (indentTypeId) => {
        let result = await indentService.InactivateIndentType(indentTypeId);
        if (!result.redirect) {
            toast.success('Indent Type has been inactivated successfully')
            getIndentType();
        }
        else {
            toast.error("Error occured while inactivating the indent type");

        }
    }

    useEffect(() => {
        sizeToFit();
    }, [indentType])

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: true
    })

    const onGridReady = useCallback(() => {
        getIndentType();
    }, []);

    const getIndentType = async () => {
        showLoading();
        let result = await indentService.GetIndentTypes();
        if (!result.redirect) {

            setIndentType(result.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching indent type details');
            hideLoading();
        }
    }

    const onAdd = () => {
        setIsAdd(true);
    }

    const showAlert = (message, type) => {

        if (type === 'error') {
            toast.error(message);
        }
        else if (type === 'success') {
            toast.success(message);
        }
    }



    return (


        isAdd ? <AddIndentType /> : <Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Col sm="11" style={{ color: 'darkblue', fontWeight: 'bold' }}> Indent Type </Col>
                <Col sm="1" style={{ textAlign: 'right' }}>  <Button color="primary" size="sm" onClick={onAdd} disabled={InProgress} >Add{""}</Button></Col>
            </Row>
            <div >

                <div style={{ width: '100%', height: 500 }} className="ag-theme-alpine" >
                    <AgGridReact rowHeight={32}
                        ref={gridRef}
                        rowData={indentType}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{ showAlert, getIndentType }}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while Indent Types are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Indent Type to show</span>'
                        }

                        suppressCellFocus={true} >

                    </AgGridReact>
                </div>
            </div>


            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </Container>
    )

}