import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function StateDropDown(props) {

    const administrationService = new AdministrationService();

    const [states, updateStates] = useState([]);
   // const [selectedState, updateSelectedState] = useState(props.selectedCountryId==1? { label: 'TamilNadu', value: '23' }:null);

    useEffect(() => { getStates(); }, [])

    const getStates = async () => {
        let result = await administrationService.GetStates(props.selectedCountryId == null? 0 :props.selectedCountryId);
        if (!result.redirect) {

            updateStates(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val,action) => {
       // updateSelectedState(val);
        //if (action.action == 'clear') 
            props.selectedStateId(val)
        //else
         //props.selectedStateId(val.value)
    }


    return (
        <Select options={states} isClearable={true} onChange={onSelectChange} value={props.state==null?null:states.find(o => o.value === props.state)} />
    );



}