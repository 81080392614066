import React, { useState, useEffect } from "react";
import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function RoleDropDown(props) {

    const administrationService = new AdministrationService();

    const [Roles, updateRoles] = useState([]);
   

    useEffect(() => { getRoles(); }, [])

    const getRoles = async () => {
        let result = await administrationService.GetRoles();
        if (!result.redirect) {

            updateRoles(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
       
        props.selectedRole(val)
       
    }


    return (
        <Select options={Roles} isClearable={true} onChange={onSelectChange} value={props.Role == null ? null : Roles.find(o => o.value === props.Role)} />
    );



}