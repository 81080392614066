import React, { useState, useEffect } from "react";
import IndentService from "../../services/IndentService";
import Select from 'react-select'



export function ItemMappedUnitTypesDropDown(props) {

    const indentService = new IndentService();

    const [unitTypes, updateUnitTypes] = useState([]);


    useEffect(() => { getDepartment(); }, []);

    const getDepartment = async () => {
        let result = await indentService.GetItemMappedUnitTypes(props.selectedItemId == null ? 0 : props.selectedItemId);

        if (!result.redirect) {

            updateUnitTypes(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {

        props.selectedUnitTypeId(val)

    }


    return (
        <Select options={unitTypes} isClearable={true} onChange={onSelectChange} value={props.unitType == null ? null : unitTypes.find(o => o.value === props.unitType)} />


    );



}