import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import { Navigate, Link } from 'react-router-dom'
import BookingService from "../../services/BookingService"
import { AgGridReact } from 'ag-grid-react';
import { logged } from "../../lib/logged"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { BookingActionRenderer } from './BookingActionRenderer'
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import { IconContext } from "react-icons";
import { AiFillCloseCircle } from "react-icons/ai";
import { ReservationStatus } from "../../lib/ReservationStatus";
import 'react-toastify/dist/ReactToastify.css';



export function Bookings(props) {

    const bookingService = new BookingService();


    useEffect(() => {
       // getReservations();

    }, [])


    const checkoutModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };

    const cancelModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '40%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };
    const [cancelModalIsOpen, setCancelModalIsOpen] = React.useState(false);

    const [checkoutModalIsOpen, setCheckoutModalIsOpen] = React.useState(false);
    const [tableNo, updateTableNo] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const [message, setMessage] = useState('')
    const [bookingId, setBookingId] = useState(0)
    const [cancelComments, updateCancelComments] = useState('')

    const openCancelModal = () => {
        setCancelModalIsOpen(true)
    }

    const closeCancelModal = () => {
        setCancelModalIsOpen(false)
    }
    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }
    const openCheckoutModal = () => {
        setCheckoutModalIsOpen(true)
    }

    const closeCheckoutModal = () => {
        setCheckoutModalIsOpen(false)
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '90%' }), []);
    const gridStyle = useMemo(() => ({ height: '95%', width: '100%' }), []);
    const gridRef = useRef(null);

    const [InProgress, setInProgress] = useState(false);
    const [reservations, updateReservations] = useState([]);
    const [isAdd, updateIsAdd] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        { cellRenderer: BookingActionRenderer, headerName: '',width:100 },
        { field: 'bookingId', headerName: 'Id', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true, cellRenderer: (d) => { return <Link data-testid="link-bookingId" to={`/bookings/${d.data.bookingId}`}>{d.data.bookingId}</Link> } },
        {
            field: 'restaurant', headerName: 'Restaurant', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'source', headerName: 'Source', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'externalBookingId', headerName: 'Source Booking Id', width: 200, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        { field: 'name', headerName: 'Name', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'Mobile No', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'bookingStatus', headerName: 'Status', width: 110, sortable: true,
            cellStyle: (p) => {
                if (p.node.data.bookingStatus == 'Confirmed') return { color: "green", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Waitlisted') return { color: "red", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Check-In') return { color: "#216ba5", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Cancelled') return { color: "grey", fontWeight: 600 }
            }, filter: "agTextColumnFilter", floatingFilter: true
        },
        { field: 'noOfPax', headerName: 'PAX', width: 90, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'ocassion', headerName: 'Ocassion', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'package', headerName: 'Package', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'reservationDate', headerName: 'Date', width: 100, sortable: true, cellRenderer: (d) => { return moment(d.data.reservationDate).format("DD/MM/YY") } },
        { field: 'reservationTime', headerName: 'Time', width: 80, sortable: true },
        { field: 'specialInstruction', headerName: 'Instruction', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'bookedBy', headerName: 'Booked By', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'bookedOn', headerName: 'Booked On', width: 140, sortable: true, cellRenderer: (d) => { return moment(d.data.bookedOn).format("DD/MM/YY h:mm:ss") } },
        { field: 'modifiedBy', headerName: 'Modified By', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'modifiedOn', headerName: 'Modified On', width: 140, sortable: true, cellRenderer: (d) => {
                if (d.data.modifiedOn != null)
                    return moment(d.data.modifiedOn).format("DD/MM/YY h:mm:ss")
                else
                    return d.data.modifiedOn
            }
        }
      
    ]);
    
    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const updateBookingId = (val) => {
        setBookingId(val);
        }


    const onGridReady = useCallback((params) => {
        getReservations();
    }, []);

    const getReservations = async () => {
        showLoading();
        let result = await bookingService.GetReservations(logged.UserId);
        if (!result.redirect) {
            updateReservations(result.data.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in getting booking records');
            hideLoading();
        }
    }

    const onAdd = () => {
        updateIsAdd(true);
    }

    const showAlert = (message,type) => {

        if (type == 'error') {
            toast.error(message);
        }
        else if (type == 'success') {
            toast.success(message);
        }
    }

    const updateBookingStatus = async (status) => {
        setMessage('UPDATE IN PROGRESS');
        openMessageModal();
        let result = await bookingService.UpdateReservationStatus(bookingId, status, tableNo, cancelComments, logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                closeMessageModal();

                if (status === 'I') {
                    toast.success('Check-In successfull')
                }
                closeCheckoutModal();
                getReservations();

            }
            else {
                closeMessageModal();
                closeCheckoutModal();

                if (status === 'I') {
                    toast.error('Check-In failure')
                }
                
            }

            setBookingId(0);

        }
        else {
            closeMessageModal();
            toast('Error occured in check-in or cancellation of the booking');
        }
    }

    const updateTable = async (bookingId) => {
        setMessage('UPDATE IN PROGRESS');
        openMessageModal();
        let result = await bookingService.UpdateTable(bookingId, tableNo, logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                closeMessageModal();

                closeCheckoutModal();
                getReservations();

            }
            else {
                closeMessageModal();
                closeCheckoutModal();


            }

            setBookingId(0);

        }
        else {
            closeMessageModal();
            toast('Error occured in updating the table');
        }
    }


    return (

        
        isAdd ? <Navigate to='/booking/restaurant' /> : <Container fluid={true} style={{ backgroundColor: "white" }}>

            <Row style={{ paddingTop: '5px',paddingBottom:'5px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> BOOKINGS </Col>
                <Col sm="2" style={{ textAlign: 'right' }}>  <Button color="primary" size="sm" onClick={onAdd} disabled={InProgress} >Add{""}</Button></Col>
            </Row>
            <div style={containerStyle}>

            <div style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact rowHeight={32}
                        ref={gridRef}
                        rowData={reservations}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{ showAlert, getReservations, openCheckoutModal, updateBookingId, openCancelModal }}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while bookings are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No bookings to show</span>'
                        }
                        domLayout={'autoHeight'}


                        suppressCellFocus={true}
                        enableCellTextSelection={true}
                    >

                        </AgGridReact>
                </div>

                <Modal isOpen={checkoutModalIsOpen} style={checkoutModalStyles}
                    conetentLable="CheckOut" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                        <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Check-In Table</Col>
                        <Col sm="1" style={{ textAlign: "right" }}>
                            <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                                <Button color='link' title='close' style={{ padding: 0 }} onClick={closeCheckoutModal}>

                                    <AiFillCloseCircle />
                                </Button>
                            </IconContext.Provider>
                        </Col>

                    </Row>
                    <Row style={{ paddingTop: '25px' }}><Col sm='12' style={{ textAlign: 'center', fontWeight: 500 }} >Table No {` :  `} <input
                        type="text"
                        className="bookingInput"
                        style={{ width: '25%' }}
                        value={tableNo}
                        onChange={e => updateTableNo(e.target.value)}
                    /> </Col></Row>

                    <Row><Col sm='12' style={{ textAlign: 'right' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={() => { closeCheckoutModal(); updateTable(bookingId); }} disabled={InProgress} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={closeCheckoutModal} disabled={InProgress} >Close{""}</Button>

                    </Col></Row>
                </Modal>

                <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
                </Modal>

                <Modal isOpen={cancelModalIsOpen} style={cancelModalStyles}
                    conetentLable="Cancel" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }}>
                        <Col sm='11' style={{ color: 'white', fontWeight: 600 }}>Reason For Cancellation</Col>
                        <Col sm="1" style={{ textAlign: "right" }}>
                            <IconContext.Provider value={{ size: '25px', color: 'white' }}>
                                <Button color='link' title='close' style={{ padding: 0 }} onClick={closeCancelModal}>

                                    <AiFillCloseCircle />
                                </Button>
                            </IconContext.Provider>
                        </Col>

                    </Row>
                    <Row><Col sm="12" sytle={{ textAlign: 'center', fontWeight: 600 }}>Please provide the reason for cancellation</Col></Row>
                    <Row style={{ paddingTop: '5px' }}><Col sm='12'> <textarea
                        rows="4" cols="50"

                        value={cancelComments}
                        onChange={e => updateCancelComments(e.target.value)}
                    /> </Col></Row>

                    <Row><Col sm='12' style={{ textAlign: 'right' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={() => { closeCancelModal(); updateBookingStatus('X'); }} disabled={InProgress} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={closeCancelModal} disabled={InProgress} >Close{""}</Button>

                    </Col></Row>
                </Modal>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />

            </div>

          

        </Container>
        )

}