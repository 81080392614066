import { Row, Col, Button, Progress, Container } from 'reactstrap'

import React, { useState } from "react";

export function ItemActionRenderer(props) {

    const [isEdit, setIsEdit] = useState(true);
    const oldPrice = props.data.price
    const oldUnit = props.data.unit

    return (

        isEdit ? (<Button style={{height:'20px',paddingTop:0,paddingBottom:'20px'}} onClick={() => { setIsEdit(false); props.context.setDisable(false, props.data.itemPriceMappingId) }} color="danger" size="sm" > Edit </Button >) :
            (
                <div>
                    <Button style={{ height: '20px', paddingTop: 0, paddingBottom: '20px' }} onClick={() => { setIsEdit(true); props.context.setDisable(true, props.data.itemPriceMappingId); props.context.save(props.data.itemPriceMappingId, props.data.unit, props.data.price,props.data.supplierId) }} color="success" size="sm" >
                Save
            </Button>
                    <Button style={{ height: '20px', paddingTop: 0, paddingBottom: '20px', marginLeft: '5px' }} onClick={() => { setIsEdit(true); props.context.setDisable(true, props.data.itemPriceMappingId); props.context.updatePrice(oldPrice, props.data.itemPriceMappingId); }} color="secondary" size="sm" >
                Cancel
             </Button>
                </div>
            )
        
       
       

    )
}