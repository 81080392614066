
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";



export function PriceChangeReport(props) {

    const reportService = new ReportService();


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [priceChangeData, updatePriceChangeData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);

    //const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    //const onRestaurantSelectionChange = (val) => {
    //    setSelectedRestaurant(val);
    //    updatePriceChangeData([]);
    //}

    useEffect(() => {
        sizeToFit();
    }, [priceChangeData])

 

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const sizeToFit = useCallback(() => {
       if(gridRef.current.api != undefined)
        gridRef.current.api.sizeColumnsToFit();
    }, []);


    const getColumDefs = (obj) => {
        var defs = [];
        let childs = [];
            if (!obj) return defs;
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if(key=='ItemName')
                        defs.push({ field: key, headerName: 'Item', width: 150, filter: "agTextColumnFilter", floatingFilter: true });
                    else if (key == 'UnitName')
                        defs.push({ field: key, headerName: 'Unit Name', width: 120, filter: "agTextColumnFilter", floatingFilter: true });
                    else if (key == 'Unit')
                        defs.push({ field: key, headerName: 'Unit', width: 80, filter: "agTextColumnFilter", floatingFilter: true });
                    else
                        childs.push({ field: key, headerName: getHeaderName(key), width: 100 });
                }
        }
        defs.push({ headerName: 'DATES ON PRICE CHANGED', children: childs })

        return defs;
        

    }

    const getHeaderName = (headerValue) => {
        const headerArray = headerValue.split(" ");
        return headerArray[1] + "-" + headerArray[0] + "-" + headerArray[2].substring(2, 4)
    }

    const getPriceChangeData = async () => {
        if (isInputValid()) {
            showLoading();
            let result = await reportService.GetPriceChangeData(startDate,endDate);
            if (!result.redirect) {
                let strData = result.data.data
                let rptData = JSON.parse(strData);
                let o = rptData[0];
                let defData = getColumDefs(o)
                setColumnDefs(defData);
                updatePriceChangeData(rptData);
                hideLoading();
               sizeToFit();
            }
            else {
                toast.error('Error occured in fetching report data');
                hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "PriceChangeReport" });
    }, []);

    const isInputValid = () => {
        let isAfter = false;
        if (startDate !== null && endDate !== null) {
            isAfter = moment(endDate).isSameOrAfter(startDate);

        }
         if (startDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (endDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }
   
    return (
        isClose ? <Navigate to='/reports' /> : <Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ marginBottom: '10px', background: 'whitesmoke', padding: 0, margin: 0 }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> <span>PRICE CHANGE REPORT</span> </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'whitesmoke', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row style={{ background:'#a4b3dc',padding:0,margin:0} }>
                <Col sm="8">
                    <Row>




                        <Col sm="2" style={{ textAlign: 'right' ,marginTop:'4px'}}>
                            <label className="required-fields">
                                Start Date {` : `}

                            </label>
                        </Col>


                        <Col sm="2" style={{ textAlign: 'left' ,marginTop:'0.1rem'}}>
                            <DatePicker selected={startDate} onChange={(date) => { setStartDate(date); updatePriceChangeData([]); }} />
                        </Col>

                        <Col sm="2" style={{ textAlign: 'right', marginTop: '4px' }}>
                            <label className="required-fields">
                                End Date {` : `}

                            </label>
                        </Col>


                        <Col sm="2" style={{marginTop:'0.1rem'} }>
                            <DatePicker selected={endDate} onChange={(date) => { setEndDate(date); updatePriceChangeData([]); }} />
                        </Col>

                        <Col sm="1" >
                            <Button style={{marginTop:'3px'}} color="success" size='sm' onClick={getPriceChangeData}>Submit</Button>
                        </Col>

                    </Row>
            </Col>

                <Col sm="4">
                    <Button style={{ backgroundColor: '#a4b3dc', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>
            </Row>
         

            <div id="priceChangeReport">
                {/*<Row>*/}

                {/*     <Col sm="12" style={{ textAlign: 'center' }}> <span style={{ color: 'black', fontWeight: 700 }}>BOOKING REPORT</span></Col>*/}
                {/* </Row> */}

                {/*<Row>*/}

                {/*    <Col sm="2" style={{ textAlign: 'left' }}> <span style={{ fontWeight: 700, color: "#6c757d" }}>{selectedRestaurant == null ? '' : selectedRestaurant.label}</span>{`     `} <span style={{ paddingLeft: '50px' }}>{reservationStartDate == null ? '' : ``}</span> </Col>*/}
                {/*    <Col sm="8"  ></Col>*/}
                {/*    <Col sm="2" style={{ textAlign: 'right' }} ><span style={{ fontWeight: 700, color: "#6c757d" }}>{reservationStartDate == null ? '' : moment(reservationStartDate).format(moment.HTML5_FMT.DATE)}</span> </Col>*/}
                {/*</Row>*/}
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={22}
                                ref={gridRef}
                                rowData={priceChangeData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }
                                suppressCellFocus={true} >


                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
