

import React from "react";
import { IoRemoveCircle } from "react-icons/io5";
import { IconContext } from "react-icons";
import { Button } from 'reactstrap'
export function RemoveUnitTypeRenderer(props) {


    return (
        <Button onClick={() => props.context.removeUnitType(props.data)} style={{ backgroundColor: '#cddc3900', border: 0, textAlign:'left' }} size="sm" > 
            <IconContext.Provider value={{ size: '22px', color: 'red' }}> < IoRemoveCircle /></IconContext.Provider>
        </Button>
        
    )
}