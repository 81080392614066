import React, { Component } from 'react';
import { DashboardMain } from './Dashboard/DashboardMain'

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
            <DashboardMain />
    );
  }
}
