let userlogged = JSON.parse(sessionStorage.getItem("K1Prms"))

var logged = {}

if (userlogged != null) {

    logged.IsAuth = userlogged.isAuth;
    logged.AuthDate = userlogged.authDate;
    logged.UserId = userlogged.userId;
    logged.Name = userlogged.name;
    logged.Roles = userlogged.roles;
    logged.AccessToken = userlogged.accessToken;
}


export { logged }