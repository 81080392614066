import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import IndentService from "../../services/IndentService";
import { UserRestaurantDefaultDropDown } from '../Controls/UserRestaurantDefaultDropDown';
import { MonthDropDown } from '../Controls/MonthDropDown';
import { AgGridReact } from 'ag-grid-react';
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import { Navigate, Link } from 'react-router-dom'
import { logged } from "../../lib/logged"
import {    Chart as ChartJS, ArcElement, Tooltip, Legend,  CategoryScale, LinearScale, BarElement,Title } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import moment from "moment";
const IndentSummary = (props) => {
  

     

    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState((new Date().getMonth() + 1));
    const [categoryData, updateCategoryData] = useState([])
    const indentService = new IndentService()
    const [pieChartData, updatePieChartData] = useState({
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    });
    const [barChartData, updateBarChartData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Kitchen',
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Dataset 2',
                data: [7, 8, 9, 10, 11, 12],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    })

    const [categoryColumnDefs, setCategoryColumnDefs] = useState([

        {
            field: 'categoryName', headerName: 'Category', filter: "agTextColumnFilter",
            cellStyle: (p) => {
                if (p.node.data.category == 'Total Amount') return { color: "Red", fontWeight: 'bold', background: 'yellow' }

            }

        },
        {
            field: 'amountSpend', headerName: 'Total Price', filter: "agTextColumnFilter",
            valueFormatter: params => parseFloat(params.data.amountSpend).toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            }).substring(1),
            cellStyle: (p) => {
                if (p.node.data.categoryName == 'Total Amount') return { color: "Red", fontWeight: 'bold', background: 'yellow', textAlign: 'right', borderLeftColor: 'black' }
                else
                    return { textAlign: 'right' }
            }
        }
    ]);
    useEffect(() => {
        sizeToFit();
    }, [categoryData])
    const cgridRef = useRef(null);

    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false,
        sortable: true,
    })

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: 'white',
                font: {
                    size: 12,
                    weight:'bold'
                },
                display: 'auto',
                anchor: 'center',
                backgroundColor: 'red',
                borderColor: 'yellow',
                borderWidth:1
            },
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Spend till date by category',
            },

        },
    }

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y:
            {
                title: {
                    display: true,
                    text: 'Amount Spend',
                    color:'blue'
                },
                min: 0,
                max: 60000,
                ticks: {
                    stepSize: 2500,
                }
            },
        },
        plugins: {
            datalabels: {
                color: '#36A2EB',
                display: 'auto',
                rotation: 90,
                anchor: 'end',
                align: 'end',
                offset: 0,
                font: {
                    size: 10,
                    weight:'bold'
                }
            },
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Last 7 days indent spends',
            },
            
        },
    };


  const  dynamicColors =  ()=> {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
    }

    const getRandomColor=() =>{
        var characters = "0123456789ABCDEF";
        var color = '#';

        for (var i = 0; i < 6; i++) {
            color += characters[getRandomNumber(0, 15)];
        }

        return color;
    }

    const getRandomNumber=(low, high)=> {
        var r = Math.floor(Math.random() * (high - low + 1)) + low;
        return r;
    }

    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        getPieCategoryData(val!=null?val.value:0);
        getBarIndentData(val != null ? val.value : 0);
        getCategoryData(val != null ? val.value : 0, selectedMonth);
    }
    const getPieCategoryData = async (restaurantId) => {
        let result = await indentService.GetAmountSpendByCategory(restaurantId);
        if (!result.redirect) {
        
            let cLabels = result.data.data.map(a => a.categoryName)
            let cData = result.data.data.map(a => a.amountSpend)
            let cBackgroundColor = [], cBorderColor = []
            for (let i = 0; i < result.data.data.length; i++) {
                let color = dynamicColors();
                cBackgroundColor.push(color);
                cBorderColor.push(color);
            }
            updatePieChartData({
                labels: cLabels, datasets: [{ label: 'Amount Spend',data: cData, backgroundColor:cBackgroundColor,borderColor: cBorderColor, borderWidth: 1 }],
                 });
        }
        else {
           
        }
    }

    const getBarIndentData = async (restaurantId) => {
        let result = await indentService.GetAmountSpendByIndent(restaurantId);
        let barDateLabels = []
        let datasets=[]
        if (!result.redirect) {
            const distinctDates = [...new Set(result.data.data.map(obj => obj.createDate))]
            const distinctDepartments = [...new Set(result.data.data.map(obj => obj.departmentName))]
            for (let m = 0; m < distinctDates.length; m++) {
                barDateLabels.push(moment(distinctDates[m]).format("DD-MMM"))
            }

            for (let n = 0; n < distinctDepartments.length; n++) {
                let datasetData = []
                for (let p = 0; p < distinctDates.length; p++) {
                    let val = result.data.data.find(a => a.departmentName == distinctDepartments[n] && a.createDate == distinctDates[p])
                    if (val != undefined) {
                        datasetData.push(val.total);

                    }
                    else {
                        datasetData.push(0);
                    }

                    if (p == distinctDates.length - 1) {
                        datasets.push({ label: distinctDepartments[n], data: datasetData, backgroundColor: getRandomColor() })
                    }
                }
                

            }


            updateBarChartData({ labels: barDateLabels, datasets: datasets })
        }
        else {

        }
        
    }

    const updateSelectedMonth = (val) => {

        setSelectedMonth(val == null ? null : val.value);
        if (val != null)
            getCategoryData(selectedRestaurant == null ? 0 : selectedRestaurant.value, val.value)
    }

    const getCategoryData = async (rest = selectedRestaurant, mnth = selectedMonth) => {
        let result = await indentService.GetAmountSpendByCategoryMonthly(rest, mnth);
        if (!result.redirect) {
            if (result.data.data.length > 0) {

                let categoryTotalAmount = 0;
                for (let m = 0; m < result.data.data.length; m++) {
                    categoryTotalAmount = categoryTotalAmount + result.data.data[m].amountSpend;
                }

                updateCategoryTotal([{
                    categoryName: 'Total Amount', amountSpend: parseFloat(categoryTotalAmount)
                    //   .toLocaleString('en-IN', {
                    //    maximumFractionDigits: 2,
                    //    style: 'currency',
                    //    currency: 'INR'
                    //})
                }])

                updateCategoryData(result.data.data);
            }
            else {
                updateCategoryData(result.data.data);
                updateCategoryTotal([{
                    categoryName: 'Total Amount', amountSpend: parseFloat(0)
                    
                }])

            }


        }
        else {

        }


    }

    const updateCategoryTotal = (rows) => {
        if (cgridRef.current != null)
            cgridRef.current.api.setPinnedBottomRowData(rows)
    }

    const pinnedBottomRowCategoryData = useMemo(() => {
        return [{
            categoryName: 'Total Amount', amountSpend: parseFloat('0.00')
                .toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            })
        }]

    }, []);

    const onGridReady = useCallback(() => {
        getCategoryData(selectedRestaurant == null ? 1 : selectedRestaurant.value, selectedMonth== null ? 0 : selectedMonth.value);

    }, []);

   



    const sizeToFit = useCallback(() => {
        if (cgridRef.current.api != undefined)
            cgridRef.current.api.sizeColumnsToFit();
    }, []);

    return (
        <Container fluid={true}>
            <Row>
                <Col sm="9"></Col>
                <Col sm="3">< UserRestaurantDefaultDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />
</Col>
            </Row>

            <Row style={{paddingTop:'5px'} }>
                <Col sm="6" >
                    <Row style={{ background: 'linear-gradient(120deg, rgb(231 155 149), rgb(182, 221, 239))', textAlign: 'center', paddingTop: '3px', marginRight: '3px', marginLeft: '3px' }} ><Col> <h4>SPEND TILL DATE BY CATEGORY</h4></Col> </Row>
                    <div style={{ height: '450px' }}>
                        <Pie data={pieChartData} options={pieOptions} />
                    </div>
                </Col>
                <Col sm="6" style={{ height: '450px' }} >
                    <Row style={{ background: 'linear-gradient(120deg, #CDDC39, rgb(182, 221, 239))', textAlign: 'center', paddingTop: '3px', marginRight: '3px', marginLeft: '3px' }} ><Col> <h4> LAST 7 DAYS INDENT SPENDS</h4></Col> </Row>
                    <div style={{ height: '450px' }}>
                        <Bar options={barOptions} data={barChartData} />
                    </div>


                </Col>
            </Row>
            <Row>
                <Col sm="6" style={{ paddingTop: '5px' }}>
                    <Row style={{ background: 'linear-gradient(120deg, #F3E5F5, #0097A7)', padding: 0 }}><Col sm="9" style={{paddingTop:'6px'} }><h4>MONTHLY SPEND BY CATEGORY</h4></Col><Col sm="3" style={{ padding: 0, paddingRight: '15px', paddingBottom: '5px',paddingTop:'5px' }}><MonthDropDown selectedMonthId={updateSelectedMonth} month={selectedMonth} /></Col></Row>
                    <div style={{ width: '100%', height: 400,paddingTop:'5px' ,paddingBottom:'5px'}} className="ag-theme-alpine" >
                        <AgGridReact rowHeight={25}
                            ref={cgridRef}
                            rowData={categoryData}
                            defaultColDef={defaultColDef}
                            columnDefs={categoryColumnDefs}
                            enableRangeSelection={true}
                            headerHeight={30}
                            onGridReady={onGridReady}
                            overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                            }
                            overlayNoRowsTemplate={
                                '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No items</span>'
                            }
                            enableCellTextSelection={true}
                            suppressCellFocus={true}
                            pinnedBottomRowData={pinnedBottomRowCategoryData}

                        >

                        </AgGridReact>
                    </div>

                </Col>

            </Row>
          
        </Container>
    )

}


export default IndentSummary;