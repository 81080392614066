import React from 'react';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoMdCloseCircle } from "react-icons/io";
import { logged } from "../../lib/logged"
import { Roles } from "../../lib/Roles"
import { SiAirtable } from "react-icons/si";
import { IconContext } from "react-icons";
import { ReservationStatus } from "../../lib/ReservationStatus";
import BookingService from "../../services/BookingService"
import { CgUnavailable } from "react-icons/cg";
import { BsFillBagXFill } from "react-icons/bs";
import { HiEmojiSad } from "react-icons/hi";
import { UncontrolledTooltip } from "reactstrap"





export function ConfirmedRenderer(props) {

    const bookingService = new BookingService();

    const BookingAction = async (bookingId, status, mobileNo = '') => {

        if (status === 'I' && mobileNo == '') {
            props.context.updateBookingId(bookingId);
            props.context.openCheckinModal();
        }
        else {

            let result = await bookingService.UpdateReservationStatus(bookingId, status, '', '', logged.UserId);
            if (!result.redirect) {
                if (result.data.status) {
                    if (status === 'I') {
                        props.context.showAlert('Check-In successfull', 'success')
                    }
                    if (status === 'X') {
                        props.context.showAlert('Booking is cancelled', 'success')
                    }

                    props.context.getBookingData();
                }
                else {
                    if (status === 'I') {
                        props.context.showAlert('Check-In failure', 'error')
                    }
                    if (status === 'X') {
                        props.context.showAlert('Booking cancellation failed', 'error')
                    }
                }
            }
            else {
                props.context.showAlert('Error occured in check-in or cancellation of the booking', 'error');
            }
        }

    }


    return (
        <React.Fragment>

            <button id="btncheckin" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0 }} onClick={() => { BookingAction(props.data.bookingId, ReservationStatus.CheckIn, props.data.mobileNo) }
            }>
                <IconContext.Provider value={{ size: '22px', color: '#216ba5', verticalAlign: 'sub' }}> < IoCheckmarkCircleSharp /></IconContext.Provider>
            </button>

            <button id="btncancel" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '10px' }} onClick={() => { props.context.updateBookingId(props.data.bookingId); props.context.openCancelModal(); }
            }>
                <IconContext.Provider value={{ size: '22px', color: '#dc3545', verticalAlign: 'sub' }}> < IoMdCloseCircle /></IconContext.Provider>
            </button>

            <button id="btnnoshow" style={{ backgroundColor: 'rgb(255 255 255 / 10%)', border: 0, paddingLeft: '10px' }} onClick={() => { BookingAction(props.data.bookingId, ReservationStatus.NoShow) }
            }>
                <IconContext.Provider value={{ size: '22px', color: 'dimgrey', verticalAlign: 'sub' }}> < HiEmojiSad /></IconContext.Provider>
            </button>

            <UncontrolledTooltip placement="left" target="btncheckin">
                Check-In
            </UncontrolledTooltip>

            <UncontrolledTooltip placement="left" target="btncancel">
                Cancel
            </UncontrolledTooltip>

            <UncontrolledTooltip placement="left" target="btnnoshow">
                No show
            </UncontrolledTooltip>

        </React.Fragment>

    )

}