import React, { useState, useEffect } from "react";
import { Navigate} from 'react-router-dom'
import { Row, Col, Button, Progress, Container } from 'reactstrap'
import SecurityService from "../services/SecurityService"
import { logged } from '../lib/logged'
import { ToastContainer, toast } from 'react-toastify';

export function Login(props) {
    const [InProgress, setInProgress] = useState(false);
    const [hasLogged, setLogged] = useState(false);
    const [loginDetails, updateLoginDetails] = useState({ loginId: '', password: '' })
    const securityService = new SecurityService();

    const validateLogin = async (e) => {
        e.preventDefault();

        setInProgress(true);
        let result = await securityService.ValidateLogin(loginDetails.loginId, loginDetails.password);
        if (!result.redirect) {
            if (result.data.status==false) {
                toast('Login Failed')
            }
            else {
                let usr = result.data.data;
                sessionStorage.setItem("K1Prms", JSON.stringify(usr));
                logged.AuthDate = usr.authDate;
                logged.IsAuth = usr.isAuth;
                logged.Name = usr.name;
                logged.UserId = usr.userId;
                logged.Roles = usr.roles;
                logged.AccessToken = usr.accessToken;
                setLogged(true);


            }
            setInProgress(false);
        }
        else {
            toast("Error Occured");
            setInProgress(false);

        }
    }

    return (
        hasLogged ? <Navigate to='/' /> : <Container fluid={true} style={{
            margin: 0,
            padding: 0,
            background: 'linear-gradient(120deg,#2980b9, #8e44ad)',
            height: '100vh',
            overflow: 'hidden'
        }}>
            <div className="center">
                <h1>RMS</h1>
                <form onSubmit={validateLogin}>
                    <div className="logintxt_field">
                        <input
                            type="text"
                            data-testid="username-text"
                            style={{ width: '100%' }}
                            value={loginDetails.loginId}
                            onChange={e => updateLoginDetails({ ...loginDetails, loginId: e.target.value })}
                            required
                        />
                        <span></span>
                        <label>Username</label>
                    </div>
                    <div className="logintxt_field">
                        <input
                            type="password"
                            data-testid="password-text"
                            style={{ width: '100%' }}
                            value={loginDetails.password}
                            onChange={e => updateLoginDetails({ ...loginDetails, password: e.target.value })}
                            required
                        />
                        <span></span>
                        <label>Password</label>
                    </div>

                    <button id="loginSubmit" type="submit" data-testid="login-button" disabled={InProgress} >Login{""}</button>

                </form>
                {InProgress ? (<div>
                    <Row style={{ paddingTop: '25px' }}>
                        <Col sm="3" />
                        <Col sm="6" >
                            <span style={{ paddingLeft:'15px' }}> LOGIN IN PROGRESS...</span>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3" />
                        <Col sm="6" >
                            <Progress animated color="info" value="100" />
                        </Col>
                    </Row></div>) : (null)
                }
            </div>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

        </Container>
        

        )
}