import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import AdministrationService from "../../services/AdministrationService";
import { RestaurantSupplierDropDown } from "../Controls/RestaurantSupplierDropDown"
import { Row, Col, Button, Container } from 'reactstrap'
import Modal from "react-modal";
import { logged } from "../../lib/logged"
import { RemoveMappedDepartment } from './RemoveMappedDepartment'
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from '../Controls/UserRestaurantDropDown';
import { DepartmentDropDown } from "../Controls/DepartmentDropDown";
import { SubDepartmentDropDown } from "../Controls/SubDepartmentDropDown";
import { ToastContainer, toast } from 'react-toastify';

const GroceryItemDepartments = (props) => {

    useEffect(() => {
        GetGroceryItemDepartments();

    }, []);




    const administrationService = new AdministrationService()
    const [restaurantDepartments, updateRestaurantDepartments] = useState([]);
    const [selectedRestaurant, updateSelectedRestaurant] = useState(null);
    const [selectedDepartment, updateSelectedDepartment] = useState(null);
    const [selectedSubDepartment, updateSelectedSubDepartment] = useState(null);
    const [message, setMessage] = useState('')
    const [messageModalIsOpen, setMessageModalIsOpen] = React.useState(false);
    const gridRef = useRef(null);

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);


    useEffect(() => {
        sizeToFit();
    }, [restaurantDepartments])

    const [columnDefs, setColumnDefs] = useState([
        { headerName: '', floatingFilter: false, cellRenderer: RemoveMappedDepartment },
        { field: 'id', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'departmentId', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'restaurantId', headerName: '', filter: "agTextColumnFilter", hide: true },
        { field: 'restaurantName', headerName: 'Restaurant', filter: "agTextColumnFilter" },
        { field: 'departmentName', headerName: 'Department', filter: "agTextColumnFilter" },






    ]);

    const onRestaurantSelectionChange = (val) => {
        updateSelectedRestaurant(val);
        updateSelectedDepartment(null);
    }

    const onDepartmentSelectionChange = (val) => {

        updateSelectedDepartment(val);
        updateSelectedSubDepartment(null);

    }


    const onSubDepartmentSelectionChange = (val) => {

        updateSelectedSubDepartment(val);

    }


    const addrows = useCallback((gitems, addIndex) => {
        const newItems = [
            gitems
        ];


        gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: addIndex,
        });

    }, []);




    const getGridData = () => {

        let gdata = []
        gridRef.current.api.forEachNode(node => gdata.push(node.data));
        return gdata;

    }





    const onItemAdd = () => {

        let isValid = validateForm();
        if (isValid) {



            let itemFound = false;
            let itemDepartments = getGridData();
            let nextListId = 0;
            if (itemDepartments.length == 0) {
                nextListId = 1
            }
            else {
                let maxListId = Math.max(...itemDepartments.map(o => o.id));
                nextListId = maxListId + 1;
            }


            for (let i = 0; i < itemDepartments.length; i++) {
                if (itemDepartments[i].restaurantId === selectedRestaurant.value && itemDepartments[i].departmentId == (selectedDepartment.value == 1 ? selectedSubDepartment.value : selectedDepartment.value)) {
                    itemFound = true
                    break;
                }

            }
            if (!itemFound) {

                addrows({ id: nextListId, groceryItemDepartmentId: 0, itemId: props.itemId, departmentId: selectedDepartment.value == 1 ? selectedSubDepartment.value : selectedDepartment.value, restaurantId: selectedRestaurant.value, departmentName: selectedDepartment.value == 1 ? selectedSubDepartment.label: selectedDepartment.label, restaurantName: selectedRestaurant.label }, undefined)
                updateSelectedRestaurant(null);
                updateSelectedDepartment(null);
                updateSelectedSubDepartment(null);


            }
            else {
                toast.info('The item is already in the list');
            }
        }


    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);

    const [close, setClose] = useState(false);


    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: false
    })

    const onGridReady = useCallback(() => {


    }, []);

    const messageModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
            maxHeight: '75%',
            width: '30%',
            paddingRight: '13px',
            paddingLeft: '13px',
            paddingTop: '0px',
            paddingBottom: '5px'
        },
    };


    const openMessageModal = () => {
        setMessageModalIsOpen(true)
    }

    const closeMessageModal = () => {
        setMessageModalIsOpen(false)
    }


    const onRemoveSelected = useCallback((selectedData) => {
        gridRef.current.api.applyTransaction({ remove: selectedData });

    }, []);


    function removeDepartmentMap(data) {
        onRemoveSelected([data]);
    }



    const onClose = () => {
        setClose(true);
    }





    const validateForm = () => {

        if (selectedRestaurant == null) {
            toast.error('Please select a restaurant')
            return false;
        }
        else if (selectedDepartment == null) {
            toast.error('Please select a department')
            return false;
        }
        else if (selectedDepartment.value == 1 && selectedSubDepartment==null) {
            toast.error('Please select a sub department')
            return false;
        }

        return true;
    }


    const getRowId = useMemo(() => {
        return (params) => {
            return params.data.id;
        };
    }, []);


    const addUpdateItems = async () => {

        setMessage('SAVING IN PROGRESS');
        openMessageModal();
        let _grocerySuppliers = getGridData();

        let result = await administrationService.SaveDepartmentMapping(props.itemId, _grocerySuppliers, logged.UserId);
        if (!result.redirect) {
            if (result.data.status) {
                closeMessageModal();
                toast("Department details has been updated")

            }
            else {
                closeMessageModal();
                toast("Failure in updating department details")
            }
        }
        else {
            closeMessageModal();
            toast('Error occured while updating department details', 'error');
        }

    }



    const GetGroceryItemDepartments = async () => {
        let result = await administrationService.GetGroceryItemDepartments(props.itemId);
        if (!result.redirect) {
            updateRestaurantDepartments(result.data.data);
        }
        else {
            toast.error('Error occured in fetching item departments');
        }

    }


    return (
        <Container>
            <form >



                <Row style={{ paddingTop: '15px' }}>

                    <Col sm="4" style={{ textAlign: 'left' }}>
                        <label>
                            Choose Restaurant{` : `}

                        </label>
                        < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant} />

                    </Col>
                    <Col sm="3">
                        <label>
                            Choose Department{` : `}

                        </label>
                        <DepartmentDropDown key={selectedRestaurant != null ? selectedRestaurant.value : 0} selectedDepartmentId={onDepartmentSelectionChange} selectedRestaurantId={selectedRestaurant != null ? selectedRestaurant.value : 0} department={selectedDepartment != null ? selectedDepartment.value:null} />


                    </Col>
                    {selectedDepartment !== null && selectedDepartment.value== 1 ? <Col sm="3">
                        <label>
                            Choose Sub Department{` : `}

                        </label>
                        <SubDepartmentDropDown key={selectedDepartment != null ? selectedDepartment.value : 0} selectedSubDepartmentId={onSubDepartmentSelectionChange} selectedDepartmentId={selectedDepartment != null ? selectedDepartment.value : null} subDepartment={selectedSubDepartment != null ? selectedSubDepartment.value:null} />


                    </Col> : <Col sm="3"></Col>}
                    <Col sm="2">

                        <Button style={{ marginRight: '5px', marginTop: '23px' }} color="success" size='sm' onClick={onItemAdd} >ADD{""}</Button>

                    </Col>
                </Row>






                <Row style={{ paddingTop: '15px' }}>


                    <Col sm="10" style={{ margin: 0, padding: 0, marginTop: '3px' }}>

                        <div style={{ width: '100%', height: 300 }} className="ag-theme-alpine" >
                            <AgGridReact rowHeight={32}
                                ref={gridRef}
                                rowData={restaurantDepartments}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                context={{ removeDepartmentMap }}
                                getRowId={getRowId}
                                animateRows={true}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while items are loaded </span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Please add items to be ordered</span>'
                                }

                                suppressCellFocus={true} >

                            </AgGridReact>
                        </div>

                    </Col>
                </Row>


                <Row style={{ marginTop: '15px' }}>

                    <Col sm="12" style={{ textAlign: 'center' }}>
                        <Button style={{ marginRight: '5px' }} color="primary" size='sm' onClick={addUpdateItems} >Save{""}</Button>
                        <Button style={{ marginRight: '5px' }} color="secondary" size='sm' onClick={() => props.modalClose()} >Close{""}</Button>
                    </Col>


                </Row>






                <Modal isOpen={messageModalIsOpen} style={messageModalStyles}
                    conetentLable="Message" ariaHideApp={false}
                >
                    <Row style={{ background: 'linear-gradient(120deg,#2980b9, #8e44ad)' }} >
                        <Col sm='12' style={{ color: 'white', fontWeight: 'bolder', textAlign: 'center' }}>{message}</Col>
                    </Row>

                    <Row ><Col sm='12' style={{ textAlign: 'center' }}><img src='./img/Loading.gif' width='150px' /></Col></Row>
                </Modal>

            </form>

            {/*<ToastContainer*/}
            {/*    position="top-center"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*    theme="dark"*/}
            {/*/>*/}


        </Container>
    );
};

export default GroceryItemDepartments;