import React, { useState } from "react";


export function ItemUnitCountRenderer(props) {

    const [itemPriceUnit, setItemPriceUnit] = useState(props.data.itemPriceUnit);

return(
    <input
        type="text"
        value={itemPriceUnit}
        style={{height:'25px',width:'60px'} }
        onChange={e => {
            setItemPriceUnit(e.target.value);
            props.context.updatePriceUnitCount(e.target.value, props.data.id);
        }}
        />
)
}