import React, { useState, useEffect } from "react";
//import AdministrationService from "../../services/AdministrationService"
import Select from 'react-select'



export function TimeDropDown(props) {

   // const administrationService = new AdministrationService();

    const [time, updateTime] = useState([]);
   // const [selectedTime, updateSelectedTime] = useState(props.time);

    useEffect(() => { getTimes(); }, [])

    const getTimes = async () => {
        let result = [

            { label: '12:00', value: '12:00' },
            { label: '12:15', value: '12:15' },
            { label: '12:30', value: '12:30' },
            { label: '12:45', value: '12:45' },
            { label: '13:00', value: '13:00' },
            { label: '13:15', value: '13:15' },
            { label: '13:30', value: '13:30' },
            { label: '13:45', value: '13:45' },
            { label: '14:00', value: '14:00' },
            { label: '14:15', value: '14:15' },
            { label: '14:30', value: '14:30' },
            { label: '14:45', value: '14:45' },
            { label: '15:00', value: '15:00' },
            { label: '15:15', value: '15:15' },
            { label: '15:30', value: '15:30' },
            { label: '15:45', value: '15:45' },
            { label: '16:00', value: '16:00' },
            { label: '16:15', value: '16:15' },
            { label: '16:30', value: '16:30' },
            { label: '16:45', value: '16:45' },
            { label: '17:00', value: '17:00' },
            { label: '17:15', value: '17:15' },
            { label: '17:30', value: '17:30' },
            { label: '17:45', value: '17:45' },
            { label: '18:00', value: '18:00' },
            { label: '18:15', value: '18:15' },
            { label: '18:30', value: '18:30' },
            { label: '18:45', value: '18:45' },
            { label: '19:00', value: '19:00' },
            { label: '19:15', value: '19:15' },
            { label: '19:30', value: '19:30' },
            { label: '19:45', value: '19:45' },
            { label: '20:00', value: '20:00' },
            { label: '20:15', value: '20:15' },
            { label: '20:30', value: '20:30' },
            { label: '20:45', value: '20:45' },
            { label: '21:00', value: '21:00' },
            { label: '21:15', value: '21:15' },
            { label: '21:30', value: '21:30' },
            { label: '21:45', value: '21:45' },
            { label: '22:00', value: '22:00' },
            { label: '22:15', value: '22:15' },
            { label: '22:30', value: '22:30' },
            { label: '22:45', value: '22:45' },
            { label: '23:00', value: '23:00' }


        ]
   

            updateTime(result);

        
        
    }

    const onSelectChange = (val, action) => {
        //updateSelectedTime(val);
        //if (action.action == 'clear')
            props.selectedTimeId(val)
        //else
        //    props.selectedTimeId(val.value)
    }


    return (
        <Select options={time} isClearable={true} onChange={onSelectChange} value={props.time == null ? null: time.find(o => o.value === props.time)} />
    );



}