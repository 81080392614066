import React, { useState, useEffect } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { IoCopy } from "react-icons/io5";
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas';
import { IconContext } from "react-icons";
import { Navigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function GenerateQRCode(props) {
    let alertOptions = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    }
    const [isClose, setIsClose] = useState(false)
    const onCopy =  () => {
        html2canvas(document.querySelector("#booking")).then(function (canvas) {
            //document.body.appendChild(canvas);
            canvas.toBlob( function (blob) {
                 navigator.clipboard
                    .write([
                        new window.ClipboardItem(
                            Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                            })
                        )
                    ])
                    .then(function () {
                        window.open('https://wa.me/91' + props.MobileNo, '_blank');

                    });
            });
        });




    }
    const onSimpleCopy = () => {
        html2canvas(document.querySelector("#booking")).then(function (canvas) {
            canvas.toBlob(function (blob) {
                navigator.clipboard
                    .write([
                        new window.ClipboardItem(
                            Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                            })
                        )
                    ])
                    .then(function () {
                        toast('Copied', alertOptions)
                    });
            });
        });


    }
    const onClose = () => {
        setIsClose(true)
    }
    return (
        isClose ? <Navigate to='/bookings' />:  <Container>
            <Row style={{ textAlign: "right" }}><Col><Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '36px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col></Row>
            <Row style={{ textAlign: "right" }}><Col sm="8" ><Button style={{ backgroundColor: 'white', border: 0 }} onClick={onSimpleCopy}> <IconContext.Provider value={{ size: '24px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button></Col></Row>

            <Row >
                <Col sm="4"></Col>
                <Col sm="4">
                    <div id="booking">
                        <Row style={{ textAlign: 'center', color: 'green', paddingTop: '5px', fontSize: 'medium', fontWeight:900 }}><Col>Booking is confirmed</Col></Row>
                        <Row style={{ textAlign: 'center', color: 'blue', paddingTop: '3px', fontWeight: 600 }}><Col>Booking Id {` : `}{props.BookingId}</Col></Row>
                        <Row style={{ textAlign: 'center', color: 'blue', paddingTop: '3px', fontWeight: 500 }}><Col>No Of Guests {` : `}{props.NoOfPax}</Col></Row>
                        
                <Row style={{ textAlign: 'center', color:'blue',paddingTop: '3px', fontSize:'medium' }}><Col>{`Reserved For`}</Col></Row>
                        <Row style={{ textAlign: 'center', color: 'blue', paddingTop: '3px', fontSize: '14px', fontWeight: 600 }}><Col>{props.ReservationDate}{` @ `} {props.ReservationTime}</Col></Row>

                <Row style={{ height: "auto", margin: "0 auto", maxWidth: 192, width: "100%",paddingTop:'15px' }}>
                 <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={String(props.BookingId)}
                    viewBox={`0 0 256 256`}
                />
                </Row>
                        <Row style={{ textAlign: 'center', paddingTop: '5px', color: 'crimson', fontSize: '24px', fontFamily: 'ui-sans-serif' }}><Col>{props.Restaurant}</Col></Row>
                    </div>
                </Col>
            </Row>
           

            <Row style={{ textAlign: "center",paddingTop:'30px' }}><Col><Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '60px', color: 'green' }}> < IoLogoWhatsapp /></IconContext.Provider></Button></Col></Row>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

        </Container>
        )
}