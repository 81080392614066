import React, { useState, useEffect } from "react";
import IndentService from "../../services/IndentService"
import Select from 'react-select'



export function IndentTypeDropDown(props) {

    const indentService = new IndentService();

    const [indentType, updateIndentType] = useState([]);
  

    useEffect(() => { getIndentType(); }, [])

    const getIndentType = async () => {
        let result = await indentService.GetIndentTypes();
        if (!result.redirect) {

            updateIndentType(result.data);

        }
        else {
            alert("Error Occured");
        }
    }

    const onSelectChange = (val, action) => {
    
            props.selectedIndentId(val)
     
    }


    return (
        <Select options={indentType} isClearable={true} onChange={onSelectChange} value={props.type == null ? null : indentType.find(o => o.value === props.type)} />


    
    );



}