
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import BookingService from "../../services/BookingService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import { logged } from "../../lib/logged"
import { CancelledRenderer } from "./CancelledRenderer"


import * as XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";



export function CancelledBooking(props) {

    const bookingService = new BookingService();

    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [bookingData, updateBookingData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        { cellRenderer: CancelledRenderer, headerName: '', width: 70 },
        { field: 'bookingId', headerName: 'Id', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true, cellRenderer: (d) => { return <Link to={`/bookings/d/${d.data.bookingId}`}>{d.data.bookingId}</Link> } },
        { field: 'reservationTime', headerName: 'Time', width: 100, sortable: true },
        { field: 'name', headerName: 'Name', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'Mobile No', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'noOfPax', headerName: 'PAX', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'ocassion', headerName: 'Ocassion', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'restaurant', headerName: 'Restaurant', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'source', headerName: 'Source', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'externalBookingId', headerName: 'Source Booking Id', width: 175, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        { field: 'package', headerName: 'Package', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'specialInstruction', headerName: 'Instruction', width: 160, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },

        { field: 'cancelComments', headerName: 'Comments', width: 150, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'bookedBy', headerName: 'Booked By', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'bookedOn', headerName: 'Booked On', width: 120, sortable: true, cellRenderer: (d) => { return moment(d.data.bookedOn).format("DD/MM/YY H:mm:ss") } },
        { field: 'modifiedBy', headerName: 'Modified By', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'modifiedOn', headerName: 'Modified On', width: 120, sortable: true, cellRenderer: (d) => {
                if (d.data.modifiedOn != null)
                    return moment(d.data.modifiedOn).format("DD/MM/YY H:mm:ss")
                else
                    return d.data.modifiedOn
            }
        }

    ]);

    const showAlert = (message, type) => {

        if (type == 'error') {
            toast.error(message);
        }
        else if (type == 'success') {
            toast.success(message);
        }
    }

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })

    const onGridReady = useCallback((params) => {
        getBookingData();
    }, []);


    const getBookingData = async () => {

        showLoading();
        let result = await bookingService.GetCancelledBookings(logged.UserId)
        if (!result.redirect) {
            let rptData = result.data.data
            updateBookingData(rptData);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching report data');
            hideLoading();
        }
        props.refreshStatusCount();

    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "CancelledBookings" });
    }, []);




    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>


            <Row style={{ paddingTop: '25px', }}>
                <Col sm="4"></Col>
                <Col sm="4" style={{ textAlign: 'center', color: 'darkblue', fontWeight: 700 }}>CANCELLED BOOKINGS</Col>

                <Col sm="4" style={{ textAlign: 'right' }}>
                    {/*<Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>*/}

                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>
            </Row>
            <div id="ActiveBooking">

                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={32}
                                ref={gridRef}
                                rowData={bookingData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                context={{ showAlert, getBookingData }}
                                onGridReady={onGridReady}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }

                                suppressCellFocus={true}
                                enableCellTextSelection={true}
                            >

                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
