import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import { Navigate, Link } from 'react-router-dom'
import AdministrationService from "../../services/AdministrationService"
import { AgGridReact } from 'ag-grid-react';
import { logged } from "../../lib/logged"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import 'react-toastify/dist/ReactToastify.css';
import { AddOcassion } from './AddOcassion';


export function Ocassion(props) {

    const administrationService = new AdministrationService();







    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '500px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [InProgress, setInProgress] = useState(false);
    const [ocassions, setOcassions] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        { field: 'value', headerName: 'Ocassion Id', width: 300, filter: "agTextColumnFilter", floatingFilter: true, width: 200 },
        {
            field: 'label', headerName: 'Ocassion Name', filter: "agTextColumnFilter", floatingFilter: true, width: 275
        },
        { headerName: 'Inactivate', width: 200, cellRenderer: (d) => { return <Button onClick={() => { onInactivate(d.data.value); }} style={{ backgroundColor: '#ff1200', padding: '2px', margin: 0, marginBottom: '6px', visibility: 'visible' }} disabled={false} color="danger" size="sm" > Inactivate</Button> }, floatingFilter: false },

        
    ]);

    const onInactivate = async (ocassionId) => {
        toast.info(ocassionId);
        let result = await administrationService.InactivateOcassion(ocassionId);
        if (!result.redirect) {
            toast.success('Occasion has been inactivated successfully')
            getOcassions();
        }
        else {
            toast.error("Error occured while inactivating the occasion");

        }
    }
    useEffect(() => {
        sizeToFit();
    }, [ocassions])

    const sizeToFit = useCallback(() => {
        if (gridRef.current.api != undefined)
            gridRef.current.api.sizeColumnsToFit();
    }, []);
    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
        editable: true
    })

    const onGridReady = useCallback(() => {
        getOcassions();
    }, []);

    const getOcassions = async () => {
        showLoading();
        let result = await administrationService.GetOcassions();
        if (!result.redirect) {

            setOcassions(result.data);
            hideLoading();
        }
        else {
            toast.error('Error occured in fetching Occasion details');
            hideLoading();
        }
    }

    const onAdd = () => {
        setIsAdd(true);
    }

    const showAlert = (message, type) => {

        if (type === 'error') {
            toast.error(message);
        }
        else if (type === 'success') {
            toast.success(message);
        }
    }



    return (


        isAdd ? <AddOcassion /> : <Container style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> OCCASION </Col>
                <Col sm="2" style={{ textAlign: 'right' }}>  <Button color="primary" size="sm" onClick={onAdd} disabled={InProgress} >Add{""}</Button></Col>
            </Row>
            <div style={containerStyle}>

                <div style={gridStyle} className="ag-theme-alpine" >
                    <AgGridReact rowHeight={32}
                        ref={gridRef}
                        rowData={ocassions}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        context={{ showAlert, getOcassions }}
                        onGridReady={onGridReady}
                        overlayLoadingTemplate={
                            '<span class="ag-overlay-loading-center">Please wait while Occasions are loading</span>'
                        }
                        overlayNoRowsTemplate={
                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No Occasions bookings to show</span>'
                        }

                        suppressCellFocus={true} >

                    </AgGridReact>
                </div>
            </div>


            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

        </Container>
    )

}