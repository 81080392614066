
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Row, Col, Button, Progress, Container, Collapse, Card, CardHeader, CardBody } from 'reactstrap'
import moment from "moment";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { UserRestaurantDropDown } from "../Controls/UserRestaurantDropDown"
import { IoLogoWhatsapp, IoIosCloseCircle } from "react-icons/io";
import { GrDocumentCsv } from "react-icons/gr";
import { IoCopy } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { IconContext } from "react-icons";
import ReportService from "../../services/ReportService"
import { Navigate, Link } from 'react-router-dom'
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from "xlsx";

import "react-datepicker/dist/react-datepicker.css";



export function BookingReport(props) {

    const reportService = new ReportService();


    const [reservationStartDate, setReservationStartDate] = useState(null);
    const [reservationEndDate, setReservationEndDate] = useState(null);

    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const onRestaurantSelectionChange = (val) => {
        setSelectedRestaurant(val);
        updateBookingData([]);
    }

    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '450px', width: '100%' }), []);
    const gridRef = useRef(null);

    const [bookingData, updateBookingData] = useState([]);
    const [isClose, updateIsClose] = useState(false);
    const [columnDefs, setColumnDefs] = useState([

        { field: 'bookingId', headerName: 'Id', width: 100, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'restaurant', headerName: 'Restaurant', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        {
            field: 'source', headerName: 'Source', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true
        },
        { field: 'name', headerName: 'Name', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'mobileNo', headerName: 'Mobile No', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        {
            field: 'bookingStatus', headerName: 'Status', width: 140, sortable: true,
            cellStyle: (p) => {
                if (p.node.data.bookingStatus == 'Confirmed') return { color: "green", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Waitlisted') return { color: "brown", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Check-In') return { color: "#216ba5", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'Cancelled') return { color: "red", fontWeight: 600 }
                else if (p.node.data.bookingStatus == 'NoShow') return { color: "deepskyblue", fontWeight: 600 }

            }, filter: "agTextColumnFilter", floatingFilter: true
        },
        { field: 'noOfPax', headerName: 'PAX', width: 90, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'ocassion', headerName: 'Ocassion', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'package', headerName: 'Package', width: 140, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'reservationDate', headerName: 'Date', width: 110, cellRenderer: (d) => { return moment(d.data.reservationDate).format("DD/MM/YY") } },
        { field: 'reservationTime', headerName: 'Time', width: 100, sortable: true },
        { field: 'tableNo', headerName: 'Table', width: 90, sortable: true },
        { field: 'bookedBy', headerName: 'Booked By', width: 120, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'modifiedBy', headerName: 'Modified By', width: 130, filter: "agTextColumnFilter", floatingFilter: true, sortable: true },
        { field: 'createDate', headerName: 'Create Date', width: 150, cellRenderer: (d) => { return d.data.createDate != null? moment(d.data.createDate).format("DD/MM/YY h:mm:ss A"):'' } },
        { field: 'modifiedDate', headerName: 'Modify Date', width: 150, cellRenderer: (d) => { return d.data.modifiedDate != null ? moment(d.data.modifiedDate).format("DD/MM/YY h:mm:ss A"):'' } }

    ]);

    const showLoading = useCallback(() => {
        gridRef.current.api.showLoadingOverlay();
    }, []);

    const showNoRows = useCallback(() => {
        gridRef.current.api.showNoRowsOverlay();
    }, []);

    const hideLoading = useCallback(() => {
        gridRef.current.api.hideOverlay();
    }, []);



    const [defaultColDef, setDefaultColDef] = useState({
        resizable: true,
    })



    const getBookingData = async () => {
        if (isInputValid()) {
            showLoading();
            let result = await reportService.GetBookingData(selectedRestaurant.value, reservationStartDate,reservationEndDate);
            if (!result.redirect) {
                let rptData = result.data.data
                updateBookingData(rptData);
                hideLoading();
            }
            else {
                toast.error('Error occured in fetching report data');
                hideLoading();
            }
        }
    }

    const onClose = () => {
        updateIsClose(true);
    }


    const onExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv({ fileName: "BookingReport" });
    }, []);

    const isInputValid = () => {
        let isAfter = false;
        if (reservationStartDate !== null && reservationEndDate !== null) {
            isAfter = moment(reservationEndDate).isSameOrAfter(reservationStartDate);

        }

        if (selectedRestaurant == null) {
            toast.error('Please choose a restaurant')
            return false;
        }
        else if (reservationStartDate == null) {
            toast.error('Please choose a start date')
            return false;
        }
        else if (reservationEndDate == null) {
            toast.error('Please choose a end date')
            return false;
        }
        else if (!isAfter) {
            toast.error('End date cannot be lesser than the Start date')
            return false;
        }

        return true;
    }


    return (
        isClose ? <Navigate to='/reports' /> : <Container fluid={true} style={{ backgroundColor: "white", height: "100%" }}>

            <Row style={{ paddingBottom: '20px' }}>
                <Col sm="10" style={{ color: 'darkblue', fontWeight: 'bold' }}> BOOKING REPORT </Col>
                <Col sm="2" style={{ textAlign: 'right' }}> <Button style={{ backgroundColor: 'white', border: 0 }} onClick={onClose}> <IconContext.Provider value={{ size: '28px', color: '#1b6ec2' }}> < IoIosCloseCircle /></IconContext.Provider></Button></Col>
            </Row>
            <Row>


                <Col sm="1" style={{padding:0,paddingLeft:'10px'} } >
                    <label className="required-fields" >
                        Restaurant {` : `}

                    </label>

                </Col>
                <Col sm="2" >
                    < UserRestaurantDropDown selectedRestaurantId={onRestaurantSelectionChange} restaurant={selectedRestaurant != null ? selectedRestaurant.value : selectedRestaurant} />
                </Col>


                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        Start Date {` : `}

                    </label>
                </Col>


                <Col sm="2">
                    <DatePicker selected={reservationStartDate} onChange={(date) => { setReservationStartDate(date); updateBookingData([]); }} />
                </Col>

                <Col sm="2" style={{ textAlign: 'right' }}>
                    <label className="required-fields">
                        End Date {` : `}

                    </label>
                </Col>


                <Col sm="2">
                    <DatePicker selected={reservationEndDate} onChange={(date) => { setReservationEndDate(date); updateBookingData([]); } } />
                </Col>

                <Col sm="1" >
                    <Button color="primary" size='sm' onClick={getBookingData}>Submit</Button>
                </Col>

            </Row>

            <Row style={{ paddingTop: '25px', }}>
                <Col sm="2"></Col>
                <Col sm="8" style={{ textAlign: 'right' }}>
                    {/*<Button style={{ backgroundColor: 'white', border: 0 }} onClick={onCopy}> <IconContext.Provider value={{ size: '22px', color: '#6c757d' }}> < IoCopy /></IconContext.Provider></Button>*/}

                    <Button style={{ backgroundColor: 'white', border: 0, marginLeft: '5px' }} onClick={onExport}> <IconContext.Provider value={{ size: '22px', color: 'green' }}> < GrDocumentCsv /></IconContext.Provider></Button>

                </Col>
                <Col sm="2" ></Col>
            </Row>
            <div id="bookingreport">
                {/*<Row>*/}

                {/*     <Col sm="12" style={{ textAlign: 'center' }}> <span style={{ color: 'black', fontWeight: 700 }}>BOOKING REPORT</span></Col>*/}
                {/* </Row> */}

                {/*<Row>*/}

                {/*    <Col sm="2" style={{ textAlign: 'left' }}> <span style={{ fontWeight: 700, color: "#6c757d" }}>{selectedRestaurant == null ? '' : selectedRestaurant.label}</span>{`     `} <span style={{ paddingLeft: '50px' }}>{reservationStartDate == null ? '' : ``}</span> </Col>*/}
                {/*    <Col sm="8"  ></Col>*/}
                {/*    <Col sm="2" style={{ textAlign: 'right' }} ><span style={{ fontWeight: 700, color: "#6c757d" }}>{reservationStartDate == null ? '' : moment(reservationStartDate).format(moment.HTML5_FMT.DATE)}</span> </Col>*/}
                {/*</Row>*/}
                <Row style={{ paddingTop: '10px' }}>
                    <Col sm="12">


                        <div style={gridStyle} className="ag-theme-balham">
                            <AgGridReact rowHeight={22}
                                ref={gridRef}
                                rowData={bookingData}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefs}
                                overlayLoadingTemplate={
                                    '<span class="ag-overlay-loading-center">Please wait while report is loading</span>'
                                }
                                overlayNoRowsTemplate={
                                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data to show</span>'
                                }

                                suppressCellFocus={true}
                                enableCellTextSelection={true }
                            >

                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </Container>
    )
}
